import { Pipe, PipeTransform } from '@angular/core';
import { SelectionOption } from '@core/models';

@Pipe({
    name: 'employeeselectionoptionsubtext'
})

export class EmployeeselectionoptionsubtextPipe implements PipeTransform  {
  transform(employee: SelectionOption, showCompany: boolean): string {
    var subtext: string = "";

    if (!!employee.workEmail) {
        subtext = employee.workEmail;
  
        if (!!employee.department || (showCompany && !!employee.companyName)) {
          subtext += ",";
        }
      }

      if (!!employee.personalEmail) {
        subtext = employee.personalEmail;
  
        if (!!employee.department || (showCompany && !!employee.companyName)) {
          subtext += ",";
        }
      }
  
      if (!!employee.department) {
        if (subtext.length > 0) {
          subtext += " ";
        }
  
        subtext += `in ${ employee.department }`;
      }
      
      if (showCompany && !!employee.companyName) {
        if (subtext.length > 0) {
          subtext += " ";
        }
  
        subtext += `at ${ employee.companyName }`;
      }
  

    return subtext;
  }
}
