<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6 class="grey1">{{data.sysText.assignCaseAdminDialog_title | titlecase}}</h6>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
  </ng-container>
</div> 

<mat-dialog-content>
  <app-autocomplete-field
    [icon]="'person_add'"
    [label]="data.sysText.assignCaseAdminDialog_search"
    [control]="employeeId"
    [options]="this.data.options"
    [showSearchIcon]="true"
    [showImage]="true">
  </app-autocomplete-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!(isSubmitting$ | async); else saving" class="actions">
  <button
    mat-stroked-button
    class="button"
    color="accent"
    (click)="close()"
    [disabled]="isSubmitting$ | async"
    *ngIf="!isMobile">
    {{data.sysText.cancel}}
  </button>
  <button 
    mat-stroked-button 
    class="button" 
    color="accent" 
    (click)="assign()" 
    [disabled]="(isSubmitting$ | async)">
    {{data.sysText.assign}}
  </button>
</mat-dialog-actions>

<ng-template #saving>
  <mat-dialog-actions class="actions">
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>
