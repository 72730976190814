import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { EmployeeRecordStoreService } from './employee-record-store.service';
import { EmployerProfileStoreService } from './employer-profile-store.service';
import { HomeStoreService } from './home-store.service';
import { LeaveAdminDashboardFormService } from './leave-admin-dashboard-form.service';
import { LeaveAdminDashboardStoreService } from './leave-admin-dashboard-store.service';
import { LeaveAdminStoreService } from './leave-admin-store.service';
import { ManageEmployeesDashboardStoreService } from './manage-employees-dashboard-store.service';
import { ManageEmployersDashboardStoreService } from './manage-employers-dashboard-store.service';
import { MyDocumentsService } from './my-documents.service';
import { RedirectService } from './redirect.service';
import { ReportsStoreService } from './reports-store.service';
import { RoleAdminStoreService } from './role-admin-store.service';
import { CaseCommunicationStoreService } from './case-communication.store.service';
import { Observable, Subject } from 'rxjs';
import { WorkScheduleDetailsStoreService } from './work-schedule-details.store.service';
import { WorkSchedulesDashboardStoreService } from './work-schedules-dashboard.store.service';

@Injectable({
  providedIn: 'root'
})
export class TearDownService {
  private tearDownTriggerSub: Subject<boolean>;
  private tearDownTriggerObs$: Observable<boolean>;

  constructor(
    private laFormService: LeaveAdminDashboardFormService,
    private laDashboardStore: LeaveAdminDashboardStoreService,
    private laStore: LeaveAdminStoreService,
    private myDocumentsService: MyDocumentsService,
    private roleAdminStore: RoleAdminStoreService,
    private manageEmployersStore: ManageEmployersDashboardStoreService,
    private employerProfileStore: EmployerProfileStoreService,
    private reportsStore: ReportsStoreService,
    private homeStore: HomeStoreService,
    private accountService: AccountService,
    private redirectService: RedirectService,
    private manageEmployeeStore: ManageEmployeesDashboardStoreService,
    private employeeRecordStore: EmployeeRecordStoreService,
    private caseComStore: CaseCommunicationStoreService,
    private workSchedDash: WorkSchedulesDashboardStoreService,
    private workSchedDetailsStore: WorkScheduleDetailsStoreService
  ) {
    this.tearDownTriggerSub = new Subject<boolean>();
    this.tearDownTriggerObs$ = this.tearDownTriggerSub.asObservable();
  }

  public get tearDownTrigger$(): Observable<boolean> {
    return this.tearDownTriggerObs$;
  }

  public tearDown() {
    this.laFormService.unloadStore();
    this.laDashboardStore.unloadStore();
    this.laStore.unloadStore();
    this.myDocumentsService.unloadStore();
    this.roleAdminStore.unloadStore();
    this.manageEmployersStore.unloadStore();
    this.employerProfileStore.unloadStore();
    this.reportsStore.unloadStore();
    this.homeStore.unloadStore();
    this.manageEmployeeStore.unloadStore();
    this.employeeRecordStore.unloadStore();
    this.caseComStore.unloadStore();
    this.workSchedDash.unloadStore();
    this.workSchedDetailsStore.unloadStore();
    this.accountService.resetData();
    this.redirectService.resetRedirectUrl();
    this.tearDownTriggerSub.next(true);
  }
}
