<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6 class="grey1">{{ data.sysText.closeCase.closeCase_title | titlecase }}</h6>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
  </ng-container>
</div>
  
<mat-dialog-content>
  <ng-container *ngIf="!canClose">
    <p class="body2 bottom-margin">{{ data.sysText.closeCase.canNotCloseMessage }}</p>
  </ng-container>

  <ng-container *ngIf="canClose">
    <p class="body2 bottom-margin">{{ data.sysText.closeCase.infoMessage }}</p>
    <p class="body2 bottom-margin">{{ data.sysText.closeCase.infoMessage2 }}</p>
    <form [formGroup]="form">
      <app-date-field
        [control]="this.form.controls.closeDate"
        [icon]="'today'"
        [label]="data.sysText.closeCase.datePickerCaption"
        (emitDateChange)="closeDateChangeHandler()">
      </app-date-field>
      <app-select-field
        [control]="this.form.controls.closeReasonId"
        [icon]="'help_center'"
        [label]="data.sysText.closeCase.closeReasonCaption"
        [options]="data.ccdata.closeReasons"
        (emitSelectionChange)="closeReasonChangeHandler()">
      </app-select-field>
      <app-text-area
        [control]="this.form.controls.comments"
        [label]="data.sysText.closeCase.closeCommentsCaption"
        [icon]="'comment'">
      </app-text-area>
    </form>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="!data.ccdata.isAnonymous">
    <app-slide-toggle
      [control]="form.controls.notifyEmployee"
      [isDisabled]="(isSaving$ | async)"
      [label]="data.sysText.closeCase.closeCase_notifyEmployee">
    </app-slide-toggle>
  </ng-container>
  <ng-container *ngIf="!(isSaving$ | async); else saving">
    <div>
      <button
        mat-stroked-button
        class="button"
        color="accent"
        (click)="cancel()"
        [disabled]="isSaving$ | async"
        *ngIf="!isMobile">
        {{ data.sysText.closeCase.cancel }}
      </button>
      <button 
        mat-stroked-button 
        class="button" 
        color="accent" 
        (click)="closeCase()" 
        [disabled]="!canClose || (isSaving$ | async)">
        {{ data.sysText.closeCase.closeCase_button }}
      </button>
    </div>
  </ng-container>
  <ng-template #saving>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
  </ng-template>
</mat-dialog-actions>

