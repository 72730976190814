<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6 >
		{{ data.sysText.sendNewEmailDialog_title | titlecase}}
	</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content>

	<!-- Recipients -->
	<mat-expansion-panel 
    class="info-panel" 
    (opened)="recipientsOpenState = true"
    (closed)="recipientsOpenState = false"
    [expanded]="recipientsOpenState"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
			<div class="section-header-container">
					<p class="overline grey1 semibold section-title">{{data.sysText.sendNewEmailDialog_recipients | uppercase}}</p>
					<mat-divider class="section-header-underline"></mat-divider>
			</div> 
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="panel-content">
			<app-add-recipients
				[formGroup]="form.controls.to"
				[sysText]="data.sysText"
				[boxLabel]="data.sysText.sendNewEmailDialog_to"
				[dialogData]="data.dialogData"
			></app-add-recipients>
		</div>
	</mat-expansion-panel>

	<!-- Add Cc -->
	<mat-expansion-panel 
    class="info-panel" 
    (opened)="addCcOpenState = true"
    (closed)="addCcOpenState = false"
    [expanded]="addCcOpenState"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
			<div class="section-header-container">
					<p class="overline grey1 semibold section-title">{{data.sysText.sendNewEmailDialog_addCc | uppercase}}</p>
					<mat-divider class="section-header-underline"></mat-divider>
			</div> 
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="panel-content">
			<app-add-recipients
				[formGroup]="form.controls.cc"
				[sysText]="data.sysText"
				[boxLabel]="data.sysText.sendNewEmailDialog_cc"
				[dialogData]="data.dialogData"
				[recipientType]="recipientTypes.cc"
			></app-add-recipients>
		</div>
	</mat-expansion-panel>

	<!-- Add Bcc -->
	<mat-expansion-panel 
    class="info-panel" 
    (opened)="addBccOpenState = true"
    (closed)="addBccOpenState = false"
    [expanded]="addBccOpenState"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
			<div class="section-header-container">
					<p class="overline grey1 semibold section-title">{{data.sysText.sendNewEmailDialog_addBcc | uppercase}}</p>
					<mat-divider class="section-header-underline"></mat-divider>
			</div> 
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="panel-content">
			<app-add-recipients
				[formGroup]="form.controls.bcc"
				[sysText]="data.sysText"
				[boxLabel]="data.sysText.sendNewEmailDialog_bcc"
				[dialogData]="data.dialogData"
				[recipientType]="recipientTypes.bcc"
			></app-add-recipients>
		</div>
	</mat-expansion-panel>

	<!-- Email Content -->
	<mat-expansion-panel 
    class="info-panel" 
    (opened)="emailContentOpenState = true"
    (closed)="emailContentOpenState = false"
    [expanded]="emailContentOpenState"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
			<div class="section-header-container">
					<p class="overline grey1 semibold section-title">{{data.sysText.sendNewEmailDialog_emailContent | uppercase}}</p>
					<mat-divider class="section-header-underline"></mat-divider>
			</div> 
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="panel-content">
			<app-select-field
				[control]="this.form.controls.templates"
				[label]="data.sysText.sendNewEmailDialog_template"
				[options]="data.dialogData.templateOptions"
				[icon]="'format_list_bulleted'"
				[showBlankOption]="true"
				[shouldCapitalize]="false"
				[showSpinner]="(templateIsLoading$ | async)"
			></app-select-field>
			<div>
				<div class="editor-container">
					<mat-icon class="section-label">title</mat-icon>
					<p class="grey1">{{data.sysText.sendNewEmailDialog_subjectLine}}</p>
				</div>
				<div class="tiny-editor">
					<app-email-subject-editor
						[control]="form.controls.subject"
						[placeholderText]="data.sysText.sendNewEmailDialog_subjectLine"
						[placeHolders]="data.dialogData.placeholderOptions"
					></app-email-subject-editor>
				</div>
			</div>
			<span class="spacer"></span>
			<div>
				<div class="editor-container">
					<mat-icon class="section-label">subject</mat-icon>
					<p class="grey1">{{data.sysText.sendNewEmailDialog_emailBody}}</p>
				</div>
				<div class="tiny-editor">
					<app-email-body-editor
						[control]="form.controls.body"
						[placeholderText]="data.sysText.sendNewEmailDialog_emailBody"
						[placeHolders]="data.dialogData.placeholderOptions"
					></app-email-body-editor>
				</div>
			</div>
		</div>
	</mat-expansion-panel>

	<!-- Attach Documents -->
	<mat-expansion-panel 
    class="info-panel" 
    (opened)="attachDocsOpenState = true"
    (closed)="attachDocsOpenState = false"
    [expanded]="attachDocsOpenState"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
			<div class="section-header-container">
					<p class="overline grey1 semibold section-title">{{data.sysText.sendNewEmailDialog_attachDoc | uppercase}}</p>
					<mat-divider class="section-header-underline"></mat-divider>
			</div> 
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="panel-content">
			<input hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files)"/>
      <div class="file-input-field">
        <button mat-stroked-button color="accent" (click)="imgFileInput.click()" class="body2">
          <mat-icon>upload</mat-icon>
          {{data.sysText.sendNewEmailDialog_uploadDoc | titlecase}}
        </button>
      </div>
      <div class="file-upload-container" *ngIf="attachments">
        <div class="file-upload" *ngFor="let attachment of attachments">
          <mat-icon class="fill4-dk">check</mat-icon>
          <div>
            <span class="body2 side-space grey3">{{ attachment.file?.name }}</span>
          </div>
          <mat-icon class="black-inactive small" (click)="clearFile(attachment.file)">clear</mat-icon>
        </div>
      </div>
		</div>
	</mat-expansion-panel>
</mat-dialog-content>

<mat-dialog-actions class="actions">
	<ng-container *ngIf="!(isSending$ | async); else sending">
		<button mat-stroked-button class="button" color="accent" (click)="onPreview()">
			{{data.sysText.sendNewEmailDialog_preview}}
		</button>
		<div>
			<button mat-stroked-button class="button" color="accent" (click)="close()">
				{{data.sysText.sendNewEmailDialog_cancel}}
			</button>
			<button mat-stroked-button class="button" color="accent" (click)="onSend()">
				{{data.sysText.sendNewEmailDialog_send}}
			</button>
		</div>
	</ng-container>
	<ng-template #sending>
		<div class="full-width" align="end">
			<button mat-button class="button" type="button" color="accent" disabled>
				<mat-spinner diameter="30" color="accent"></mat-spinner>
			</button>
		</div>
	</ng-template>
</mat-dialog-actions>