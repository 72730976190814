import { Injectable } from '@angular/core';
import { AccountPagesSysText } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { SysTextService } from './sys-text.service';

@Injectable({
  providedIn: 'root',
})
export class AccountPagesSystemTextService {
  private _templates: AccountPagesSysText = {
    login: 'login',
    forgotPassword: 'forgotPassword',
    resetPassword: 'resetPassword',
    setPassword: 'setPassword',
    impersonateEmployee: 'impersonateEmployee',
  };

  private sysTextSubject: BehaviorSubject<AccountPagesSysText>;

  sysText$: Observable<AccountPagesSysText>;

  constructor(private textService: SysTextService) {
    this.sysTextSubject = new BehaviorSubject<AccountPagesSysText>(null);
    this.sysText$ = this.sysTextSubject.asObservable();
  }

  get templates(): AccountPagesSysText {
    return this._templates;
  }

  set sysText(txt: AccountPagesSysText) {
    this.sysTextSubject.next(txt);
  }

  getSysText(template: string): string {
    return this.sysTextSubject.value[template];
  }
}
