import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SidenavMenuLink } from '@core/models';
import { appRoutePaths } from '@core/routes/route-paths.constants';

@Component({
  selector: 'app-sidenav-list-item',
  templateUrl: './sidenav-list-item.component.html',
  styleUrls: ['./sidenav-list-item.component.scss'],
})
export class SidenavListItemComponent implements OnInit {
  _route: string;
  _link: SidenavMenuLink;

  get route(): string {
    return this._route;
  }

  get link(): SidenavMenuLink {
    return this._link;
  }
  @Input() set link(val: SidenavMenuLink) {
    this._link = val;
    this._route = val.route;
  }
  @Output() linkClick = new EventEmitter<SidenavMenuLink>();
  @Output() logout = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  handleLogout(): void {
    this.logout.emit();
    this.linkClick.emit();
  }

  handleLinkClick(link: SidenavMenuLink): void {
    this.linkClick.emit(link);
  }
}
