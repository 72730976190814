<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 class="grey1">{{ data.caseLeaveHours ? data.sysText.caseLeaveHours_editLeaveHours : data.sysText.employeeLeaveHours_editLeaveHours }}</h6>
    <ng-container *ngIf="isHandheld">
        <span class="spacer"></span>
        <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
    </ng-container>
    <mat-divider class="section-header-underline"></mat-divider>
</div>

<mat-dialog-content *ngIf="!isAnonymous; else anonymousMessage" class="mat-dialog-content">
    <ng-container>
        <form [formGroup]="form">
            <app-input-field
                *ngIf="data.showFmlaHours"
                [control]="this.form.controls.fmlaHours"
                [icon]="'schedule'"
                [label]="data.caseLeaveHours ? (data.sysText.caseLeaveHours_employee + ' ' + data.sysText.caseLeaveHours_fmlaHours) : data.sysText.employeeLeaveHours_fmlaHours"
                [inputType]="'number'"
                [removeLabelCasePipe]="true">
            </app-input-field>
            <app-input-field
                *ngIf="data.showStateHours"
                [control]="this.form.controls.stateHours"
                [icon]="'schedule'"
                [label]="data.caseLeaveHours ? (data.sysText.caseLeaveHours_employee + ' ' + data.sysText.caseLeaveHours_stateHours) : data.sysText.employeeLeaveHours_stateHours"
                [inputType]="'number'">
            </app-input-field>
            <app-input-field
                *ngIf="data.showPloHours"
                [control]="this.form.controls.ploHours"
                [icon]="'schedule'"
                [label]="data.caseLeaveHours ? (data.sysText.caseLeaveHours_employee + ' ' + data.sysText.caseLeaveHours_ploHours) : data.sysText.employeeLeaveHours_ploHours"
                [inputType]="'number'"
                [removeLabelCasePipe]="true">
            </app-input-field>
        </form>
    </ng-container>
</mat-dialog-content>

<ng-template #anonymousMessage>
    <mat-dialog-content class="form-container">
        <p class="disallow">{{ data.sysText.caseLeaveHours_anonymousMessage }}</p>
    </mat-dialog-content>
</ng-template>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
  <button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSaving$ | async" *ngIf="!isHandheld">
    {{ !isAnonymous ? data.sysText.cancel : data.sysText.close }}
  </button>
    <button *ngIf="!isAnonymous" mat-stroked-button class="button" color="accent" (click)="onSubmit()">
        {{ data.sysText.save }}
    </button>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
          </button>
    </mat-dialog-actions>
</ng-template>