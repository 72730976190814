import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AccountFormOptions } from '@core/models';
import { AuthService } from '@core/services';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent implements OnInit, OnDestroy {
  @Input() isSubmitting = false;
  @Input() options: AccountFormOptions;
  @Input() page: string;
  @Input() sysText: any;
  @Input() usernameOrEmail: string;
  @Output() formSubmit = new EventEmitter<FormGroup>();
  acctForm: FormGroup;
  buttonDisabled$ = new BehaviorSubject<boolean>(true);
  formValid: boolean = true;
  hidePassword: boolean = true;

  get password(): FormControl {
    return this.acctForm.get('password') as FormControl;
  }

  get phonenumber(): FormControl {
    return this.acctForm.get('phonenumber') as FormControl;
  }

  get username(): FormControl {
    return this.acctForm.get('username') as FormControl;
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit() {
    this.buildForm();
    if (this.options.isButtonEnabled) {
      this.buttonDisabled$.next(false);
    } else {
      this.listenToFormChanges();
    }

    this.authService.isLoggedOut$
      .pipe(
        takeUntil(this.destroy$)
      )    
      .subscribe((res: boolean) => {        
        if (res) {
          this.acctForm.reset();
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    this.formSubmit.emit(this.acctForm);
  }

  private buildForm(): void {
    this.acctForm = this.fb.group({
      username: new FormControl({
        value: this.usernameOrEmail || '',
        disabled: this.options.username.disabled || false,
      }),
      phonenumber: null,
      password: '',
    });
  }

  private listenToFormChanges(): any {
    this.acctForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.setButtonState({username: this.username.value, password: this.password.value, phonenumber: this.phonenumber.value});
        })
      )
      .subscribe();
  }

  private setButtonState({ username, password, phonenumber }: { username: string; password: string; phonenumber:string }): void {
    const isUsernameEmpty: boolean = this.options.username.show && !username ? true : false;
    const isPasswordEmpty: boolean = this.options.password.show && !password ? true : false;
    const isPhoneNumberEmpty: boolean = this.options.phonenumber.show && !phonenumber ? true : false;
    this.buttonDisabled$.next(isUsernameEmpty || isPasswordEmpty || isPhoneNumberEmpty);
  }
}
