<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 class="grey1">{{ data.sysText.editEmployerContacts.editEmployerContacts_header_title | titlecase }}</h6>
    <ng-container *ngIf="isHandheld">
        <span class="spacer"></span>
        <mat-icon role="button" (click)="cancel(false)" class="icon__button">close</mat-icon>
    </ng-container>
</div>
<mat-dialog-content>
    <ng-container>
        <div class="section">
          <p class="overline grey1 semibold section-title">{{ data.sysText.editEmployerContacts.admins_subtitle | uppercase }}</p>
          <mat-divider class="section-header-underline"></mat-divider>

            <!-- PRIMARY TENANT ADMIN -->
            <app-autocomplete-field *ngIf="data.employerContacts.canEditTenantAdmin"
              [group]="ecForm"
              [control]="primaryTenantAdminForm.controls.employeeId"
              [icon]="'supervised_user_circle'"
              [label]="data.sysText.editEmployerContacts.primaryTenantAdmin_label"
              [options]="data.employerContacts.tenantAdmins"
              [interfaceName]="data.sysText.editEmployerContacts.primaryTenantAdmin_label"
              [isDisabled]="isNewPrimaryTenantAdmin || !data.employerContacts.canEditTenantAdmin"
              (hintClick)="onAddNewPrimaryTenantAdmin()"
              [hasClickableHint]="data.employerContacts.canEditTenantAdmin"
              [showSearchIcon]="true"
              [comboMsError]="true">
            </app-autocomplete-field>
            <div class="new-employee" *ngIf="isNewPrimaryTenantAdmin && data.employerContacts.canEditTenantAdmin">
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_firstName" 
                    [control]="primaryTenantAdminForm.controls.firstName"
                    [group]="primaryTenantAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_middleName" 
                    [control]="primaryTenantAdminForm.controls.middleName" 
                    [group]="primaryTenantAdminForm">
                  </app-input-field>
                </div>
              </div>
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_lastName" 
                    [control]="primaryTenantAdminForm.controls.lastName" 
                    [group]="primaryTenantAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_suffix" 
                    [control]="primaryTenantAdminForm.controls.suffix" 
                    [group]="primaryTenantAdminForm">
                  </app-input-field>
                </div>
              </div> 
              <div class="new-ee-full-column">
                <div>
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_preferredName" 
                    [control]="primaryTenantAdminForm.controls.preferredName"
                    [group]="primaryTenantAdminForm">
                  </app-input-field>
                </div>
              </div>
              <app-email-field-group
                [group]="primaryTenantAdminForm"
                [hideIcon]="true"
                [workEmailControl]="primaryTenantAdminForm.controls.workEmail"
                [isWorkEmailPreferredControl]="primaryTenantAdminForm.controls.workEmailChecked"
                [personalEmailControl]="primaryTenantAdminForm.controls.personalEmail"
                [isPersonalEmailPreferredControl]="primaryTenantAdminForm.controls.personalEmailChecked">
              </app-email-field-group>
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_workPhone" 
                    [control]="primaryTenantAdminForm.controls.workPhone"
                    [group]="primaryTenantAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_personalPhone" 
                    [control]="primaryTenantAdminForm.controls.personalPhone" 
                    [group]="primaryTenantAdminForm">
                  </app-input-field>
                </div>
              </div>
            </div>

            <!-- PRIMARY EMPLOYER ADMIN -->
            <app-autocomplete-field
              [group]="ecForm"
              [control]="primaryEmployerAdminForm.controls.employeeId"
              [icon]="'supervisor_account'"
              [label]="data.sysText.editEmployerContacts.primaryEmployerAdmin_label"
              [options]="data.employerContacts.employerAdmins"
              [interfaceName]="data.sysText.editEmployerContacts.primaryEmployerAdmin_label"
              [isDisabled]="isNewPrimaryEmployerAdmin"
              (hintClick)="onAddNewPrimaryEmployerAdmin()"
              [hasClickableHint]="true"
              [showSearchIcon]="true"
              [comboMsError]="true">
            </app-autocomplete-field>
            <div class="new-employee" *ngIf="isNewPrimaryEmployerAdmin">
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_firstName" 
                    [control]="primaryEmployerAdminForm.controls.firstName"
                    [group]="primaryEmployerAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_middleName" 
                    [control]="primaryEmployerAdminForm.controls.middleName" 
                    [group]="primaryEmployerAdminForm">
                  </app-input-field>
                </div>
              </div>
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_lastName" 
                    [control]="primaryEmployerAdminForm.controls.lastName" 
                    [group]="primaryEmployerAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_suffix" 
                    [control]="primaryEmployerAdminForm.controls.suffix" 
                    [group]="primaryEmployerAdminForm">
                  </app-input-field>
                </div>
              </div> 
              <div class="new-ee-full-column">
                <div>
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_preferredName" 
                    [control]="primaryEmployerAdminForm.controls.preferredName"
                    [group]="primaryEmployerAdminForm">
                  </app-input-field>
                </div>
              </div>
              <app-email-field-group
                [group]="primaryEmployerAdminForm"
                [hideIcon]="true"
                [workEmailControl]="primaryEmployerAdminForm.controls.workEmail"
                [isWorkEmailPreferredControl]="primaryEmployerAdminForm.controls.workEmailChecked"
                [personalEmailControl]="primaryEmployerAdminForm.controls.personalEmail"
                [isPersonalEmailPreferredControl]="primaryEmployerAdminForm.controls.personalEmailChecked">
              </app-email-field-group>
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_workPhone" 
                    [control]="primaryEmployerAdminForm.controls.workPhone"
                    [group]="primaryEmployerAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_personalPhone" 
                    [control]="primaryEmployerAdminForm.controls.personalPhone" 
                    [group]="primaryEmployerAdminForm">
                  </app-input-field>
                </div>
              </div>
            </div>

            <!-- HELP AND FEEDBACK ADMIN -->
            <app-autocomplete-field
              [group]="ecForm"
              [control]="helpFeedbackAdminForm.controls.employeeId"
              [icon]="'help'"
              [label]="data.sysText.editEmployerContacts.helpFeedbackAdmin_label"
              [options]="data.employerContacts.helpFeedbackAdmins"
              [interfaceName]="data.sysText.editEmployerContacts.helpFeedbackAdmin_label"
              [isDisabled]="isNewHelpFeedbackAdmin"
              (hintClick)="onAddNewHelpFeedbackAdmin()"
              [hasClickableHint]="true"
              [showSearchIcon]="true"
              [comboMsError]="true">
            </app-autocomplete-field>
            <div class="slider">
              <app-slide-toggle
                  [control]="includeCaseAdminsOnHelpForm"
                  [label]="data.sysText.editEmployerContacts.includeAssignedAdmins"
                  [checked]="true">
              </app-slide-toggle>
              <app-slide-toggle
                  [control]="showHelpAndFeedbackOnCaseDetails"
                  [label]="data.sysText.editEmployerContacts.useThisEmailAddress"
                  [checked]="true">
              </app-slide-toggle>
            </div>
            <div class="new-employee" *ngIf="isNewHelpFeedbackAdmin">
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_firstName" 
                    [control]="helpFeedbackAdminForm.controls.firstName"
                    [group]="helpFeedbackAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_middleName" 
                    [control]="helpFeedbackAdminForm.controls.middleName" 
                    [group]="helpFeedbackAdminForm">
                  </app-input-field>
                </div>
              </div>
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_lastName" 
                    [control]="helpFeedbackAdminForm.controls.lastName" 
                    [group]="helpFeedbackAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_suffix" 
                    [control]="helpFeedbackAdminForm.controls.suffix" 
                    [group]="helpFeedbackAdminForm">
                  </app-input-field>
                </div>
              </div> 
              <div class="new-ee-full-column">
                <div>
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_preferredName" 
                    [control]="helpFeedbackAdminForm.controls.preferredName"
                    [group]="helpFeedbackAdminForm">
                  </app-input-field>
                </div>
              </div>
              <app-email-field-group
                [group]="helpFeedbackAdminForm"
                [hideIcon]="true"
                [workEmailControl]="helpFeedbackAdminForm.controls.workEmail"
                [isWorkEmailPreferredControl]="helpFeedbackAdminForm.controls.workEmailChecked"
                [personalEmailControl]="helpFeedbackAdminForm.controls.personalEmail"
                [isPersonalEmailPreferredControl]="helpFeedbackAdminForm.controls.personalEmailChecked">
              </app-email-field-group>
              <div class="sub-employee">
                <div class="new-ee-column">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_workPhone" 
                    [control]="helpFeedbackAdminForm.controls.workPhone"
                    [group]="helpFeedbackAdminForm">
                  </app-input-field>
                </div>
                <div class="new-ee-column left-margin">
                  <app-input-field 
                    [label]="data.sysText.editEmployerContacts.employerContact_personalPhone" 
                    [control]="helpFeedbackAdminForm.controls.personalPhone" 
                    [group]="helpFeedbackAdminForm">
                  </app-input-field>
                </div>
              </div>
            </div>
        </div>
        <div class="section">
            <p class="overline grey1 semibold section-title">{{ data.sysText.editEmployerContacts.contacts_subtitle | uppercase}}</p>
            <mat-divider class="section-header-underline"></mat-divider>

            <!-- MAIN CONTACTS -->
            <div class="contact-group">
              <div *ngFor="let mc of mainContactsArray.controls; let i = index" class="group-form-container">
                <div class="contact-container">
                  <div class="input-auto-field" [ngClass]="{'w-90': (mainContactsArray.controls.length > 1)}">
                    <app-autocomplete-field
                      [group]="mc"
                      [control]="mc.controls.employeeId"
                      [icon]="i > 0 ? '' : 'person'"
                      [label]="data.sysText.editEmployerContacts.mainContact_label"
                      [options]="data.employerContacts.employerContacts"
                      [interfaceName]="data.sysText.editEmployerContacts.mainContact_label"
                      [isDisabled]="isNewMainContact[i]"
                      (hintClick)="onAddNewMainContact(i)"
                      [hasClickableHint]="true"
                      [showSearchIcon]="true"
                      [comboMsError]="true">
                    </app-autocomplete-field>
                  </div>
                  <button
                    mat-icon-button
                    aria-label="remove button"
                    [disableRipple]="true"
                    (click)="onRemoveMainContact(i)"
                    *ngIf="mainContactsArray.controls.length > 1">
                    <mat-icon [ngClass]="{'accent': true, 'margin-bottom-17': !isHandheld}">close</mat-icon>
                  </button>
                </div>
                <div class="new-employee" *ngIf="isNewMainContact[i]">
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_firstName" 
                        [control]="mc.controls.firstName"
                        [group]="mc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_middleName" 
                        [control]="mc.controls.middleName" 
                        [group]="mc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_lastName" 
                        [control]="mc.controls.lastName" 
                        [group]="mc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_suffix" 
                        [control]="mc.controls.suffix" 
                        [group]="mc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="new-ee-full-column">
                    <div>
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_preferredName" 
                        [control]="mc.controls.preferredName"
                        [group]="mc">
                      </app-input-field>
                    </div>
                  </div>
                  <app-email-field-group
                    [group]="mc"
                    [hideIcon]="true"
                    [workEmailControl]="mc.controls.workEmail"
                    [isWorkEmailPreferredControl]="mc.controls.workEmailChecked"
                    [personalEmailControl]="mc.controls.personalEmail"
                    [isPersonalEmailPreferredControl]="mc.controls.personalEmailChecked">
                  </app-email-field-group>
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_workPhone" 
                        [control]="mc.controls.workPhone"
                        [group]="mc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_personalPhone" 
                        [control]="mc.controls.personalPhone" 
                        [group]="mc">
                      </app-input-field>
                    </div>
                  </div>
                </div>
              </div>
              <button
                (click)="addMainContactFormGroup()"
                mat-flat-button
                color="accent"
                class="button add-additional-button">
                {{data.sysText.editEmployerContacts.addAdditionalContact}}
              </button>
            </div>

            <!-- BENEFITS CONTACTS -->
            <div class="contact-group">
              <div *ngFor="let bc of benefitsContactsArray.controls; let i = index" class="group-form-container">
                <div class="contact-container">
                  <div class="input-auto-field" [ngClass]="{'w-90': (benefitsContactsArray.controls.length > 1)}">
                    <app-autocomplete-field
                      [group]="bc"
                      [control]="bc.controls.employeeId"
                      [icon]="i > 0 ? '' : 'thumb_up'"
                      [label]="data.sysText.editEmployerContacts.benefitsContacts_label"
                      [options]="data.employerContacts.employerContacts"
                      [interfaceName]="data.sysText.editEmployerContacts.benefitsContacts_label"
                      [isDisabled]="isNewBenefitsContact[i]"
                      (hintClick)="onAddNewBenefitContact(i)"
                      [hasClickableHint]="true"
                      [showSearchIcon]="true"
                      [comboMsError]="true">
                    </app-autocomplete-field>
                  </div>
                  <button
                    mat-icon-button
                    aria-label="remove button"
                    [disableRipple]="true"
                    (click)="onRemoveBenefitsContact(i)"
                    *ngIf="benefitsContactsArray.controls.length > 1">
                    <mat-icon [ngClass]="{'accent': true, 'margin-bottom-17': !isHandheld}">close</mat-icon>
                  </button>
                </div>
                <div class="new-employee" *ngIf="isNewBenefitsContact[i]">
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_firstName" 
                        [control]="bc.controls.firstName"
                        [group]="bc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_middleName" 
                        [control]="bc.controls.middleName" 
                        [group]="bc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_lastName" 
                        [control]="bc.controls.lastName" 
                        [group]="bc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_suffix" 
                        [control]="bc.controls.suffix" 
                        [group]="bc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="new-ee-full-column">
                    <div>
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_preferredName" 
                        [control]="bc.controls.preferredName"
                        [group]="bc">
                      </app-input-field>
                    </div>
                  </div>
                  <app-email-field-group
                    [group]="bc"
                    [hideIcon]="true"
                    [workEmailControl]="bc.controls.workEmail"
                    [isWorkEmailPreferredControl]="bc.controls.workEmailChecked"
                    [personalEmailControl]="bc.controls.personalEmail"
                    [isPersonalEmailPreferredControl]="bc.controls.personalEmailChecked">
                  </app-email-field-group>
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_workPhone" 
                        [control]="bc.controls.workPhone"
                        [group]="bc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_personalPhone" 
                        [control]="bc.controls.personalPhone" 
                        [group]="bc">
                      </app-input-field>
                    </div>
                  </div>
                </div>
              </div>
              <button
                (click)="addBenefitsContactFormGroup()"
                mat-flat-button
                color="accent"
                class="button add-additional-button">
                {{data.sysText.editEmployerContacts.addAdditionalContact}}
              </button>
            </div>
 
            <!-- BENEFITS DEDUCTIONS CONTACTS -->
            <div class="contact-group">
              <div *ngFor="let bdc of benefitDeductionsContactsArray.controls; let i = index" class="group-form-container">
                <div class="contact-container">
                  <div class="input-auto-field" [ngClass]="{'w-90': (benefitDeductionsContactsArray.controls.length > 1)}">
                    <app-autocomplete-field
                      [group]="bdc"
                      [control]="bdc.controls.employeeId"
                      [icon]="i > 0 ? '' : 'trending_down'"
                      [label]="data.sysText.editEmployerContacts.benefitDeductionsContacts_label"
                      [options]="data.employerContacts.employerContacts"
                      [interfaceName]="data.sysText.editEmployerContacts.benefitDeductionsContacts_label"
                      [isDisabled]="isNewBenefitDeductionsContact[i]"
                      (hintClick)="onAddNewBenefitDeductionsContact(i)"
                      [hasClickableHint]="true"
                      [showSearchIcon]="true"
                      [comboMsError]="true">
                    </app-autocomplete-field>
                  </div>
                  <button
                    mat-icon-button
                    aria-label="remove button"
                    [disableRipple]="true"
                    (click)="onRemoveBenefitDeductionsContact(i)"
                    *ngIf="benefitDeductionsContactsArray.controls.length > 1">
                    <mat-icon [ngClass]="{'accent': true, 'margin-bottom-17': !isHandheld}">close</mat-icon>
                  </button>
                </div>
                <div class="new-employee" *ngIf="isNewBenefitDeductionsContact[i]">
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_firstName" 
                        [control]="bdc.controls.firstName"
                        [group]="bdc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_middleName" 
                        [control]="bdc.controls.middleName" 
                        [group]="bdc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_lastName" 
                        [control]="bdc.controls.lastName" 
                        [group]="bdc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_suffix" 
                        [control]="bdc.controls.suffix" 
                        [group]="bdc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="new-ee-full-column">
                    <div>
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_preferredName" 
                        [control]="bdc.controls.preferredName"
                        [group]="bdc">
                      </app-input-field>
                    </div>
                  </div>
                  <app-email-field-group
                    [group]="bdc"
                    [hideIcon]="true"
                    [workEmailControl]="bdc.controls.workEmail"
                    [isWorkEmailPreferredControl]="bdc.controls.workEmailChecked"
                    [personalEmailControl]="bdc.controls.personalEmail"
                    [isPersonalEmailPreferredControl]="bdc.controls.personalEmailChecked">
                  </app-email-field-group>
                  
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_workPhone" 
                        [control]="bdc.controls.workPhone"
                        [group]="bdc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_personalPhone" 
                        [control]="bdc.controls.personalPhone" 
                        [group]="bdc">
                      </app-input-field>
                    </div>
                  </div>
                </div>
              </div>
              <button
                (click)="addBenefitDeductionsContactFormGroup()"
                mat-flat-button
                color="accent"
                class="button add-additional-button">
                {{data.sysText.editEmployerContacts.addAdditionalContact}}
              </button>
            </div>

            <!-- PAYROLL CONTACTS -->
            <div class="contact-group">
              <div *ngFor="let pc of payrollContactsArray.controls; let i = index" class="group-form-container">
                <div class="contact-container">
                  <div class="input-auto-field" [ngClass]="{'w-90': (payrollContactsArray.controls.length > 1)}">
                    <app-autocomplete-field
                      [group]="pc"
                      [control]="pc.controls.employeeId"
                      [icon]="i > 0 ? '' : 'attach_money'"
                      [label]="data.sysText.editEmployerContacts.payrollContacts_label"
                      [options]="data.employerContacts.employerContacts"
                      [interfaceName]="data.sysText.editEmployerContacts.payrollContacts_label"
                      [isDisabled]="isNewPayrollContact[i]"
                      (hintClick)="onAddNewPayrollContact(i)"
                      [hasClickableHint]="true"
                      [showSearchIcon]="true"
                      [comboMsError]="true"
                      ></app-autocomplete-field>
                  </div>
                  <button
                    mat-icon-button
                    aria-label="remove button"
                    [disableRipple]="true"
                    (click)="onRemovePayrollContact(i)"
                    *ngIf="payrollContactsArray.controls.length > 1">
                    <mat-icon [ngClass]="{'accent': true, 'margin-bottom-17': !isHandheld}">close</mat-icon>
                  </button>
                </div>
                <div class="new-employee" *ngIf="isNewPayrollContact[i]">
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_firstName" 
                        [control]="pc.controls.firstName"
                        [group]="pc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_middleName" 
                        [control]="pc.controls.middleName" 
                        [group]="pc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_lastName" 
                        [control]="pc.controls.lastName" 
                        [group]="pc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_suffix" 
                        [control]="pc.controls.suffix" 
                        [group]="pc">
                      </app-input-field>
                    </div>
                  </div>
                  <div class="new-ee-full-column">
                    <div>
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_preferredName" 
                        [control]="pc.controls.preferredName"
                        [group]="pc">
                      </app-input-field>
                    </div>
                  </div>
                  <app-email-field-group
                    [group]="pc"
                    [hideIcon]="true"
                    [workEmailControl]="pc.controls.workEmail"
                    [isWorkEmailPreferredControl]="pc.controls.workEmailChecked"
                    [personalEmailControl]="pc.controls.personalEmail"
                    [isPersonalEmailPreferredControl]="pc.controls.personalEmailChecked">
                  </app-email-field-group>
                  <div class="sub-employee">
                    <div class="new-ee-column">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_workPhone" 
                        [control]="pc.controls.workPhone"
                        [group]="pc">
                      </app-input-field>
                    </div>
                    <div class="new-ee-column left-margin">
                      <app-input-field 
                        [label]="data.sysText.editEmployerContacts.employerContact_personalPhone" 
                        [control]="pc.controls.personalPhone" 
                        [group]="pc">
                      </app-input-field>
                    </div>
                  </div>
                </div>
              </div>
              <button
                (click)="addPayrollContactFormGroup()"
                mat-flat-button
                color="accent"
                class="button add-additional-button">
                {{data.sysText.editEmployerContacts.addAdditionalContact}}
              </button>
            </div>
        </div>
    </ng-container>
</mat-dialog-content> 


<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
    <button mat-stroked-button class="button" color="accent" (click)="cancel(false)" [disabled]="isSaving$ | async">
      {{ data.sysText.editEmployerContacts.cancel }}
    </button>
      <button mat-stroked-button class="button" color="accent" (click)="save(false)">
          {{ data.sysText.editEmployerContacts.save }}
      </button>
</mat-dialog-actions>
<ng-template #submitting>
    <mat-dialog-actions align="end">
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </mat-dialog-actions>
</ng-template>
