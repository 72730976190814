import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent implements OnInit {
  @ViewChild('input') input: MatInput;

  // #region Properties (8)

  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Input() public hint: string;
  @Input() public icon: string;
  @Input() public hideIconSpace: boolean;
  @Input() public isHintHighlighted: boolean = true;
  @Input() public isHintWarning: boolean = false;
  @Input() public isTouchDevice: boolean;
  @Input() public label: string;
  @Input() public msErrorKey: string;
  @Input() public customMsError: string;
  @Input() public info: string;
  @Output() emitDateChange = new EventEmitter<Date>();
  public sysText: any;
  public _isDisabled: boolean;

  // #endregion Properties (8)

  // #region Constructors (1)

  constructor(private text: SharedComponentsSysTextService) {}

  @Input() public set isDisabled(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
    this._isDisabled = isDisabled;
  }

  // #endregion Constructors (1)

  // #region Public Methods (3)

  public ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.dateField];
  }

  public focus(): void {
    this.input.focus();
  }

  public onEmitDateChange(dateValue: Date) {
    this.emitDateChange.emit(dateValue)
  }

  // #endregion Public Methods (3)
}
