<mat-card appearance="raised" class="code-form--container mat-elevation-z12">
    <div class="code-form--header">
      <h4 class="code-form--title">{{ sysText[page + '_verificationForm_title'] }}</h4>
      <p 
        *ngIf="otpIsPhone" 
        class="body2 code-form--subtitle">
        {{ sysText[page + '_verificationForm_subtitlePhone']}} {{otpDest}}
      </p>
      <p 
        *ngIf="!otpIsPhone" 
        class="body2 code-form--subtitle">
        {{ sysText[page + '_verificationForm_subtitleEmail']}} {{otpDest}}
      </p>
    </div>
    <mat-card-content>
        <form [formGroup]="verificationForm" (ngSubmit)="onSubmit()" class="code-form--form">
            <mat-form-field color="accent">
                <input
                  type="number"
                  matInput
                  [placeholder]="sysText[page + '_verificationForm_enterCode']"
                  formControlName="code"
                />
                <mat-error>{{ code.errors?.msError }}</mat-error>
            </mat-form-field>
            <mat-checkbox *ngIf="options.rememberMe.show" formControlName="rememberMe">{{ sysText[page + '_verificationForm_remember'] }}</mat-checkbox>
            <button
                type="submit"
                mat-flat-button
                color="accent"
                class="button code-form--button"
                *ngIf="!isSubmitting; else submitting"
            >
             {{ sysText[page + '_verificationForm_button'] }}
            </button>
            <ng-template #submitting>
              <button type="submit" mat-flat-button color="accent" class="button code-form--button__disabled" disabled>
                <mat-spinner [diameter]="30" color="accent"></mat-spinner>
              </button>
            </ng-template>
        </form>
        <div class="code-form--link caption">
            <a (click)="onResend()">
              {{ sysText[page + '_verificationForm_link'] }}
            </a>
            <a *ngIf="options.secondaryLink.show" (click)="onEmailCode()" class="code-form--link__secondary">
              {{ sysText[page + '_verificationForm_secondaryLink'] }}
            </a>
            <a *ngIf="options.goBack.show" (click)="onGoBack()" class="code-form--link__secondary">
              {{ sysText[page + '_verificationForm_goBack'] }}
            </a>
          </div>
              
    </mat-card-content>
</mat-card>
  