import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorService, LayoutService } from '@core/services';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { EventApi } from '@fullcalendar/core';
import { CalendarEventTypes } from '@core/enums/calendar-event-type.enum';
import { CaseDetails, SelectionOption, StiiraError } from '@core/models';
import { MatRadioChange } from '@angular/material/radio';
import { WorkdayPostOption } from '@core/enums/workday-post-option.enum';
import { isNumber } from '@microsoft/applicationinsights-core-js';
import { CaseDetailsCalendarWorkdayPost, CustomCalendarEventProps, EmployeeCalendarWorkdayPost } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { EmployeeRecord } from '@core/models/leave-admin/employees/employee-record.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-add-edit-calendar-workday',
  templateUrl: './add-edit-calendar-workday.component.html',
  styleUrl: './add-edit-calendar-workday.component.scss'
})
export class AddEditCalendarWorkdayComponent extends DialogDragConstraints implements OnInit{
  public form: UntypedFormGroup;
  public isSaving$: Subject<boolean> = new Subject<boolean>();
  public formInitValues: any;
  public workdayOptions: SelectionOption[] = [];
  public defaultItems: string[] = [];

  private workdayPostOption = WorkdayPostOption;

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get isHandheld$(): Observable<boolean> {
    return this.layoutService.isHandheld$();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      event: EventApi,
      caseDetails: CaseDetails; //nullable
      employeeRecord: EmployeeRecord; //nullable
      sysText: any;
      unsavedChangesSysText: any,
    },
    private fb: FormBuilder,
    private errorService: ErrorService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddEditCalendarWorkdayComponent>,
    private manageEmployeeService: ManageEmployeesService,
    private leaveAdminService: LeaveAdminService
  ) {
    super(dialogRef);
    
    this.form = this.fb.group({
      hours: this.fb.control(null),
      workdayOption: this.fb.control(null)
    });
  }

  ngOnInit(): void {
    this.setupForm();
    this.buildWorkdayModeOptions();
    this.formInitValues = { ...this.form.value };
  }

  public onChange(event: MatRadioChange): void {
    if (event.value === WorkdayPostOption.Workday) {
      this.form.controls.hours.enable();
    } else {
      this.form.controls.hours.setValue(null);
      this.form.controls.hours.disable();
    }
  }

  public save(): void {
    if (this.data.employeeRecord) {
      const dto: EmployeeCalendarWorkdayPost = {
        employeeId: this.data.employeeRecord.employeeId,
        date: this.data.event.start,
        hours: this.form.controls.hours.value,
        workdayPostOption: this.form.controls.workdayOption.value
      }
      this.manageEmployeeService.postLeaveCalendarWorkday(dto)
        .pipe(indicate(this.isSaving$))
        .subscribe((res)=> {
          this.dialogRef.close(res);
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    } else if (this.data.caseDetails) {
      const dto: CaseDetailsCalendarWorkdayPost = {
        caseId: this.data.caseDetails.leaveInformation.caseId,
        date: this.data.event.start,
        hours: this.form.controls.hours.value,
        workdayPostOption: this.form.controls.workdayOption.value
      }
      this.leaveAdminService.postLeaveCalendarWorkday(dto)
        .pipe(indicate(this.isSaving$))
        .subscribe((res)=> {
          this.dialogRef.close(res);
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    }
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private buildWorkdayModeOptions(): void {
    const timeframeIds = Object.values(this.workdayPostOption).filter(v => isNumber(v));
    timeframeIds.forEach(id => {
      const desc: string = this.data.sysText['workdayPostOption_' + id]
      const option: SelectionOption = {
        id: id,
        description: desc
      };
      this.workdayOptions.push(option);
    });
  }

  private setupForm(): void {
    const eventProps = this.data.event.extendedProps.customProps as CustomCalendarEventProps;

    // workday cell
    if (eventProps.eventType == CalendarEventTypes.BgWorkdayCell) {
      if (eventProps.workCalendarDay?.isOverride == true) {
        this.form.controls.workdayOption.setValue(WorkdayPostOption.Workday);
        this.form.controls.hours.setValue(eventProps.workCalendarDay.overrideHours);
      } else {
        this.form.controls.workdayOption.setValue(WorkdayPostOption.Default);
        this.form.controls.hours.disable();
      }
    } 

    // non-workday cell
    else if (eventProps.eventType == CalendarEventTypes.BgNonWorkdayCell) {
      this.form.controls.hours.disable();
      if (eventProps.workCalendarDay?.isOverride == true) {
        this.form.controls.workdayOption.setValue(WorkdayPostOption.NonWorkday);
      } else {
        this.form.controls.workdayOption.setValue(WorkdayPostOption.Default);
      }
    } 
    
    // blank cell 
    else {
      this.form.controls.workdayOption.setValue(WorkdayPostOption.Default);
      this.form.controls.hours.disable();
    }

    // set default option text
    if (eventProps.workCalendarDay?.isScheduledWorkday == true && eventProps.workCalendarDay.scheduledHours != null) {
      this.defaultItems.push(`${this.data.sysText.scheduleWorkday} ${eventProps.workCalendarDay.scheduledHours}h`);
    } else if (eventProps.workCalendarDay?.isScheduledWorkday == false) {
      eventProps.workCalendarDay?.scheduledNonWorkdays?.forEach(nwd => {
        this.defaultItems.push(nwd.name);
      });
    }
  }
}