export enum ActionItemNotifications {
    DeterminationNoticeEligibility = -1,
    RequestMedicalCertificationDocumentation = -2,
    NoticeMedicalCertificationDocumentationDeadline = -3,
    CheckInPriorLeaveEndDate = -4,
    ReturnToWorkExtent1 = -5,
    ReturnToWorkExtent2 = -6,
    ExpiringLeaveNotice = -7,
    ClosingLeave = -8,
    DesignationNotice = -9    
}