import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService, SecurityQuestionsService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard  {
  constructor(private router: Router, private acctService: AccountService, private sqService: SecurityQuestionsService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.sqService.hasCorrectSqAnswers) {
      this.acctService.setUsername(this.router.getCurrentNavigation().extras.state.username);
      return true;
    }

    const userId: string = next.queryParams.userId;
    const code: string = next.queryParams.code;
    const otpDest: string = next.queryParams.otpDest; 

    if (userId && code) { 
      this.acctService.setUserId(userId);
      this.acctService.setCode(code);
      this.acctService.setOtpDest(otpDest);
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
