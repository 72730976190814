import { Injectable } from '@angular/core';
import { ReportData } from '@core/models/leave-admin/reports/report-data.model';
import { ReportTypes } from '@core/models/leave-admin/reports/report-type.model';
import { ReportsStore } from '@core/models/leave-admin/reports/reports-store.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsStoreService {

  private readonly _initialStoreData: ReportsStore = {
    reportTypes: null,
    sysText: null,
    reportData: null
  };

  private readonly templateNames = {
    reports: "reports"
  };

  private reportsStore: BehaviorSubject<ReportsStore>;  
  private store$: Observable<ReportsStore>;

  public showTable: BehaviorSubject<boolean>;
  public showTable$: Observable<boolean>;

  constructor() {
    this.reportsStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.reportsStore.asObservable();

    this.showTable = new BehaviorSubject(false);
    this.showTable$ = this.showTable.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }

  public get store() {
    return this.reportsStore.value;
  }

  public get reportTypes$(): Observable<ReportTypes> {
    return this.store$.pipe(pluck('reportTypes'));
  }

  public get reportData$(): Observable<ReportData> {
    return this.store$.pipe(pluck('reportData'));
  }

  public get reportTypes() {
    return this.store.reportTypes;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.reportsStore.next(store);
  }

  public set reportTypes(reportTypes: ReportTypes) {
    const store = { ...this.store, reportTypes };
    this.reportsStore.next(store);
  }

  public set reportData(reportData: ReportData) {
    const store = { ...this.store, reportData };
    this.reportsStore.next(store);
  }

  public uploadReportData(): void {
    const reportData: ReportData = null;
    const store = { ...this.store, reportData };
    this.reportsStore.next(store);
  }

  public unloadStore() {
    const store: ReportsStore = {
      reportTypes: null,
      sysText: null,
      reportData: null
    }
    this.reportsStore.next(store)
  }
}