<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6>
		{{ data.sysText.editScheduleInfo_header | titlecase }}
	</h6>
</div>
<mat-dialog-content>
	<form [formGroup]="form" class="dialog-content-form">
		<app-input-field
			[control]="this.form.controls.scheduleName"
			[icon]="'description'"
			[label]="data.sysText.workScheduleName">
		</app-input-field>
		<app-select-field
			[control]="form.controls.ownerEmployerId"
			[label]="data.sysText.owner"
			[options]="data.options.employers"
			[icon]="'business'"
			[shouldCapitalize]="false">
		</app-select-field>
		<app-multi-select-field
			[control]="form.controls.nonWorkdays"
			[label]="data.sysText.nonWorkdays"
			[options]="data.options.nonWorkdays"
			[icon]="'event'"
			[shouldCapitalize]="false">
		</app-multi-select-field>
		<app-slide-toggle 
			[control]="this.form.controls.allowUse"
			[label]="data.sysText.allowUse"
		></app-slide-toggle>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
	<button mat-stroked-button class="button" color="accent" (click)="cancel()">
		{{ data.sysText.cancel }}
	</button>
	<button mat-stroked-button class="button" color="accent" (click)="save()">
		{{ data.sysText.save }}
	</button>
</mat-dialog-actions>
<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>