// adapted from Handling Observables with Structural Directives in Angular
// by Nils Mehlhorn at https://nils-mehlhorn.de/posts/angular-observable-directive
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { concatMapTo, takeUntil } from 'rxjs/operators';

export interface ObserveContext<T> {
  $implicit: T;
  observe: T;
}

export interface ErrorContext {
  $implicit: Error;
}

@Directive({
  selector: '[observe]',
})
export class ObserveDirective<T> implements OnInit, OnDestroy {
  private errorRef: TemplateRef<ErrorContext>;
  private beforeRef: TemplateRef<null>;
  private unsubscribe = new Subject<boolean>();
  private init = new AsyncSubject<void>();
  constructor(private view: ViewContainerRef, private nextRef: TemplateRef<ObserveContext<T>>, private changes: ChangeDetectorRef) {}

  @Input()
  set observe(source: Observable<T>) {
    if (!source) {
      return;
    }

    this.showBefore();
    this.unsubscribe.next(true);
    this.init.pipe(concatMapTo(source), takeUntil(this.unsubscribe)).subscribe(
      (value) => {
        this.view.clear();
        this.view.createEmbeddedView(this.nextRef, { $implicit: value, observe: value });
        this.changes.markForCheck();
      },
      (err) => {
        if (this.errorRef) {
          this.view.clear();
          this.view.createEmbeddedView(this.errorRef, { $implicit: err });
          this.changes.markForCheck();
        }
      }
    );
  }

  @Input()
  set observeError(ref: TemplateRef<ErrorContext>) {
    this.errorRef = ref;
  }

  @Input()
  set observeBefore(ref: TemplateRef<null>) {
    this.beforeRef = ref;
  }
  ngOnInit() {
    this.showBefore();
    this.init.next();
    this.init.complete();
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
  }

  private showBefore(): void {
    if (this.beforeRef) {
      this.view.clear();
      this.view.createEmbeddedView(this.beforeRef);
    }
  }
}
