import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards';
import { caseCommunicationRoutes } from '@core/routes/route-paths.constants';
import { EmailTemplatesDashboardComponent } from './pages/email-templates-dashboard/email-templates-dashboard.component';
import { EmailTemplatesResolver } from '@core/resolvers/email-templates.resolver';
import { TemplateDetailsComponent } from './pages/template-details/template-details.component';
import { TemplateDetailsResolver } from '@core/resolvers/template-details.resolver';

const routes: Routes = [{
  path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: `${caseCommunicationRoutes.EMAIL_TEMPLATES_DASHBOARD}`,
      },
      {
        path: `${caseCommunicationRoutes.EMAIL_TEMPLATES_DASHBOARD}`,
        component: EmailTemplatesDashboardComponent,
        resolve: { emailTemplates: EmailTemplatesResolver },
        canActivate: [AuthGuard],
      },
      {
        path: `${caseCommunicationRoutes.TEMPLATE}/:templateId`,
        component: TemplateDetailsComponent,
        resolve: { template: TemplateDetailsResolver },
        canActivate: [AuthGuard],
        canDeactivate: [(component: TemplateDetailsComponent) => component?.canDeactivate()],
        children: [],
      },
    ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseCommunicationRoutingModule { }
