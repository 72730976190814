import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarEventTypes } from '@core/enums/calendar-event-type.enum';
import { LeaveYears } from '@core/enums/leave-years.enum';
import { CaseDetails, LeaveInfo, SelectionOption } from '@core/models';
import { CustomCalendarEventProps, EmployeeLeaveHoursExtended, WorkCalendarDay } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { LayoutService } from '@core/services';
import { EventApi } from '@fullcalendar/core';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-view-calendar-item',
  templateUrl: './view-calendar-item.component.html',
  styleUrls: ['./view-calendar-item.component.scss']
})
export class ViewCalendarItemComponent extends DialogDragConstraints implements OnInit {
  public calendarEventTypes = CalendarEventTypes;
  public leaveInformation: LeaveInfo;
  public leaveYearOptions: SelectionOption[];
  public employeeLeaveHoursExtended: EmployeeLeaveHoursExtended;
  public ploLeaveYearId: number = LeaveYears.OregonRollingForward;
  public eventProps: CustomCalendarEventProps;

  constructor (
    private datePipe: DatePipe,
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<ViewCalendarItemComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      event: EventApi;
      caseDetails: CaseDetails;
      leaveYearOptions: SelectionOption[];
      workday: WorkCalendarDay;
      sysText: any;
    },
  ) {
    super(dialogRef);
    
    this.leaveInformation = this.data.caseDetails?.leaveInformation;
    this.leaveYearOptions = this.data.leaveYearOptions;
    this.eventProps = this.data.event.extendedProps?.customProps as CustomCalendarEventProps;
    this.employeeLeaveHoursExtended = this.eventProps?.employeeLeaveHoursExtended;
  }

  ngOnInit(): void { }

  public get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  public close(): void {
    this.dialogRef.close();
  }
  
  public hasCaseHoursToday(): boolean {
    return this.employeeLeaveHoursExtended?.leaveCaseHoursID != null && this.leaveInformation?.hiddenDateTime == null;
  }

  public caseIsHidden(): boolean {
    if (this.employeeLeaveHoursExtended?.leaveCaseHoursID == null)
      return false;

    return this.leaveInformation?.hiddenDateTime != null;
  }

  public outsideCaseTimeframe(): boolean {
    if (this.employeeLeaveHoursExtended?.leaveCaseHoursID == null)
      return false;

    let calendarDateFormed = this.employeeLeaveHoursExtended?.calendarDate ? 
      this.datePipe.transform(new Date(this.employeeLeaveHoursExtended?.calendarDate), "yyyy-MM-dd")
      : null;

    let outsideTimeframe = this.leaveInformation?.startDate == null
      || this.leaveInformation?.endDate == null
      || calendarDateFormed < this.datePipe.transform(this.leaveInformation?.startDate, "yyyy-MM-dd") 
      || calendarDateFormed > this.datePipe.transform(this.leaveInformation?.endDate, "yyyy-MM-dd");

      return outsideTimeframe;
  }

  public notWorkday(): boolean {
    if (this.employeeLeaveHoursExtended?.leaveCaseHoursID == null)
      return false;

    if (this.outsideCaseTimeframe())
      return false;

    let notWorkday = this.data.workday?.effectiveWorkday != true;

    return notWorkday;
  }

  public exceedsWorkdayHours(): boolean {
    if (this.employeeLeaveHoursExtended?.leaveCaseHoursID == null)
      return false;

    if (this.outsideCaseTimeframe() || this.notWorkday())
      return false;

    let exceedsHours = this.employeeLeaveHoursExtended.leaveCaseLeaveHours > this.data.workday?.effectiveHours;

    return exceedsHours;
  }

  public fmlaApplies(): boolean {
    return this.leaveInformation?.isFmlaHoursApplicable 
    || (!this.leaveInformation && this.employeeLeaveHoursExtended?.fmlaLeavesCount > 0);
  }

  public fmlaExhausted(): boolean {
    return this.fmlaApplies() 
      && this.employeeLeaveHoursExtended?.leaveCaseLeaveHours == null 
      && this.employeeLeaveHoursExtended?.employeeFmlaAvailableHours != null 
      && this.employeeLeaveHoursExtended?.employeeFmlaAvailableHours <= 0;
  }

  public stateApplies(): boolean {
    return this.leaveInformation?.isStateHoursApplicable 
      || (!this.leaveInformation && this.employeeLeaveHoursExtended?.stateLeavesCount > 0);
  }

  public stateExhausted(): boolean {
    return this.stateApplies() 
      && this.employeeLeaveHoursExtended?.leaveCaseLeaveHours == null 
      && this.employeeLeaveHoursExtended?.employeeStateAvailableHours != null 
      && this.employeeLeaveHoursExtended?.employeeStateAvailableHours <= 0;
  }

  public ploApplies(): boolean {
    return this.leaveInformation?.isPloHoursApplicable 
      || (!this.leaveInformation && this.employeeLeaveHoursExtended.ploLeavesCount > 0);
  }

  public ploExhausted(): boolean {
    return this.ploApplies() 
      && this.employeeLeaveHoursExtended?.leaveCaseLeaveHours == null 
      && this.employeeLeaveHoursExtended?.employeePloAvailableHours != null 
      && this.employeeLeaveHoursExtended?.employeePloAvailableHours <= 0;
  }

  public exhausted(): boolean {
    return this.fmlaExhausted() || this.stateExhausted() || this.ploExhausted();
  }

  public banks(): string {
    if (!this.fmlaApplies() && !this.stateApplies() && !this.ploApplies())
      return null;

    var drawsValue = "";

    if (this.fmlaApplies())
    {
      if (drawsValue.length > 0)
        drawsValue += ", ";
      
      drawsValue += "FMLA";
    }

    if (this.stateApplies()) {
      if (drawsValue.length > 0)
        drawsValue += ", ";
      
      drawsValue += "State";
    }

    if (this.ploApplies()) {
      if (drawsValue.length > 0)
        drawsValue += ", ";
      
      drawsValue += "PLO";
    }

    return drawsValue;
  }

  public multipleDraws(): boolean {
    let drawCount = 0;
    if (this.fmlaApplies())
      drawCount++;
    
    if (this.stateApplies())
      drawCount++;

    if (this.ploApplies())
      drawCount++;

    return drawCount > 1;
  }

  public period(startDate: Date, endDate: Date): string {
    if (startDate == null || endDate == null)
      return null;

    return `${this.datePipe.transform(startDate, "mediumDate")} - ${this.datePipe.transform(endDate, "mediumDate")}`;
  }

  public getLeaveYearName(leaveYearId: number): string { //TODO no leave year options from ee record
    if (leaveYearId == null)
      return null;
    
    if (this.leaveYearOptions?.find(ly => ly.id == leaveYearId) == null)
      return leaveYearId.toString();

    return this.leaveYearOptions?.find(ly => ly.id == leaveYearId).description;
  }
}
