import { Component, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorService, LayoutService } from '@core/services';
import { WorkSchedulesService } from '@core/services/work-schedules.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { 
  AddEditNonWorkdaysDailog, 
  NonWorkday, 
  NonWorkdayFormGroup, 
  NonWorkdayItem, 
  NonWorkdaysForm, 
  NonWorkdaysFormValues, 
  NonWorkdaysPost, 
  YearNonWorkdaysFormGroup } from '@core/models/work-schedules/work-schedules.model';
import { finalize, takeUntil } from 'rxjs/operators';
import { WorkSchedulesDashboardStoreService } from '@core/services/work-schedules-dashboard.store.service';
import { StiiraError } from '@core/models';
import { DateFieldComponent } from '@shared/form-field-types/date-field/date-field.component';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-add-edit-non-workdays',
  templateUrl: './add-edit-non-workdays.component.html',
  styleUrl: './add-edit-non-workdays.component.scss'
})
export class AddEditNonWorkdaysComponent extends DialogDragConstraints implements OnInit{
  @Output() isEditing = new EventEmitter<boolean>();
  @ViewChild('dateInput') dateInput: DateFieldComponent;

  @HostListener('window:keydown.enter', ['$event'])
  handleKeyDown() {
    if (this.editingRow){
      this.onSaveNonWorkday(this.editingRow.yearGroupIndex, this.editingRow.nonWorkdayIndex);
    }
  }

  public form: FormGroup<NonWorkdaysForm>;
  public formInitValues: NonWorkdaysFormValues;
  public isSubmitting$: Subject<boolean>;
  public yearPanelOpenState: number[] = [];
  public formChangeEmitted: boolean = false;
  public addedMessage: {desc: string, date: Date};
  public editingRow: {yearGroupIndex: number, nonWorkdayIndex: number} = null;
  
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.getFormVals(), noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get isHandheld$(): Observable<boolean> {
    return this.layoutService.isHandheld$();
  }

  get isMobile$(): Observable<boolean> {
    return this.layoutService.isMobile$();
  }

  get yearFormGroups(): FormArray<FormGroup<YearNonWorkdaysFormGroup>> {
    return this.form.controls.yearNonWorkdayGroups;
  }

  get fc(): NonWorkdaysForm {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      editing: boolean;
      nonWorkday: NonWorkdayItem; //nullable
      isDuplicate: boolean;
      dialogData: AddEditNonWorkdaysDailog;
      sysText: any;
      unsavedChangesSysText: any;
    },
    private service: WorkSchedulesService,
    private store: WorkSchedulesDashboardStoreService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddEditNonWorkdaysComponent>
  ) {
    super(dialogRef);
    
    this.isSubmitting$ = new Subject();
    this.form = this.fb.group<NonWorkdaysForm>({
      name: this.fb.control(null),
      ownerEmployerId: this.fb.control(null),
      allowUse: this.fb.control(false),
      nonWorkdayDateAdd: this.fb.control(null),
      nonWorkdayDescAdd: this.fb.control(null),
      yearNonWorkdayGroups:  new FormArray<FormGroup<YearNonWorkdaysFormGroup>>([]),
    });
  }

  ngOnInit(): void {
    if (this.data.nonWorkday) {
      this.form.patchValue({
        name: this.data.nonWorkday.name,
        ownerEmployerId: this.data.nonWorkday.ownerEmployer?.id,
        allowUse: this.data.nonWorkday.allowUse
      });
      this.data.dialogData.nonWorkdays.forEach((nwd) => {
        this.addToYearGroup(nwd, true);
      });
      this.sortYearGroups();
    }
    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.formInitValues = this.getFormVals();
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public onSubmit() {
    this.clearNonWorkdayErrors();
    if (this.fc.nonWorkdayDateAdd.value || this.fc.nonWorkdayDescAdd.value ) {
      this.yearFormGroups.setErrors({notAdded: true});
    } 
    else {
      this.dialogRef.disableClose = true;
      
      const dto: NonWorkdaysPost = {
        id: this.data.nonWorkday?.id ?? null,
        name: this.fc.name.value,
        ownerEmployerId: this.fc.ownerEmployerId.value,
        allowUse: this.fc.allowUse.value,
        yearNonWorkdayGroups: []
      }
  
      this.yearFormGroups.controls.forEach(group => {
        let nonWorkdays: NonWorkday[] = []
        group.controls.nonWorkdays.controls.forEach(nwdGroup => {
          nonWorkdays.push({
            date: nwdGroup.controls.date.value,
            name: nwdGroup.controls.name.value
          });
        });
        dto.yearNonWorkdayGroups.push({nonWorkdays: nonWorkdays});
      });
  
      const postObs = this.data.nonWorkday && !this.data.isDuplicate ? 
        this.service.postEditNonWorkdays(dto) : this.service.postNewNonWorkdays(dto);
      
      postObs.pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        }))
        .subscribe((res) => {
          this.store.workSchedules = res;
          this.dialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors, true));
    }
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public onAddNonWorkday(): void {
    this.addedMessage = null;
    if (!this.fc.nonWorkdayDateAdd.value) {
      this.fc.nonWorkdayDateAdd.setErrors({required: true});
    }
    if (!this.fc.nonWorkdayDescAdd.value) {
      this.fc.nonWorkdayDescAdd.setErrors({required: true});
    }
    if (this.fc.nonWorkdayDateAdd.value && this.fc.nonWorkdayDescAdd.value) {
      this.addedMessage = {
        desc: this.fc.nonWorkdayDescAdd.value, 
        date: this.fc.nonWorkdayDateAdd.value
      };
      this.addToYearGroup({date: this.fc.nonWorkdayDateAdd.value, name: this.fc.nonWorkdayDescAdd.value});
      this.sortYearGroups();
      this.form.patchValue({
        nonWorkdayDateAdd: null,
        nonWorkdayDescAdd: null
      });
    }
    this.dateInput.focus();
  }

  public onEditNonWorkday(yearGroupIndex: number, nonWorkdayIndex: number): void {
    this.editingRow = {yearGroupIndex: yearGroupIndex, nonWorkdayIndex: nonWorkdayIndex};
    const nonWorkdayGroup = this.yearFormGroups.at(yearGroupIndex).controls.nonWorkdays.at(nonWorkdayIndex);
    nonWorkdayGroup.controls.date.enable();
    nonWorkdayGroup.controls.name.enable();
  }

  public onSaveNonWorkday(yearGroupIndex: number, nonWorkdayIndex: number): void {
    const nonWorkdayGroup = this.yearFormGroups.at(yearGroupIndex).controls.nonWorkdays.at(nonWorkdayIndex);
    if (!nonWorkdayGroup.controls.name.value || nonWorkdayGroup.controls.name.value == '') {
      nonWorkdayGroup.controls.name.setErrors({required: true});
    }
    if (!nonWorkdayGroup.controls.date.value) {
      nonWorkdayGroup.controls.date.setErrors({required: true});
    }
    if (nonWorkdayGroup.controls.name.value && nonWorkdayGroup.controls.date.value) {
      this.clearNonWorkdayErrors();
      this.editingRow = null;
      nonWorkdayGroup.controls.date.disable();
      nonWorkdayGroup.controls.name.disable();
      this.sortList();
    }
  }

  public onRemoveNonWorkday(yearGroupIndex: number, nonWorkdayIndex: number): void {
    const yearGroup = this.yearFormGroups.at(yearGroupIndex);
    yearGroup.controls.nonWorkdays.removeAt(nonWorkdayIndex);
    this.cleanUpYearGroup(yearGroup, yearGroupIndex);
  }

  private sortList(): void {
    for (let i = this.yearFormGroups.length - 1; i >= 0; i--) {
      const yearGroup = this.yearFormGroups.at(i);
      const year = this.yearFormGroups.at(i).controls.year.value;
      for (let j = yearGroup.controls.nonWorkdays.length - 1; j >= 0; j--) {
        const nonWorkdayGroup = yearGroup.controls.nonWorkdays.at(j);
        const nonWorkdayVal = nonWorkdayGroup.value as NonWorkday;
        const yearVal = new Date(nonWorkdayVal.date).getUTCFullYear();
        if (yearVal != year) {
          yearGroup.controls.nonWorkdays.removeAt(j);
          this.addToYearGroup(nonWorkdayVal);
        }
      }
      this.cleanUpYearGroup(yearGroup, i);
    }
    this.sortYearGroups();
  }

  private cleanUpYearGroup(yearGroup: FormGroup<YearNonWorkdaysFormGroup>, yearGroupIndex: number): void {
    if (yearGroup.controls.nonWorkdays.length === 0) {
      this.yearFormGroups.removeAt(yearGroupIndex);
      const i = this.yearPanelOpenState.indexOf(yearGroup.controls.year.value);
      if (i > 0) {
        this.yearPanelOpenState.splice(i, 1);
      }
    }
  }

  private sortYearGroups(): void {
    this.yearFormGroups.controls.sort((a, b) => {
      const aVal = a.controls.year.value;
      const bVal = b.controls.year.value;
      return aVal > bVal ? -1 : aVal < bVal ? 1 : 0;
    });

    this.yearFormGroups.controls.forEach(group => {
      group.controls.nonWorkdays.controls.sort((a, b) => {
        const aVal = new Date(a.controls.date.value);
        const bVal = new Date(b.controls.date.value);
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
      });
    });
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private initNewYearNonWorkdayGroup(year: number = null, nonWorkdayFormGroup: FormGroup<NonWorkdayFormGroup> = null): FormGroup<YearNonWorkdaysFormGroup> {
    return this.fb.group<YearNonWorkdaysFormGroup>({
      year: this.fb.control(year),
      nonWorkdays: new FormArray<FormGroup<NonWorkdayFormGroup>>(nonWorkdayFormGroup ? [nonWorkdayFormGroup] : [])
    });
  }

  private initNewNonWorkdayGroup(nonWorkday: NonWorkday = null): FormGroup<NonWorkdayFormGroup> {
    return  this.fb.group<NonWorkdayFormGroup>({
      date: new FormControl<Date>({value: nonWorkday?.date ?? null, disabled: true}),
      name: new FormControl<string>({value: nonWorkday?.name, disabled: true}),
    });
  }

  private addToYearGroup(nwd: NonWorkday, init: boolean = false): void {
    const nwdYear = nwd.date ? new Date(nwd.date).getFullYear() : null;
    const yearGroup = this.yearFormGroups.controls.find(c => c.controls.year.value == nwdYear);
    if (!yearGroup) {
      this.yearFormGroups.push(this.initNewYearNonWorkdayGroup(nwdYear, this.initNewNonWorkdayGroup(nwd)));
      if (!init) {
        this.yearPanelOpenState.push(nwdYear);
      }
    } else {
      yearGroup.controls.nonWorkdays.push(this.initNewNonWorkdayGroup(nwd));
    }
  }

  // sometimes form.value was not including the nested array values so get them explicitly 
  private getFormVals(): NonWorkdaysFormValues {
    let formValues = this.form.value as NonWorkdaysFormValues;
    formValues.yearNonWorkdayGroups.forEach((g, index) => {
      g.nonWorkdays = this.yearFormGroups.at(index).controls.nonWorkdays.value as NonWorkday[];
    });
    return formValues;
  }

  private clearNonWorkdayErrors(): void {
    this.addedMessage = null;
    this.yearFormGroups.setErrors(null);
    this.fc.nonWorkdayDateAdd.setErrors(null);
    this.fc.nonWorkdayDescAdd.setErrors(null);
    this.yearFormGroups.controls.forEach(yearGroup => {
      yearGroup.controls.nonWorkdays.controls.forEach(nonWorkdayGroup => {
        nonWorkdayGroup.controls.date.setErrors(null);
        nonWorkdayGroup.controls.name.setErrors(null);
      });
    });
  }
}