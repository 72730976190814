import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GuardsCheckEnd, NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { LayoutService } from '@core/services';
import { HeaderService } from '@core/services/header.service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { appRoutePaths } from '@core/routes/route-paths.constants';
import { filter, takeUntil } from 'rxjs/operators';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() helpIsLoading$: Subject<boolean>;
  @Input() caseComIsLoading$: Subject<boolean>;
  
  @Output() menuClick = new EventEmitter<void>();
  @Output() helpClick = new EventEmitter<void>();
  @Output() emailClick = new EventEmitter<number>();

  public isHandheld$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public title$: Observable<string>;
  public showEmailButton$: Observable<boolean>;
  public environment = environment;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private headerService: HeaderService,
    private layoutService: LayoutService,
    private router: Router,
    private leaveAdminStore: LeaveAdminStoreService
    ) {}

  ngOnInit() {
    this.environment = environment;
    this.title$ = this.headerService.title$;
    this.showEmailButton$ = this.headerService.showEmailButton$;
    this.onInitDisplayOptions();

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
      ).subscribe((re: RouterEvent) => {
        this.handleEmailButtonVisibility(re);
      });
  }

  ngOnDestroy(): void {
    this.headerService.setTitle('');
    this.destroy$.next();
    this.destroy$.complete();
  }

  
  public onMenuClick(): void {
    this.menuClick.emit();
  }
  
  public onHelpClick(): void {
    this.helpClick.emit();
  }
  
  public onEmailClick(): void {
    this.emailClick.emit(+this.router.url.split('/').pop());
  }

  private onInitDisplayOptions(): void {
    this.isHandheld$ = this.layoutService.isHandheld$();
    this.isMobile$ = this.layoutService.isMobile$();
  }

  private handleEmailButtonVisibility(re: RouterEvent) {
    if (re instanceof NavigationEnd
      && re.url.includes(appRoutePaths.CASE_DETAILS)
      && this.leaveAdminStore.caseDetails?.showCaseCommunication) {
      this.headerService.showEmailButton(true);
    } else if (re instanceof GuardsCheckEnd) {
      const guardsCheckEnd = re as GuardsCheckEnd
      if (guardsCheckEnd.shouldActivate) {
        this.headerService.showEmailButton(false);
      }
    }
  }
}
