import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { Observable, of } from 'rxjs';
import { AddEditNonWorkdaysDailog, NewSchedulePost, NonWorkdaysPost, WorkSchedulesDashboard } from '@core/models/work-schedules/work-schedules.model';
import { NON_WORKDAYS_DIALOG, SCHEDULE_ASSIGNED_EMPLOYERS_DIALOG, WORK_SCHEDULES, WORK_SCHEDULE_DETAILS, WORK_SCHED_INFO_DIALOG } from '@core/mocks/work-schedules/work-schedules.mock';
import { delay } from 'rxjs/operators';
import { PostScheduleWorkWeeks, ScheduleAssignedEmployersDialog, ScheduleAssignedEmployersDialogPost, ScheduleInfoDialogPost, WorkScheduleDetails, WorkScheduleInfoDialog } from '@core/models/work-schedules/work-schedule-details.model';


@Injectable({
  providedIn: 'root'
})
export class WorkSchedulesService {
  private apiUrl: string;

  constructor(private http: HttpClient, private constants: ConstantsService) { 
    this.apiUrl = this.constants.WS_API;
  }

  public getWorkSchedules(): Observable<WorkSchedulesDashboard> {
    return this.http.get<WorkSchedulesDashboard>(`${this.apiUrl}/getWorkSchedules`);
    // return of(WORK_SCHEDULES).pipe(delay(1000));
  }

  public getWorkScheduleDetails(scheduleId: number): Observable<WorkScheduleDetails> {
    return this.http.get<WorkScheduleDetails>(`${this.apiUrl}/getWorkScheduleDetails/${scheduleId}`);
    // return of(WORK_SCHEDULE_DETAILS).pipe(delay(1000));
  }

  public getEditWorkScheduleInfoDialog(scheduleId: number): Observable<WorkScheduleInfoDialog> {
    return this.http.get<WorkScheduleInfoDialog>(`${this.apiUrl}/getEditWorkScheduleInfoDialog/${scheduleId}`);
    // return of(WORK_SCHED_INFO_DIALOG).pipe(delay(1000));
  }

  public getEditScheduleAssignedEmployersDialog(scheduleId: number): Observable<ScheduleAssignedEmployersDialog> {
    return this.http.get<ScheduleAssignedEmployersDialog>(`${this.apiUrl}/getEditScheduleAssignedEmployersDialog/${scheduleId}`);
    // return of(SCHEDULE_ASSIGNED_EMPLOYERS_DIALOG).pipe(delay(1000));
  }

  public getAddEditNonWorkdaysDialog(nonWorkdaysId: number = null): Observable<AddEditNonWorkdaysDailog> {
    return this.http.get<AddEditNonWorkdaysDailog>(`${this.apiUrl}/getAddEditNonWorkdaysDialog/${nonWorkdaysId}`)
    // return of(NON_WORKDAYS_DIALOG).pipe(delay(1000));
  }

  public postScheduleWorkWeeks(dto: PostScheduleWorkWeeks): Observable<WorkScheduleDetails> {
    return this.http.post<WorkScheduleDetails>(`${this.apiUrl}/postScheduleWorkWeeks`, dto);
    // return of(WORK_SCHEDULE_DETAILS).pipe(delay(1000));
  }

  public postNewSchedule(dto: NewSchedulePost): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/postNewSchedule`, dto);
    // return of(1).pipe(delay(1000));
  }

  public postEditNonWorkdays(dto: NonWorkdaysPost): Observable<WorkSchedulesDashboard> {
    return this.  http.post<WorkSchedulesDashboard>(`${this.apiUrl}/postEditNonWorkdays`, dto);
    // return of(WORK_SCHEDULES).pipe(delay(1000));
  }

  public postNewNonWorkdays(dto: NonWorkdaysPost): Observable<WorkSchedulesDashboard> {
    return this.http.post<WorkSchedulesDashboard>(`${this.apiUrl}/postNewNonWorkdays`, dto);
    // return of(WORK_SCHEDULES).pipe(delay(1000));
  }

  public postScheduleInfo(dto: ScheduleInfoDialogPost): Observable<WorkScheduleDetails> {
    return this.http.post<WorkScheduleDetails>(`${this.apiUrl}/postScheduleInfo`, dto);
    // return of(WORK_SCHEDULE_DETAILS).pipe(delay(1000)); 
  }

  public postScheduleDelete(dto: {scheduleId: number}): Observable<WorkSchedulesDashboard> {
    return this.http.post<WorkSchedulesDashboard>(`${this.apiUrl}/postScheduleDelete`, dto);
    // return of(WORK_SCHEDULES).pipe(delay(1000));
  }

  public postNonWorkdaysDelete(dto: {nonWorkdaysId: number}): Observable<WorkSchedulesDashboard> {
    return this.http.post<WorkSchedulesDashboard>(`${this.apiUrl}/PostNonWorkdaysDelete`, dto);
    // return of(WORK_SCHEDULES).pipe(delay(1000));
  }

  public postScheduleAssignedEmployers(dto: ScheduleAssignedEmployersDialogPost): Observable<WorkScheduleDetails> {
    return this.http.post<WorkScheduleDetails>(`${this.apiUrl}/postScheduleAssignedEmployers`, dto)
    // return of(WORK_SCHEDULE_DETAILS).pipe(delay(1000));
  }

  public postAssignMyEmployer(dto: {scheduleId: number}): Observable<boolean> { 
    return this.http.post<any>(`${this.apiUrl}/postAssignMyEmployer`, dto);
    // return of(true).pipe(delay(1000));
  }

  public postUnassignMyEmployer(dto: {scheduleId: number}): Observable<boolean> { 
    return this.http.post<any>(`${this.apiUrl}/postUnassignMyEmployer`, dto);
    // return of(false).pipe(delay(1000));
  }
}