import { Injectable } from '@angular/core';
import { EmployeeWithRoles, Role, RoleAdminStore, SelectionOption } from '@core/models';
import { EmployeeRole } from '@core/models/role-admin/employee-role.model';
import { RoleAdminDetails } from '@core/models/role-admin/role-admin-details.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleAdminStoreService {
  private roleAdminStore: BehaviorSubject<RoleAdminStore>;
  store$: Observable<RoleAdminStore>;
  private _templates = {
    roleAdmin: 'roleAdmin',
  };
  constructor() {
    this.roleAdminStore = new BehaviorSubject({
      details: null,
      roles: null,
      sysText: null,
    });

    this.store$ = this.roleAdminStore.asObservable();
  }

  get store(): RoleAdminStore {
    return this.roleAdminStore.value;
  }

  public get details() {
    return this.store.details;
  }

  public get details$() {
    return this.store$.pipe(pluck('details'));
  }

  public set details(details: RoleAdminDetails) {
    const store = { ...this.store, details };
    this.roleAdminStore.next(store);
  }

  public get roles() {
    return this.store.roles;
  }

  public set roles(roles: Role[]) {
    const store = { ...this.store, roles };
    this.roleAdminStore.next(store);
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText: sysText };
    this.roleAdminStore.next(store);
  }

  public get sysText() {
    return this.store.sysText;
  }

  get templates() {
    return this._templates;
  }

  get rolesAsSelectionOptions(): SelectionOption[] {
    return this.store.roles.map((r) => {
      return { id: r.id, description: r.name } as SelectionOption;
    });
  }

  addRole(roleId: string, eeId: number) {
    const role = this.roles.find((r) => r.id === roleId);
    const newEmployees = this.details.employees.map((eeWr: EmployeeWithRoles) => {
      if (eeWr.eeId === eeId) {
        return { ...eeWr, roles: [...eeWr.roles, role] };
      } else {
        return eeWr;
      }
    });
    const newDetails: RoleAdminDetails = {
      employees: newEmployees,
      showEmployer: this.details.showEmployer
    }
    this.details = newDetails;
  }

  removeRole(eeRole: EmployeeRole) {
    const newEmployees = this.details.employees.map((ewr: EmployeeWithRoles) => {
      if (ewr.eeId === eeRole.eeId) {
        ewr.roles = ewr.roles.filter((r) => r.id !== eeRole.roleId);
        return ewr;
      } else {
        return ewr;
      }
    });
    const newDetails: RoleAdminDetails = {
      employees: newEmployees,
      showEmployer: this.details.showEmployer
    }
    this.details = newDetails;
  }

  public unloadStore() {
    const store: RoleAdminStore = {
      details: null,
      roles: null,
      sysText: null
    }
    this.roleAdminStore.next(store)
  }
}
