import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EmployerProfile } from '@core/models/leave-admin/employers/employer-profile.model';
import { ErrorService, LayoutService } from '@core/services';
import { EmployerProfileStoreService } from '@core/services/employer-profile-store.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CaseComSettingsForm, EmployerCaseComSettingsPost } from '@core/models/leave-admin/employers/case-com-settings.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { StiiraError } from '@core/models';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-employer-case-com-settings',
  templateUrl: './edit-employer-case-com-settings.component.html',
  styleUrls: ['./edit-employer-case-com-settings.component.scss']
})
export class EditEmployerCaseComSettingsComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<CaseComSettingsForm>;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();

  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      employerProfile: EmployerProfile,
      sysText: any
    },
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<EditEmployerCaseComSettingsComponent>,
    private store: EmployerProfileStoreService,
    private dialog: MatDialog,
    private service: ManageEmployersService,
    private errorService: ErrorService,
  ) { 
    super(dialogRef);
    
    this.form = this.fb.group<CaseComSettingsForm>({
      automaticCcAddresses: this.fb.control(null),
      automaticBccAddresses: this.fb.control(null)
    });
  }

  ngOnInit(): void {
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit() {
    const dto: EmployerCaseComSettingsPost = {
      employerId: this.data.employerProfile.employerId,
      automaticCcAddresses: this.form.controls.automaticCcAddresses.value,
      automaticBccAddresses: this.form.controls.automaticBccAddresses.value
    };
    this.service.postEmployerCaseComSettings(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        this.store.employerProfile = res;
        this.dialogRef.close();
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.store.sysText.unsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private setupForm(): void {
    this.form.patchValue({
      automaticCcAddresses:this.data.employerProfile.automaticCcAddresses,
      automaticBccAddresses: this.data.employerProfile.automaticBccAddresses
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.formChangeEmitted && !this.noChanges) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
      
    this.formInitValues = { ...this.form.value };
  }
}