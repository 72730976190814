import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SidenavEmployeeInfo } from '@core/models';
import { appRoutePaths } from '@core/routes/route-paths.constants';

@Component({
  selector: 'app-sidenav-employee-info',
  templateUrl: './sidenav-employee-info.component.html',
  styleUrls: ['./sidenav-employee-info.component.scss']
})
export class SidenavEmployeeInfoComponent implements OnInit {
  @Input() customEmployerLogo: string = null;
  @Input() employeeInfo: SidenavEmployeeInfo;
  @Output() linkClick = new EventEmitter<void>();

  public myProlfilePath: string = appRoutePaths.PROFILE;

  constructor() {}

  ngOnInit() {}

  public handleLinkClick(): void {
    this.linkClick.emit();
  }
}
