import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Credentials, ImpersonateEmployee, ImpersonateEmployeeCredentials, Token } from '@core/models';
import { ImpersonateService } from '@core/services/impersonate.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
  providers: [ImpersonateService],
})
export class ImpersonateComponent extends DialogDragConstraints implements OnInit {
  form: UntypedFormGroup;
  impersonate$: Observable<ImpersonateEmployee>;
  isSearching$ = new Subject<boolean>();
  isSubmitting$ = new Subject<boolean>();
  sysText$: Observable<any>;

  get searchString(): string {
    return this.form.get('searchString').value;
  }

  get eeId(): number {
    return this.form.get('eeId').value;
  }

  constructor(
    private impersonationService: ImpersonateService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ImpersonateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: 
    { 
      sysText: any;
      creds: Credentials;
      token: Token;
    }
  ) {
    super(dialogRef);
    
    this.form = this.fb.group({
      searchString: [''],
      eeId: [null],
    });
  }

  ngOnInit() {
    this.impersonate$ = this.impersonationService.impersonate$;
  }

  login(): void {
    this.dialogRef.disableClose = true;

    const creds: ImpersonateEmployeeCredentials = {
      ...this.data.creds,
      // mat-selection-list provides an array, even though
      // multiple is set to false
      // get the eeId in the 0th position
      employeeId: this.eeId[0]
    };

    this.impersonationService
      .impersonateEmployee(creds, this.data.token)
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => (this.dialogRef.disableClose = false))
      )
      .subscribe((res) => {
        this.dialogRef.close(res);
      });
  }

  onSearch(): void {
    if (this.searchString) {
      let encodedString = encodeURI(this.searchString)
      this.impersonationService.searchForEmployees(encodedString, this.data.token).pipe(indicate(this.isSearching$)).subscribe();
    }
  }
}
