import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConstantsService } from '@core/services';
import { FormControl } from '@angular/forms';
import { Editor } from 'tinymce';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { PlaceholderOption } from '@core/models/case-communication/placeholder-option.model';

@Component({
  selector: 'app-email-subject-editor',
  templateUrl: './email-subject-editor.component.html',
  styleUrls: ['./email-subject-editor.component.scss']
})
export class EmailSubjectEditorComponent implements OnInit {
  @Input() control: FormControl;
  @Input() placeholderText: string;
  @Input() placeHolders: PlaceholderOption[] = [];
  @ViewChild('subjectEditor') subEditor: EditorComponent;

  public placeholdersMenuItems: string = "";
  public editorStyle: string;

  private placeholderChip: string;

  constructor(
    private constants: ConstantsService
  ) { }

  ngOnInit(): void {
    this.editorStyle = this.constants.EDITOR_STYLES;
    this.placeholderChip = this.constants.EDITOR_CHIP;
    this.placeHolders?.forEach(ph => {
      this.placeholdersMenuItems += (ph.key + " ");
    });
  }

  public getEditorText(): string {
    return this.subEditor.editor.getContent({format: "text"});
  }

  public subjectEditorSetup = (editor: Editor): void => {
    editor.on('keydown', function(e){ 
      if (e.code === 'Enter') {
        e.preventDefault();
      }
    })

    const noop = function(){};
    editor.on('init', function(){
      editor.shortcuts.add("meta+b", "", noop);
      editor.shortcuts.add("meta+u", "", noop);
      editor.shortcuts.add("meta+i", "", noop);
    })

    this.placeHolders?.forEach(ph => {
      editor.ui.registry.addMenuItem(ph.key, {
        text: ph.name,
        onAction : () => {
          editor.insertContent(this.placeholderChip
            .replace("#placeholder-name#", ph.name))
        }
      });
    });
  }
}