import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectionOption } from '@core/models';
import { EmailNotice } from '@core/models/help-and-feedback/email-notice.model';
import { HelpAndFeedback } from '@core/models/help-and-feedback/help-and-feedback.model';
import { LayoutService, NotificationService, SnackbarService } from '@core/services';
import { ContactAdminComponent } from '@modules/dialogs/contact-admin/contact-admin.component';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Input() helpAndFeedback: {sysText: any, helpInfo: HelpAndFeedback};
  @Output() closeHelp = new EventEmitter<void>();

  get isMobile$(): Observable<boolean> {
    return this.layoutService.isMobile$();
  }
  
  constructor(
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  public onCloseHelp(): void {
    this.closeHelp.emit();
  }

  public contactAdmin(admin: SelectionOption): void {
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      data: {
        admin: admin,
        sysText: this.helpAndFeedback.sysText
      }
    };
    
    this.dialog.open(ContactAdminComponent, dialogConfig)
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe((res: EmailNotice) => {
        if (res.emailDelivered) {
          let snackText = this.helpAndFeedback.sysText.contactAdmin_emailSuccess;
          snackText = snackText.replace('@[recipient]', res.recipient);
          this.snackbarService.open(snackText, this.helpAndFeedback.sysText.dismiss, 4000);
        } else {
          let toastText =  this.helpAndFeedback.sysText.contactAdmin_emailFailure;
          this.notificationService.showErrorToast(toastText);
        }
      });
  }

  public getInitials(nameString: string) {
    //remove quotes in case there is a nickname in the nameString (and no firstname)
    nameString = nameString.replace(/["]/g, '');
    const fullName = nameString.split(', ');
    const initials = fullName.pop().charAt(0) + (fullName.length > 0 ? fullName.shift().charAt(0) : "");
    return initials.toUpperCase();
  }

}
