// ignore intellisense errors for placeholders 
// non-string data types will appear as an error until the 
// string replacement has been made in the  build pipeline 
export const environment = {
  production: false,
  gatewayApi: 'https://dev-gateway.stiira.dev',
  appInsightsDisableTelemetry: false, // ignore intellisense error 
  appInsightsInstrumentationKey: '43092fca-e1b0-4225-a809-e9274365fd55',
  disableIdleTimeout: false, // ignore intellisense error 
  idleTimeLimitSeconds: 1800, // ignore intellisense error 
  alertUserTimeLeftSeconds: 180, // ignore intellisense error 
  debugBannerTitle: '(Dev)', 
};
