import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { ErrorService } from '@core/services';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { finalize, takeUntil } from 'rxjs/operators';
import { StiiraError } from '@core/models';
import { Validators } from '@angular/forms';
import { ScheduleInfoDialogPost, ScheduleInfoForm, WorkScheduleDetails, WorkScheduleInfoDialog } from '@core/models/work-schedules/work-schedule-details.model';
import { WorkSchedulesService } from '@core/services/work-schedules.service';
import { WorkScheduleDetailsStoreService } from '@core/services/work-schedule-details.store.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
@Component({
  selector: 'app-edit-schedule-info',
  templateUrl: './edit-schedule-info.component.html',
  styleUrl: './edit-schedule-info.component.scss'
})
export class EditScheduleInfoComponent extends DialogDragConstraints implements OnInit{
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<ScheduleInfoForm>;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      workScheduleDetails: WorkScheduleDetails;
      options: WorkScheduleInfoDialog;
      sysText: any; 
      unsavedChangesSysText: any
    },
    private templateInfoDialogRef: MatDialogRef<EditScheduleInfoComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private service: WorkSchedulesService,
    private store: WorkScheduleDetailsStoreService,
    private errorService: ErrorService
    ) { 
    super(templateInfoDialogRef);
    
    this.isSaving$ = new Subject();
    this.form = this.fb.group<ScheduleInfoForm>({
      scheduleName: this.fb.control(null, Validators.required),
      ownerEmployerId: this.fb.control(null),
      nonWorkdays: this.fb.control(null),
      allowUse: this.fb.control(null)
    })
  }

  ngOnInit(): void {
    this.form.patchValue({
      scheduleName: this.data.workScheduleDetails.name,
      ownerEmployerId: this.data.workScheduleDetails.ownerEmployerId,
      nonWorkdays: this.data.workScheduleDetails.nonWorkdays.map(er => er.id as number),
      allowUse: this.data.workScheduleDetails.allowUse
    });

    if (!this.data.options.canEditName) {
      this.form.controls.scheduleName.disable();
      this.form.controls.nonWorkdays.disable();
    }

    if (!this.data.options.canEditOwner) {
      this.form.controls.ownerEmployerId.disable();
    }

    if (!this.data.options.canEditAllowUse) {
      this.form.controls.allowUse.disable();
    }

    this.formInitValues = { ...this.form.value };
    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.workScheduleDetails.missingDetails)
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public save(): void {
    this.templateInfoDialogRef.disableClose = true;
    const dto: ScheduleInfoDialogPost = {
      scheduleId: this.data.workScheduleDetails.id,
      scheduleName: this.form.controls.scheduleName.value,
      nonWorkdays: this.form.controls.nonWorkdays.value,
      ownerEmployerId: this.form.controls.ownerEmployerId.value,
      allowUse: this.form.controls.allowUse.value
    }
      this.service.postScheduleInfo(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.templateInfoDialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.store.workScheduleDetails = res;
          this.templateInfoDialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.templateInfoDialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.templateInfoDialogRef.close();
        }
      });
  }
}
