<div class="title-container">
  <h6 class="grey1">{{ data.sysText.impersonate_title | titlecase }}</h6>
  <p class="subtitle1">{{ data.sysText.impersonate_subtitle | capitalizefirst }}</p>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <ng-template #searching>
      <div class="spinner-container">
        <mat-spinner color="accent" diameter="50"></mat-spinner>
      </div>
    </ng-template>
    <ng-container *ngIf="!(isSearching$ | async); else searching">
      <div class="field-container">
        <mat-form-field
          color="accent"
          [hintLabel]="(impersonate$ | async).additionalMatches ? (data.sysText.additionalMatchesFound | capitalizefirst) : ''">
          <input matInput [placeholder]="data.sysText.impersonate_search" formControlName="searchString"/>
          <mat-hint align="end" *ngIf="(impersonate$ | async).eeList !== null">
            {{ (impersonate$ | async)?.totalMatchesFound }}
            {{ (impersonate$ | async)?.eeList?.length === 1 ? data.sysText.matchFound : data.sysText.matchesFound }}
          </mat-hint>
        </mat-form-field>
        <span class="spacer"></span>
        <button mat-icon-button color="accent" aria-label="icon button with a search icon" type="submit" (click)="onSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="impersonate$ | async as impersonate">
        <mat-selection-list formControlName="eeId" [multiple]="false">
          <mat-list-option *ngFor="let ee of impersonate?.eeList" [value]="ee.eeId">{{ ee.contactName }}</mat-list-option>
        </mat-selection-list>
      </ng-container>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngIf="!(isSubmitting$ | async); else submitting">
    <button
      mat-stroked-button
      class="button"
      color="accent"
      (click)="login()"
      [disabled]="!eeId || (isSubmitting$ | async)">
      {{ data.sysText.impersonate_button }}
    </button>
  </ng-container>
  <ng-template #submitting>
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </ng-template>
</mat-dialog-actions>
