<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 class="grey1">{{data.sysText.contact}} {{data.admin.description}}</h6>
</div>
  
<mat-dialog-content class="mat-dialog-content">
  <ng-container>
    <form [formGroup]="form">
        <app-input-field
            [group]="form"
            [control]="title"
            [icon]="'mail'"
            [label]="data.sysText.title"
        ></app-input-field>
        <app-text-area
            [control]="message"
            [label]="data.sysText.message"
            [icon]="'edit_note'"
            [rows]="1"
        ></app-text-area>

      <input hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files)"/>
      <div class="file-input-field">
        <mat-icon class="input-icon">attach_file</mat-icon>
        <button mat-stroked-button color="accent" (click)="imgFileInput.click()" class="body2">
          <mat-icon>upload</mat-icon>
          <span> {{data.sysText.upload | capitalizefirst}} </span>
        </button>
        <mat-error class="body 2 upload-error">{{ attachment.errors?.msError}}</mat-error>
      </div>
      <div class="pad" *ngIf="fileToUpload">
        <div class="flex-horiz bot-space">
          <mat-icon class="fill4-dk">check</mat-icon>
          <div>
            <span class="body2 side-space grey3">{{ fileToUpload?.name }}</span>
          </div>
          <mat-icon class="black-inactive small pointer" (click)="clearFile()">clear</mat-icon>
        </div>
      </div>
    </form>
  </ng-container>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <ng-container *ngIf="!(isSaving$ | async); else saving">
        <button
            mat-stroked-button
            class="button"
            color="accent"
            (click)="cancel()"
            [disabled]="isSaving$ | async"
        >
            {{ data.sysText.cancel }}
        </button>
        <button mat-stroked-button class="button" color="accent" (click)="onSubmit()" [disabled]="(isSaving$ | async)">
            {{ data.sysText.submit }}
        </button>
    </ng-container>
    <ng-template #saving>
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
        </button>
    </ng-template>
</mat-dialog-actions>
  
