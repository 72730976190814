import { Injectable } from '@angular/core';
import { SidenavEmployeeInfo } from '@core/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private avatarSubject: BehaviorSubject<SidenavEmployeeInfo>;
  private sidenavSubject: Subject<void>;
  
  public sidenavUpdate$: Observable<void>;
  public avatar$: Observable<SidenavEmployeeInfo>;

  constructor() {
    this.avatarSubject = new BehaviorSubject(null);
    this.avatar$ = this.avatarSubject.asObservable();

    this.sidenavSubject = new Subject();
    this.sidenavUpdate$ = this.sidenavSubject.asObservable();
  }

  public setAvatarSubject(info: SidenavEmployeeInfo): void {
    this.avatarSubject.next(info);
  }

  public updateAvatar(img: string): void {
    this.avatarSubject.next({
      ...this.avatarSubject.value,
      image: img
    });
  }

  public updateName(name: string): void {
    this.avatarSubject.next({
      ...this.avatarSubject.value,
      name: name
    });
  }

  public updateSidenav(): void {
    this.sidenavSubject.next();
  }
}
