import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TemplateRequest } from '@core/models';
import { Observable, of } from 'rxjs';
import SYSTEXT_MODELS from '../../../resources/systemText-english.json';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class SysTextService {
  // #region Constructors (1)

  constructor(private constants: ConstantsService, private http: HttpClient) {}

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public getSysText(template: string | object): Observable<any> {
    const tempReq: TemplateRequest = this.makeTemplateRequestDTO(template);

    return this.http.post(`${this.constants.LANGUAGE_API}/GetTemplateSystemText/`, tempReq);

    // if (tempReq.names.length > 1) {
    //   const sysTextModels = {};
    //   tempReq.names.forEach((n) => {
    //     sysTextModels[n] = SYSTEXT_MODELS.templates[n];
    //   });
    //   return of(sysTextModels);
    // } else {
    //   return of(SYSTEXT_MODELS.templates[tempReq.names[0]]);
    // }
  }

  // #endregion Public Methods (1)

  // #region Private Methods (3)

  private isObject(obj: any): boolean {
    return obj === Object(obj);
  }

  private makeTemplateNameArray(templateObj: object): string[] {
    return Object.keys(templateObj).map((itm) => templateObj[itm]);
  }

  private makeTemplateRequestDTO(template: string | object): TemplateRequest {
    const tReq: TemplateRequest = {
      languageCode: 'en',
      names: [],
    };

    if (!this.isObject(template)) {
      tReq.names.push(template as string);
    } else {
      tReq.names = this.makeTemplateNameArray(template as object);
    }
    return { ...tReq };
  }

  // #endregion Private Methods (3)
}
