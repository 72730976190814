<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 >
      {{data.sysText.mergeDuplicates_confirmMerge}}
  </h6>
    <ng-container *ngIf="isHandheld">
      <span class="spacer"></span>
      <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
    </ng-container>
</div>

<mat-dialog-content>
    <p class="body2">{{data.sysText.mergeDuplicates_confirmMerge_subtitle}}:</p>
    <app-merge-duplicates-card
        class="bottom-margin"
        [employeeInfo]="data.employeeInfo"
        [sysText]="data.sysText"
        [isPrimary]="true">
      </app-merge-duplicates-card>
    <p class="body2">{{data.sysText.mergeDuplicates_confirmMerge_message_1}}</p>
    <p class="body2">{{data.sysText.mergeDuplicates_confirmMerge_message_2}} <span class="semibold">{{data.sysText.mergeDuplicates_confirmMerge_message_3}}</span></p>
</mat-dialog-content>

<mat-dialog-actions class="actions" align="end" *ngIf="!(isSubmitting$ | async); else submitting">   
    <mat-error *ngIf="this.form.errors?.msError" class="mse-form-error caption">{{ this.form.errors?.msError }}</mat-error>
    <button mat-stroked-button class="button" color="accent" (click)="close()">
      {{data.sysText.cancel}}
    </button>
    <button 
        mat-stroked-button 
        class="button" 
        color="accent" 
        (click)="onConfirm()">
      {{data.sysText.confirm}}
    </button>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </mat-dialog-actions>
</ng-template>