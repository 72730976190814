<ng-container [formGroup]="group" *ngIf="group">
  <ng-container *ngTemplateOutlet="radioGroup"></ng-container>
</ng-container>

<ng-container *ngIf="!group">
  <ng-container *ngTemplateOutlet="radioGroup"></ng-container>
</ng-container>

<ng-template #radioGroup>
  <div class="container">
    <div class="label">
      <mat-icon [ngClass]="isStacked ? 'align-start' : ''">{{ icon }}</mat-icon>
      <p class="body2">{{ shouldCapitalizeLabel ? (label | titlecase) : label }}</p>
      <mat-icon *ngIf="info" [matTooltip]="info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
    </div>
    <div [ngClass]="isStacked ? 'radio-stacked' : 'radio-flat'" class="radio-group">
      <mat-radio-group aria-label="Select an option" [formControl]="control" color="accent" (change)="onChange($event)">
        <ng-container *ngIf="!isYesNo; else yesNoButtons">
          <mat-radio-button *ngFor="let option of options" [value]="option.id">{{ option.description | capitalizefirst }}</mat-radio-button>
        </ng-container>
        <ng-template #yesNoButtons>
          <mat-radio-button [value]="true">{{ customYes ? customYes : (sysText.yes | capitalizefirst) }}</mat-radio-button>
          <mat-radio-button [value]="false">{{ customNo ? customNo : (sysText.no | capitalizefirst) }}</mat-radio-button>
        </ng-template>
        <mat-hint *ngIf="hint" [ngClass]="{ highlight: isHintHighlighted }">
          <span class="hint">
            {{ hint }}
          </span>
        </mat-hint>
        <mat-error *ngIf="control.errors?.msError" class="hint">{{ control.errors?.msError }}</mat-error>
      </mat-radio-group>
    </div>
  </div>
</ng-template>
