import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SendInvitationDialog, SendInvitationPost } from '@core/models/leave-admin/employees/send-invitation-post.model';
import { LayoutService, SnackbarService } from '@core/services';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss']
})
export class SendInvitationComponent extends DialogDragConstraints implements OnInit {
  isSaving$: Subject<boolean>;
  isHandheld$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      inviteDialog: SendInvitationDialog; 
      sysText: any;
    },
    private sendInvitationdialogRef: MatDialogRef<SendInvitationComponent>,
    private manageEmployeesService: ManageEmployeesService,
    private layoutService: LayoutService,
    private snackbar: SnackbarService
    ) { 
    super(sendInvitationdialogRef);
    
    this.isSaving$ = new Subject();
    }

  ngOnInit(): void {
    this.isHandheld$ = this.layoutService.isHandheld$();
  }

  save(): void {
    this.sendInvitationdialogRef.disableClose = true;
    const dto: SendInvitationPost = {
      employeeId: this.data.inviteDialog.employeeId
    }
      this.manageEmployeesService.
        postSendInvitation(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.sendInvitationdialogRef.disableClose = false;
            this.sendInvitationdialogRef.close();
          })
        )
        .subscribe((res) => {
          this.sendInvitationdialogRef.close();
          let snackText =  this.data.sysText.invitationSuccessSnackBar;
          this.snackbar.open(snackText, this.data.sysText.dismiss);
        });
  }

}
