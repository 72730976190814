import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { AddNewEmployeeDialog } from '@core/models/leave-admin/employees/add-new-employee-dialog.model';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { NewEmployeeForm, NewEmployeePost } from '@core/models/leave-admin/employees/new-employee-post.model';
import { ErrorService, LayoutService } from '@core/services';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { DuplicateDetectedDialogComponent } from '../duplicate-detected-dialog/duplicate-detected-dialog.component';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-add-new-employee',
  templateUrl: './add-new-employee.component.html',
  styleUrls: ['./add-new-employee.component.scss'],
})
export class AddNewEmployeeComponent extends DialogDragConstraints implements OnInit {
  public form: FormGroup<NewEmployeeForm>;
  public isSaving$: Subject<boolean> = new Subject<boolean>();

  private destroy$: Subject<void> = new Subject<void>();
  private formInitValues: any;

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get firstName(): FormControl<string> {
    return this.form.controls.firstName as FormControl<string>;
  }

  get middleName(): FormControl<string> {
    return this.form.controls.middleName as FormControl<string>;
  }

  get lastName(): FormControl<string> {
    return this.form.controls.lastName as FormControl<string>;
  }

  get suffix(): FormControl<string> {
    return this.form.controls.suffix as FormControl<string>;
  }

  get preferredName(): FormControl<string> {
    return this.form.controls.preferredName as FormControl<string>;
  }

  get workEmail(): FormControl<string> {
    return this.form.controls.workEmail as FormControl<string>;
  }

  get isWorkEmailPreferred(): FormControl<boolean> {
    return this.form.controls.isWorkEmailPreferred as FormControl<boolean>;
  }

  get personalEmail(): FormControl<string> {
    return this.form.controls.personalEmail as FormControl<string>;
  }

  get isPersonalEmailPreferred(): FormControl<boolean> {
    return this.form.controls.isPersonalEmailPreferred as FormControl<boolean>;
  }

  get workPhone(): FormControl<string> {
    return this.form.controls.workPhone as FormControl<string>;
  }

  get personalPhone(): FormControl<string> {
    return this.form.controls.personalPhone as FormControl<string>;
  }

  get companyId(): FormControl<number> {
    return this.form.controls.companyId as FormControl<number>;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      addNewEmployeeDialog: AddNewEmployeeDialog,
      sysText: any,
      unsavedSysText: any,
      duplicateDetectedSysText: any
      },
    private layoutService: LayoutService,
    private addNewEmployeeComponent: MatDialogRef<AddNewEmployeeComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private errorService: ErrorService,
    private service: ManageEmployeesService,
    private dialogRef: MatDialogRef<AddNewEmployeeComponent>,
  ) { 
    super(dialogRef);

    this.form = this.fb.group<NewEmployeeForm>({
      firstName: this.fb.control(null, Validators.required),
      middleName: this.fb.control(null),
      lastName: this.fb.control(null, Validators.required),
      suffix: this.fb.control(null),
      preferredName: this.fb.control(null),
      workEmail: this.fb.control(null),
      isWorkEmailPreferred: this.fb.control(false),
      personalEmail: this.fb.control(null),
      isPersonalEmailPreferred: this.fb.control(false),
      workPhone: this.fb.control(null),
      personalPhone: this.fb.control(null),
      companyId: this.fb.control(null)
    });
  }

  ngOnInit(): void {
    this.setUpForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save(overrideDuplicates: boolean): void {
    this.form.markAsUntouched();
    const dto: NewEmployeePost = {
      overrideDuplicates: overrideDuplicates,
      firstName: this.firstName.value,
      middleName: this.middleName.value,
      lastName: this.lastName.value,
      suffix: this.suffix.value,
      preferredName: this.preferredName.value,
      workEmail: this.workEmail.value,
      isWorkEmailPreferred: this.isWorkEmailPreferred.value,
      personalEmail: this.personalEmail.value,
      isPersonalEmailPreferred: this.isPersonalEmailPreferred.value,
      workPhone: this.workPhone.value,
      personalPhone: this.personalPhone.value,
      companyId: this.companyId.value,
    }

    this.service.postNewEmployee(dto)
      .pipe(indicate(this.isSaving$))
      .subscribe((res)=>{
        if (typeof(res) != 'number') {
          this.openDuplicateDetectedDialog(res as DuplicateDetectedDialog);
        } else {
          this.dialogRef.close(res);
        }
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  public cancel(): void {
    if (this.noChanges) {
      this.addNewEmployeeComponent.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openDuplicateDetectedDialog(duplicateDetectedDialog: DuplicateDetectedDialog): void {
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '550px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      maxHeight: this.layoutService.isHandheld ? '100vh' : '',
      height: this.layoutService.isHandheld ? '100vh' : '',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.duplicateDetectedSysText,
        duplicateDetectedDialog: duplicateDetectedDialog,
      }
    };

    this.dialog.open(DuplicateDetectedDialogComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.save(true);
        }
      });
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.addNewEmployeeComponent.close();
        }
      });
  }

  private setUpForm(): void {
    if (this.data.addNewEmployeeDialog.showCompany) {
      this.companyId.setValidators(Validators.required);
    }

    this.formInitValues = { ...this.form.value };
  }
}