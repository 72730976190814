import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@modules/material/material.module';
import { GlobalLoaderComponent } from './components/global-loader/global-loader.component';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';
import { ProgressIndictorCircleComponent } from './components/progress-indictor-circle/progress-indictor-circle.component';
import { RoleChipComponent } from './components/role-chip/role-chip.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { WidgetTopBarComponent } from './components/widget-top-bar/widget-top-bar.component';
import { ObserveDirective } from './directives/observe.directive';
import { AutocompleteFieldComponent } from './form-field-types/autocomplete-field/autocomplete-field.component';
import { DateFieldComponent } from './form-field-types/date-field/date-field.component';
import { InputFieldComponent } from './form-field-types/input-field/input-field.component';
import { RadioButtonGroupComponent } from './form-field-types/radio-button-group/radio-button-group.component';
import { SelectFieldComponent } from './form-field-types/select-field/select-field.component';
import { MultiSelectFieldComponent } from './form-field-types/multi-select-field/multi-select-field.component';
import { TextAreaComponent } from './form-field-types/text-area/text-area.component';
import { CapitalizefirstPipe } from './pipes/capitalizefirst.pipe';
import { CurrencyFormatterDirective } from './directives/currency-formatter.directive';
import { FindfromidPipe } from './pipes/findfromid.pipe';
import { SplitstringPipe } from './pipes/splitstring.pipe';
import { LeaveCategoryPipe } from './pipes/leavecategory.pipe';
import { EmployeeselectionoptionsubtextPipe } from './pipes/employeeselectionoptionsubtext.pipe';
import { FormattedphonenumberPipe } from '@shared/pipes/formattedphonenumber.pipe';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { SlideToggleComponent } from './form-field-types/slide-toggle/slide-toggle.component';
import { MergeDuplicatesCardComponent } from './components/merge-duplicates-card/merge-duplicates-card.component';
import { HistoryDatePipe } from './pipes/history-date.pipe';
import { GetdocextPipe } from './pipes/getdocext.pipe';
import { EmailFieldGroupComponent } from './form-field-types/email-field-group/email-field-group.component';
import { LeaveCalendarComponent } from '@modules/leave-admin/components/leave-calendar/leave-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
  declarations: [
    PasswordValidatorComponent,
    WidgetTopBarComponent,
    CapitalizefirstPipe,
    GlobalLoaderComponent,
    ObserveDirective,
    StatusChipComponent,
    ProgressIndictorCircleComponent,
    InputFieldComponent,
    SelectFieldComponent,
    MultiSelectFieldComponent,
    RadioButtonGroupComponent,
    AutocompleteFieldComponent,
    TextAreaComponent,
    FindfromidPipe,
    LeaveCategoryPipe,
    DateFieldComponent,
    SplitstringPipe,
    RoleChipComponent,
    CurrencyFormatterDirective,
    EmployeeselectionoptionsubtextPipe,
    FormattedphonenumberPipe,
    StatusIconComponent,
    SlideToggleComponent,
    MergeDuplicatesCardComponent,
    HistoryDatePipe,
    GetdocextPipe,
    EmailFieldGroupComponent,
    LeaveCalendarComponent
  ],
  imports: [
    CommonModule, 
    MaterialModule, 
    FormsModule, 
    ReactiveFormsModule, 
    RouterModule,
    FullCalendarModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PasswordValidatorComponent,
    WidgetTopBarComponent,
    CapitalizefirstPipe,
    GlobalLoaderComponent,
    ObserveDirective,
    StatusChipComponent,
    ProgressIndictorCircleComponent,
    InputFieldComponent,
    SelectFieldComponent,
    MultiSelectFieldComponent,
    RadioButtonGroupComponent,
    AutocompleteFieldComponent,
    TextAreaComponent,
    FindfromidPipe,
    LeaveCategoryPipe,
    DateFieldComponent,
    SplitstringPipe,
    RoleChipComponent,
    CurrencyFormatterDirective,
    EmployeeselectionoptionsubtextPipe,
    FormattedphonenumberPipe,
    StatusIconComponent,
    SlideToggleComponent,
    MergeDuplicatesCardComponent,
    HistoryDatePipe,
    GetdocextPipe,
    EmailFieldGroupComponent,
    LeaveCalendarComponent
  ],
  providers: [CurrencyPipe, DecimalPipe],
})
export class SharedModule {}
