import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'historyDate'
})
export class HistoryDatePipe implements PipeTransform {

  transform(date: Date, ): string {
    const dif = Date.now() - new Date(date).valueOf();
    const m = Math.round((dif / (1000 * 60)));
    const h = Math.round((dif / (1000 * 60 * 60)));
    const d = Math.round((dif / (1000 * 60 * 60 * 24)));
    return m < 60 ? m.toString() + "m" : h < 24 ? h.toString() + "h" : d.toString() + "d";
  }

}
