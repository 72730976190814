<h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  Attention
</h6>

<mat-dialog-content>
  <p
    class="subtitle1"
  >
    We are currently troubleshooting performance issues affecting users of 
    Stiira. Be aware that you may experience long delays when attempting to 
    login or use Stiira features. Please contact your administrator if 
    normal performance levels are not restored. 
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    class="button"
    [mat-dialog-close]
    color="accent"
  >
    Ok
  </button>
</mat-dialog-actions>
