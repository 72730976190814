import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { StiiraError } from '@core/models';
import { AuthService, ErrorService, NotificationService, RedirectService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { appRoutePaths } from '@core/routes/route-paths.constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private notifyService: NotificationService,
    private router: Router,
    private redirectService: RedirectService,
  ) {}

  intercept(req: HttpRequest<any>, newReq: HttpHandler): Observable<HttpEvent<any>> {
    return newReq.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        const stiiraError: StiiraError = this.errorService.createStiiraError(error);
        
        //sylvania is unreachable during launch
        if (!this.authService.isAuth){
          if (this.errorService.isLaunchConnectionError(error)) {
            this.notifyService.showErrorToast(this.errorService.launchErrorMessage)
            return throwError(this.errorService.launchErrorMessage);
          }
        }

        // unauthorized
        if (error.status === 401) {
          // get last url navigated to before logout routes to login
          const url = this.redirectService.lastNavigationUrl;
          // then logout (will 'resetRedirectUrl')
          this.authService.logout();
          // then set redirect 
          this.redirectService.setRedirectUrl = url;
        }

        // handle url query parameters returning a 500
        if (this.authService.isUrlWithQueryParams && this.authService.isAuth && error.status === 500) {
          this.authService.setIsUrlWithQueryParams(false);
          this.router.navigate([appRoutePaths.HOME]);
        }

        // display global error
        if (stiiraError.globalError) {
          this.notifyService.showErrorToast(stiiraError.globalError);
        }

        return throwError(stiiraError);
      })
    );
  }
}
