import { Pipe, PipeTransform } from '@angular/core';
import { LeaveListItem } from '@core/models/leave-admin/leave-list-item.model';

@Pipe({
  name: 'leavecategory'
})
export class LeaveCategoryPipe implements PipeTransform {

  transform(value: LeaveListItem[], categoryId: number): any {
      return value.filter(s => s.leaveCategory?.id === categoryId && !s.isClosed && !s.isHidden)
  }

}
