import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionOption, AssignCaseAdmin, StiiraError, AssignAdminForm } from '@core/models';
import { ErrorService, LayoutService } from '@core/services';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-case-leave-admin',
  templateUrl: './assign-case-admin.component.html',
  styleUrls: ['./assign-case-admin.component.scss']
})
export class AssignCaseAdminComponent extends DialogDragConstraints implements OnInit {
  public form: FormGroup<AssignAdminForm>;
  isSubmitting$: Subject<boolean>;

  get employeeId(): FormControl<number> {
    return this.form.controls.employeeId as FormControl<number>;
  }  

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }
  
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: { options: SelectionOption[], caseId: number, showCompany: boolean, sysText: any },
    private service: LeaveAdminService,
    private store: LeaveAdminStoreService,
    private assignCaseDialogRef: MatDialogRef<AssignCaseAdminComponent>,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private errorService: ErrorService
  ) { 
    super(assignCaseDialogRef);

    this.isSubmitting$ = new Subject();
    this.form = this.fb.group<AssignAdminForm>({
      employeeId: this.fb.control(null)
    });
  }

  ngOnInit(): void { }
  
  public close(): void {
    this.assignCaseDialogRef.close();
  }
  
  public assign(): void {
    this.assignCaseDialogRef.disableClose = true;
    const assignAdmin: AssignCaseAdmin = {
      employeeId: this.employeeId.value,
      caseId: this.data.caseId
    }
    this.service
    .assignCaseAdmin(assignAdmin)
    .pipe(
      indicate(this.isSubmitting$),
      finalize(() => {
          this.assignCaseDialogRef.disableClose = false;
      })
    )
    .subscribe((res) => {
      this.store.updateCaseAdmin(res.administrator);
      this.assignCaseDialogRef.close(res);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }  

  public displayFn(id: number): string {
    if (!id) {
      return '';
    }
    return (this.data.options as SelectionOption[]).find((opt) => opt.id === id).description;
  }
}