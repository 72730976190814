<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6>
		{{ data.sysText.editSchedule_header | titlecase }}
	</h6>
</div>

<mat-dialog-content>
	<form [formGroup]="form">
		<div class="schedule-container">
			<div class="field-container" *ngIf="form.controls.scheduleWeeks.controls.length > 1">
				<app-date-field
					[label]="data.sysText.alignmentDate | titlecase"
					[info]="data.sysText.alignmentDate_info"
					[control]="form.controls.alignmentDate"
					[icon]="'calendar_today'"
					[group]="form">
				</app-date-field>
			</div>
			<div class="workday-headers">
				<span class="row-label body2 grey2"></span>
				<span class="span-cell body2 grey2">{{ data.sysText.sun | titlecase }}</span>
				<span class="span-cell body2 grey2">{{ data.sysText.mon | titlecase }}</span>
				<span class="span-cell body2 grey2">{{ data.sysText.tue | titlecase }}</span>
				<span class="span-cell body2 grey2">{{ data.sysText.wed | titlecase }}</span>
				<span class="span-cell body2 grey2">{{ data.sysText.thu | titlecase }}</span>
				<span class="span-cell body2 grey2">{{ data.sysText.fri | titlecase }}</span>
				<span class="span-cell body2 grey2">{{ data.sysText.sat | titlecase }}</span>
				<span *ngIf="form.controls.scheduleWeeks.controls.length > 1" class="button-spacer"></span>
			</div>
			<div class="schedule-week-container">
				@for(weekFormGroup of form.controls.scheduleWeeks.controls; track weekFormGroup; let i = $index) {
					<div class="schedule-week">
						<div class="week-container">
							<div class="row">
								<span class="row-label body2 grey2">{{ data.sysText.scheduleWorkday }}</span>
								<mat-checkbox
									class="span-cell"
									[formControl]="weekFormGroup.controls.worksSunday">
								</mat-checkbox>
								<mat-checkbox
									class="span-cell"
									[formControl]="weekFormGroup.controls.worksMonday">
								</mat-checkbox>
								<mat-checkbox
									class="span-cell"
									[formControl]="weekFormGroup.controls.worksTuesday">
								</mat-checkbox>
								<mat-checkbox
									class="span-cell"
									[formControl]="weekFormGroup.controls.worksWednesday">
								</mat-checkbox>
								<mat-checkbox
									class="span-cell"
									[formControl]="weekFormGroup.controls.worksThursday">
								</mat-checkbox>
								<mat-checkbox
									class="span-cell"
									[formControl]="weekFormGroup.controls.worksFriday">
								</mat-checkbox>
								<mat-checkbox
									class="span-cell"
									[formControl]="weekFormGroup.controls.worksSaturday">
								</mat-checkbox>
							</div>
							<div class="row">
								<span class="row-label body2 grey2">{{ data.sysText.scheduleHours }}</span>
								<app-input-field
									class="hours-input"
									[group]="form"
									[hideIconSpace]="true"
									[control]="weekFormGroup.controls.sundayHours"
									[inputType]="'number'"
									[isHintHighlighted]="false"
									[isHintWarning]="true">
								</app-input-field>
								<app-input-field
									class="hours-input"
									[group]="form"
									[hideIconSpace]="true"
									[control]="weekFormGroup.controls.mondayHours"
									[inputType]="'number'"
									[isHintHighlighted]="false"
									[isHintWarning]="true">
								</app-input-field>
								<app-input-field
									class="hours-input"
									[group]="form"
									[hideIconSpace]="true"
									[control]="weekFormGroup.controls.tuesdayHours"
									[inputType]="'number'"
									[isHintHighlighted]="false"
									[isHintWarning]="true">
								</app-input-field>
								<app-input-field
									class="hours-input"
									[group]="form"
									[hideIconSpace]="true"
									[control]="weekFormGroup.controls.wednesdayHours"
									[inputType]="'number'"
									[isHintHighlighted]="false"
									[isHintWarning]="true">
								</app-input-field>
								<app-input-field
									class="hours-input"
									[group]="form"
									[hideIconSpace]="true"
									[control]="weekFormGroup.controls.thursdayHours"
									[inputType]="'number'"
									[isHintHighlighted]="false"
									[isHintWarning]="true">
								</app-input-field>
								<app-input-field
									class="hours-input"
									[group]="form"
									[hideIconSpace]="true"
									[control]="weekFormGroup.controls.fridayHours"
									[inputType]="'number'"
									[isHintHighlighted]="false"
									[isHintWarning]="true">
								</app-input-field>
								<app-input-field
									class="hours-input"
									[group]="form"
									[hideIconSpace]="true"
									[control]="weekFormGroup.controls.saturdayHours"
									[inputType]="'number'"
									[isHintHighlighted]="false"
									[isHintWarning]="true">
								</app-input-field>
							</div>
						</div>
						<button
							mat-icon-button
							aria-label="remove button"
							[disableRipple]="true"
							(click)="onRemoveWeek(i)"
							*ngIf="form.controls.scheduleWeeks.controls.length > 1">
							<mat-icon class="no-margin accent">close</mat-icon>
						</button>
					</div>
				}
			</div>
		</div>
	</form>
	<div class="bottom-section">
		<mat-error *ngIf="form.controls.scheduleWeeks.errors?.msError" class="mse-form-error caption bottom-hint">
			{{ form.controls.scheduleWeeks.errors?.msError }}
		</mat-error>
		<div class="button-container">
			<button
				(click)="onAddWeek()"
				mat-flat-button
				color="accent"
				class="button">
				{{data.sysText.addAdditionalWeek | uppercase}}
			</button>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
	<button mat-stroked-button class="button" color="accent" (click)="cancel()">
		{{ data.sysText.cancel }}
	</button>
	<button mat-stroked-button class="button" color="accent" (click)="save()">
		{{ data.sysText.save }}
	</button>
</mat-dialog-actions>
<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>
