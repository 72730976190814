import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, UntypedFormGroup } from '@angular/forms';
import { EmployerContactsDialog } from '@core/models/leave-admin/employers/employer-contacts.model';

@Injectable()
export class EmployerContactsFormService {
  public form: UntypedFormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({      
        primaryTenantAdmin: this.initNewGroup(),
        primaryEmployerAdmin: this.initNewGroup(),
        helpFeedbackAdmin: this.initNewGroup(),
        includeCaseAdminsOnHelpForm: [null],
        showHelpAndFeedbackOnCaseDetails: [null],
        mainContacts: this.fb.array([this.initNewGroup()]),
        benefitsContacts: this.fb.array([this.initNewGroup()]),
        benefitDeductionsContacts: this.fb.array([this.initNewGroup()]), 
        payrollContacts: this.fb.array([this.initNewGroup()]),
    });
  }

  public initNewGroup(): FormGroup{
    return this.fb.group({
      employeeId: [null],
      firstName: [null],
      middleName: [null],
      lastName: [null],
      suffix: [null],
      preferredName: [null],
      workPhone: [null],
      personalPhone: [null],
      workEmail: [null],
      workEmailChecked: [null],
      personalEmail: [null],
      personalEmailChecked: [null]
    });
  }

  loadFormForEdit(data: EmployerContactsDialog): void {  

    this.form.controls.includeCaseAdminsOnHelpForm.setValue(data.profileEmployerContacts.includeCaseAdminsOnHelp);
    this.form.controls.showHelpAndFeedbackOnCaseDetails.setValue(data.profileEmployerContacts.showHelpAndFeedbackOnCase);

    Object.keys(data.profileEmployerContacts).forEach(key => {
      const contactData = data.profileEmployerContacts[key];
      
      if (contactData != null) {
        let formGroup = this.form.get(key) as FormGroup;

        if (Array.isArray(contactData)){
          //arrays such as mainContacts, benefitsContacts, benefitDeductionsContacts and payrollContacts
          if (contactData.length > 0) {
            const formArray = this.form.get(key) as FormArray;
            formArray.controls.forEach((obj, index) => {
              if (contactData[index] != null) {
                obj.patchValue({employeeId: contactData[index].id});  
              }
            });
          }
        } else if (formGroup) {
          //single objects such as primaryTenantAdmin, primaryEmployerAdmin, and helpFeedbackAdmin
          formGroup.controls.employeeId.patchValue(contactData.id);
        }

      }
    });
  }
}