import { Injectable } from '@angular/core';
import { SharedComponentsSysText } from '@core/models';
import { BehaviorSubject } from 'rxjs';
import { SysTextService } from './sys-text.service';

@Injectable({
  providedIn: 'root',
})
export class SharedComponentsSysTextService {
  // #region Properties (2)

  private _sysText: BehaviorSubject<SharedComponentsSysText> = new BehaviorSubject(null);
  private _templates: SharedComponentsSysText = {
    autocompleteField: 'appAutocompleteField',
    dateField: 'appDateField',
    inputField: 'appInputField',
    selectField: 'appSelectField',
    radioButtonGroup: 'appRadioButtonGroup',
    textAreaField: 'appTextAreaField',
    emailFieldGroup: 'appEmailFieldGroup'
  };

  // #endregion Properties (2)

  // #region Constructors (1)

  constructor(private textService: SysTextService) {}

  // #endregion Constructors (1)

  // #region Public Accessors (3)

  public get sysText(): SharedComponentsSysText {
    return this._sysText.value;
  }

  public set sysText(sysText: SharedComponentsSysText) {
    this._sysText.next(sysText);
  }

  public get templates(): SharedComponentsSysText {
    return this._templates;
  }

  // #endregion Public Accessors (3)

  // #region Public Methods (1)

  public async init(): Promise<void> {
    const res = await this.textService.getSysText(this._templates).toPromise();
    this.sysText = res;
  }

  // #endregion Public Methods (1)
}
