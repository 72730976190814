import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { ErrorService, ProfileService, SnackbarService } from '@core/services';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers/rxjs.helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent extends DialogDragConstraints implements OnInit {
  form: UntypedFormGroup;
  isSubmitting$ = new Subject<boolean>();

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangeEmailComponent>,
    private profileService: ProfileService,
    private snackbarService: SnackbarService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public sysText
  ) { 
    super(dialogRef);
  }

  ngOnInit(): void {
    this.form = this.fb.group({      
      email: ['', Validators.required],
    });
  }

  public changeEmail(): void {
    this.dialogRef.disableClose = true;
    this.profileService
      .changeEmail({email: this.form.value.email})
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => (this.dialogRef.disableClose = false))
      )
      .subscribe(
        () => {
          this.snackbarService.open('Email successfully changed', 'Dismiss');          
          this.dialogRef.close();
        },
        (err: StiiraError) => {
          this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors);
        }
      );
  }
}