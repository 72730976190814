import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ValidationMethod } from '@core/enums';
import { PasswordRequirement } from '@core/models';
import { ConstantsService } from '@core/services';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss']
})
export class PasswordValidatorComponent implements OnInit, OnChanges {
  @Input() password: string;
  @Input() reqList: PasswordRequirement[];
  @Output() validationUpdate = new EventEmitter<boolean>();

  asciiArray: number[];

  constructor(private constants: ConstantsService) {}

  ngOnInit(): void {
    this.runValidationChecks();
  }

  ngOnChanges(): void {
    this.runValidationChecks();
  }

  private runValidationChecks(): void {
    this.asciiArray = this.password.split('').map(char => char.charCodeAt(0));

    this.reqList.forEach(req => {
      if (req.validationMethodId === ValidationMethod.minLength) {
        this.minLengthCheck(req.value);
      } else if (req.validationMethodId === ValidationMethod.upperCase) {
        this.upperCaseCheck(req.value);
      } else if (req.validationMethodId === ValidationMethod.lowerCase) {
        this.lowerCaseCheck(req.value);
      } else if (req.validationMethodId === ValidationMethod.number) {
        this.numberCheck(req.value);
      } else {
        this.specialCharCheck(req.value);
      }
    });
  }

  private minLengthCheck(value: number): void {
    const result = this.password.length >= value ? true : false;
    this.updateRequirements(ValidationMethod.minLength, result);
  }

  private lowerCaseCheck(value: number): void {
    const result = this.countOccurances(this.constants.LOWERCASE_ASCII_RANGES) >= value ? true : false;
    this.updateRequirements(ValidationMethod.lowerCase, result);
  }

  private upperCaseCheck(value: number): void {
    const result = this.countOccurances(this.constants.UPPERCASE_ASCII_RANGES) >= value ? true : false;
    this.updateRequirements(ValidationMethod.upperCase, result);
  }

  private numberCheck(value: number): void {
    const result = this.countOccurances(this.constants.NUMBER_ASCII_RANGES) >= value ? true : false;
    this.updateRequirements(ValidationMethod.number, result);
  }

  private specialCharCheck(value: number): void {
    const result = this.countOccurances(this.constants.SPECIALCHAR_ASCII_RANGES) >= value ? true : false;
    this.updateRequirements(ValidationMethod.specialChar, result);
  }

  private countOccurances(rangeArr: { min: number; max: number }[]): number {
    let count = 0;
    rangeArr.forEach(range => {
      count += this.asciiArray.reduce((n, x) => n + (x >= range.min && x <= range.max ? 1 : 0), 0);
    });
    return count;
  }

  private updateRequirements(vmId: number, status: boolean): void {
    const requirementIndex = this.reqList.findIndex(req => req.validationMethodId === vmId);
    this.reqList[requirementIndex].valid = status;
    this.validationUpdate.emit(this.allReqsMet());
  }

  private allReqsMet(): boolean {
    return this.reqList.every(req => req.valid);
  }
}
