import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StatusChips } from '@core/models';
import { EmailTemplatesDashboardForm } from '@core/models/case-communication/email-templates-dashboard-form.model';
import { EmailTemplates } from '@core/models/case-communication/email-templates.model';
import { TemplateItem } from '@core/models/case-communication/template-item.model';
import { appRoutePaths, caseCommunicationRoutes, manageEmployersRoutes } from '@core/routes/route-paths.constants';
import { ConstantsService, SnackbarService } from '@core/services';
import { EmailTemplatesDashboardStoreService } from '@core/services/email-templates-dashboard-store.service';
import { FormControl, FormGroup } from '@angular/forms';
import { nameof } from '@shared/helpers';
import { searchIncludes } from '@shared/helpers/search.helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { OwnerOptions } from '@core/enums/owner-options.enum';

@Component({
  selector: 'app-email-templates-table',
  templateUrl: './email-templates-table.component.html',
  styleUrls: ['./email-templates-table.component.scss']
})
export class EmailTemplatesTableComponent implements OnInit {
  @Input() emailTemplatesData: EmailTemplates;
  @Input() isMobile: boolean;
  @Input() sysText: any;
  @Input() isLoadingNewTemplateDialog: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() createNew = new EventEmitter<null>();
  @Output() onDuplicate = new EventEmitter<TemplateItem>();
  
  public _et: MatTableDataSource<TemplateItem>;
  public displayedColumns: string[] = ['name','templateType','ownerEmployer','allowUse','assignedToEmployer','actionMenu'];
  public pageSizeOptions: number[];
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public isSubmittingId: number;
  public chipStyles: StatusChips;
  
  private destroy$: Subject<void> = new Subject<void>();

  set dataSource(dataSource: TemplateItem[]) {
    this._et = new MatTableDataSource<TemplateItem>(dataSource);

    this._et.sortingDataAccessor = (item: TemplateItem, property: string) => {
      switch(property) {
        case nameof<TemplateItem>('name'): 
          return item.name.toLowerCase();
        case nameof<TemplateItem>('templateType'): 
          return item.templateType.description.toLowerCase();
        case nameof<TemplateItem>('ownerEmployer'): 
          return item.id < 0 ? "-- system --" : !item.ownerEmployer ? "-- stock --" : item.ownerEmployer.employerName.toLowerCase();
        default: 
          return item[property];
      }
    };

    this._et.paginator = this.paginator;
    this._et.sort = this.sort;
  }

  public get form(): FormGroup<EmailTemplatesDashboardForm> {
    return this.store.emailTemplatesTabForm;
  }

  public get searchValue(): FormControl<string> {
    return this.form.controls.searchValue as FormControl<string>;
  }

  public get employerControl(): FormControl<number> {
    return this.form.controls.employer as FormControl<number>;
  }

  public get typeControl(): FormControl<number> {
    return this.form.controls.type as FormControl<number>;
  }

  constructor(
    private store: EmailTemplatesDashboardStoreService,
    private service: CaseCommunicationService,
    private router: Router,
    private constants: ConstantsService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.chipStyles = this.constants.STAT_CHIP_STYLES;

    this.store.emailTemplates$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {  
        if (res != null) {
          this.emailTemplatesData = res;
          this.dataSource = res?.templateItems; 
          this.setPageSizeOptions(res?.templateItems?.length);
        }
      });

    if (!this.store.emailTemplatesTabFormNoChanges) {
      this.applyFilters();
    }

    if (this.store.emailTemplatesTabPageData) {
      this.pageIndex = this.store.emailTemplatesTabPageData.pageIndex;
      this.pageSize = this.store.emailTemplatesTabPageData.pageSize;
    }

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        this.applyFilters();
      });
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.setFilterPredicate();
      this._et.filter = this.searchValue?.value?.trim().toLowerCase();
      if (this.store.emailTemplatesTabSortData) {
        this.sort.active = this.store.emailTemplatesTabSortData.active;
        this.sort.direction = this.store.emailTemplatesTabSortData.direction;
        this.sort.sortChange.emit();
      }
    },1)
    this._et.sort = this.sort;
    this._et.paginator = this.paginator;

    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((sort: Sort) => {
        if (sort) {
          this.store.emailTemplatesTabSortData = sort;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._et.filter = filterValue.trim().toLowerCase();
  }

  public onCreateNew(): void {
    this.createNew.emit()
  }

  public viewTemplate(templateId: number) {
    this.router.navigate([`${appRoutePaths.EMAIL_TEMPLATES}/${caseCommunicationRoutes.TEMPLATE}`, templateId]);
  }

  public viewEmployerProfile(employerId: number) {
    this.router.navigate([`${appRoutePaths.MANAGE_EMPLOYERS}/${manageEmployersRoutes.PROFILE}`, employerId]);
  }

  public duplicateTemplate(templateItem: TemplateItem) {
    this.onDuplicate.emit(templateItem)
  }

  public onAssignMyEmployer(templateId: number) {
    this.isSubmittingId = templateId;
    this.service.postAssignMyEmployer({templateId: templateId})
      .pipe(finalize(()=>{this.isSubmittingId = null}))
      .subscribe(res => {
        this.store.emailTemplateAssignedToEmployer(templateId, res);
        this.applyFilters();
        this.snackbarService.open(this.sysText.assignedToMyEmployerMessage, this.sysText.dismiss);
      });
  }

  public onUnassignMyEmployer(templateId: number) {
    this.isSubmittingId = templateId;
    this.service.postUnassignMyEmployer({templateId: templateId})
      .pipe(finalize(()=>{this.isSubmittingId = null}))
      .subscribe(res => {
        this.store.emailTemplateAssignedToEmployer(templateId, res);
        this.applyFilters();
        this.snackbarService.open(this.sysText.unassignedToMyEmployerMessage, this.sysText.dismiss);
      });
  }

  public changePage(event: PageEvent) {
    this.store.emailTemplatesTabPageData = event;
  }

  private applyFilters() {
    let filteredList = this.store.emailTemplates.templateItems;
    if (this.employerControl.value !== null) {
      filteredList = filteredList.filter(i => {
        return (i.ownerEmployer?.id === this.employerControl.value) 
          || (i.id < 0 && this.employerControl.value === OwnerOptions.System)
          || ((!i.ownerEmployer && i.id >= 0) && this.employerControl.value === OwnerOptions.Stock)
      });
    }
    if (this.typeControl.value !== null) {
      filteredList = filteredList.filter(i => i.templateType.id === this.typeControl.value)
    }
    this.dataSource = filteredList;
    this.setPageSizeOptions(filteredList.length);
    this.setFilterPredicate();
    this._et.filter = this.searchValue?.value?.trim().toLowerCase();
  }

  private setFilterPredicate() {
    this._et.filterPredicate = (data, filter) => {
      const filterClean = filter.toLowerCase().trim()
      const dataStr = (`${data.name} ${data.ownerEmployer?.employerName} ${data.templateType ?? ''} `).toLowerCase();
      return searchIncludes(dataStr, filterClean); 
    }
  }

  private setPageSizeOptions(length: number) {
    const multipleOf = 10;
    this.pageSizeOptions = [10];
    const multiples = Math.floor(length / multipleOf) + 1;
    for (let i = 2; i <= multiples; i++) {
      this.pageSizeOptions.push(i * multipleOf);
    }
  }

}
