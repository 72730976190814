import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { NewSchedulePost } from '@core/models/work-schedules/work-schedules.model';
import { ErrorService } from '@core/services';
import { WorkSchedulesService } from '@core/services/work-schedules.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-new-schedule',
  templateUrl: './add-new-schedule.component.html',
  styleUrl: './add-new-schedule.component.scss'
})
export class AddNewScheduleComponent extends DialogDragConstraints implements OnInit{
  public form: UntypedFormGroup;

  isSubmitting$: Subject<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      sysText: any,
      duplicateName: string,
      sourceId: number 
    },
    private service: WorkSchedulesService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddNewScheduleComponent>
  ) {
    super(dialogRef);
    
    this.isSubmitting$ = new Subject();
    this.form = this.fb.group({
      scheduleName: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    if(this.data.duplicateName) {
      this.form.controls.scheduleName.patchValue(this.data.duplicateName + ' (' + this.data.sysText.copy + ')')
    }
  }

  public onSubmit() {
    this.dialogRef.disableClose = true;
    let newSchedulePost: NewSchedulePost = {
      scheduleName: this.form.controls.scheduleName.value,
      sourceId: this.data.sourceId ?? null
    }
    this.service.postNewSchedule(newSchedulePost)
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe((res) => {
        this.dialogRef.close(res);
      }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }
}
