import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AddNewTemplateMode } from '@core/enums/add-new-template-mode.enum';
import { EmailTemplates } from '@core/models/case-communication/email-templates.model';
import { TemplateItem } from '@core/models/case-communication/template-item.model';
import { appRoutePaths, caseCommunicationRoutes } from '@core/routes/route-paths.constants';
import { HeaderService, LayoutService } from '@core/services';
import { EmailTemplatesDashboardStoreService } from '@core/services/email-templates-dashboard-store.service';
import { AddNewTemplateComponent } from '@modules/dialogs/add-new-template/add-new-template.component';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-email-templates-dashboard',
  templateUrl: './email-templates-dashboard.component.html',
  styleUrls: ['./email-templates-dashboard.component.scss'],
})
export class EmailTemplatesDashboardComponent implements OnInit {
  public emailTemplates$: Observable<EmailTemplates>;
  public sysText: any;
  public isLoadingNewTemplateDialog$: Subject<boolean> = new Subject<boolean>();
  public selectedIndex: number;

  private destroy$: Subject<void> = new Subject<void>();

  get isMobile$(): Observable<boolean> {
    return this.layoutService.isMobile$();
  }

  constructor(
    private headerService: HeaderService,
    private store: EmailTemplatesDashboardStoreService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.emailTemplates$ = this.store.emailTemplates$;
    this.sysText = this.store.sysText;
    this.headerService.setTitle(this.sysText.emailTemplates["emailTemplates_header_title"]);

    if (this.store.selectedIndex) {
      this.selectedIndex = this.store.selectedIndex;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCreateNew(): void {
    this.isLoadingNewTemplateDialog$.next(true);
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '550px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.sysText.addNewTemplate,
        duplicateName: null,
        sourceId: null,
        mode: AddNewTemplateMode.emailTemplate
      }
    };

    const dialogRef = this.dialog.open(AddNewTemplateComponent, dialogConfig);

    dialogRef.afterOpened()
      .subscribe(()=>{
        this.isLoadingNewTemplateDialog$.next(false);
      })

    dialogRef.afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.router.navigate([`${appRoutePaths.EMAIL_TEMPLATES}/${caseCommunicationRoutes.TEMPLATE}`, res]);
      });
  }

  public changeTab(event: MatTabChangeEvent) {
    this.store.selectedIndex = event.index;
  }

  public onDuplicate(templateItem: TemplateItem): void {
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '550px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.sysText.addNewTemplate,
        duplicateName: templateItem.name,
        sourceId: templateItem.id,
        mode: AddNewTemplateMode.emailTemplate
      }
    };
    
    this.dialog.open(AddNewTemplateComponent, dialogConfig)
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.router.navigate([`${appRoutePaths.EMAIL_TEMPLATES}/${caseCommunicationRoutes.TEMPLATE}`, res]);
      });
  }
}
