/** Used for JSON.stringify, this replacer will replace any 'falsy' value with 
null, number values with their string form, and date values will be formatted
with toLocaleDateString() and converted to a string. */
export function noChangesReplacer(key: string, value: any): any {
  // To handle dates, Date.parse will also parse out numbers (example -3) 
  // so an addition length check is necessary - min length of a date in 
  // string format "2022-12-30T00:00:00" is 19.
  if (Date.parse(value) && value.toString().length >= 19)
    return new Date(value).toLocaleDateString().toString();
  return !value ? null : typeof value === "number"? value.toString() : value;
}