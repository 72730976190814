import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { ErrorService } from '@core/services';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { finalize, takeUntil } from 'rxjs/operators';
import { StiiraError } from '@core/models';
import { ScheduleAssignedEmployersDialog, ScheduleAssignedEmployersDialogPost, WorkScheduleDetails } from '@core/models/work-schedules/work-schedule-details.model';
import { WorkSchedulesService } from '@core/services/work-schedules.service';
import { WorkScheduleDetailsStoreService } from '@core/services/work-schedule-details.store.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-schedule-assigned-employers',
  templateUrl: './edit-schedule-assigned-employers.component.html',
  styleUrl: './edit-schedule-assigned-employers.component.scss'
})
export class EditScheduleAssignedEmployersComponent extends DialogDragConstraints implements OnInit{
  @Output() isEditing = new EventEmitter<boolean>();

  public form: UntypedFormGroup;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      workScheduleDetails: WorkScheduleDetails; 
      options: ScheduleAssignedEmployersDialog;
      sysText: any; 
      unsavedChangesSysText: any
    },
    private dialogRef: MatDialogRef<EditScheduleAssignedEmployersComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private service: WorkSchedulesService,
    private store: WorkScheduleDetailsStoreService,
    private errorService: ErrorService
    ) { 
    super(dialogRef);
    
    this.isSaving$ = new Subject();
    this.form = this.fb.group({
      employers: [null]
    })
  }

  ngOnInit(): void {
    this.form.patchValue({
      employers: this.data.workScheduleDetails.employers.map(er => er.id)
    });
    this.formInitValues = { ...this.form.value };
    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.workScheduleDetails.missingDetails)
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public save(): void {
    this.dialogRef.disableClose = true;
    const dto: ScheduleAssignedEmployersDialogPost = { 
      scheduleId: this.data.workScheduleDetails.id,
      employers: this.form.controls.employers.value 
    }
    this.service.postScheduleAssignedEmployers(dto)
      .pipe(
        indicate(this.isSaving$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe((res) => {
        this.store.workScheduleDetails = res as WorkScheduleDetails;
        this.dialogRef.close(res);
      }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }
}
