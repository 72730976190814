<h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  {{ sysText.changePasswordDialog_title }}
</h6>

<mat-dialog-content [formGroup]="form">
  <p class="subtitle1 bottom-margin">
    {{ sysText.changePasswordDialog_subtitle }}
  </p>
  <mat-form-field color="accent">
    <input
      matInput
      [type]="hideCurrentPassword ? 'password' : 'text'"
      [placeholder]="sysText.currentPassword | titlecase"
      formControlName="currentPassword"
    />
    <!-- mat hint for spacing -->
    <mat-hint></mat-hint>
    <mat-error>{{ currentPassword.errors?.msError }}</mat-error>
    <mat-icon matSuffix (click)="hideCurrentPassword = !hideCurrentPassword">{{
      hideCurrentPassword ? 'visibility_off' : 'visibility'
    }}</mat-icon>
  </mat-form-field>
  <mat-form-field color="accent">
    <input
      matInput
      [type]="hideNewPassword ? 'password' : 'text'"
      [placeholder]="sysText.newPassword | titlecase"
      formControlName="newPassword"
    />
    <mat-error>{{ newPassword.errors?.msError }}</mat-error>
    <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
  </mat-form-field>
  <app-password-validator
    [password]="newPassword.value"
    (validationUpdate)="passwordValid = $event"
    [reqList]="passwordRequirements"
  ></app-password-validator>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="!(isSubmitting$ | async); else submitting">
    <button mat-stroked-button class="button" [mat-dialog-close] color="accent">{{ sysText.cancel }}</button>
    <button
      mat-stroked-button
      class="button"
      (click)="changePassword()"
      color="accent"
      [disabled]="form.invalid || !passwordValid"
    >
      {{ sysText.changePassword }}
    </button>
  </ng-container>
  <ng-template #submitting>
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </ng-template>
</mat-dialog-actions>
