import { IdleTimerConfig } from '@core/models';
import { environment } from '@environments/environment';

export const IDLETIMERCONFIG: IdleTimerConfig = {
  idleTimeLimit: +environment.idleTimeLimitSeconds,
  alertUserTimeLeft:
    +environment.alertUserTimeLeftSeconds < +environment.idleTimeLimitSeconds
      ? +environment.alertUserTimeLeftSeconds
      : +environment.idleTimeLimitSeconds / 4,
};
