<h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  {{ data.title | titlecase }} 
</h6>

<mat-dialog-content>
  <p *ngIf="data.message1" class="subtitle1">
    {{ data.message1 }}
  </p>
  <p *ngIf="data.message2" class="subtitle1">
    {{ data.message2 }}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button class="button" [mat-dialog-close]="false" color="accent">
    {{ data.cancelCaption }}
  </button>
  <button mat-stroked-button class="button" [mat-dialog-close]="true" color="accent">
    {{ data.proceedCaption }}
  </button>
</mat-dialog-actions>