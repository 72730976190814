import { PlaceholderOption } from "@core/models/case-communication/placeholder-option.model";

// Replaces all placeholder keys with 'chips' including the placeholder names
export function insertPlaceholderChips(inputText: string, chipHtml: string, placeholderOptions: PlaceholderOption[]): string {
	if (inputText) {
		let outputText = inputText;
		placeholderOptions?.forEach(po => {
			let placeholderChip = chipHtml.replace("#placeholder-name#", po.name);
			while (outputText.includes(po.key))
				outputText = outputText.replace(po.key, placeholderChip);
		})
		return outputText;
	}
	return inputText;
}

// Replaces all placeholder 'chips'/names with keys
export function removePlaceholderChips(inputText: string, chipHtml: string, placeholderOptions: PlaceholderOption[]): string {
	if (inputText) {
		let outputText = inputText;
		placeholderOptions?.forEach(po => {
			let placeholderChip = chipHtml.replace("#placeholder-name#", po.name);
			while (outputText.includes(placeholderChip))
				outputText = outputText.replace(placeholderChip, po.key);
		});
		return outputText;
	}
	return inputText;
};
