<mat-card appearance="raised" class="acct-form--container mat-elevation-z12">
  <div class="acct-form--header">
    <h4 class="acct-form--title">{{ sysText[page + '_accountForm_title'] }}</h4>
    <p class="body2 acct-form--subtitle">{{ sysText[page + '_accountForm_subtitle'] }}</p>
  </div>
  <mat-card-content>
    <form [formGroup]="acctForm" (ngSubmit)="onSubmit()" class="acct-form--form">
      <mat-form-field *ngIf="options.username.show" color="accent">
        <input
          matInput
          [placeholder]="sysText[page + '_accountForm_username']"
          formControlName="username"          
        />
        <!-- mat hint for spacing -->
        <mat-hint></mat-hint>
        <mat-error>{{ username.errors?.msError }}</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="options.password.show" color="accent">
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          [placeholder]="sysText[page + '_accountForm_password']"
          formControlName="password"         
        />
        <mat-error>{{ password.errors?.msError }}</mat-error>
        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>
      <app-password-validator
        *ngIf="options.password.requirements"
        [reqList]="options.password.requirements"
        [password]="password.value"
        (validationUpdate)="formValid = $event"
      >
      </app-password-validator>
      <ng-container *ngIf="options.phonenumber.show">
        <div class="acct-form--phone">
          <h6  class="acct-form--title">{{ sysText[page + '_accountForm_phoneTitle'] }}</h6>
          <p class="body2 acct-form--subtitle">{{ sysText[page + '_accountForm_phoneSubtitle'] }}</p>
        </div>
        <mat-form-field color="accent">
          <input
            matInput
            [placeholder]="sysText[page + '_accountForm_phonenumber']"
            formControlName="phonenumber"            
          />
          <mat-error>{{ username.errors?.msError }}</mat-error>
        </mat-form-field>
      </ng-container>
      <button
        type="submit"
        mat-flat-button
        color="accent"
        class="button acct-form--button"
        [disabled]="!formValid || (buttonDisabled$ | async)"
        *ngIf="!isSubmitting; else submitting"        
      >
        {{ sysText[page + '_accountForm_button'] }}
      </button>
      <ng-template #submitting>
        <button type="submit" mat-flat-button color="accent" class="button acct-form--button__disabled" disabled>
          <mat-spinner [diameter]="30" color="accent"></mat-spinner>
        </button>
      </ng-template>
    </form>
    <div class="acct-form--link caption">
      <a [routerLink]="['/', options.link.route]">
        {{ sysText[page + '_accountForm_link'] }}
      </a>
    </div>
  </mat-card-content>
</mat-card>
