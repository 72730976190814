import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DirectiveQueryParam } from '@core/enums';
import { AccountFormOptions, Credentials, SetPassword, StiiraError, VerifyPhoneCode } from '@core/models';
import { SendPhoneCode } from '@core/models/account/send-phone-code.model';
import { appRoutePaths, leaveAdminRoutes } from '@core/routes/route-paths.constants';
import { AccountService, AuthService, ConstantsService, ErrorService, RedirectService, SecurityQuestionsService, SnackbarService } from '@core/services';
import { AccountPagesSystemTextService } from '@core/services/account-pages-system-text.service';
import { FormGroup } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  public isSubmitting$ = new Subject<boolean>();
  public setPhoneNumberShowField$ = new Subject<boolean>();
  public options: AccountFormOptions;
  public sysText$: Observable<any>;
  public username: string;
  public templateName: string;
  public showSetPhoneNumber: boolean;

  private phoneNumber: string;
  private successNotificationText: string;
  private creds: Credentials;

  constructor(
    private acctService: AccountService,
    private sqService: SecurityQuestionsService,
    private acctTextService: AccountPagesSystemTextService,
    private constants: ConstantsService,
    private snackbarService: SnackbarService,
    private router: Router,
    private errorService: ErrorService,
    private authService: AuthService,
    private redirect: RedirectService
  ) {}

  ngOnInit() {
    this.templateName = this.acctTextService.templates.setPassword;
    this.sysText$ = this.acctTextService.sysText$.pipe(
      pluck(this.templateName),
      tap((res) => (this.successNotificationText = res[`${this.templateName}_successNotification`]))
    );
    this.options = this.constants.ACCOUNTFORM_OPTIONS_SETPASSWORD;
    this.username = this.acctService.getUsername();
    this.showSetPhoneNumber = false;
    this.setPhoneNumberShowField$.next(false); 
  }

  ngOnDestroy() {
    this.acctService.resetCodeAndUsername();
    this.sqService.disallowAccessToSetOrResetPwd();
  }

  public onFormSubmit(form: FormGroup): void {
    const setPasswordModel: SetPassword = this.buildSetPasswordModel({pwd: form.value.password, phone: form.value.phonenumber});
    this.creds = {
      username: this.username,
      password: form.value.password,
      clientTzo: new Date().getTimezoneOffset().toString()
    }
    this.phoneNumber = form.value.phonenumber 
    this.acctService
      .setFirstPassword(setPasswordModel)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(
        () => {
          this.showSetPhoneNumber = true;
          this.snackbarService.open(this.successNotificationText, '', 5000);
        },
        (err: StiiraError) => this.errorService.setFormModelStateErrors(form, err.modelStateErrors)
      );
  }

  public onPhoneVerificationSubmit(form: FormGroup): void {
    const setPhoneModel: VerifyPhoneCode = {
      username: this.username,
      phoneNumber: this.phoneNumber,
      otp: form.value.otp
    }
    this.isSubmitting$.next(true);
    this.acctService
      .verifyPhoneCode(setPhoneModel)
      .subscribe(
        () => {
          this.snackbarService.open("Phone number successfully set!", '', 5000);
          this.handleLogin();
        },
        (err: StiiraError) => {
          this.isSubmitting$.next(false);
          this.errorService.setFormModelStateErrors(form, err.modelStateErrors)
        }
      );
  }

  public onGoBack(): void {
    this.router.navigate(['/login'], { queryParams: { username: this.acctService.getUsername() } });
  }

  public onResendCode(): void {
    const sendCode: SendPhoneCode = {
      username: this.username,
      phoneNumber: this.phoneNumber
    }
    this.acctService.sendPhoneCode(sendCode)
    .pipe(indicate(this.isSubmitting$))
      .subscribe(
        () => {
            this.snackbarService.open('Code resent!', 'Dismiss');
        }
      );
  }

  public onSetPhoneNumber(number: string): void {
    this.phoneNumber = number;
    const sendCode: SendPhoneCode = {
      username: this.username,
      phoneNumber: this.phoneNumber
    }
    this.acctService.sendPhoneCode(sendCode)
    .pipe(indicate(this.isSubmitting$))
      .subscribe(
        () => {
            this.snackbarService.open('Code sent!!!', 'Dismiss');
            this.setPhoneNumberShowField$.next(false)
        }
      );
  }

  private handleLogin(): void {
    this.authService
      .login(this.creds)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((token) => {
        this.authService.updateUser(token);
        this.router.navigate([this.getRedirectPath()]);
        this.redirect.resetRedirectUrl();
      });
  }

  private buildSetPasswordModel(values: {pwd: string, phone: string}): SetPassword {
    const setPasswordModel: SetPassword = {
      username: this.username,
      phoneNumber: values.phone,
      password: values.pwd,
      code: this.acctService.getCode(),
    };
    return setPasswordModel;
  }

  private getRedirectPath(): string {
    const dir2: DirectiveQueryParam = this.acctService.getDir2();
    const cid: string = this.acctService.getCaseId();
    let route = '';

    switch (dir2) {
      case DirectiveQueryParam.la:
        route = `/${appRoutePaths.LEAVE_ADMIN}`;
        break;
      case DirectiveQueryParam.cd:
        if (cid) {
          this.authService.setIsUrlWithQueryParams(true);
          route = `/${appRoutePaths.LEAVE_ADMIN}/${leaveAdminRoutes.DETAILS}/${cid}`;
        }
        break;
      default:
        route = this.redirect.redirectUrl;
    }

    if (!route) {
      route = `/${appRoutePaths.HOME}`;
    }

    return route;
  }
}
