<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6 class="grey1">{{ data.sysText.editDocument_title | titlecase }}</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content class="mat-dialog-content">
	<form [formGroup]="form">
		<app-input-field 
			[control]="documentTitle" 
			[label]="data.sysText.documentTitle" 
			[inputType]="'string'"
			[icon]="'comment'"
		></app-input-field>
		<app-select-field 
			[group]="form" 
			[control]="documentCategoryId" 
			[options]="data.options.categories"
			[icon]="'list'" 
			[label]="data.sysText.documentCategory" 
			[shouldCapitalize]="false"
		></app-select-field>
		<app-input-field 
			[control]="documentComments" 
			[label]="data.sysText.documentComments" 
			[inputType]="'string'"
			[icon]="'comment'"
		></app-input-field>
		<div *ngIf="data.mode == docUploadMode.Employee" class="select-container">
			<mat-icon [ngClass]="{ focus: select.focused }">assignment_turned_in</mat-icon>
			<mat-form-field color="accent">
			  <mat-label>{{ data.sysText.associatedCase | titlecase }}</mat-label>
			  <mat-select #select="matSelect" [formControl]="associatedCase" disableOptionCentering panelClass="dropDownSelect">
				<mat-select-trigger>
				  {{associatedCase.value}}
				</mat-select-trigger>
				<mat-option *ngFor="let case of data.options.cases" [value]="case.caseId">
				  <span>{{ case.caseId }}</span>
				  <span *ngIf="case.leaveType" class="caption option-extras">{{ case.leaveType }}</span>
				  <span *ngIf="case.startDate" class="caption option-extras"><span class="bullet-spacing" *ngIf="case.leaveType">&bull;</span>{{ data.sysText.caseLeaveInfo_started | titlecase }} {{case.startDate | date}}</span>
				</mat-option>
			  </mat-select>
			  <mat-hint *ngIf="data.options.cases?.length === 0">{{ data.sysText.noCases }}</mat-hint>
			</mat-form-field>
		</div>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<ng-container *ngIf="!(isSaving$ | async); else saving">
		<button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSaving$ | async"
			*ngIf="!isHandheld">
			{{ data.sysText.cancel }}
		</button>
		<button mat-stroked-button class="button" color="accent" (click)="save()" [disabled]="(isSaving$ | async)">
			{{ data.sysText.save }}
		</button>
	</ng-container>
	<ng-template #saving>
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</ng-template>
</mat-dialog-actions>