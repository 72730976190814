import { Pipe, PipeTransform } from '@angular/core';
import { SelectionOption } from '@core/models';

@Pipe({
  name: 'findfromid'
})
export class FindfromidPipe implements PipeTransform {

  transform(id: number | boolean | string, selections: SelectionOption[]): string {
    return selections.find(s => s.id === id).description;
  }

}
