import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getdocext'
})
export class GetdocextPipe implements PipeTransform {
  transform(docTitle: string, fileName: string): unknown {
    if (!fileName) {
      return docTitle;
    }
    const ext = fileName.substring(fileName.lastIndexOf('.'));
    return `${docTitle} (${ext})`;
  }
}
