import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-set-phonenumber-form',
  templateUrl: './set-phonenumber-form.component.html',
  styleUrls: ['./set-phonenumber-form.component.scss']
})
export class SetPhonenumberFormComponent implements OnInit {
  @Input() isSubmitting = false;
  @Input() sysText: any;
  @Input() page: string;  
  @Input() showNumberField: Subject<boolean>;
  @Output() goBack = new EventEmitter();
  @Output() resendCode = new EventEmitter();
  @Output() formSubmit = new EventEmitter<FormGroup>();
  @Output() setPhoneNumber = new EventEmitter<string>();
  phoneForm: FormGroup;  

  get otp(): FormControl {
    return this.phoneForm.get('otp') as FormControl;
  }

  get phonenumber(): FormControl {
    return this.phoneForm.get('otp') as FormControl;
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {    
    this.phoneForm = this.fb.group({
      otp: '',
      phonenumber: ''
    });    
  }

  onResend(): void {
    this.resendCode.emit();
  }

  onShowSetPhoneNumber(): void {    
    this.showNumberField.next(true)   
  }

  onHideSetPhoneNumber(): void {    
    this.showNumberField.next(false)   
  }

  onSetNumber(): void {    
    this.setPhoneNumber.emit(this.phoneForm.value.phonenumber)
  }

  onGoBack(): void {
    this.goBack.emit();
  }

  onSubmit(): void {
    this.formSubmit.emit(this.phoneForm);
  }
}