import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-role-explanation',
  templateUrl: './role-explanation.component.html',
  styleUrls: ['./role-explanation.component.scss'],
})
export class RoleExplanationComponent extends DialogDragConstraints implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      roleSysText: {
        adminSysText: string; responsibleSysText: string 
      },
      actionSysText: {
        enabledSysText: string, disabledSysText: string, copySysText: string
      }
    },
    private dialogRef: MatDialogRef<RoleExplanationComponent>,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {}
}
