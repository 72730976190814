import { CdkDragEnd } from '@angular/cdk/drag-drop';

export class DialogDragConstraints {
    constructor ( private componentDialogRef ) {};

    // this object is tied to the cdk drag position
    public position = { x: 0, y: 0 };

    // Make sure the dialog title is grabbale after a
    // drag operation has ended
    onDragEnded(event: CdkDragEnd) {
        const element = event.source.getRootElement();
        const rect = element.getBoundingClientRect();
        const htmlElement = element as HTMLElement;

        // if the dialog hasn't been transformed it hasn't been moved
        // and there is nothing to do

        if (htmlElement?.style?.transform == null || htmlElement.style.transform.length == 0)
            return;

        var rightPart = htmlElement.style.transform.split("(")[1]; // everything after the "(" in "translate3d(94px, 775px, 0px)"
        var leftPart = rightPart.split(")")[0]; // everthing before the ")" in "94px, 775px, 0px)"
        var params = leftPart.split(","); // the three parts of "94px, 775px, 0px"

        var translateX = parseInt(params[0].replace(" ", "").replace("px", "")); // integer of "94" of "94px"
        var translateY = parseInt(params[1].replace(" ", "").replace("px", "")); // integer of "775" of " 775px"

        var xPos = rect.left;
        var yPos = rect.top;

        // dialog title is 55px
        const topOutOfViewSize = 15.0; // keep 55 - 15 = 40px of title bar visible
        const leftRightBottomInViewSize = 40.0; // keep 40px of title bar visible

        // calculate new x position
            
        if (rect.left > window.innerWidth - leftRightBottomInViewSize)
            // grabbable left edge of dialog is off the right side of window
            xPos = window.innerWidth - leftRightBottomInViewSize;

        if (rect.right < leftRightBottomInViewSize)
            // grabbable right edge of dialog is off the left side of window
            xPos = -rect.width + leftRightBottomInViewSize;

        // calculate new y position

        if (rect.top < -topOutOfViewSize)
            // grabbale top edge of dialog is off the top of window
            yPos = -topOutOfViewSize;

        if (rect.top > window.innerHeight - leftRightBottomInViewSize)
            // grabble top edge of dialog is off the bottom of window
            yPos = window.innerHeight - leftRightBottomInViewSize;

        if (rect.left != xPos || rect.top != yPos) {
            var adjustX = xPos - rect.left;
            var adjustY = yPos - rect.top;

            var newTranslateX = translateX + adjustX;
            var newTranslateY = translateY + adjustY;

            this.position = { x: newTranslateX, y: newTranslateY };
        }
    }
}