<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner color="accent"></mat-spinner>
  </div>
</ng-template>

<h6 mat-dialog-title>{{ sysText.changeImageDialog_title | titlecase }}</h6>
<ng-container *ngIf="!(isLoading$ | async); else loading">
  <mat-dialog-content>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [cropperMinWidth]="128"
      [resizeToWidth]="168"
      [resizeToHeight]="168"
      [onlyScaleDown]="true"
      [roundCropper]="true"
      [transform]="transform"
      [alignImage]="'left'"
      output="base64"
      format="png"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <div class="zoom-container" *ngIf="croppedImage">
      <button mat-icon-button color="accent" (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
      <mat-slider color="accent" min="0" max="2" step="0.1" #ngSlider><input matSliderThumb [(ngModel)]="scale" (change)="zoom({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider>
      <button mat-icon-button color="accent" (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
    </div>
  </mat-dialog-content>
</ng-container>
<span class="spacer"></span>
<mat-dialog-actions align="end">
  <input hidden type="file" accept="image/*" #imgFileInput (change)="fileChangeEvent($event)" />
  <button
    mat-stroked-button
    class="button"
    color="accent"
    (click)="imgFileInput.click()"
    [disabled]="isLoading$ | async"
  >
    {{ sysText.chooseImage }}
  </button>
  <span class="spacer"></span>
  <button
    mat-stroked-button
    mat-dialog-close
    class="button"
    color="accent"
    [disabled]="isLoading$ | async"
    *ngIf="!isMobile"
  >
    {{ sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" color="accent" [disabled]="!croppedImage || (isLoading$ | async)" (click)="save()">
    {{ sysText.save }}
  </button>
</mat-dialog-actions>
