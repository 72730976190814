import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPLOYER_PROFILE } from '@core/mocks/leave-admin/employer-profile.mock';
import { MANAGEEMPLOYERS } from '@core/mocks/leave-admin/manage-employers.mock';
import { ApplicableStatesDialog, ApplicableStatesPost } from '@core/models/leave-admin/employers/applicable-states.model';
import { EmployerLeaveAdminInfoDialog, EmployerLeaveAdminInfoPost } from '@core/models/leave-admin/employers/employer-leave-admin.model';
import { EmployerContactsDialog, EmployerContactsPost } from '@core/models/leave-admin/employers/employer-contacts.model';
import { EmployerNote, EmployerNoteAddPost, EmployerNoteDeletePost } from '@core/models/leave-admin/employers/employer-note.model';
import { EmployerProfile } from '@core/models/leave-admin/employers/employer-profile.model';
import { EmployerSettingsPost } from '@core/models/leave-admin/employers/employer-settings-post.model';
import { ManageEmployers } from '@core/models/leave-admin/employers/manage-employers.model';
import { NewEmployerPost } from '@core/models/leave-admin/employers/new-employer-post.model';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { FileDownload } from '@core/models/shared/file-download.model';
import { DocumentUploadDialog } from '@core/models/leave-admin/document-upload';
import { DocumentEditDialog, PostEmployerDocumentEdit } from '@core/models';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { EmployerProfileHistory } from '@core/models/leave-admin/employers/employer-profile-history.model';
import { EMPLOYER_PROFILE_HISTORY } from '@core/mocks/leave-admin/employer-profile-history.mock';
import { EmployerCaseComSettingsPost } from '@core/models/leave-admin/employers/case-com-settings.model';
import { EmployerLeaveYearRuleDialog, LeaveYearRulePost } from '@core/models/leave-admin/employers/employer-leave-year-rule.model';
import { LEAVE_YEAR_RULE_DIALOG } from '@core/mocks/leave-admin/leave-year-rule-dialog.mock';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';


@Injectable({
  providedIn: 'root'
})
export class ManageEmployersService {
  private apiUrl: string;
  
  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.apiUrl = this.constants.MER_API;
  }

  public getManageEmployers(): Observable<ManageEmployers> {
    return this.http.get<ManageEmployers>(`${this.apiUrl}/getManageEmployers`);
    // return of(MANAGEEMPLOYERS).pipe(delay(1000));
  }

  public postNewEmployer(dto: NewEmployerPost): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/postNewEmployer`, dto)
    // return of(300217).pipe(delay(1000));
  }

  public getEmployerProfileDetails(employerId: number): Observable<EmployerProfile> {
    if (employerId == null) {
      return this.http.get<EmployerProfile>(`${this.apiUrl}/getEmployerProfile`);
    }
    else {
      return this.http.get<EmployerProfile>(`${this.apiUrl}/getEmployerProfile/${employerId}`);
    }   
    //return of(EMPLOYER_PROFILE).pipe(delay(1000));    
  }

  public getEmployerProfileHistory(employerId: number): Observable<EmployerProfileHistory> {
    return this.http.get<EmployerProfileHistory>(`${this.apiUrl}/getEmployerProfileHistory/${employerId}`);
    //return of(EMPLOYER_PROFILE_HISTORY).pipe(delay(1000));
  }

  public postEmployerNoteAdd(dto: EmployerNoteAddPost): Observable<EmployerNote> {
    return this.http.post<EmployerNote>(`${this.apiUrl}/postEmployerNoteAdd`, dto)
  }

  public postEmployerNoteEdit(dto: EmployerNote): Observable<EmployerNote> {
    return this.http.post<EmployerNote>(`${this.apiUrl}/postEmployerNoteEdit`, dto)
  }

  public getEmployerContactsDialog(employerId: number): Observable<EmployerContactsDialog> {
    return this.http.get<EmployerContactsDialog>(`${this.apiUrl}/GetEmployerContactsDialog/${employerId}`);
    // return of(MANAGEEMPLOYERS).pipe(delay(1000));
  }

  public postEmployerNoteDelete(dto: EmployerNoteDeletePost): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/postEmployerNoteDelete`, dto)
    // return of(1).pipe(delay(1000));
  }

  public postEmployerSettings(dto: EmployerSettingsPost): Observable<EmployerProfile> {
    return this.http.post<EmployerProfile>(`${this.apiUrl}/PostEmployerSettings`, dto)
    //return of(EMPLOYER_PROFILE).pipe(delay(1000)); 
  }

  public getApplicableStatesDialog(employerId: number): Observable<ApplicableStatesDialog> {
    return this.http.get<ApplicableStatesDialog>(`${this.apiUrl}/getApplicableStatesDialog/${employerId}`);
  }

  public postApplicableStates(dto: ApplicableStatesPost): Observable<EmployerProfile> {
    return this.http.post<EmployerProfile>(`${this.apiUrl}/postApplicableStates`, dto)
    //return of(EMPLOYER_PROFILE).pipe(delay(1000)); 
  }

  public postEmployerCaseComSettings(dto: EmployerCaseComSettingsPost): Observable<EmployerProfile> {
    return this.http.post<EmployerProfile>(`${this.apiUrl}/postEmployerCaseComSettings`, dto)
  }

  public getEmployerLeaveAdminInfoDialog(employerId: number): Observable<EmployerLeaveAdminInfoDialog> {
    return this.http.get<EmployerLeaveAdminInfoDialog>(`${this.apiUrl}/getEmployerLeaveAdminInfoDialog/${employerId}`);
  }

  public getEmployerLeaveYearRulesDialog(employerId: number): Observable<EmployerLeaveYearRuleDialog> {
    return this.http.get<EmployerLeaveYearRuleDialog>(`${this.apiUrl}/getEmployerLeaveYearRulesDialog/${employerId}`);
    //return of(LEAVE_YEAR_RULE_DIALOG).pipe(delay(1000));
  }

  public postEmployerContacts(dto: EmployerContactsPost): Observable<EmployerProfile | DuplicateDetectedDialog> {    
    return this.http.post<EmployerProfile | DuplicateDetectedDialog>(`${this.apiUrl}/PostEmployerContacts`, dto)
    //return of(EMPLOYER_PROFILE).pipe(delay(1000)); 
  }
  
  public postEmployerLeaveAdminInfo(dto: EmployerLeaveAdminInfoPost): Observable<EmployerProfile> {
    return this.http.post<EmployerProfile>(`${this.apiUrl}/postEmployerLeaveAdminInfo`, dto)
    //return of(EMPLOYER_PROFILE).pipe(delay(1000)); 
  }

  public postLeaveYearRulesAdd(dto: LeaveYearRulePost): Observable<EmployerProfile | UpdateConfirmationDialog> {
    return this.http.post<EmployerProfile | UpdateConfirmationDialog>(`${this.apiUrl}/postLeaveYearRulesAdd`, dto)
    //return of(EMPLOYER_PROFILE).pipe(delay(1000)); 
  }

  public postLeaveYearRulesEdit(dto: LeaveYearRulePost): Observable<EmployerProfile | UpdateConfirmationDialog> {
    return this.http.post<EmployerProfile | UpdateConfirmationDialog>(`${this.apiUrl}/postLeaveYearRulesEdit`, dto)
    //return of(EMPLOYER_PROFILE).pipe(delay(1000)); 
  }

  public postLeaveYearRulesDelete(dto: {leaveYearRuleId: number}): Observable<EmployerProfile> {
    return this.http.post<EmployerProfile>(`${this.apiUrl}/postLeaveYearRulesDelete`, dto)
    //return of(EMPLOYER_PROFILE).pipe(delay(1000)); 
  }

  public getDocument(employerDocumentId: number): Observable<FileDownload> {
    return this.http.get<FileDownload>(`${this.apiUrl}/getDocument/${employerDocumentId}`)
    //return of(DOCDOWNLOAD);
  }

  public getDocumentUploadDialog(employerId: number): Observable<DocumentUploadDialog> {
    return this.http.get<DocumentUploadDialog>(`${this.apiUrl}/getDocumentUploadDialog/${employerId}`)
    //return of(DOCUMENTUPLOADDIALOG_MOCK);
  }

  public getDocumentEditDialog(employerId: number): Observable<DocumentEditDialog> {
    return this.http.get<DocumentEditDialog>(`${this.apiUrl}/getDocumentEditDialog/${employerId}`)
  }

  public postDocumentUpload (formData: FormData): Observable<EmployerProfile> {
    return this.http.post<EmployerProfile>(`${this.apiUrl}/postDocumentUpload`, formData)
    //return of(EMPLOYER_PROFILE);
  }

  public postDocumentEdit(dto: PostEmployerDocumentEdit): Observable<EmployerProfile> { 
    return this.http.post<EmployerProfile>(`${this.apiUrl}/postDocumentEdit`, dto);
  }

  public postDocumentDelete(dto: {employerDocumentId: number}): Observable<EmployerProfile> { 
    return this.http.post<EmployerProfile>(`${this.apiUrl}/postDocumentDelete`, dto);
    //return of(CASEDETAILS);
  }
}
