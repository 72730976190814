import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountFormOptions, ResetPassword, StiiraError, VerificationFormOptions, SendCode, SelectedProviders, VerifyCode } from '@core/models';
import { AccountService, ConstantsService, ErrorService, SecurityQuestionsService, SnackbarService } from '@core/services';
import { AccountPagesSystemTextService } from '@core/services/account-pages-system-text.service';
import { FormGroup } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  isSubmitting$ = new Subject<boolean>();
  options: AccountFormOptions;
  verificationOptions: VerificationFormOptions;
  successNotificationText: string;
  sysText$: Observable<any>;
  templateName: string;
  otpDest: string;
  otpIsPhone: boolean;
  showVerificationForm: boolean;
  selectedProviders: SelectedProviders;
  provider: string;

  constructor(
    private acctService: AccountService,
    private sqService: SecurityQuestionsService,
    private acctTextService: AccountPagesSystemTextService,
    private constants: ConstantsService,
    private snackbarService: SnackbarService,
    private router: Router,
    private errorService: ErrorService,
    private snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.templateName = this.acctTextService.templates.resetPassword;
    this.sysText$ = this.acctTextService.sysText$.pipe(
      pluck(this.templateName),
      tap((res) => (this.successNotificationText = res[`${this.templateName}_successNotification`]))
    );
    this.options = this.constants.ACCOUNTFORM_OPTIONS_RESETPASSWORD;
    this.verificationOptions = this.constants.VERIFICATIONFORM_OPTIONS_RESETPASSWORD    
    this.showVerificationForm = true;
    this.selectedProviders = this.constants.SELECTED_PROVIDERS;
    this.provider = this.selectedProviders.emailProvider;
    this.otpDest = this.acctService.getOtpDest();
    
    // auto send otp to email (default) or phone number (otpDest length is 4 and can convert to int)  
    let int: number = Number(this.otpDest)
    this.otpIsPhone = this.otpDest.length == 4 && Number.isInteger(int);
    if (this.otpIsPhone) { 
      this.verificationOptions.phoneNumberConfirmed = true;
      this.verificationOptions.secondaryLink.show = true;
      this.provider = this.selectedProviders.phoneProvider;
    }    

    const sendCode: SendCode = {
      selectedProvider: this.provider,
      userId: this.acctService.getUserId()
    }
    this.acctService.sendCode(sendCode).subscribe(()=>{});     
  }

  ngOnDestroy() {
    this.acctService.resetCodeAndUserId();
    this.sqService.disallowAccessToSetOrResetPwd();
  }

  onFormSubmit(form: FormGroup): void {
    const resetPasswordModel: ResetPassword = this.buildResetPasswordModel(form.value.password);

    this.acctService
      .resetPassword(resetPasswordModel)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(
        (res) => this.handleSuccess(),
        (err: StiiraError) => this.errorService.setFormModelStateErrors(form, err.modelStateErrors)
      );
  }

  onVerificationSubmit(form: FormGroup) {    
    const verifyCode: VerifyCode = {
      code: form.value.code,
      provider: this.provider, 
      userId: this.acctService.getUserId()
    }    
    this.acctService.verifyCode(verifyCode)
    .pipe(indicate(this.isSubmitting$))
      .subscribe(
        (res) => {          
          if (res) {
            this.snackbar.open('Code accepted!', 'Dismiss');
            this.showVerificationForm = false; // HACKABLE!?
          }
        },
        (err: StiiraError) => {
          this.errorService.setFormModelStateErrors(form, err.modelStateErrors);
        }
      );
  }

  onResendCode(handleCode: {provider: string, resend: boolean}) : void {    
    const sendCode: SendCode = {
      selectedProvider: handleCode.provider,
      userId: this.acctService.getUserId()
    }
    this.acctService.sendCode(sendCode)
    .pipe(indicate(this.isSubmitting$))
      .subscribe(
        () => {          
          if ( handleCode.resend && handleCode.provider === this.selectedProviders.emailProvider){
            //todo: text should come from system text
            this.provider = this.selectedProviders.emailProvider;
            this.snackbar.open('Code emailed!', 'Dismiss');
          } 
          else if ( handleCode.resend && handleCode.provider === this.selectedProviders.phoneProvider){
            //todo: text should come from system text
            this.provider = this.selectedProviders.phoneProvider;
            this.snackbar.open('Code resent!', 'Dismiss');
          }              
        }
      );
  }

  private buildResetPasswordModel(pwd: string): ResetPassword {
    const resetPasswordModel: ResetPassword = {
      userId: this.acctService.getUserId(),
      username: this.acctService.getUsername(),
      code: this.acctService.getCode(),
      password: pwd,
    };
    return resetPasswordModel;
  }

  private handleSuccess(): void {
    this.snackbarService.open(this.successNotificationText, '', 5000);
    this.router.navigate(['/login']);
  }
}
