<ng-template #loadingTemplate>
  <div class="spinner-container">
    <mat-spinner color="accent" [diameter]="30"></mat-spinner>
  </div>
</ng-template>

<ng-template #errorTemplate></ng-template>

<ng-container *observe="data$ as data; before: loadingTemplate; error: errorTemplate">
  <app-sidenav-employee-info 
    [customEmployerLogo]="data.customEmployerLogo"
    [employeeInfo]="employeeInfo$ | async" 
    (linkClick)="handleLinkClick($event)">
  </app-sidenav-employee-info>
  <app-sidenav-list [links]="data.links" (logout)="logout()" (linkClick)="handleLinkClick($event)"></app-sidenav-list>
  <span class="spacer"></span>
  <app-sidenav-copyright [copyright]="data.copyright"></app-sidenav-copyright>
</ng-container>
