<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6>
		{{ data.sysText.editScheduleAssignedEmployers_header | titlecase }}
	</h6>
</div>
<mat-dialog-content>
	<form [formGroup]="form" class="dialog-content-form">
		<app-multi-select-field
			[control]="form.controls.employers"
			[label]="data.sysText.employers"
			[options]="data.options.employers"
			[icon]="'domain_add'"
			[shouldCapitalize]="false">
		</app-multi-select-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
	<button mat-stroked-button class="button" color="accent" (click)="cancel()">
		{{ data.sysText.cancel }}
	</button>
	<button mat-stroked-button class="button" color="accent" (click)="save()">
		{{ data.sysText.save }}
	</button>
</mat-dialog-actions>
<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>
