<ng-container *ngIf="group" [formGroup]="group">
  <ng-container *ngTemplateOutlet="isSimple ? formFieldSimple : formFieldComplex"></ng-container>
</ng-container>

<ng-container *ngIf="!group">
  <ng-container *ngTemplateOutlet="isSimple ? formFieldSimple : formFieldComplex"></ng-container>
</ng-container>

<ng-template #formFieldComplex>
  <div class="container">
    <mat-icon *ngIf="icon" [ngClass]="{ focus: input.focused }">{{ icon }}</mat-icon>
    <mat-form-field color="accent">
      <mat-label *ngIf="shouldCasePipe">{{ label | titlecase }}</mat-label>
      <mat-label *ngIf="!shouldCasePipe">{{ label }}</mat-label>
      <mat-icon *ngIf="showClear && !_isDisabled && hasData() && !showSpinner" matSuffix class="suffix-icon mat-clear" (click)="clearInput($event)">clear</mat-icon>
      <mat-icon *ngIf="!_isDisabled && showSearchIcon && !hasData()" matSuffix class="suffix-icon">search</mat-icon>
      <mat-spinner *ngIf="showSpinner" matSuffix [diameter]="20" color="accent" class="suffix-icon"></mat-spinner>
      <input matInput aria-label="Select an option" [matAutocomplete]="auto" [formControl]="control" #input="matInput" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="onOptionSelect()">
        <mat-option *ngFor="let option of filteredSelectionOptions | async" [value]="option.id">
          <div class="flex">
            <img *ngIf="showImage" [src]="option.image || 'assets/images/missing_avatar.svg'" alt="user-image" class="profile-image"/>
            {{ option.description }} 
          </div>
          <div [ngClass]="{ 'leave-option-info': showImage, 'leave-option-info-noimage': !showImage }">
            <span *ngIf="option.companyName" class="caption option-extras">
              <span class="bullet-spacing">&bull;</span>
              {{option.companyName}}
            </span>
            <span *ngIf="option.workEmail" class="caption option-extras">
              <span class="bullet-spacing">&bull;</span>
              {{option.workEmail}}
            </span>
            <span *ngIf="option.personalEmail" class="caption option-extras">
              <span class="bullet-spacing">&bull;</span>
              {{option.personalEmail}}
            </span>
            <span *ngIf="option.explicitEmailAddress" class="caption option-extras">
              <span class="bullet-spacing">&bull;</span>
              {{option.explicitEmailAddress}}
            </span>
          </div>
        </mat-option>
      </mat-autocomplete>
      <mat-hint [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }" align="end">{{ hint }}</mat-hint>
      <mat-hint *ngIf="!_isDisabled && hasClickableHint; else disabledHint" align="start">
        <span *ngIf="shouldCasePipe">{{ interfaceName | capitalizefirst }}</span>
        <span *ngIf="!shouldCasePipe">{{ interfaceName }}</span>
        {{ sysText.notFound | lowercase }}?
        <span (click)="onHintClick()" class="pointer">
          {{ sysText.addANew | capitalizefirst }}
          <span *ngIf="shouldCasePipe">{{ secondaryInterfaceName || interfaceName | lowercase }}</span>
          <span *ngIf="!shouldCasePipe">{{ secondaryInterfaceName || interfaceName }}</span>.
        </span>
      </mat-hint>
      <ng-template #disabledHint>
        <mat-hint *ngIf="hasClickableHint" align="start">
          <span (click)="onHintClick()" class="pointer">
            {{ sysText.cancelNew | capitalizefirst }}
            <span *ngIf="shouldCasePipe">{{ secondaryInterfaceName || interfaceName | lowercase }}</span>
            <span *ngIf="!shouldCasePipe">{{ secondaryInterfaceName || interfaceName }}</span>.
          </span>
        </mat-hint>
      </ng-template>
      <mat-error *ngIf="control.errors && !control.errors?.msError && hasClickableHint">
        {{ sysText.pleaseChooseAnExisting | capitalizefirst }}
        <span *ngIf="shouldCasePipe">{{ interfaceName | lowercase }}</span>
        <span *ngIf="!shouldCasePipe">{{ interfaceName }}</span>
        {{ sysText.or | lowercase }}
        <span (click)="onHintClick()" class="pointer">
          {{ sysText.addANew | lowercase }}
          <span *ngIf="shouldCasePipe">{{ secondaryInterfaceName || interfaceName | lowercase }}</span>
          <span *ngIf="!shouldCasePipe">{{ secondaryInterfaceName || interfaceName }}</span>.
        </span>
      </mat-error>
      <mat-error *ngIf="control.errors && !control.errors?.msError && !hasClickableHint">
        {{ sysText.pleaseChooseAnExisting | capitalizefirst }}
        <span *ngIf="shouldCasePipe">{{ interfaceName | lowercase }}</span>
        <span *ngIf="!shouldCasePipe">{{ interfaceName }}</span>
      </mat-error>
      <mat-error *ngIf="msErrorKey != null || control.errors?.msError != null ">
        {{ control.errors?.msError == msErrorKey ? customMsError : control.errors?.msError }}
        <!-- attach add new link to msError coming from back-end -->
        <span *ngIf="comboMsError" (click)="onHintClick()" class="pointer">
          {{ sysText.addANew | lowercase }}
          <span *ngIf="shouldCasePipe">{{ secondaryInterfaceName || interfaceName | lowercase }}</span>
          <span *ngIf="!shouldCasePipe">{{ secondaryInterfaceName || interfaceName }}</span>.
        </span>
      </mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="!_isDisabled && hasClickableHint && showHintAndError && control.errors" class="custom-mat-hint">
    <span *ngIf="shouldCasePipe">{{ interfaceName | capitalizefirst }}</span>
    <span *ngIf="!shouldCasePipe">{{ interfaceName }}</span>
    {{ sysText.notFound | lowercase }}?
    <span (click)="onHintClick()" class="pointer">
      {{ sysText.addANew | capitalizefirst }}
      <span *ngIf="shouldCasePipe">{{ secondaryInterfaceName || interfaceName | lowercase }}</span>
      <span *ngIf="!shouldCasePipe">{{ secondaryInterfaceName || interfaceName }}</span>.
    </span>
  </div>
</ng-template>

<ng-template #formFieldSimple>
  <div class="container">
    <mat-icon [ngClass]="{ focus: input.focused }">{{ icon }}</mat-icon>
    <mat-form-field color="accent">
      <mat-label>{{ label | titlecase }}</mat-label>
      <mat-icon *ngIf="!_isDisabled && showSearchIcon" matSuffix class="suffix-icon">search</mat-icon>
      <input matInput aria-label="Select an option" [matAutocomplete]="auto" [formControl]="control" #input="matInput" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredStringOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }" align="end">{{ hint }}</mat-hint>
      <mat-error *ngIf="control.errors?.msError">{{ control.errors?.msError }}</mat-error>
      <mat-error *ngIf="control.errors?.required">{{ sysText.required | capitalizefirst }}</mat-error>
    </mat-form-field>
  </div>
</ng-template>
