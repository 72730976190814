import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AddEmployeeToRoleComponent } from './add-employee-to-role/add-employee-to-role.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { ChangeImageComponent } from './change-image/change-image.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeUsernameComponent } from './change-username/change-username.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { IdleAlertComponent } from './idle-alert/idle-alert.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { LoginPageAlertComponent } from './login-page-alert/login-page-alert.component';
import { RoleExplanationComponent } from './role-explanation/role-explanation.component';
import { UnsavedChangesComponent } from './unsaved-changes/unsaved-changes.component';
import { ViewEmployeeRolesComponent } from './view-employee-roles/view-employee-roles.component';
import { ChangePhoneNumberComponent } from './change-phone-number/change-phone-number.component';
import { AssignCaseAdminComponent } from './assign-case-admin/assign-case-admin.component';
import { CloseCaseComponent } from './close-case/close-case.component';
import { ReopenCaseComponent } from './reopen-case/reopen-case.component';
import { EditCaseNoteComponent } from './edit-case-note/edit-case-note.component';
import { LeaveEmployeeInfoComponent } from './leave-employee-info/leave-employee-info.component';
import { LeaveInfoDialogComponent } from './leave-info-dialog/leave-info-dialog.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { SubmitNewRequestComponent } from './submit-new-request/submit-new-request.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { SubmitIntermittentTimeComponent } from './submit-intermittent-time/submit-intermittent-time.component';
import { AddNewEmployerComponent } from './add-new-employer/add-new-employer.component';
import { EditCalendarTimeComponent } from './edit-calendar-time/edit-calendar-time.component';
import { UpdateConfirmationComponent } from './update-confirmation/update-confirmation.component';
import { EmployerSettingsComponent } from './employer-settings/employer-settings.component';
import { EditApplicableStatesComponent } from './edit-applicable-states/edit-applicable-states.component';
import { EditEmployerLeaveAdminInfoComponent } from './edit-employer-leave-admin-info/edit-employer-leave-admin-info.component';
import { EditEmployerContactsComponent } from './edit-employer-contacts/edit-employer-contacts.component';
import { LeaveAdminDashboardTableFiltersComponent } from './leave-admin-dashboard-table-filters/leave-admin-dashboard-table-filters.component';
import { EditDocumentComponent } from './edit-document/edit-document.component';
import { ContactAdminComponent } from './contact-admin/contact-admin.component';
import { AddNewEmployeeComponent } from './add-new-employee/add-new-employee.component';
import { EditEmployeeRecordInfoComponent } from './edit-employee-record-info/edit-employee-record-info.component';
import { ReviewMergeComponent } from './review-merge/review-merge.component';
import { DuplicateDetectedDialogComponent } from './duplicate-detected-dialog/duplicate-detected-dialog.component';
import { EmployeeSettingsComponent } from './employee-settings/employee-settings.component';
import { EditLeaveHoursComponent } from './edit-leave-hours/edit-leave-hours.component';
import { SendInvitationComponent } from './send-invitation/send-invitation.component';
import { ViewCalendarItemComponent } from './view-calendar-item/view-calendar-item.component';
import { ViewEmailComponent } from './view-email/view-email.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SendNewEmailComponent } from './send-new-email/send-new-email.component';
import { CaseCommunicationModule } from '@modules/case-communication/case-communication.module';
import { AddNewTemplateComponent } from './add-new-template/add-new-template.component';
import { EditTemplateInfoComponent } from './edit-template-info/edit-template-info.component';
import { EditEmailContentComponent } from './edit-email-content/edit-email-content.component';
import { EditEmployerCaseComSettingsComponent } from './edit-employer-case-com-settings/edit-employer-case-com-settings.component';
import { AddEditDocumentCategoryComponent } from './add-edit-document-category/add-edit-document-category.component';
import { HideCaseComponent } from './hide-case/hide-case.component';
import { ProceedConfirmationComponent } from './proceed-confirmation/proceed-confirmation.component';
import { AddNewNoteComponent } from './add-new-note/add-new-note.component';
import { AddRecertComponent } from './add-recert/add-recert.component';
import { EditRecertComponent } from './edit-recert/edit-recert.component';
import { AddEditLeaveYearRulesComponent } from './add-edit-leave-year-rule/add-edit-leave-year-rule.component';
import { CalendarRequirementsWarningComponent } from './calendar-requirements-warning/calendar-requirements-warning.component';
import { EditTemplateAssignedEmployersComponent } from './edit-template-assigned-employers/edit-template-assigned-employers.component';
import { EditScheduleAssignedEmployersComponent } from './edit-schedule-assigned-employers/edit-schedule-assigned-employers.component';
import { EditScheduleComponent } from './edit-schedule/edit-schedule.component';
import { EditScheduleInfoComponent } from './edit-schedule-info/edit-schedule-info.component';
import { AddEditNonWorkdaysComponent } from './add-edit-non-workdays/add-edit-non-workdays.component';
import { AddNewScheduleComponent } from './add-new-schedule/add-new-schedule.component';
import { AddEditEmployeeScheduleComponent } from './add-edit-employee-schedule/add-edit-employee-schedule.component';
import { AddEditCalendarWorkdayComponent } from './add-edit-calendar-workday/add-edit-calendar-workday.component';
import { SpreadLeaveHoursComponent } from './spread-leave-hours/spread-leave-hours.component';

@NgModule({
  declarations: [
    ChangePasswordComponent,
    ChangeUsernameComponent,
    ChangeImageComponent,
    EditEmployeeComponent,
    UnsavedChangesComponent,
    ComingSoonComponent,
    IdleAlertComponent,
    RoleExplanationComponent,
    ImpersonateComponent,
    AddRoleComponent,
    AddEmployeeToRoleComponent,
    ViewEmployeeRolesComponent,
    DeleteConfirmationComponent,
    LoginPageAlertComponent,
    ChangePhoneNumberComponent,
    AssignCaseAdminComponent,
    LeaveInfoDialogComponent,
    EditCaseNoteComponent,
    CloseCaseComponent,
    LeaveEmployeeInfoComponent,
    ReopenCaseComponent,
    ChangeEmailComponent,
    SubmitNewRequestComponent,
    DocumentUploadComponent,
    SubmitIntermittentTimeComponent,
    AddNewEmployerComponent,
    EditCalendarTimeComponent,
    UpdateConfirmationComponent,
    EmployerSettingsComponent,
    EditApplicableStatesComponent,
    EditEmployerLeaveAdminInfoComponent,
    EditEmployerContactsComponent,
    LeaveAdminDashboardTableFiltersComponent,
    EditDocumentComponent,
    ContactAdminComponent,
    AddNewEmployeeComponent,
    EditEmployeeRecordInfoComponent,
    ReviewMergeComponent,
    DuplicateDetectedDialogComponent,
    EmployeeSettingsComponent,
    EditLeaveHoursComponent,
    SendInvitationComponent,
    ViewCalendarItemComponent,
    ViewEmailComponent,
    SendNewEmailComponent,
    AddNewTemplateComponent,
    EditTemplateInfoComponent,
    EditEmailContentComponent,
    EditEmployerCaseComSettingsComponent,
    AddEditDocumentCategoryComponent,
    HideCaseComponent,
    ProceedConfirmationComponent,
    AddNewNoteComponent,
    AddRecertComponent,
    EditRecertComponent,
    AddEditLeaveYearRulesComponent,
    CalendarRequirementsWarningComponent,
    EditTemplateAssignedEmployersComponent,
    EditScheduleComponent,
    EditScheduleInfoComponent,
    EditScheduleAssignedEmployersComponent,
    AddEditNonWorkdaysComponent,
    AddNewScheduleComponent,
    AddEditEmployeeScheduleComponent,
    AddEditCalendarWorkdayComponent,
    SpreadLeaveHoursComponent
  ],
  imports: [SharedModule, ImageCropperModule, EditorModule, CaseCommunicationModule],
  providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}]
})
export class DialogsModule {}
