import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { EmployeeDetails, EmployeeDetailsPost } from '@core/models';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class EmployeeFormService {
  public form: FormGroup;
  public formInitValues: any;

  
  get firstName(): FormControl {
    return this.form.controls.firstName as FormControl<string>;
  }

  get middleName(): FormControl {
    return this.form.controls.middleName as FormControl<string>;
  }
  
  get lastName(): FormControl {
    return this.form.controls.lastName as FormControl<string>;
  }
  
  get preferredFirstName(): FormControl {
    return this.form.controls.preferredFirstName as FormControl<string>;
  }
  
  get suffix(): FormControl {
    return this.form.controls.suffix as FormControl<string>;
  }

  get personalEmail(): FormControl {
    return this.form.controls.personalEmail as FormControl<string>;
  }

  get personalEmailChecked(): FormControl {
    return this.form.controls.personalEmailChecked as FormControl<boolean>;
  }
  
  get workEmail(): FormControl {
    return this.form.controls.workEmail as FormControl<string>;
  }

  
  get workEmailChecked(): FormControl {
    return this.form.controls.workEmailChecked as FormControl<boolean>;
  }

  get personalPhone(): FormControl {
    return this.form.controls.personalPhone as FormControl<string>;
  }

  get workPhone(): FormControl {
    return this.form.controls.workPhone as FormControl<string>;
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      firstName: [null, Validators.required],
      middleName: null,
      lastName: [null, Validators.required],
      preferredFirstName: null,
      suffix: null,
      workEmail: [null],
      workEmailChecked: null,
      personalEmail: [null],
      personalEmailChecked: null,
      workPhone: null,
      personalPhone: null
    });
  }

  loadFormForEdit(data: EmployeeDetails): void {
    this.form.patchValue({
      ...data,
    });

    if (data.primaryEmailIsPersonal){
      this.form.controls.personalEmailChecked.patchValue(true);
    } else if (data.primaryEmailIsPersonal === false){
      this.form.controls.workEmailChecked.patchValue(true);
    }

    this.formInitValues = { ...this.form.value };
  }

  createEmployeeDetailsDto(): EmployeeDetailsPost {
    const details: EmployeeDetailsPost = {
      firstName: this.firstName.value,
      middleName: this.middleName.value,
      lastName: this.lastName.value,
      preferredFirstName: this.preferredFirstName.value,
      suffix: this.suffix.value, 
      personalEmail: this.personalEmail.value,
      workEmail: this.workEmail.value,
      primaryEmailIsPersonal: this.workEmailChecked.value ? false : this.personalEmailChecked.value ? true : null,
      personalPhone: this.personalPhone.value,
      workPhone: this.workPhone.value
    }

    return details;
  }  
}