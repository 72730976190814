<ng-container *ngIf="showBgImg">  
  <ng-container *observe="employerLogo$ as erLogoData; before: loadingLogoTemplate; error: errorTemplate">
    <img *ngIf="erLogoData.image; else stiiraLogo" [src]="erLogoData.image" alt="Employer Logo" class="logo custom"/>
    <ng-template #stiiraLogo>
      <img src="assets/images/stiira_logo.svg" alt="Stiira Logo" class="logo" width="140px" />
    </ng-template>
    <div class="flowers-container">
      <img src="assets/images/flowers.svg" alt="flowers" class="flowers" />
    </div>
  </ng-container>
</ng-container>

<div class="outlet" *observe="data$ as data; before: loadingTemplate; error: errorTemplate">
  <router-outlet *ngIf="showBgImg"></router-outlet>
</div>

<ng-template #loadingLogoTemplate>
  <mat-spinner class="logo" color="accent" [diameter]="40"></mat-spinner>
</ng-template>

<ng-template #loadingTemplate>
  <div class="outlet spinner-container">
    <mat-spinner color="accent" [diameter]="40"></mat-spinner>
  </div>
</ng-template>

<ng-template #errorTemplate></ng-template>
