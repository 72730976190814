<mat-expansion-panel 
    class="info-panel" 
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState">
    <mat-expansion-panel-header>
        <mat-panel-title>
        <div class="section-header-container">
            <div class="d-flex">
                <p class="overline grey1 semibold section-title">{{sysText.emailContent | uppercase}}</p>
                <app-status-chip *ngIf="templateDetails?.missingDetails?.hasOwnProperty('subjectHtml') 
                    || templateDetails?.missingDetails?.hasOwnProperty('bodyHtml')" 
                    class="status-chip"
                    [icon]="'error_outline'"
                    [ngStyle]="chipStyles.warnBg"
                    [removeMargin]="true"
                    [matTooltip]="(
                        (templateDetails?.missingDetails?.hasOwnProperty('subjectHtml') 
                            && !templateDetails?.missingDetails?.hasOwnProperty('bodyHtml')) ? sysText.emailContent_subjectRequired :
                        (!templateDetails?.missingDetails?.hasOwnProperty('subjectHtml') 
                            && templateDetails?.missingDetails?.hasOwnProperty('bodyHtml')) ? sysText.emailContent_bodyRequired : 
                        sysText.emailContent_required)" [matTooltipShowDelay]="500" matTooltipPosition="above">
                </app-status-chip>
            </div>
            <mat-divider class="section-header-underline"></mat-divider>
        </div> 
        <ng-container *ngIf="templateDetails.canEditEmail">
            <button mat-icon-button color="accent" aria-label="icon-button with edit icon"  class="edit-employee-button" (click)="editEmailContent($event)">
                <mat-icon>edit</mat-icon>
            </button>
        </ng-container>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="main-content flex">
        <p class="body2 grey2">{{sysText.emailSubjectLine}}</p>
        <div class="editor-container">
            <editor
                #subEditor
                [(ngModel)]="templateDetails.subjectHtml"
                [init]="{ 
                max_height: 53,
                width: '100%',
                menubar: false,
                statusbar: false,
                toolbar: false,
                setup: tinySetup,
                paste_block_drop: true,
                paste_as_text: true,
                promotion: false,
                plugins: 'autoresize',
                content_style: editorStyle}">
            </editor>
        </div>
        <p class="body2 grey2">{{sysText.emailBody}}</p>
        <div class="editor-container">
            <editor
                #bodyEditor
                [(ngModel)]="templateDetails.bodyHtml"
                [init]="{ 
                width: '100%',
                max_height: 500,
                menubar: false,
                statusbar: false,
                toolbar: false,
                setup: tinySetup,
                paste_block_drop: true,
                paste_as_text: true,
                promotion: false,
                plugins: 'autoresize',
                autoresize_bottom_margin: 5,
                content_style: editorStyle}">
            </editor>
        </div>
    </div>
</mat-expansion-panel>