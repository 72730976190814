<ng-template #loadingTemplate>
	<div class="spinner-container">
		<mat-spinner color="accent"></mat-spinner>
	</div>
</ng-template>

<ng-template #errorTemplate></ng-template>

<ng-container *ngIf="templateDetails$ | async as templateDetails; else loadingTemplate">
    <div class="template-details-container">
    
        <!-- Template Information -->
        <div class="info-section">
            <app-template-information
                (isEditing)="onEdit($event)"
                [templateDetails]="templateDetails"
                [sysText]="sysText.templateDetails"
                [unsavedChangesSysText]="sysText.unsavedChanges">
            </app-template-information>
        </div>

        <!-- Assigned Employers -->
        <div *ngIf="templateDetails.showAssignedEmployers" class="info-section">
            <app-template-assigned-employers
                (isEditing)="onEdit($event)"
                [templateDetails]="templateDetails"
                [sysText]="sysText.templateDetails"
                [unsavedChangesSysText]="sysText.unsavedChanges">
            </app-template-assigned-employers>
        </div>

        <!-- Email Content -->
        <div class="info-section">
            <app-template-email-content
                (isEditing)="onEdit($event)"
                [templateDetails]="templateDetails"
                [sysText]="sysText.templateDetails"
                [unsavedChangesSysText]="sysText.unsavedChanges">
            </app-template-email-content>
        </div>

        <!-- Template Documents -->
        <div class="info-section">
            <app-template-attached-documents
                (isEditing)="onEdit($event)"
                [templateDetails]="templateDetails"
                [documents]="templateDetails.documents"
                [sysText]="sysText.templateDetails"
                [templateId]="templateDetails.id"
                [uploadDocSysText]="sysText.uploadDocumentDialog"
                [unsavedChangesSysText]="sysText.unsavedChanges">
            </app-template-attached-documents>
        </div>

        <!-- Buttons -->
        <div *ngIf="templateDetails.showAssignToEmployer" class="bottom-section">
            <button
                *ngIf="!templateDetails.assignedToEmployer"
                (click)="assignEmployer()"
                mat-flat-button
                color="accent"
                class="button bottom-button"
                [disabled]="(isSaving$ | async)">
                <span *ngIf="!(isSaving$ | async)">{{ 'Assign My Employer' | uppercase }}</span>
                <span *ngIf="(isSaving$ | async)"><mat-spinner [diameter]="30" color="accent"></mat-spinner></span>
            </button>
            <button
                *ngIf="templateDetails.assignedToEmployer"
                (click)="unassignEmployer()"
                mat-flat-button
                color="warn"
                class="button bottom-button"
                [disabled]="(isSaving$ | async)">
                <span *ngIf="!(isSaving$ | async)">{{ 'Unassign My Employer' | uppercase }}</span>
                <span *ngIf="(isSaving$ | async)"><mat-spinner [diameter]="30" color="accent"></mat-spinner></span>
            </button>
          </div>

    </div>
</ng-container>
