<ng-container *ngIf="!showVerificationForm">
  <app-account-form
    [sysText]="sysText$ | async"
    [page]="templateName"
    [options]="options"
    [isSubmitting]="isSubmitting$ | async"
    (formSubmit)="onFormSubmit($event)"
  ></app-account-form>
</ng-container>

<ng-container *ngIf="showVerificationForm">  
  <app-verification-form
    [sysText]="sysText$ | async"    
    [page]="templateName"
    [options]="verificationOptions"
    [selectedProviders]="selectedProviders"
    [otpDest]="otpDest"
    [otpIsPhone]="otpIsPhone"
    [isSubmitting]="isSubmitting$ | async"
    (sendCode)="onResendCode($event)"
    (formSubmit)="onVerificationSubmit($event)"
  >  
  </app-verification-form>
</ng-container>
