import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EmailTemplates } from '@core/models/case-communication/email-templates.model';
import { delay } from 'rxjs/operators';
import { ATTACHDOWNLOAD, CASECOMDRAWER, CASEEMAILINFO } from '@core/mocks/case-communication/case-communication-drawer.mock';
import { CaseCommunicationDrawer, CaseEmailInfo, GetAttachment } from '@core/models/case-communication/case-communication-drawer.model';
import { FileDownload } from '@core/models/shared/file-download.model';
import { EMAILTEMPLATES } from '@core/mocks/case-communication/template-item.mock';
import { EmailPreview, GetSendNewEmailTemplateDetails, SendNewEmailDialog, SendNewEmailTemplateDetails } from '@core/models/case-communication/send-new-email-dialog.model';
import { EMAILPREVIEW, NEWEMAILTEMPLATE, SENDNEWEMAILDIALOG } from '@core/mocks/case-communication/send-new-email-dialog.mock';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { TEMPLATEDETAILS } from '@core/mocks/case-communication/template-details.mock'
import { NewTemplatePost } from '@core/models/case-communication/new-template-post.model';
import { TemplateInfoDialog, TemplateInfoDialogPost } from '@core/models/case-communication/template-info-dialog.model';
import { TEMPLATEINFODIALOG } from '@core/mocks/case-communication/template-info-dialog.mock';
import { EmailContentPost } from '@core/models/case-communication/email-content-form.model';
import { DOCDOWNLOAD } from '@core/mocks/my-documents/my-documents.mock';
import { DocumentUploadDialog } from '@core/models/leave-admin/document-upload';
import { DOCUMENTUPLOADDIALOG_MOCK } from '@core/mocks/leave-admin/document-upload.mock';
import { DocumentEditDialog, PostTemplateDocumentEdit } from '@core/models';
import { PostDocumentCategory } from '@core/models/case-communication/post-document-category.model';
import { AddEditDocumentCategoryDialog } from '@core/models/case-communication/add-edit-document-category-dialog.model';
import { ADDEDITDOCUMENTCATEGORYDIALOG_MOCK } from '@core/mocks/case-communication/add-edit-document-category-dialog.mock';
import { TemplateAssignedEmployersDialog, TemplateAssignedEmployersDialogPost } from '@core/models/case-communication/template-assigned-employers-dialog.model';

@Injectable({
  providedIn: 'root'
})
export class CaseCommunicationService {

  constructor(private constants: ConstantsService, private http: HttpClient) { }

  public getCaseCommunicationDrawer(caseId: number): Observable<CaseCommunicationDrawer> {
    return this.http.get<any>(`${this.constants.CC_API}/getCaseCommunicationDrawer/${caseId}`);
    //return of(CASECOMDRAWER).pipe(delay(1000));
  }

  public getEmailInfoDialog(caseEmailId: number): Observable<CaseEmailInfo> {
    return this.http.get<CaseEmailInfo>(`${this.constants.CC_API}/getEmailInfoDialog/${caseEmailId}`);
    //return of(CASEEMAILINFO).pipe(delay(1000));
  }

  public getSendNewEmailDialog(caseId: number): Observable<SendNewEmailDialog> {
    return this.http.get<SendNewEmailDialog>(`${this.constants.CC_API}/getSendNewEmailDialog/${caseId}`);
    //return of(SENDNEWEMAILDIALOG).pipe(delay(1000));
  }

  public getEmailPreview(dto: EmailPreview): Observable<EmailPreview> {
    return this.http.post<EmailPreview>(`${this.constants.CC_API}/getEmailPreview`, dto)
    //return of(EMAILPREVIEW).pipe(delay(1000));
  }

  public getEmailTemplates(): Observable<EmailTemplates> {
    return this.http.get<EmailTemplates>(`${this.constants.CC_API}/getEmailTemplates`);
    //return of(EMAILTEMPLATES).pipe(delay(1000));
  }

  public getTemplateDetails(templateId: number): Observable<TemplateDetails> {
    return this.http.get<TemplateDetails>(`${this.constants.CC_API}/getTemplateDetails/${templateId}`);
    //return of(TEMPLATEDETAILS).pipe(delay(1000));
  }

  public getSendNewEmailTemplateDetails(dto: GetSendNewEmailTemplateDetails): Observable<SendNewEmailTemplateDetails> {
    return this.http.post<SendNewEmailTemplateDetails>(`${this.constants.CC_API}/getSendNewEmailTemplateDetails`, dto);
    //return of(NEWEMAILTEMPLATE).pipe(delay(1000));
  }

  public getAttachment(dto: GetAttachment): Observable<FileDownload> {
    return this.http.post<FileDownload>(`${this.constants.CC_API}/getAttachment`, dto)
    //return of(ATTACHDOWNLOAD).pipe(delay(1000));
  }

  public getEditTemplateInfoDialog(templateId: number): Observable<TemplateInfoDialog> {
    return this.http.get<TemplateInfoDialog>(`${this.constants.CC_API}/getEditTemplateInfoDialog/${templateId}`);
    //return of(TEMPLATEINFODIALOG).pipe(delay(1000))
  }

  public getEditTemplateAssignedEmployersDialog(templateId: number): Observable<TemplateAssignedEmployersDialog> {
    return this.http.get<TemplateAssignedEmployersDialog>(`${this.constants.CC_API}/getEditTemplateAssignedEmployersDialog/${templateId}`);
  }

  public getDocumentUploadDialog(templateId: number): Observable<DocumentUploadDialog> {
    return this.http.get<DocumentUploadDialog>(`${this.constants.CC_API}/getDocumentUploadDialog/${templateId}`)
    //return of(DOCUMENTUPLOADDIALOG_MOCK);
  }

  public getDocument(documentId: number): Observable<FileDownload> {
    return this.http.get<FileDownload>(`${this.constants.CC_API}/getDocument/${documentId}`)
    //return of(DOCDOWNLOAD);
  }

  public getDocumentEditDialog(templateId: number): Observable<DocumentEditDialog> {
    return this.http.get<DocumentEditDialog>(`${this.constants.CC_API}/getDocumentEditDialog/${templateId}`)
    //return of({categories: []});
  }

  public getAddEditDocumentCategoryDialog(categoryId: number = null): Observable<AddEditDocumentCategoryDialog> {
    return this.http.get<AddEditDocumentCategoryDialog>(`${this.constants.CC_API}/getAddEditDocumentCategoryDialog/${categoryId}`)
    //return of(ADDEDITDOCUMENTCATEGORYDIALOG_MOCK);
  }

  public postDocumentEdit(dto: PostTemplateDocumentEdit): Observable<TemplateDetails> { 
    return this.http.post<TemplateDetails>(`${this.constants.CC_API}/postDocumentEdit`, dto);
    //return of(TEMPLATEDETAILS).pipe(delay(1000));
  }

  public postTemplateDocumentUpload(formData: FormData): Observable<TemplateDetails> {
    return this.http.post<TemplateDetails>(`${this.constants.CC_API}/postTemplateDocumentUpload`, formData)
    //return of(TEMPLATEDETAILS).pipe(delay(1000));
  }

  public postNewTemplate(dto: NewTemplatePost): Observable<number> {
    return this.http.post<number>(`${this.constants.CC_API}/postNewTemplate`, dto)
    //return of(300217).pipe(delay(1000));
  }

  public postTemplateInfo(dto: TemplateInfoDialogPost): Observable<TemplateDetails> {
    return this.http.post<TemplateDetails>(`${this.constants.CC_API}/postTemplateInfo`, dto)
    //return of(TEMPLATEDETAILS).pipe(delay(1000)); 
  }

  public postTemplateAssignedEmployers(dto: TemplateAssignedEmployersDialogPost): Observable<TemplateDetails> {
    return this.http.post<TemplateDetails>(`${this.constants.CC_API}/postTemplateAssignedEmployers`, dto)
    //return of(TEMPLATEDETAILS).pipe(delay(1000)); 
  }

  public postTemplateEmailContent(dto: EmailContentPost): Observable<TemplateDetails> {
    return this.http.post<TemplateDetails>(`${this.constants.CC_API}/postTemplateEmailContent`, dto)
    //return of(TEMPLATEDETAILS).pipe(delay(1000)); 
  }

  public postDocumentDelete(dto: {templateDocumentId: number}): Observable<TemplateDetails> { 
    return this.http.post<TemplateDetails>(`${this.constants.CC_API}/postDocumentDelete`, dto);
    //return of(0).pipe(delay(1000));
  }

  public postSendNewEmail(dto: FormData): Observable<CaseCommunicationDrawer > {
    return this.http.post<CaseCommunicationDrawer>(`${this.constants.CC_API}/postSendNewEmail`, dto)
    //return of(null).pipe(delay(1000)); 
  }

  public postEditDocumentCategory(dto: PostDocumentCategory): Observable<EmailTemplates> {
    return this.http.post<EmailTemplates>(`${this.constants.CC_API}/postEditDocumentCategory`, dto)
    //return of().pipe(delay(1000));
  }

  public postNewDocumentCategory(dto: PostDocumentCategory): Observable<EmailTemplates> {
    return this.http.post<EmailTemplates>(`${this.constants.CC_API}/postNewDocumentCategory`, dto)
    //return of().pipe(delay(1000));
  }

  public postAssignMyEmployer(dto: {templateId: number}): Observable<boolean> { 
    return this.http.post<any>(`${this.constants.CC_API}/postAssignMyEmployer`, dto);
  }

  public postUnassignMyEmployer(dto: {templateId: number}): Observable<boolean> { 
    return this.http.post<any>(`${this.constants.CC_API}/postUnassignMyEmployer`, dto);
  }
}
