import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CalendarEventFlags, CalendarEventTypes } from '@core/enums/calendar-event-type.enum';
import { CaseDetails, LeaveInfo } from '@core/models';
import { ConstantsService, LayoutService } from '@core/services';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { EditCalendarTimeComponent } from '@modules/dialogs/edit-calendar-time/edit-calendar-time.component';
import { ViewCalendarItemComponent } from '@modules/dialogs/view-calendar-item/view-calendar-item.component';
import { CalendarOptions, Calendar, EventApi, EventSourceFuncArg, EventInput, EventContentArg, ViewMountArg } from '@fullcalendar/core';
import dayGridMonth from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { LeaveCalendarPageData, EmployeeCalendarPageDataGet, CaseDetailsCalendarPageDataGet, CustomCalendarEventProps } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { EmployeeRecord } from '@core/models/leave-admin/employees/employee-record.model';
import { AddEditCalendarWorkdayComponent } from '@modules/dialogs/add-edit-calendar-workday/add-edit-calendar-workday.component';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { Router } from '@angular/router';
import { appRoutePaths, leaveAdminRoutes } from '@core/routes/route-paths.constants';
import { EmployeeRecordStoreService } from '@core/services/employee-record-store.service';
import { VerboseFormattingArg } from '@fullcalendar/core/internal';

@Component({
  selector: 'app-leave-calendar',
  templateUrl: './leave-calendar.component.html',
  styleUrls: ['./leave-calendar.component.scss']
})
export class LeaveCalendarComponent implements OnInit {
  @Input() tabChangeIndex: Subject<number>;
  @Input() caseDetails: CaseDetails;
  @Input() employeeRecord: EmployeeRecord;
  @Input() cbShowCalendarWarning: () => void;

  @Input() sysText: any;
  @Input() unsavedChangesSysText: any;
  @Input() editCalendarTimeSysText: any;
  @Input() viewCalendarItemSysText: any;
  @Input() addEditCalWorkdaySysText: any;

  public tabIndex: number;
  public hasShownWarning: boolean = false;
  public isLoading: boolean = false;
  public calendarOptions: CalendarOptions;
  private calendar: Calendar;
  
  private hasBeenInitialized: boolean = false;
  private blockGetEvents: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  private calendarNeedsUpdate: boolean = false;
  private currentView: string;

  constructor(
    private datePipe: DatePipe,
    private constants: ConstantsService,
    private layoutService: LayoutService,
    private caseDetailsStore: LeaveAdminStoreService,
    private employeeRecordStore: EmployeeRecordStoreService,
    private dialog: MatDialog,
    private leaveAdminStore: LeaveAdminService,
    private manageEmployeesService: ManageEmployeesService,
    private router: Router
  ) {
    this.calendarOptions = this.createCalendarOptions();
  }

  ngOnInit(): void {
    if (this.caseDetails == null)
      this.calendarOptions.footerToolbar["center"] = this.calendarOptions.footerToolbar["center"].replace(' listLeaveHourExtents', '');
    
    this.tabChangeIndex
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res == null || this.tabIndex == res)
          // the tab index is poked (and not changed) by some operations
          // (e.g. adding intermittent time or spreading hours) to trigger
          // a refresh of the calendar

          this.calendarNeedsUpdate = true;

        if (res != null)
          this.tabIndex = res;

        if (!this.hasBeenInitialized && this.tabIndex == 1) {
          this.hasBeenInitialized = true;
          setTimeout(()=>{
            // first time viewing the calendar tab since the case details were 
            // loaded, so initialize the calendar 

            this.renderCal(true);
          }, 500);
        } 
        else if (this.hasBeenInitialized && this.calendarNeedsUpdate) {
          this.renderCal();
        }
        
        if (this.caseDetails?.showCalendarOverlayForAdmins 
            && (this.caseDetails.leaveCalendar?.missingCalendarRequirements 
              || this.caseDetails.leaveCalendar?.hasLeaveHoursErrors) 
            && !this.hasShownWarning && this.tabIndex == 1) {
          // first time on the calendar tab since loading case details for a 
          // case with missing calendar requirements, so show the warning dialog 

          this.hasShownWarning = true;
          this.cbShowCalendarWarning();
        }
    });

    if (this.employeeRecord) {
      this.employeeRecordStore.employeeRecord$
        .pipe(takeUntil(this.destroy$))
        .subscribe((res)=> {
          this.employeeRecord = res;

          if (res != null && this.hasBeenInitialized) {
            this.calendarNeedsUpdate = true;
            this.renderCal();
          }
        });
    } 
    else if (this.caseDetails) {
      this.caseDetailsStore.caseDetails$
        .pipe(takeUntil(this.destroy$))
        .subscribe((res)=> {
          this.caseDetails = res;

          if (res != null && this.hasBeenInitialized) {
            this.calendarNeedsUpdate = true;
            this.renderCal();
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /* renderCal will be called for  two cases:
   * 1) the calendar is being initialized for the first time after
   *    retrieving a case (calendarNeedsUpdate == false)
   * 2) an operation has changed data that has potentially affected
   *    the calendar (calendarNeedsUpdate == true)
   */
  private renderCal(goToCaseDate: boolean = false): void {
    if (!this.calendar) {
      // 'new Calendar' causes 'getEvents' to fire, so set 'blockGetEvents' 
      // flag to block getEvents from getting data twice - getEvents will 
      // fire again on 'gotoDate'

      this.blockGetEvents = true;
      this.calendar = new Calendar(document.getElementById('calendar'), this.calendarOptions);
      this.blockGetEvents = false;
    }

    if (this.calendarNeedsUpdate) {
      this.calendarNeedsUpdate = false;

      if (this.calendar.view.type == 'listLeaveHourExtents') {
        // reset the view so the calendar title updates

        this.calendar.changeView('listLeaveHourExtents', this.leaveHourExtentsVisibleRange(this.calendar.getDate()));
      }

      this.calendar.removeAllEvents();
      this.calendar.refetchEvents();  

      return;
    }

    let targetCalendarDate = this.getStickyCalendarDate();
    
    if (!targetCalendarDate) {
      targetCalendarDate = new Date();
    }

    if (goToCaseDate 
      && this.caseDetails?.leaveInformation.startDate != null 
      && targetCalendarDate < new Date(this.caseDetails?.leaveInformation.startDate)) {
      this.calendar.gotoDate(new Date(this.caseDetails?.leaveInformation.startDate));
    }
    else if (goToCaseDate 
      && this.caseDetails?.leaveInformation.endDate != null 
      && targetCalendarDate > new Date(this.caseDetails?.leaveInformation.endDate)) {
      this.calendar.gotoDate(new Date(this.caseDetails?.leaveInformation.endDate));
    }
    else {
      this.calendar.gotoDate(targetCalendarDate);
    }

    this.calendar.render();
  }

  private setStickyCalendarDate(stickyCalendarDate: Date) {
    sessionStorage.setItem("stickyCalendarDate", stickyCalendarDate.toLocaleDateString());
  }

  private getStickyCalendarDate(): Date {
    let stickyCalendarDate: Date;
    const lastStickyCalendarDate = sessionStorage.getItem("stickyCalendarDate");

    if (lastStickyCalendarDate) {
      stickyCalendarDate = new Date(lastStickyCalendarDate);
    }

    return stickyCalendarDate;
  }

  private viewWillUnmount(arg: ViewMountArg) {
    // when switching FROM calendar TO list, handle 
    // changing all background events to block since 
    // background events will not render in list view
    // when switching FROM list TO calendar, handle 
    // changing 'cell' event's display to 'background'

    let events = arg.view.calendar.getEvents();

    const displayValue = arg.view.type.startsWith('calendar')
      ? 'block'
      : 'background';

    events.forEach((event) => {
      const customProps = event.extendedProps.customProps as CustomCalendarEventProps;

      if (customProps?.eventType == CalendarEventTypes.BgWorkdayCell 
        || customProps?.eventType == CalendarEventTypes.BgNonWorkdayCell
        || customProps?.eventType == CalendarEventTypes.BgBlankCell
        || customProps?.eventType == CalendarEventTypes.BgBlankCellNoTitle) {
        event.setProp('display', displayValue);
      }
    });
  }

  private eventOrder(a: EventApi, b: EventApi): number {
    // for list view, sort 'cell' events to the top and sort hours events by case id 

    const customPropsA = a.extendedProps.customProps as CustomCalendarEventProps;
    const customPropsB = b.extendedProps.customProps as CustomCalendarEventProps;

    if (customPropsA?.eventType == CalendarEventTypes.BgWorkdayCell 
      || customPropsA?.eventType == CalendarEventTypes.BgNonWorkdayCell
      || customPropsA?.eventType == CalendarEventTypes.BgBlankCell
      || customPropsA?.eventType == CalendarEventTypes.BgBlankCellNoTitle) {
      return -1;
    } 
    else if ((customPropsA.employeeLeaveHoursExtended?.leaveCaseID 
        > customPropsB.employeeLeaveHoursExtended?.leaveCaseID)
      && (customPropsB?.eventType != CalendarEventTypes.BgWorkdayCell 
        && customPropsB?.eventType != CalendarEventTypes.BgNonWorkdayCell
        && customPropsB?.eventType != CalendarEventTypes.BgBlankCell
        && customPropsB?.eventType != CalendarEventTypes.BgBlankCellNoTitle)) {
      return -1;
    }

    return 1;
  }

  private eventContent(arg: EventContentArg) {
    // handle '* cell background' events w clickable title

    const customProps = arg.event.extendedProps.customProps as CustomCalendarEventProps;

    if (customProps?.eventType == CalendarEventTypes.BgWorkdayCell 
      || customProps?.eventType == CalendarEventTypes.BgNonWorkdayCell
      || customProps?.eventType == CalendarEventTypes.BgBlankCell
      || customProps?.eventType == CalendarEventTypes.BgBlankCellNoTitle) {
      const flexContainer = document.createElement('div');
      flexContainer.className = 'fc-event-flex-container';

      const titleSpan = document.createElement('span');
      titleSpan.innerHTML = arg.event.title;
      titleSpan.className = 'fc-event-title custom-event-title';

      if (customProps?.eventType == CalendarEventTypes.BgBlankCellNoTitle)
        titleSpan.className += ' custom-event-blank-title';

      if (this.employeeRecord
        || this.caseDetails?.leaveCalendar?.canChangeWorkdays) {
        titleSpan.className += ' cell-title-hover calendar-pointer';
        
        titleSpan.addEventListener('click', (event) => {
          event.stopPropagation();
          event.preventDefault();
          this.onTitleClick(arg.event);
        }, false);  
      }

      flexContainer.appendChild(titleSpan);

      // ...handle adding flag text to bottom of cell

      if (customProps?.eventFlag && customProps?.flagText) {
        const exhaustedDiv = document.createElement('div');
        exhaustedDiv.className = 'fc-event-title';
        exhaustedDiv.innerHTML = customProps.flagText;
        flexContainer.appendChild(exhaustedDiv);
      }

      return {domNodes: [flexContainer]};
    }

    // otherwise handle adding case id links

    else if (this.employeeRecord && customProps?.eventType == CalendarEventTypes.Hours) {
      return {
        html: `<a class="calendar-pointer cell-title-hover">${arg.event.title} (${customProps.employeeLeaveHoursExtended.leaveCaseID})</a>`
      }
    } 
    
    // otherwise just pass through event title text as normal

    else {
      return arg.event.title;
    }
  }

  private eventClick(event: EventApi): void {
    this.setCalFocus();
    const eventProps = event.extendedProps.customProps as CustomCalendarEventProps;

    // show a dialog to add or edit the hours 

    if (this.caseDetails
        && (eventProps.eventType == CalendarEventTypes.Hours
          || eventProps.eventType == CalendarEventTypes.AddHours)) {
      if (this.caseDetails.leaveCalendar?.canChangeHours) {
        const dialogConfig: MatDialogConfig = {
          width: this.layoutService.isHandheld ? '100vw' : '450px',
          maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
          panelClass: "mat-dialog-container-mobileWidth",
          autoFocus: true,
          data: { 
            event: event,
            caseDetails: this.caseDetails,
            hoursExtended: eventProps.employeeLeaveHoursExtended,
            sysText: this.editCalendarTimeSysText,
            unsavedChangesSystext: this.unsavedChangesSysText
          }
        };
  
        this.dialog.open(EditCalendarTimeComponent, dialogConfig)
          .afterClosed()
          .pipe(filter((res) => !!res))
          .subscribe(res => {
            // updating the store object will trigger the subscription to the
            // store object and cause the calendar to update
  
            this.caseDetailsStore.caseDetails = res;
          });
        }
    }

    // show a dialog of the event (action item or case event)

    else if (eventProps.eventType == CalendarEventTypes.ActionItem) {
      const dialogConfig: MatDialogConfig = {
        width: "50vw",
        maxWidth: "450px",
        panelClass: "mat-dialog-container-mobileWidth",
        disableClose: false,
        closeOnNavigation: true,
        data: { 
          event: event,
          sysText: this.viewCalendarItemSysText,
        }
      };

      this.dialog.open(ViewCalendarItemComponent, dialogConfig);
    }

    // handle link to case on hours events

    else if (this.employeeRecord
      && eventProps.eventType == CalendarEventTypes.Hours 
      && eventProps.employeeLeaveHoursExtended.leaveCaseID) {
      this.router.navigate([`${appRoutePaths.LEAVE_ADMIN}/${leaveAdminRoutes.DETAILS}`, eventProps.employeeLeaveHoursExtended.leaveCaseID]);
    }
       
    // show a dialog of the leave hours summary only if working with 
    // a case and extended hours are available

    else if (this.caseDetails && eventProps.employeeLeaveHoursExtended) {
      const dialogConfig: MatDialogConfig = {
        width: "80vw",
        maxWidth: "1000px",
        panelClass: "mat-dialog-container-mobileWidth",
        disableClose: false,
        closeOnNavigation: true,
        data: { 
          event: event,
          caseDetails: this.caseDetails,
          leaveYearOptions: this.caseDetails.leaveCalendar?.leaveYearOptions,
          workday: eventProps.workCalendarDay,
          sysText: this.viewCalendarItemSysText,
        }
      };

      this.dialog.open(ViewCalendarItemComponent, dialogConfig);
    }
  }

  private onTitleClick = (event: EventApi) => {
    this.setCalFocus();

    const dialogConfig: MatDialogConfig = {
      width: "400px",
      panelClass: "mat-dialog-container-mobileWidth",
      disableClose: false,
      closeOnNavigation: true,
      data: { 
        event: event,
        employeeRecord: this.employeeRecord,
        caseDetails: this.caseDetails,
        sysText: this.addEditCalWorkdaySysText,
        unsavedChangesSysText: this.unsavedChangesSysText
      }
    };

    this.dialog.open(AddEditCalendarWorkdayComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.calendarNeedsUpdate = true;
          this.renderCal();
        }
      });
  }

  private leaveHourExtentsVisibleRange = (currentDate: Date) => {
    // by default, show the month surrounding the supplied date

    const caseDetails = this.caseDetails;

    var startDate = new Date(currentDate);
    startDate.setDate(1);
    
    var endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(endDate.getDate() - 1);

    if (caseDetails?.leaveCalendar?.firstLeaveDate) {
      // show the period from the first to the last leave

      startDate = new Date(caseDetails.leaveCalendar.firstLeaveDate);
      endDate = new Date(caseDetails.leaveCalendar.lastLeaveDate);

      // full calendar will stop at the day before a date that has
      // no time (00:00:00), so give the end date some time

      endDate.setSeconds(1);
    }

    if (endDate.getFullYear() - startDate.getFullYear() > 2) {
      // reduce the extents to a period of 2 years

      startDate = new Date(endDate);
      startDate.setFullYear(startDate.getFullYear() - 2);
    }

    return { start: startDate, end: endDate };
  }

  private monthTitleFormat = (arg: VerboseFormattingArg) => {
    var title = '';

    const monthDate = new Date(arg.date.year, arg.date.month, arg.date.day);

    if (this.layoutService.isMobile) {
      title = monthDate.toLocaleString('default', { month: 'short', year: 'numeric'});
    }
    else {
      title = monthDate.toLocaleString('default', { month: 'long', year: 'numeric'});
    }

    return title;
  }

  private leaveHourExtentsTitleFormat = (arg: VerboseFormattingArg) => {
    var title = '';

    const startDate = new Date(arg.date.year, arg.date.month, arg.date.day);
    const endDate = new Date(arg.end.year, arg.end.month, arg.end.day);

    if (this.layoutService.isMobile) {
      if (arg.date.year == arg.end.year)
        title = `${arg.date.month}/${arg.date.day} - ${arg.end.month}/${arg.end.day}/${arg.end.year.toString().substring(2,4)}`;
      else
        title = `${arg.date.month}/${arg.date.day}/${arg.date.year.toString().substring(2,4)} - ${arg.end.month}/${arg.end.day}/${arg.end.year.toString().substring(2,4)}`;
    }
    else {
      if (arg.date.year == arg.end.year)
        title = 
          startDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
          ' - ' +
          endDate.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });      
      else
        title = 
          startDate.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' }) +
          ' - ' +
          endDate.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });      
    }

    return title;
  }

  private createCalendarOptions(): CalendarOptions {
    var calendarOptions: CalendarOptions = {
      // listPlugin provides support for "list" modes (e.g. list1Month)

      plugins: [ listPlugin, dayGridMonth, interactionPlugin ],

      // displayEventTime avoids showing "all-day" on every event when in list mode

      displayEventTime: false, 
      initialView: this.layoutService.isMobile
        ? 'list1Month'
        : 'calendar1Month',
      contentHeight: 600,

      eventClick: (info) => {
        this.eventClick(info.event);
      },

      datesSet: (event) => {
        var midDate = new Date((event.start.getTime() + event.end.getTime()) / 2);
        this.setStickyCalendarDate(midDate);
      },

      headerToolbar: {
        start: 'prev',
        center: 'title',
        end: this.layoutService.isMobile
          ? 'next'
          : 'today next'
      },

      footerToolbar: {
        // listLeaveHourExtents will be removed from the toolbar during init
        // if the calendar isn't for a case

        center: this.layoutService.isMobile
          ? 'calendar1Month list1Month listLeaveHourExtents today'
          : 'calendar1Month list1Month list1Year listLeaveHourExtents'
      },

      listDaySideFormat: {
        weekday: 'short'
      },

      // using eventDidMount to set text properties for background 
      // events (setting directly by event properties not supported) 

      eventDidMount: function(info) {
        if (info.event.textColor) {
          info.el.style.color = info.event.textColor;
        }
        if (info.event.extendedProps.opacity) {
          info.el.style.opacity = info.event.extendedProps.opacity;
        }
      },

      // get calendar events in response to the calendar request 
      // for events (which provides a timeframe) 

      events: (arg: EventSourceFuncArg) => {
        return this.getEvents(arg);
      },

      loading: (isLoading) => {
        if (this.hasBeenInitialized && isLoading) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
      },

      // handle custom event html

      eventContent: (arg) => {
        return this.eventContent(arg);
      },

      viewDidMount: (arg) => {
        this.currentView = arg.view.type;
      },

      // handle switching views

      viewWillUnmount: (arg) => {
        return this.viewWillUnmount(arg);
      },

      eventOrder: (a: EventApi, b: EventApi) => {
        return this.eventOrder(a, b);
      },

      // todo - something was causing sticky headers to glitch out

      stickyHeaderDates: false,

      buttonText: { today: 'Today' },

      views: {
        calendar1Month: {
          type: 'dayGridMonth',
          duration: { months: 1 },
          titleFormat: (arg) => {
            return this.monthTitleFormat(arg);
          },
          buttonText: 'Calendar'
        },

        list1Month: {
          type: 'list',
          duration: { months: 1 },
          titleFormat: (arg) => {
            return this.monthTitleFormat(arg);
          },
          buttonText: 'Month'
        },

        list1Year: {
          type: 'list',
          duration: { years: 1 },
          buttonText: 'Year'
        },

        listLeaveHourExtents: {
          type: 'list',
          visibleRange: (arg) => {
            return this.leaveHourExtentsVisibleRange(arg);
          },
          titleFormat: (arg) => {
            return this.leaveHourExtentsTitleFormat(arg);
          },
          buttonText: 'Extents'
        }
      }      
    };
    
    return calendarOptions;
  }

  private async getEvents(args: EventSourceFuncArg): Promise<EventInput[]> {
    // get calendar page data (employee record or case details)

    const calendarStartDate = new Date(args.start);
    const calendarEndDate = new Date(args.end);
    const cd = this.caseDetails;
    const er = this.employeeRecord;
    
    let calendarPageData: LeaveCalendarPageData = null;

    if (this.hasBeenInitialized && !this.blockGetEvents && er) {
      const dto: EmployeeCalendarPageDataGet = {
        employeeId: er.employeeId,
        calendarStartDate: calendarStartDate,
        calendarEndDate: calendarEndDate
      };

      calendarPageData = await this.manageEmployeesService.getEmployeeCalendarPageData(dto)
        .toPromise()
        .catch(() => {}) as LeaveCalendarPageData;
    } 
    else if (this.hasBeenInitialized && !this.blockGetEvents && cd) {
      const dto: CaseDetailsCalendarPageDataGet = {
        caseId: cd.leaveInformation.caseId,
        calendarStartDate: calendarStartDate,
        calendarEndDate: calendarEndDate
      };

      calendarPageData = await this.leaveAdminStore.getCaseDetailsCalendarPageData(dto)
        .toPromise()
        .catch(() => {}) as LeaveCalendarPageData;
    }

    // build and return events

    return new Promise<EventInput[]> (
      (resolve) => {
        let events: EventInput[] = [];

        if (!calendarPageData) {
          resolve(events);
        }

        const calendarStartDateFormed = this.datePipe.transform(new Date(calendarStartDate), 'yyyy-MM-dd');
        const calendarEndDateFormed = this.datePipe.transform(new Date(calendarEndDate), 'yyyy-MM-dd');
        const cdli = cd?.leaveInformation;
        const caseStartDate = cdli?.startDate ? new Date(cdli?.startDate) : null;
        const caseEndDate = cdli?.endDate ? new Date(cdli?.endDate) : null;
        const caseStartDateFormed = caseStartDate ? this.datePipe.transform(new Date(caseStartDate), 'yyyy-MM-dd') : null;
        const caseEndDateFormed = caseEndDate ? this.datePipe.transform(new Date(caseEndDate), 'yyyy-MM-dd'): null;
        let nonBlankCells: string[] = [];

        this.createWorkCalendarCellEvents(calendarPageData, nonBlankCells, events);
        this.createHoursEvents(calendarPageData, cd, events, calendarStartDateFormed, calendarEndDateFormed);
        this.createActionItemEvents(calendarPageData, events, calendarStartDateFormed, calendarEndDateFormed);

        if (cd?.leaveCalendar?.canChangeHours) {
          this.createAddHoursEvents(calendarPageData, cdli, events, calendarStartDate, calendarEndDate, caseStartDateFormed, caseEndDateFormed);
        }

        this.createBlankCellEvents(calendarPageData, cd, nonBlankCells, events, calendarStartDate, calendarEndDate);

        // lastly flag events 

        this.flagCellEvents(calendarPageData, events, cdli, calendarStartDateFormed, calendarEndDateFormed, caseStartDateFormed, caseEndDateFormed);

        resolve(events);
      }
    );
  }

  private createWorkCalendarCellEvents(
    calendarPageData: LeaveCalendarPageData,
    nonBlankCells: string[], 
    events: EventInput[]): void {
    if (calendarPageData?.workCalendarDays)
      calendarPageData?.workCalendarDays.forEach(wcd => {
        const dateFormed = this.datePipe.transform(wcd.date, 'yyyy-MM-dd', 'UTC');
        const currentView = this.currentView;
        nonBlankCells.push(dateFormed);
        const xhours = calendarPageData?.employeeLeaveHoursExtended
          .find(xhours => this.datePipe.transform(xhours.calendarDate, 'yyyy-MM-dd') == dateFormed);

        // workdays

        if (wcd.effectiveWorkday) {
          const customProps: CustomCalendarEventProps = {
            eventType: CalendarEventTypes.BgWorkdayCell,
            workCalendarDay: wcd,

            employeeLeaveHoursExtended: xhours
          };

          const event: EventInput = {
            title: this.sysText.workday + " " + wcd.effectiveHours + "h",
            start: dateFormed,
            color: this.constants.APP_COLORS_THEME.primary,
            textColor: this.constants.APP_COLORS_THEME.accent,
            opacity: '.6',
            display: currentView.startsWith('calendar') ? 'background' : 'block',
            customProps: customProps
          };

        // the background of a calendar day is only clickable on a case
        // where extended hours are available (to show the leave hours
        // summary dialog)

        if (this.caseDetails && xhours)
            event.classNames = ['calendar-pointer'];

          events.push(event);
        } 

        // non-workdays

        else {
          let eventTitle: string = '';

          if (!wcd.isOverride) {
            wcd.scheduledNonWorkdays?.forEach((nwd, i)=> {
                eventTitle += ' ' + nwd.name;
                if (i != (wcd.scheduledNonWorkdays.length - 1)) {
                  eventTitle += ', ';
                }
            });
          } 
          else {
            eventTitle =  this.sysText.non_workday;
          }
          
          const customProps: CustomCalendarEventProps = {
            eventType: CalendarEventTypes.BgNonWorkdayCell,
            workCalendarDay: wcd,

            employeeLeaveHoursExtended: xhours
          };

          const event: EventInput = {
            title: eventTitle,
            start: dateFormed,
            color: '#d4d4d6', // colors.scss $grey5
            classNames: ['calendar-non-workday'],
            textColor: this.constants.APP_COLORS_THEME.accent,
            opacity: '.6',
            display: currentView.startsWith('calendar') ? 'background' : 'block',
            customProps: customProps
          };

        // the background of a calendar day is only clickable on a case
        // where extended hours are available (to show the leave hours
        // summary dialog)

        if (this.caseDetails && xhours)
            event.classNames = [ ...event.classNames, 'calendar-pointer' ];

          events.push(event);
        }
      });
  }

  private createBlankCellEvents(
    calendarPageData: LeaveCalendarPageData,
    cd: CaseDetails,
    nonBlankCells: string[],
    events: EventInput[],
    calendarStartDate: Date,
    calendarEndDate: Date): void {
    // create 'blank cell background' events for any day that's not a workday/non-worday

    const currentView = this.currentView;

    for (let calendarDate = new Date(calendarStartDate); calendarDate <= new Date(calendarEndDate); calendarDate.setDate(calendarDate.getDate() + 1)) {
      const dateFormed = this.datePipe.transform(calendarDate, 'yyyy-MM-dd', 'UTC');

      if (!nonBlankCells.includes(dateFormed)) {
        const xhours = calendarPageData?.employeeLeaveHoursExtended
          ?.find(xhours => this.datePipe.transform(xhours.calendarDate, 'yyyy-MM-dd') == dateFormed);

        let event: EventInput;

        if (this.employeeRecord 
          || (cd?.leaveCalendar?.canChangeWorkdays && cd?.employeeInformation.isAnonymous == false)) {
          const customProps: CustomCalendarEventProps = {
            eventType: CalendarEventTypes.BgBlankCell,
            employeeLeaveHoursExtended: xhours
          };

          event = {
            title: '...',
            start: dateFormed,
            color: '#e9e9e9', // colors.scss $grey7
            classNames: ['calendar-blank-cell'],
            textColor: this.constants.APP_COLORS_THEME.accent,
            opacity: '.6',
            display: currentView.startsWith('calendar') ? 'background' : 'block',
            customProps: customProps
          };          
        }
        else {
          const customProps: CustomCalendarEventProps = {
            eventType: CalendarEventTypes.BgBlankCellNoTitle,
            employeeLeaveHoursExtended: xhours
          };

          event = {
            start: dateFormed,
            color: '#e9e9e9', // colors.scss $grey7
            classNames: ['calendar-blank-cell'],
            opacity: '.6',
            display: currentView.startsWith('calendar') ? 'background' : 'block',
            customProps: customProps
          };
        }

        // the background of a calendar day is only clickable on a case
        // where extended hours are available (to show the leave hours
        // summary dialog)

        if (this.caseDetails && xhours)
          event.classNames = [ ...event.classNames, 'calendar-pointer' ];

        events.push(event);
      }
    }
  }

  private createHoursEvents(
    calendarPageData: LeaveCalendarPageData,
    cd: CaseDetails,
    events: EventInput[],
    calendarStartDateFormed: string,
    calendarEndDateFormed: string): void {
    const filteredHoursExtendedNoHours = calendarPageData?.employeeLeaveHoursExtended?.filter(xhours => {
      const dateFormed = this.datePipe.transform(new Date(xhours.calendarDate), 'yyyy-MM-dd');

      return xhours.leaveCaseHoursID != null 
        && calendarStartDateFormed <= dateFormed
        && dateFormed <= calendarEndDateFormed;
    });

    filteredHoursExtendedNoHours?.forEach(xhours => {
      const calendarDateFormed = this.datePipe.transform(new Date(xhours.calendarDate), 'yyyy-MM-dd');
      
      const customProps: CustomCalendarEventProps = {
        eventType: CalendarEventTypes.Hours,
        employeeLeaveHoursExtended: xhours
      };

      const event: EventInput = {
        title: xhours.leaveCaseLeaveHours + 'h ' + this.sysText.leave,
        start: calendarDateFormed,
        classNames: ['calendar-hours'],
        color: this.constants.APP_COLORS_THEME.accent,
        customProps: customProps
      };

      // make this non-background event clickable if the
      // user is allowed to change hours

      if (cd?.leaveCalendar?.canChangeHours)
        event.classNames = [ ...event.classNames, 'calendar-pointer' ];

      events.push(event);
    });
  }

  private createAddHoursEvents(
    calendarPageData: LeaveCalendarPageData,
    cdli: LeaveInfo,
    events: EventInput[],
    calendarStartDate: Date,
    calendarEndDate: Date,
    caseStartDateFormed:string,
    caseEndDateFormed: string): void {
    // 'add hours' events show add hours button on dates within
    // the case timeframe and if there are no leave hours taken

    for (let calendarDate = new Date(calendarStartDate); calendarDate <= new Date(calendarEndDate); calendarDate.setDate(calendarDate.getDate() + 1)) {
      const calendarDateFormed = this.datePipe.transform(new Date(calendarDate), 'yyyy-MM-dd');

      const workday = calendarPageData?.workCalendarDays.find(wcd => 
        this.datePipe.transform(new Date(wcd.date), 'yyyy-MM-dd', 'UTC') == calendarDateFormed);

      // ...add hours can only occur within the timeframe of the case,
      // and only on workdays if restrict hours is enabled

      if (caseStartDateFormed <= calendarDateFormed && calendarDateFormed <= caseEndDateFormed
        && (!calendarPageData.restrictHoursToWorkdays || workday?.effectiveWorkday == true)) {
        // ...include the add hours button on workdays that don't
        // already have hours, and where hours aren't exhausted

        const xhours = calendarPageData?.employeeLeaveHoursExtended
          ?.find(xhours => this.datePipe.transform(new Date(xhours.calendarDate), 'yyyy-MM-dd') == calendarDateFormed);

        if (xhours != null
          && xhours.leaveCaseHoursID == null
          && ((!cdli.isFmlaHoursApplicable && !cdli.isStateHoursApplicable && !cdli.isPloHoursApplicable)
            || (cdli.isFmlaHoursApplicable && xhours.employeeFmlaAvailableHours > 0) 
            || (cdli.isStateHoursApplicable && xhours.employeeStateAvailableHours > 0) 
            || (cdli.isPloHoursApplicable && xhours.employeePloAvailableHours > 0))) {
          const customProps: CustomCalendarEventProps = {
            eventType: CalendarEventTypes.AddHours,
            employeeLeaveHoursExtended: xhours
          };

          const event: EventInput = {
            title: this.sysText.addHours,
            start: calendarDateFormed,
            classNames: ['calendar-pointer'],
            color: this.constants.APP_COLORS_FILLS.fill2ltalt,
            customProps: customProps
          };

          events.push(event);
        }
      }
    }
  }

  private createActionItemEvents(
    calendarPageData: LeaveCalendarPageData,
    events: EventInput[],
    calendarStartDateFormed: string,
    calendarEndDateFormed: string): void {
    const filteredCalEvents = calendarPageData?.calendarEvents?.filter(ev => {
      const dateFormed = this.datePipe.transform(new Date(ev.eventDate), 'yyyy-MM-dd');

      return calendarStartDateFormed <= dateFormed
        && dateFormed <= calendarEndDateFormed;
    });
    
    filteredCalEvents?.forEach(ev => {
      const eventDateFormed = this.datePipe.transform(new Date(ev.eventDate), 'yyyy-MM-dd');

      if (ev.eventType == CalendarEventTypes.ActionItem) {
        const customProps: CustomCalendarEventProps = {
          eventType: CalendarEventTypes.ActionItem,
        };

        const event: EventInput = {
          title: ev.message,
          start: eventDateFormed,
          classNames: ['calendar-pointer', 'calendar-action-item'],
          color: this.constants.STAT_CHIP_STYLES.inProgBg.backgroundColor,
          textColor: this.constants.STAT_CHIP_STYLES.inProgBg.color,
          customProps: customProps
        };

        events.push(event);
      }
    });
  }

  private flagCellEvents(
    calendarPageData: LeaveCalendarPageData,
    events: EventInput[],
    cdli: LeaveInfo,
    calendarStartDateFormed: string,
    calendarEndDateFormed: string,
    caseStartDateFormed:string,
    caseEndDateFormed: string): void {
    // update '* cell background' events to be flagged if necessary for all workdays
    // within the case timeframe, or on any calendar date with leaves for this case

    const filteredHoursExtended = calendarPageData?.employeeLeaveHoursExtended?.filter(xhours => {
      const dateFormed = this.datePipe.transform(new Date(xhours.calendarDate), 'yyyy-MM-dd');

      return calendarStartDateFormed <= dateFormed
        && dateFormed <= calendarEndDateFormed;
    });
    
    filteredHoursExtended?.forEach(xhours => {
      const calendarDateFormed = this.datePipe.transform(new Date(xhours.calendarDate), 'yyyy-MM-dd');

      const event = events.find(e => {
        const customProps = e.customProps as CustomCalendarEventProps;
        return e.start == calendarDateFormed 
        && (customProps?.eventType == CalendarEventTypes.BgWorkdayCell 
        || customProps?.eventType == CalendarEventTypes.BgNonWorkdayCell
        || customProps?.eventType == CalendarEventTypes.BgBlankCell);
      });

      const eventCustomProps = event?.customProps as CustomCalendarEventProps;

      const workday = calendarPageData?.workCalendarDays.find(wcd => 
        this.datePipe.transform(new Date(wcd.date), 'yyyy-MM-dd', 'UTC') == calendarDateFormed);

      // ...show 'leave date warning' if a leave is outside the timeframe of the case,
      // not on a workday and workday restriction is enabled, or exceeds work hours and
      // workday hours restriction is enabled

      if (event && xhours.leaveCaseHoursID != null
        && (calendarDateFormed < caseStartDateFormed 
          || calendarDateFormed > caseEndDateFormed
          || (calendarPageData.restrictHoursToWorkdays && (workday == null || !workday.effectiveWorkday))
          || (calendarPageData.restrictHoursToWorkdayHours && (workday == null || xhours.leaveCaseLeaveHours > workday.effectiveHours)))) {
        event.color = this.constants.APP_COLORS_THEME.warn;
        event.classNames = ['calendar-pointer', 'calendar-warning'];
        event.textColor = this.constants.APP_COLORS_THEME.primary;
        event.customProps.eventFlag = CalendarEventFlags.Warning;
        event.customProps.flagText = this.sysText.warning;
      }

      // ...show 'exhausted' if workday and there are no draws and available hours is zero, or
      // if available hours is less than zero (overdrawn)

      else if (eventCustomProps?.eventType == CalendarEventTypes.BgWorkdayCell
        && ((xhours.leaveCaseHoursID == null
          && ((cdli?.isFmlaHoursApplicable && xhours.employeeFmlaAvailableHours != null && xhours.employeeFmlaAvailableHours == 0) 
            || (cdli?.isStateHoursApplicable && xhours.employeeStateAvailableHours != null && xhours.employeeStateAvailableHours == 0) 
            || (cdli?.isPloHoursApplicable && xhours.employeePloAvailableHours != null && xhours.employeePloAvailableHours == 0)))
        || (cdli?.isFmlaHoursApplicable && xhours.employeeFmlaAvailableHours != null && xhours.employeeFmlaAvailableHours < 0) 
        || (cdli?.isStateHoursApplicable && xhours.employeeStateAvailableHours != null && xhours.employeeStateAvailableHours < 0) 
        || (cdli?.isPloHoursApplicable && xhours.employeePloAvailableHours != null && xhours.employeePloAvailableHours < 0))) {
        event.color = this.constants.APP_COLORS_THEME.accent;
        event.classNames = ['calendar-pointer', 'calendar-exhasuted'];
        event.textColor = this.constants.APP_COLORS_THEME.primary;
        event.customProps.eventFlag = CalendarEventFlags.Exhausted;
        event.customProps.flagText = this.sysText.exhausted;
      }
    });
  }

  private setCalFocus(): void {
    // the calendar click event does not remove focus from the mat case calendar tab
    // so we set focus to the calendar div here manually 

    const calDiv = document.getElementById('calendar');

    calDiv.tabIndex = 0;
    calDiv.focus();
  }
}