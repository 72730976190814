import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Token } from '@core/models';
import { ConstantsService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private authHeader: string;
  private tokenName: string;
  constructor(private constants: ConstantsService) {
    this.authHeader = constants.AUTH_HEADER;
    this.tokenName = constants.TOKEN_NAME;
  }

  intercept(req: HttpRequest<any>, newReq: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get(this.authHeader)) {
      const jwt: Token = JSON.parse(sessionStorage.getItem(this.tokenName));

      if (jwt && jwt.access_token) {
        req = req.clone({
          headers: req.headers.set(this.authHeader, jwt.access_token),
        });
      }
    }
    return newReq.handle(req);
  }
}
