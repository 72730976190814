<mat-expansion-panel 
    class="info-panel" 
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="section-header-container">
                <p class="overline grey1 semibold section-title">{{sysText.templateAssignedEmployers | uppercase}}</p>
                <mat-divider class="section-header-underline"></mat-divider>
            </div> 
            <ng-container *ngIf="templateDetails.canEditEmployers">
                <button 
                    *ngIf="!(isLoading$ | async); else loading" 
                    mat-icon-button color="accent"
                    aria-label="icon-button with edit icon"
                    class="edit-employee-button" 
                    (click)="editTemplateAssignedEmployers($event)">
                    <mat-icon>edit</mat-icon>
                </button>
                <ng-template #loading>
                    <mat-spinner [diameter]="25" color="accent"></mat-spinner>
                </ng-template>
            </ng-container>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="main-content flex">
        <div class="item-container">
            <div class="item-label">
                <p class="body2 grey2">{{sysText.employers | titlecase}}</p>
            </div>
            <div *ngIf="templateDetails.employers?.length > 0; else noEmployers" class="item-content body2 grey1 semibold">
                <span *ngFor="let er of templateDetails.employers; index as i">
                    <span class="link" (click)="viewEmployerProfile(er.id)">
                        {{ er.employerName }}
                    </span>
                    <span *ngIf="er.isTemplateOwner">
                        <app-status-chip
                            [icon]="'stars'"
                            [ngStyle]="chipStyles.successBg"
                            [tooltip]="sysText.templateOwner | titlecase"
                            [removeMargin]="true">
                        </app-status-chip>
                    </span>
                    {{templateDetails.employers[i+1] != undefined ? ', ' : ''}}
                </span>
            </div>
            <ng-template #noEmployers>
                <div class="item-content">
                    <p class="body2 grey1 semibold">
                        {{"--"}}
                    </p>
                </div>
            </ng-template>
        </div>
    </div>
</mat-expansion-panel>