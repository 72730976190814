<p *ngIf="customEmployerLogo; else stiiraLogo" class="overline text--header">
    <img [src]="customEmployerLogo" alt="Employer Logo" class="custom-logo"/>
</p>
<ng-template #stiiraLogo>
    <p class="overline text--header">
        <img src="assets/images/stiira_logo.svg" alt="Stiira Logo" class="logo" width="140px" />
    </p>
</ng-template>
<a [routerLink]="myProlfilePath" (click)="handleLinkClick()">
    <img class="user-image" [src]="employeeInfo?.image || 'assets/images/missing_avatar.svg'" alt="user-image" />
</a>
<p class="body1 text--name">{{ employeeInfo?.name }}</p>
<p class="caption text--employer">{{ employeeInfo?.employer }}</p>
