import { Injectable } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ManageEmployersDashboardStore } from '@core/models/leave-admin/employers/manage-employers-dashboard-store.model';
import { ManageEmployers } from '@core/models/leave-admin/employers/manage-employers.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployersDashboardStoreService {

  private readonly _initialStoreData: ManageEmployersDashboardStore = {
    manageEmployers: null,
    sysText: null,
    matSortData: null,
    pageData: null,
    dashForm: this.fb.group({
      searchValue: [null],
    })
  };

  private manageEmployersDashboardStore: BehaviorSubject<ManageEmployersDashboardStore>;  

  private store$: Observable<ManageEmployersDashboardStore>;

  private readonly templateNames = {
    manageEmployers: "manageEmployers",
    addNewEmployer: "addNewEmployer",
    unsavedChanges: "unsavedChanges"
  };

  constructor(private fb: FormBuilder) { 
    this.manageEmployersDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.manageEmployersDashboardStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.manageEmployersDashboardStore.value;
  }
  
  public get manageEmployers$(): Observable<ManageEmployers> {
    return this.store$.pipe(pluck('manageEmployers'));
  }

  public get manageEmployers() {
    return this.store.manageEmployers;
  }

  public set manageEmployers(manageEmployers: ManageEmployers) {
    const store = { ...this.store, manageEmployers };
    this.manageEmployersDashboardStore.next(store);
  }
  
  public get sysText() {
    return this.store.sysText;
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.manageEmployersDashboardStore.next(store);
  }

  public get form(): UntypedFormGroup {
    return this.store.dashForm;
  }

  public set sortData(sortData: Sort) {
    this.store.matSortData = sortData;
  }

  public get sortData(): Sort {
    return this.store.matSortData;
  }

  public set pageData(pageData: PageEvent) {
    this.store.pageData = pageData;
  }

  public get pageData(): PageEvent {
    return this.store.pageData;
  }

  public unloadStore() {
    const store: ManageEmployersDashboardStore = {
      manageEmployers: null,
      sysText: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group({
        searchValue: [null],
      })
    }
    this.manageEmployersDashboardStore.next(store)
  }
}
