import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePassword, ChangeUsername, EmployeeDetails, EmployeeDetailsPost, Gender, SendPhoneCode, VerifyPhoneCode } from '@core/models';
import { ChangeEmail } from '@core/models/account-settings/change-email.model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  employeeDetails$: Observable<EmployeeDetails>;
  private employeeDetailsSubject: BehaviorSubject<EmployeeDetails>;

  get employeeDetails(): EmployeeDetails {
    return this.employeeDetailsSubject.value;
  }

  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.employeeDetailsSubject = new BehaviorSubject<EmployeeDetails>(null);
    this.employeeDetails$ = this.employeeDetailsSubject.asObservable();
  }

  getMyProfile(): Observable<EmployeeDetails> {
    return this.http
      .get<EmployeeDetails>(`${this.constants.PROFILE_API}/getmyprofile`)
      .pipe(tap((res) => this.updateCachedEmployeeDetails(res)));
    // return of(EMPLOYEEDETAILS_MOCK).pipe(tap((res) => this.updateCachedEmployeeDetails(res)));
  }

  changeProfileImage(img: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(`${this.constants.PROFILE_API}/changeprofileimage`, JSON.stringify(img), httpOptions);
    // return of(null);
  }

  updateEmployeeDetails(details: EmployeeDetailsPost): Observable<any> {
    return this.http.put(`${this.constants.PROFILE_API}/updateemployeedetails`, details);
    // return of(null);
  }

  getGenders(): Observable<Gender[]> {
    return this.http.get<Gender[]>(`${this.constants.PROFILE_API}/getgenders`);
    // return of(GENDER_MOCK);
  }

  changePassword(cpModel: ChangePassword): Observable<any> {
    return this.http.post(`${this.constants.PROFILE_API}/changepassword`, cpModel);
    // return of('success!');
  }

  changeUsername(cuModel: ChangeUsername): Observable<any> {
    return this.http.post(`${this.constants.PROFILE_API}/changeusername`, cuModel);
  }

  changeEmail(ceModel: ChangeEmail): Observable<any> {
    return this.http.post(`${this.constants.PROFILE_API}/changeemail`, ceModel);    
    //return of(null);
  }

  sendChangePhoneCode(dto: SendPhoneCode): Observable<any> {   
    return this.http.post<any>(`${this.constants.PROFILE_API}/sendchangephonecode`, dto)    
    // return of(null);
  }

  verifyChangePhoneCode(dto: VerifyPhoneCode): Observable<any> {
    return this.http.post<any>(`${this.constants.PROFILE_API}/verifychangephonecode`, dto)
  }

  getProfileData(): Observable<any> {
    const eeInfo$ = this.getMyProfile();
    return combineLatest([eeInfo$]);
  }

  updateCachedEmployeeDetails(details: EmployeeDetails): void {
    let newEmployeeDetails = { ...this.employeeDetailsSubject.value, ...details };
    this.employeeDetailsSubject.next(newEmployeeDetails);
  }
}
