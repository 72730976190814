export const removeDashes = (dashedString: string): string => {
  return dashedString.replace(/-/g, '');
};

export const capitalizeFirst = (text: string): string => {
  let capText: string;
  if (text) {
    capText = text[0].toUpperCase() + text.slice(1);
  }  
  return capText;
}

export const isEmptyObject = (obj: object): boolean => {
  if (obj === null) {
    return true;
  }

  return !Object.keys(obj).some((key) => obj[key] !== null && obj[key] !== '');
};

export const nameof = <T>(name: keyof T) => name;

export const objectHasAtLeastOnePropertyWithSomeValue = (obj: object): boolean => {
  if (!obj) return false;
  return Object.keys(obj).some((k) => !!obj[k]);
};
