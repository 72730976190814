import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseCommunicationDrawer } from '@core/models/case-communication/case-communication-drawer.model';
import { LayoutService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { CaseCommunicationStoreService } from '@core/services/case-communication.store.service';
import { SendNewEmailComponent } from '@modules/dialogs/send-new-email/send-new-email.component';
import { ViewEmailComponent } from '@modules/dialogs/view-email/view-email.component';
import { indicate } from '@shared/helpers';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-case-communication-drawer',
  templateUrl: './case-communication-drawer.component.html',
  styleUrls: ['./case-communication-drawer.component.scss']
})
export class CaseCommunicationDrawerComponent implements OnInit {
  @Input() caseComData: CaseCommunicationDrawer;
  @Input() caseId: number;
  @Output() closeCaseCom = new EventEmitter<void>();

  public isLoadingSendEmailDialog$: Subject<boolean> = new Subject<boolean>();
  public showCaseEmailIdLoading$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public sysText: any

  private destroy$: Subject<void> = new Subject<void>();

  get isMobile$(): Observable<boolean> {
    return this.layoutService.isMobile$();
  }

  get loadingViewEmailDialogId$(): Observable<number> {
    return this.showCaseEmailIdLoading$.asObservable();
  }

  constructor(
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private service: CaseCommunicationService,
    private ccStore: CaseCommunicationStoreService,
  ) { }

  ngOnInit(): void {
    this.sysText = this.ccStore.sysText.caseCommunicationDrawer;
    this.ccStore.caseComDrawer$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => { this.caseComData = res });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.ccStore.unloadStore();
  }

  public onCloseCaseCom(): void {
    this.closeCaseCom.emit();
  }

  public onSendEmail(): void {
    this.service.getSendNewEmailDialog(this.caseId)
      .pipe(indicate(this.isLoadingSendEmailDialog$))
      .subscribe((res) => {
        const dialogConfig: MatDialogConfig = {
          width: this.layoutService.isHandheld ? '100vw' : '600px',
          maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
          maxHeight: this.layoutService.isHandheld ? '85vh' : '',
          height: this.layoutService.isHandheld ? '85vh' : '',
          data: { 
            sysText: this.ccStore.sysText.sendNewEmailDialog,
            viewEmailSysText: this.ccStore.sysText.viewEmailDialog,
            unsavedSysText: this.ccStore.sysText.unsavedChanges,
            dialogData: res,
          }
        };

        this.dialog.open(SendNewEmailComponent, dialogConfig)
          .afterClosed().subscribe(() => {
            this.ccStore.setCcIsEditing = false;
          });
      });
  }

  public onEmailClick(caseEmailId: number): void {
    this.showCaseEmailIdLoading$.next(caseEmailId);
    this.service.getEmailInfoDialog(caseEmailId)
      .pipe(finalize(() => this.showCaseEmailIdLoading$.next(null)))
      .subscribe((res) => {
        const dialogConfig: MatDialogConfig = {
          width: this.layoutService.isHandheld ? '100vw' : '600px',
          maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
          maxHeight: this.layoutService.isHandheld ? '85vh' : '',
          height: this.layoutService.isHandheld ? '85vh' : '',
          disableClose: false,
          closeOnNavigation: true,
          data: { 
            sysText: this.ccStore.sysText.viewEmailDialog,
            emailData: res,
            isPreview: false
          }
        }
        
        this.dialog.open(ViewEmailComponent, dialogConfig);
      });
  }

  public getInitials(nameString: string) {
    //remove quotes in case there is a nickname in the nameString (and no firstname)
    nameString = nameString.replace(/["]/g, '');
    const fullName = nameString.split(', ');
    const initials = fullName.pop().charAt(0) + (fullName.length > 0 ? fullName.shift().charAt(0) : "");
    return initials.toUpperCase();
  }
}
