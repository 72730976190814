<ng-container *ngIf="!showSetPhoneNumber">
  <app-account-form
  [sysText]="sysText$ | async"
  [options]="options"
  [page]="templateName"
  [usernameOrEmail]="username"
  [isSubmitting]="isSubmitting$ | async"
  (formSubmit)="onFormSubmit($event)"
  ></app-account-form>
</ng-container>

<ng-container *ngIf="showSetPhoneNumber">
  <app-set-phonenumber-form
  [sysText]="sysText$ | async"
  [page]="templateName"
  [showNumberField]="setPhoneNumberShowField$"
  [isSubmitting]="isSubmitting$ | async"
  (resendCode)="onResendCode()"
  (goBack)="onGoBack()"
  (formSubmit)="onPhoneVerificationSubmit($event)"
  (setPhoneNumber)="onSetPhoneNumber($event)"
  ></app-set-phonenumber-form>
</ng-container>