import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentUploadMode } from '@core/enums/document-upload-mode.enum';
import { TemplateDetails, TemplateDocument } from '@core/models/case-communication/template-details.model';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { TemplateDetailsStoreService } from '@core/services/template-details-store.service';
import { DeleteConfirmationComponent } from '@modules/dialogs/delete-confirmation/delete-confirmation.component';
import { DocumentUploadComponent } from '@modules/dialogs/document-upload/document-upload.component';
import { EditDocumentComponent } from '@modules/dialogs/edit-document/edit-document.component';
import { indicate } from '@shared/helpers';
import { base64StringToBlob } from 'blob-util';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template-attached-documents',
  templateUrl: './template-attached-documents.component.html',
  styleUrls: ['./template-attached-documents.component.scss']
})
export class TemplateAttachedDocumentsComponent implements OnInit {
  @Input() templateDetails: TemplateDetails;
  @Input() documents: TemplateDocument[];
  @Input() sysText: any;
  @Input() templateId: number;
  @Input() uploadDocSysText: any;
  @Input() unsavedChangesSysText: any;
  @Output() isEditing = new EventEmitter<boolean>();
  
  public isMobile$: Observable<boolean>;
  public panelOpenState: boolean = true;
  public _docs: MatTableDataSource<TemplateDocument>;
  public displayedColumns: string[] = ['documentTitle', 'documentCategory', 'comments', 'uploadedBy','icons'];
  public isSubmittingId: number;
  
  public isLoadingDocumentDialog$: Subject<boolean> = new Subject<boolean>();
  public isDeleting$: Subject<boolean> = new Subject<boolean>();
  public isLoadingEditDialog$: Subject<number> = new Subject<number>();
  public isDownloadingDocument$: Subject<boolean> = new Subject<boolean>();

  private destroy$: Subject<void> = new Subject<void>();
  
  private docBeingDownloaded: number = null;

  set dataSource(dataSource: TemplateDocument[]) {
    this._docs = new MatTableDataSource<TemplateDocument>(dataSource);
  }

  constructor(
    private service: CaseCommunicationService,
    private store: TemplateDetailsStoreService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.dataSource = this.documents;

    this.store.templateDetails$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((res)=> {
        if (res != null) {
          this.documents = res.documents;
          this.dataSource = this.documents;
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public uploadDocument(): void {
    this.service.getDocumentUploadDialog(this.templateId)
      .pipe(indicate(this.isLoadingDocumentDialog$))
      .subscribe(res => {
        const dialogConfig: MatDialogConfig = {
          width: '500px',
          restoreFocus: false,
          data: { 
            subjectId: this.templateId, 
            docUploadDialog: res, 
            sysText: this.uploadDocSysText, 
            unsavedChangesSysText: this.unsavedChangesSysText, 
            mode: DocumentUploadMode.Template 
        }};

        const dialogRef = this.dialog.open(DocumentUploadComponent, dialogConfig);
        dialogRef.componentInstance.isEditing.subscribe((res) => {
          this.isEditing.emit(res);
        });
        dialogRef.afterClosed()
          .pipe(
            filter((res) => !!res),
            finalize(()=> this.isEditing.emit(false))
          )
          .subscribe(res => {
            this.store.templateDetails = res as TemplateDetails;
            this.refreshDataSource();
          });
      })
  }

  public editDocument(docId: number): void {
    const doc = this.documents.filter(d => d.templateDocumentId == docId)[0];
    this.isLoadingEditDialog$.next(docId)

    this.service.getDocumentEditDialog(this.templateId)
    .pipe(finalize(()=>{
      this.isLoadingEditDialog$.next(null)
    })) 
    .subscribe(res => {
      const dialogConfig: MatDialogConfig = {
        width: '500px',
        data: { 
          docData: doc,
          subjectId: this.templateId,
          options: res,
          sysText: this.store.sysText[this.store.templates.editDocumentDialog], 
          unsavedSysText: this.store.sysText[this.store.templates.unsavedChanges],
          mode: DocumentUploadMode.Template
        }
      };
      const dialogRef = this.dialog.open(EditDocumentComponent, dialogConfig);
      dialogRef.componentInstance.isEditing.subscribe((res) => {
        this.isEditing.emit(res);
      });
      dialogRef.afterClosed().subscribe(() => {
        this.isEditing.emit(false);
      });
    });
  }

  public downloadDocument(documentTemplateId: number): void {
    this.docBeingDownloaded = documentTemplateId;

    this.service.getDocument(documentTemplateId)
      .pipe(indicate(this.isDownloadingDocument$))
      .subscribe(res => {
        var newBlob = base64StringToBlob(res.fileBytes, res.mimeType);
        var url = window.URL.createObjectURL(newBlob);
    
        var win = window.open(url, '_blank');

        win.onload = () => { this.docBeingDownloaded = null; };
      })
  }

  public isDownloadingDocument(documentTemplateId: number): boolean {
    return documentTemplateId == this.docBeingDownloaded;
  }

  public deleteDocument(docId: number): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.store.sysText[this.store.templates.deleteConfirmation],
        deleteConfirm2: this.store.sysText[this.store.templates.templateDetails].deleteDocumentConfirm2
    }};
    
    this.dialog.open(DeleteConfirmationComponent, dialogConfig)
      .beforeClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.isSubmittingId = docId;
          this.service.postDocumentDelete({templateDocumentId: docId})
            .pipe(indicate(this.isDeleting$))
            .subscribe(res => {
              this.store.templateDetails = res;
              this.refreshDataSource();
              this.isSubmittingId = null;
            });
        }
      });
  }

  public getInitials(nameString: string): string {
    //remove quotes in case there is a nickname in the nameString (and no firstname)
    nameString = nameString.replace(/["]/g, '');
    const fullName = nameString.split(', ');
    const initials = fullName.pop().charAt(0) + (fullName.length > 0 ? fullName.shift().charAt(0) : "");
    return initials.toUpperCase();
  }

  private refreshDataSource(): void {
    this.dataSource = this.store.templateDetails.documents;
  }

}
