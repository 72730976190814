import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private titleSubject: BehaviorSubject<string>;
  private showEmailButtonSub: BehaviorSubject<boolean>;

  public title$: Observable<string>;
  public showEmailButton$: Observable<boolean>;

  constructor() {
    this.showEmailButtonSub = new BehaviorSubject(false);
    this.showEmailButton$ = this.showEmailButtonSub.asObservable();
    this.titleSubject = new BehaviorSubject('');
    this.title$ = this.titleSubject.asObservable();
  }

  public setTitle(title: string): void {
    this.titleSubject.next(title);
  }

  public showEmailButton(show: boolean): void {
    this.showEmailButtonSub.next(show);
  }
}