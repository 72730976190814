import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MYDOCUMENTS, DOCDOWNLOAD } from "@core/mocks/my-documents/my-documents.mock";
import { MyDocumentsStore } from "@core/models/my-documents/my-documents-store.model";
import { MyDocument } from "@core/models/my-documents/my-documents.model";
import { FileDownload } from "@core/models/shared/file-download.model"
import { BehaviorSubject, Observable, of } from "rxjs";
import { pluck } from "rxjs/operators";
import { ConstantsService } from ".";
import { base64StringToBlob } from "blob-util";

@Injectable({
  providedIn: "root",
})
export class MyDocumentsService {
  private myDocumentsStore: BehaviorSubject<MyDocumentsStore>;
  store$: Observable<MyDocumentsStore>;
  private _templates = {
    myDocuments: "myDocuments",
  };

  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.myDocumentsStore = new BehaviorSubject({
      documents: null,
      sysText: null,
    });

    this.store$ = this.myDocumentsStore.asObservable();
  }

  get store(): MyDocumentsStore {
    return this.myDocumentsStore.value;
  }

  public get documents() {
    return this.store.documents;
  }

  public get documents$() {
    return this.store$.pipe(pluck("documents"));
  }

  public set documents(docs: MyDocument[]) {
    const store = { ...this.store, documents: docs };
    this.myDocumentsStore.next(store);
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText: sysText };
    this.myDocumentsStore.next(store);
  }

  public get sysText() {
    return this.store.sysText;
  }

  downloadFile(docDownload: FileDownload): void {    
    var newBlob = base64StringToBlob(docDownload.fileBytes, docDownload.mimeType);
    var url = window.URL.createObjectURL(newBlob);

    window.open(url, '_blank');
  }

  getDocumentDownload(documentId: number): Observable<FileDownload> {
    return this.http.get<FileDownload>(`${this.constants.MYDOCUMENTS_API}/getFileDownload/${documentId}`)
    //return of(DOCDOWNLOAD);
  }


  getMyDocuments(): Observable<MyDocument[]> {
    return this.http.get<MyDocument[]>(`${this.constants.MYDOCUMENTS_API}/getMyDocuments`);
    //return of(MYDOCUMENTS);
  }

  get templates() {
    return this._templates;
  }

  public unloadStore() {
    const store: MyDocumentsStore = {
      documents: null,
      sysText: null
    }
    this.myDocumentsStore.next(store)
  }
}
