import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Credentials, Token } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApplicationInsightsService } from './app-insights.service';
import { ConstantsService } from './constants.service';
import { NotificationService } from './notification.service';
import { TearDownService } from './tear-down.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authSubject: BehaviorSubject<Token>;
  private isImpersonatingSubject: BehaviorSubject<boolean>;
  private logoutSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isUrlWithQueryParamsSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isAuth$: Observable<Token>;  
  public isLoggedOut$: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
    private router: Router,
    private aiService: ApplicationInsightsService,
    private tearDownService: TearDownService,
    private notificationService: NotificationService,
  ) {
    this.authSubject = new BehaviorSubject<Token>(JSON.parse(sessionStorage.getItem(constants.TOKEN_NAME)));
    this.isImpersonatingSubject = new BehaviorSubject<boolean>(false);
    this.isAuth$ = this.authSubject.asObservable();
    this.isLoggedOut$ = this.logoutSubject.asObservable();
  }

  public get isAuth(): Token {
    return this.authSubject.value;
  }

  public get isUrlWithQueryParams(): boolean {
    return this.isUrlWithQueryParamsSub.value;
  }

  public get isImpersonating(): boolean {
    return this.isImpersonatingSubject.value;
  }

  public login(credentials: Credentials): Observable<Token> {
    return this.http.post<Token>(`${this.constants.ACCOUNT_API}/login`, credentials).pipe(
      filter((res) => !!res && !!res.access_token),
      map((res) => {
        return res;
      })
    );
  }

  public setIsImpersonating(bool: boolean): void {
    this.isImpersonatingSubject.next(bool);
  }

  public setIsUrlWithQueryParams(bool: boolean): void {
    this.isUrlWithQueryParamsSub.next(bool);
  }

  public updateUser(token: Token): void {
    sessionStorage.setItem(this.constants.TOKEN_NAME, JSON.stringify(token));
    this.authSubject.next(token);
    this.aiService.setUserId(token.username);
  }

  public logout(clearCookies: boolean = false): void {
    if (clearCookies) {
      document.cookie = "ai_user=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
      document.cookie = "ai_session=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
      document.cookie = "StiiraTwoFactorRememberBrowser=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }

    this.notificationService.closeAllToasts();
    this.tearDownService.tearDown();

    sessionStorage.removeItem(this.constants.TOKEN_NAME);
    this.authSubject.next(null);
    this.logoutSubject.next(true);
    this.aiService.clearUserId();
    this.router.navigate(['/login']);
  }
}
