import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PreferredEmail } from '@core/enums/preferred-email.enum';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-email-field-group',
  templateUrl: './email-field-group.component.html',
  styleUrl: './email-field-group.component.scss'
})
export class EmailFieldGroupComponent {
  @Input() public group: FormGroup;
  @Input() public workEmailControl: FormControl;
  @Input() public isWorkEmailPreferredControl: FormControl;
  @Input() public personalEmailControl: FormControl;
  @Input() public isPersonalEmailPreferredControl: FormControl;
  @Input() public hideIconSpace: boolean = false;
  @Input() public hideIcon: boolean = false;

  public showPreferredButtons = false;
  public sysText: any;
  public preferredEmail = PreferredEmail;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private text: SharedComponentsSysTextService) {}

  public ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.emailFieldGroup];
    if (this.personalEmailControl.value && this.workEmailControl.value) {
      this.showPreferredButtons = true;
    }
  }

  public ngAfterViewInit(): void {
    this.group.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.workEmailControl.value?.trim() && this.workEmailControl.value != null) {
          this.workEmailControl.setValue(null, {emitEvent: false});
        }
        if (!this.personalEmailControl.value?.trim() && this.personalEmailControl.value != null) {
          this.personalEmailControl.setValue(null, {emitEvent: false});
        }
        if (this.personalEmailControl.value && this.workEmailControl.value) {
          this.showPreferredButtons = true;
        } else if (this.showPreferredButtons) {
          this.showPreferredButtons = false;
          this.isPersonalEmailPreferredControl.setValue(false, {emitEvent: false});
          this.isWorkEmailPreferredControl.setValue(false, {emitEvent: false});
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onPreferredClick(event: MatButtonToggleChange): void {
    this.personalEmailControl.setErrors(null);
    this.workEmailControl.setErrors(null);
    if (event.value === this.preferredEmail.Work) {
      this.isWorkEmailPreferredControl.setValue(true);
      this.isPersonalEmailPreferredControl.setValue(false);
    } else if (event.value === this.preferredEmail.Personal) {
      this.isPersonalEmailPreferredControl.setValue(true);
      this.isWorkEmailPreferredControl.setValue(false);
    }
  }
}