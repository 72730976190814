import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitstring'
})
export class SplitstringPipe implements PipeTransform {

  transform(value: string): string {
    let strArr = value.split(/(?=[A-Z])/);
    return strArr.join(" ")
  }

}
