import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionOption } from '@core/models';
import { EmployeeRole } from '@core/models/role-admin/employee-role.model';
import { RoleAdminService } from '@core/services/role-admin.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-employee-to-role',
  templateUrl: './add-employee-to-role.component.html',
  styleUrls: ['./add-employee-to-role.component.scss'],
})
export class AddEmployeeToRoleComponent extends DialogDragConstraints implements OnInit {
  selectedEmployee: FormControl<number>;
  isSubmitting$: Subject<boolean>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { options: SelectionOption[]; sysText: any; roleId: string },
    private service: RoleAdminService,
    private dialogRef: MatDialogRef<AddEmployeeToRoleComponent>
  ) {
    super(dialogRef);
    
    this.selectedEmployee = new FormControl(null, Validators.required);

    this.isSubmitting$ = new Subject();
  }

  ngOnInit(): void {}

  onSubmit() {
    this.dialogRef.disableClose = true;
    const eRole: EmployeeRole = {
      eeId: this.selectedEmployee.value,
      roleId: this.data.roleId,
    };
    this.service
      .addEmployeeRole(eRole)
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe(() => {
        this.dialogRef.close(eRole);
      });
  }
}
