import { Injectable } from '@angular/core';
import { EmployeeRecordStore } from '@core/models/leave-admin/employees/employee-record-store.model';
import { EmployeeNote, EmployeeRecord } from '@core/models/leave-admin/employees/employee-record.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRecordStoreService {

  private readonly _initialStoreData: EmployeeRecordStore = {
    employeeRecord: null,
    sysText: null
  };

  private employeeRecordStore: BehaviorSubject<EmployeeRecordStore>;  
  private store$: Observable<EmployeeRecordStore>;

  private readonly templateNames = {
    employeeRecord: "employeeRecord",
    employeeRecordInfo: "employeeRecordInfo",
    employeeLeaveCases: "employeeLeaveCases",
    employeeLeaveHours: "employeeLeaveHours",
    employeeSchedule: "employeeSchedule",
    employeeDocuments: "employeeDocuments",
    editEmployeeRecord: "editEmployeeRecord",
    employeeNotes: "employeeNotes",
    leavingPage: "leavingPage",
    unsavedChanges: "unsavedChanges",
    deleteConfirmation: "deleteConfirmation",
    editNoteDialog: "editNoteDialog",
    employeeSettings: "employeeSettings",
    uploadDocumentDialog: "uploadDocumentDialog",
    duplicateDetected: "duplicateDetected",
    sendInvitation: "sendInvitation",
    employeeHistory: "employeeHistory",
    updateConfirmation: "updateConfirmation",
    editDocumentDialog: "editDocumentDialog",
    leaveCalendar: "leaveCalendar",
    viewCalendarItem: "viewCalendarItem",
    addEditCalendarWorkday: "addEditCalendarWorkday"
  };

  constructor() {
    this.employeeRecordStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.employeeRecordStore.asObservable();
  }

  public get store() {
    return this.employeeRecordStore.value;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public get employeeRecord() {
    return this.store.employeeRecord;
  }

  public get employeeRecord$(): Observable<EmployeeRecord> {
    return this.store$.pipe(pluck('employeeRecord'))
  }

  public get templates() {
    return this.templateNames;
  }

  public set employeeRecord(employeeRecord: EmployeeRecord) {
    const store = { ...this.store, employeeRecord };
    this.employeeRecordStore.next(store);
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.employeeRecordStore.next(store);
  }

  addEmployeeNote(note: EmployeeNote) {
    const employeeRecord = this.employeeRecord;
    employeeRecord.notes.unshift(note);
    const store = { ...this.store, employeeRecord };
    this.employeeRecordStore.next(store);
  }

  deleteEmployeeNote(id: number) {
    const employeeRecord = this.employeeRecord;
    const index = employeeRecord.notes.findIndex(i => i.id === id);
    employeeRecord.notes.splice(index, 1);
    const store = { ...this.store, employeeRecord };
    this.employeeRecordStore.next(store);
  }

  updateEmployeeNote(note: EmployeeNote) {
    const employeeRecord = this.employeeRecord;
    const index = employeeRecord.notes.findIndex(i => i.id === note.id)
    employeeRecord.notes.splice(index, 1, note);
    const store = { ...this.store, employeeRecord };
    this.employeeRecordStore.next(store);
  }

  deleteDocument(id: number) {
    const employeeRecord = this.employeeRecord;
    const index = employeeRecord.documents.findIndex(i => i.documentId === id);
    employeeRecord.documents.splice(index, 1);
    const store = { ...this.store, employeeRecord };
    this.employeeRecordStore.next(store);
  }

  public unloadStore() {
    const store: EmployeeRecordStore = {
      employeeRecord: null,
      sysText: null
    }
    this.employeeRecordStore.next(store)
  }
}
