import { Injectable } from '@angular/core';
import { AnonymousRequestStore } from '@core/models/account/anonymous-request-store.model';
import { AnonymousNewRequestPage } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnonymousRequestsStoreService {

  private readonly _initialStoreData: AnonymousRequestStore = {
    anonymousRequestPage: null,
    sysText: null
  };

  private anonymousRequestStore: BehaviorSubject<AnonymousRequestStore>;  
  private store$: Observable<AnonymousRequestStore>;
  private submitted: BehaviorSubject<boolean>;
  private submitted$: Observable<boolean>;

  private readonly templateNames = {
    anonymousNewRequest: "anonymousNewRequest",
    unsavedChanges: "unsavedChanges"
  };

  constructor() { 
    this.anonymousRequestStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.anonymousRequestStore.asObservable();
    this.submitted = new BehaviorSubject(false);
    this.submitted$ = this.submitted.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.anonymousRequestStore.value;
  }
  
  public get anonymousRequestPage$(): Observable<AnonymousNewRequestPage> {
    return this.store$.pipe(pluck('anonymousRequestPage'));
  }

  public get anonymousRequestPage() {
    return this.store.anonymousRequestPage;
  }

  public get hasSubmitted$(): Observable<boolean> {
    return this.submitted$;
  }
  public set setHasSubmitted(bool: boolean) {
    this.submitted.next(bool);
  }

  public set anonymousRequestPage(anonymousRequestPage: AnonymousNewRequestPage) {
    const store = { ...this.store, anonymousRequestPage };
    this.anonymousRequestStore.next(store);
  }
  
  public get sysText() {
    return this.store.sysText;
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.anonymousRequestStore.next(store);
  }

  public unloadStore() {
    const store: AnonymousRequestStore = {
      anonymousRequestPage: null,
      sysText: null
    }
    this.anonymousRequestStore.next(store)
  }

}
