import { AccountFormOptions, VerificationFormOptions } from '../models';
import { PASSWORD_REQS } from './password-requirements.constants';

export const LOGIN_OPTIONS: AccountFormOptions = {
  username: {
    show: true,
    disabled: false,
  },
  phonenumber: {
    show: false
  },
  password: {
    show: true,
    requirements: null,
  },
  link: {
    route: 'forgot-password',
  },
  isButtonEnabled: true
};

export const FORGOTPASSWORD_OPTIONS: AccountFormOptions = {
  username: {
    show: true,
    disabled: false,
  },
  phonenumber: {
    show: false
  },
  password: {
    show: false,
    requirements: null,
  },
  link: {
    route: 'login',
  },
  isButtonEnabled: false
};

export const RESETPASSWORD_OPTIONS: AccountFormOptions = {
  username: {
    show: false,
    disabled: true,
  },
  phonenumber: {
    show: false
  },
  password: {
    show: true,
    requirements: PASSWORD_REQS,
  },
  link: {
    route: 'login',
  },
  isButtonEnabled: false
};

export const SETPASSWORD_OPTIONS: AccountFormOptions = {
  username: {
    show: true,
    disabled: true,
  },
  phonenumber: {
    show: true
  },
  password: {
    show: true,
    requirements: PASSWORD_REQS,
  },
  link: {
    route: 'login',
  },
  isButtonEnabled: false
};

export const VFORM_LOGIN_OPTIONS: VerificationFormOptions = {
  rememberMe: {
    show: true
  },
  secondaryLink: {
    show: false
  },
  goBack: {
    show: true
  },
  phoneNumberConfirmed: true
}

export const VFORM_RESETPASSWORD_OPTIONS: VerificationFormOptions = {
  rememberMe: {
    show: false
  },
  secondaryLink: {
    show: false
  },
  goBack: {
    show: false
  },
  phoneNumberConfirmed: false
}
