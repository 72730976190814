import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { SecurityQuestionDetails, UserSecurityQuestions } from '@core/models';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class SecurityQuestionsFormService {
  public form: UntypedFormGroup;
  get questions(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  get username(): FormControl {
    return this.form.get('username') as FormControl;
  }
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      username: [''],
      questions: this.fb.array([]),
    });
  }

  createUserSecurityQuestionsDto(): UserSecurityQuestions {
    const uSqs: UserSecurityQuestions = {
      username: this.username.value,
      securityQuestionDetails: this.questions.value,
    };
    return uSqs;
  }

  loadForm(uSqs: UserSecurityQuestions): void {
    this.form.patchValue({
      username: uSqs.username,
    });
    this.loadQuestions(uSqs.securityQuestionDetails);
  }

  private loadQuestions(sqs: SecurityQuestionDetails[]): void {
    sqs.forEach((q) => {
      this.addQuestion(q);
    });
  }

  private addQuestion(sqDetails: SecurityQuestionDetails): void {
    const qGroup = this.fb.group({
      id: sqDetails.id,
      answer: ['', Validators.required],
    });

    this.questions.push(qGroup);
  }
}
