import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-proceed-confirmation',
  templateUrl: './proceed-confirmation.component.html',
  styleUrls: ['./proceed-confirmation.component.scss']
})
export class ProceedConfirmationComponent extends DialogDragConstraints implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) 
    public data: {
      title: any, 
      message1: any; 
      message2: any; 
      cancelCaption: any 
      proceedCaption: any; 
    },
    private dialogRef: MatDialogRef<ProceedConfirmationComponent>,
    ) {
      super(dialogRef);
    }

  ngOnInit(): void {
  }
}