import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConstantsService } from '@core/services';
import { FormControl } from '@angular/forms';
import { Editor } from 'tinymce';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { PlaceholderOption } from '@core/models/case-communication/placeholder-option.model';

@Component({
  selector: 'app-email-body-editor',
  templateUrl: './email-body-editor.component.html',
  styleUrls: ['./email-body-editor.component.scss']
})
export class EmailBodyEditorComponent implements OnInit {
  @Input() control: FormControl;
  @Input() placeholderText: string;
  @Input() placeHolders: PlaceholderOption[] = [];
  @ViewChild('bodyEditor') bodyEditor: EditorComponent;

  public placeholdersMenuItems: string = "";
  public editorStyle: string;

  private placeholderChip: string;

  constructor(
    private constants: ConstantsService
  ) { }

  ngOnInit(): void {
    this.editorStyle = this.constants.EDITOR_STYLES;
    this.placeholderChip = this.constants.EDITOR_CHIP;
    this.placeHolders?.forEach(ph => {
      this.placeholdersMenuItems += (ph.key + " ");
    });
  }

  public getEditorText(): string {
    return this.bodyEditor.editor.getContent({format: "text"});
  }

  public bodyEditorSetup = (editor: Editor): void => {
    this.placeHolders?.forEach(ph => {
      editor.ui.registry.addMenuItem(ph.key, {
        text: ph.name,
        onAction : () => {
          editor.insertContent(this.placeholderChip
            .replace("#placeholder-name#", ph.name))
        }
      });
    });
  }
}
