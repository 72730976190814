import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ADDNEWEMPLOYEEDIALOG_MOCK } from '@core/mocks/leave-admin/add-new-employee-dialog.mock';
import { CASENOTE_1, CASENOTE_2 } from '@core/mocks/leave-admin/case-note.mock';
import { EEWORKSCHEDULEDIALOG_MOCK, LEAVECALENDARMONTH_MOCK, EMPLOYEERECORD_MOCK, LEAVECALENDARMONTH_MOCK2 } from '@core/mocks/leave-admin/employee-record.mock';
import { MANAGEEMPLOYEES } from '@core/mocks/leave-admin/manage-employees.mock';
import { DOCDOWNLOAD } from '@core/mocks/my-documents/my-documents.mock';
import { AddNewEmployeeDialog } from '@core/models/leave-admin/employees/add-new-employee-dialog.model';
import { EmployeeNote, EmployeeRecord, EmployeeRecordInfoDialog, EmployeeRecordInfoPost } from '@core/models/leave-admin/employees/employee-record.model';
import { EmployeeSettingsFormPost } from '@core/models/leave-admin/employees/employee-settings-form.model';
import { ManageEmployeesDashDownloadGet } from '@core/models/leave-admin/employees/manage-employees-dash-download-get.model';
import { MERGE_DUPLICATES_DATA, REVIEW_MERGE_EE } from '@core/mocks/leave-admin/merge-duplicates-data.mock';
import { ManageEmployees } from '@core/models/leave-admin/employees/manage-employees.model';
import { NewEmployeePost } from '@core/models/leave-admin/employees/new-employee-post.model';
import { MergeDuplicatesData, MergeDuplicatesEmployee } from '@core/models/leave-admin/employees/merge-duplicates-data.model';
import { MergeDuplicatesGet, MergeDuplicatesPost } from '@core/models/leave-admin/employees/merge-duplicates-form.model';
import { FileDownload } from '@core/models/shared/file-download.model';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { NEWEEPOSTRETURN_MOCK } from '@core/mocks/leave-admin/new-employee-post-return.mock';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { LeaveHoursFormPost } from '@core/models/leave-admin/employees/leave-hours-form.model';
import { DocumentUploadDialog } from '@core/models/leave-admin/document-upload';
import { SendInvitationDialog, SendInvitationPost } from '@core/models/leave-admin/employees/send-invitation-post.model';
import { EmployeeRecordHistory } from '@core/models/leave-admin/employees/employee-record-history.model';
import { EMPLOYEE_RECORD_HISTORY } from '@core/mocks/leave-admin/employee-record-history.mock';
import { DocumentEditDialog, PostEmployeeDocumentEdit } from '@core/models';
import { EmployeeWorkScheduleDialog, EmployeeWorkSchedulePost } from '@core/models/leave-admin/employees/employee-schedules.model';
import { EmployeeCalendarWorkdayPost, LeaveCalendarPageData, EmployeeCalendarPageDataGet } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';
import { UPDATE_CONF_DIALOG } from '@core/mocks/shared/update-confirmation-dialog.mock';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployeesService {
  private apiUrl: string;

  constructor(private http: HttpClient, private constants: ConstantsService) { 
    this.apiUrl = this.constants.MEE_API;
  }

  public getManageEmployees(): Observable<ManageEmployees> {
    return this.http.get<ManageEmployees>(`${this.apiUrl}/getManageEmployees`);
    //return of(MANAGEEMPLOYEES).pipe(delay(1000));
  }

  public getManageEmployeesDashDownload(dto: ManageEmployeesDashDownloadGet): Observable<FileDownload> {
    return this.http.post<FileDownload>(`${this.apiUrl}/getManageEmployeesDashDownload`, dto)
  }

  public getAddNewEmployeeDialog(): Observable<AddNewEmployeeDialog> {
    return this.http.get<AddNewEmployeeDialog>(`${this.apiUrl}/getAddNewEmployeeDialog`);
    //return of(ADDNEWEMPLOYEEDIALOG_MOCK).pipe(delay(1000));
  }

  public postNewEmployee(dto: NewEmployeePost): Observable<number | DuplicateDetectedDialog> {
    return this.http.post<number | DuplicateDetectedDialog>(`${this.apiUrl}/postNewEmployee`, dto)
    //return of(NEWEEPOSTRETURN_MOCK).pipe(delay(1000));
  }

  public getEmployeeRecord(employeeId: number): Observable<EmployeeRecord> {
    return this.http.get<EmployeeRecord>(`${this.apiUrl}/getEmployeeRecord/${employeeId}`);
    // return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public getEmployeeCalendarPageData(dto: EmployeeCalendarPageDataGet): Observable<LeaveCalendarPageData> {
    return this.http.post<LeaveCalendarPageData>(`${this.apiUrl}/getEmployeeCalendarPageData`, dto);

    // mock moving from sep to oct '04...

    // if (dto.calendarStartDate < new Date("09/29/24")) {
    //   return of(LEAVECALENDARMONTH_MOCK).pipe(delay(1000));
    // } else {
    //   return of(LEAVECALENDARMONTH_MOCK2).pipe(delay(1000));
    // }
  }

  public getEmployeeRecordHistory(employeeId: number): Observable<EmployeeRecordHistory> {
    return this.http.get<EmployeeRecordHistory>(`${this.apiUrl}/getEmployeeRecordHistory/${employeeId}`);
    //return of(EMPLOYEE_RECORD_HISTORY).pipe(delay(1000));
  }

  public getDocument(documentId: number): Observable<FileDownload> {
    return this.http.get<FileDownload>(`${this.apiUrl}/getDocument/${documentId}`)
    //return of(DOCDOWNLOAD);
  }

  public postDocumentDelete(dto: {documentId: number}): Observable<number> { 
    return this.http.post<any>(`${this.apiUrl}/postDocumentDelete`, dto);
    //return of();
  }

  public editEmployeeNote(note: EmployeeNote): Observable<EmployeeNote> {
    return this.http.post<EmployeeNote>(`${this.apiUrl}/postEditEmployeeNote`, note);
    //return of(CASENOTE_2)
  }

  public deleteEmployeeNote(dto: {noteId: number}): Observable<number> { 
    return this.http.post<number>(`${this.apiUrl}/postDeleteEmployeeNote`, dto);
    //return of(1).pipe(delay(1000));
  }

  public addEmployeeNote(dto: {employeeId: number, message: string}): Observable<EmployeeNote> {
    return this.http.post<EmployeeNote>(`${this.apiUrl}/postAddEmployeeNote`, dto);
    //return of(CASENOTE_1).pipe(delay(2000));
  }

  public getEmployeeRecordInfoDialog(employeeId: number): Observable<EmployeeRecordInfoDialog> {
    return this.http.get<EmployeeRecordInfoDialog>(`${this.apiUrl}/getEmployeeRecordInfoDialog/${employeeId}`);
    //return of(EMPLOYEE_RECORD_INFO_DIALOG).pipe(delay(1000));
  }

  public postEmployeeRecordInfo(dto: EmployeeRecordInfoPost): Observable<EmployeeRecord | DuplicateDetectedDialog | UpdateConfirmationDialog> {
    return this.http.post<EmployeeRecord | DuplicateDetectedDialog | UpdateConfirmationDialog>(`${this.apiUrl}/postEmployeeRecordInfo`, dto)
    // return of(UPDATE_CONF_DIALOG).pipe(delay(1000));
    // return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public getMergeDuplicatesData(dto: MergeDuplicatesGet): Observable<MergeDuplicatesData> {
    return this.http.post<MergeDuplicatesData>(`${this.apiUrl}/getMergeDuplicatesData`, dto)
    // return of(MERGE_DUPLICATES_DATA).pipe(delay(1000));
  }

  public getReviewMerge(dto: MergeDuplicatesGet): Observable<MergeDuplicatesEmployee> {
    return this.http.post<MergeDuplicatesEmployee>(`${this.apiUrl}/getReviewMerge`, dto)
    //return of(REVIEW_MERGE_EE).pipe(delay(1000));
  }

  public postConfirmMerge(dto: MergeDuplicatesPost): Observable<ManageEmployees | UpdateConfirmationDialog> {
    return this.http.post<ManageEmployees | UpdateConfirmationDialog>(`${this.apiUrl}/postConfirmMerge`, dto)
    //return of(MANAGEEMPLOYEES).pipe(delay(1000));
  }

  public postEmployeeSettings(dto: EmployeeSettingsFormPost): Observable<EmployeeRecord> {
    return this.http.post<EmployeeRecord>(`${this.apiUrl}/postEmployeeSettings`, dto);
    //return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public postLeaveHours(dto: LeaveHoursFormPost): Observable<EmployeeRecord | UpdateConfirmationDialog> {
    return this.http.post<EmployeeRecord | UpdateConfirmationDialog>(`${this.apiUrl}/postLeaveHours`, dto);
    //return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public postNewEmployeeWorkSchedule(dto: EmployeeWorkSchedulePost): Observable<EmployeeRecord | UpdateConfirmationDialog> {
    return this.http.post<EmployeeRecord | UpdateConfirmationDialog>(`${this.apiUrl}/postNewEmployeeWorkSchedule`, dto);
    // return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public postEditEmployeeWorkSchedule(dto: EmployeeWorkSchedulePost): Observable<EmployeeRecord | UpdateConfirmationDialog> {
    return this.http.post<EmployeeRecord | UpdateConfirmationDialog>(`${this.apiUrl}/postEditEmployeeWorkSchedule`, dto);
    // return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public postEmployeeWorkScheduleDelete(dto: {employeeScheduleId: number}): Observable<EmployeeRecord> { 
    return this.http.post<EmployeeRecord>(`${this.apiUrl}/postEmployeeWorkScheduleDelete`, dto);
    // return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public postLeaveCalendarWorkday(dto: EmployeeCalendarWorkdayPost): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/postLeaveCalendarWorkday`, dto);
    // return of(true).pipe(delay(1000));
  }

  public getDocumentUploadDialog(employeeId: number): Observable<DocumentUploadDialog> {
    return this.http.get<DocumentUploadDialog>(`${this.apiUrl}/getDocumentUploadDialog/${employeeId}`)
    //return of(DOCUMENTUPLOADDIALOG_MOCK);
  }

  public getAddEditEmployeeWorkScheduleDialog(employeeId: number): Observable<EmployeeWorkScheduleDialog> {
    return this.http.get<EmployeeWorkScheduleDialog>(`${this.apiUrl}/getAddEditEmployeeWorkScheduleDialog/${employeeId}`)
    // return of(EEWORKSCHEDULEDIALOG_MOCK).pipe(delay(1000));
  }

  public getDocumentEditDialog(employeeId: number): Observable<DocumentEditDialog> {
    return this.http.get<DocumentEditDialog>(`${this.apiUrl}/getDocumentEditDialog/${employeeId}`)
    //return of();
  }

  public postDocumentUpload (formData: FormData): Observable<EmployeeRecord> {
    return this.http.post<EmployeeRecord>(`${this.apiUrl}/postDocumentUpload`, formData)
    //return of(EMPLOYEERECORD_MOCK).pipe(delay(1000));
  }

  public postDocumentEdit(dto: PostEmployeeDocumentEdit): Observable<EmployeeRecord> { 
    return this.http.post<EmployeeRecord>(`${this.apiUrl}/postDocumentEdit`, dto);
  }

  public getSendInvitationDialog(employeeId: number): Observable<SendInvitationDialog> {
    return this.http.get<SendInvitationDialog>(`${this.apiUrl}/getSendInvitationdialog/${employeeId}`)
  }

  public postSendInvitation (dto: SendInvitationPost): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/postSendInvitation`, dto)
    //return of().pipe(delay(1000));
  }
}
