<ng-container *ngIf="!showVerificationForm">
  <h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    {{ sysText.changePhoneNumberDialog_title | titlecase }}
  </h6>  
  <mat-dialog-content [formGroup]="form">    
    <p class="subtitle1 bottom-margin">
      {{ sysText.changePhoneNumberDialog_subtitle }}
    </p>
    <mat-form-field color="accent">
      <input matInput type="string" [placeholder]="sysText.newPhoneNumber | titlecase" formControlName="phoneNumber"/>
      <mat-error>{{ phoneNumber.errors?.msError }}</mat-error>
    </mat-form-field>
  </mat-dialog-content>  
  <mat-dialog-actions align="end">
    <ng-container *ngIf="!(isSubmitting$ | async); else submitting">
      <button mat-stroked-button class="button" type="button" [mat-dialog-close] color="accent">
        {{ sysText.cancel }}
      </button>
      <button
        mat-stroked-button
        class="button"
        type="submit"
        (click)="changePhoneNumber()"
        color="accent"
      >
        {{ sysText.changePhoneNumber }}
      </button>
    </ng-container>
    <ng-template #submitting>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </ng-template>
  </mat-dialog-actions>
</ng-container>
<ng-container *ngIf="showVerificationForm">
  <h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    {{ sysText.changePhoneNumberDialog_verify_title | titlecase }}
  </h6>  
  <mat-dialog-content [formGroup]="verifyForm">    
    <p class="subtitle1">
      {{ sysText.changePhoneNumberDialog_verify_subtitle }}
    </p>
    <mat-form-field color="accent">
      <input matInput type="number" [placeholder]="sysText.code | titlecase" formControlName="otp"/>
      <mat-error>{{ otp.errors?.msError }}</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="center">        
    <ng-container *ngIf="!(isSubmitting$ | async); else submitting">
      <button 
        mat-stroked-button class="button" 
        type="button"
        color="accent" 
        (click)="onResend()"
      >
        {{ sysText.changePhoneNumberDialog_resendCode}}
      </button>
      <button 
        mat-stroked-button class="button" 
        type="button" 
        [mat-dialog-close] 
        color="accent" 
      >
        {{ sysText.cancel }}
      </button>
      <button
        mat-stroked-button
        class="button"
        type="submit"
        (click)="confirmPhoneNumber()"
        color="accent"
      >
        {{ sysText.verify_changePhoneNumber }}
      </button>
    </ng-container>
    <ng-template #submitting>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </ng-template>
  </mat-dialog-actions>
</ng-container>  