import { Injectable } from '@angular/core';
import { LeaveAdmin } from '@core/models';
import { LeaveAdminDashboardStore } from '@core/models/leave-admin/leave-admin-dashboard-store.model';
import { LeaveAdminDashboardTableFilters } from '@core/models/leave-admin/leave-admin-dashboard-table-filters.model';
import { LeaveListItem } from '@core/models/leave-admin/leave-list-item.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeaveAdminDashboardStoreService {

  private readonly _initialStoreData: LeaveAdminDashboardStore = {
    leaveAdmin: null,
    sysText: null
  };

  private leaveAdminDashboardStore: BehaviorSubject<LeaveAdminDashboardStore>;  

  private store$: Observable<LeaveAdminDashboardStore>;

  private readonly templateNames = {
    leaveAdmin: "leaveAdmin",
    assignCaseAdminDialog: "assignCaseAdminDialog",
    submitNewRequest: 'submitNewRequest',
    unsavedChanges: "unsavedChanges",
    leaveAdminDashboardTableFilterDialog: "leaveAdminDashboardTableFilterDialog",
    duplicateDetected: "duplicateDetected"
  };

  constructor() { 
    this.leaveAdminDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.leaveAdminDashboardStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.leaveAdminDashboardStore.value;
  }
  
  public get leaveAdmin$(): Observable<LeaveAdmin> {
    return this.store$.pipe(pluck('leaveAdmin'));
  }

  public get leaveAdmin() {
    return this.store.leaveAdmin;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public set leaveAdmin(leaveAdmin: LeaveAdmin) {
    const store = { ...this.store, leaveAdmin };
    this.leaveAdminDashboardStore.next(store);
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.leaveAdminDashboardStore.next(store);
  }

  public set tableFilterOptions(tableFilterOptions: LeaveAdminDashboardTableFilters) {
    const store = { ...this.store, tableFilterOptions };
    this.leaveAdminDashboardStore.next(store);
  }

  updateLeaveListItem(item: LeaveListItem) {
    const leaveAdmin = this.leaveAdmin;
    const index = leaveAdmin.allLeaves.findIndex(i => i.caseId === item.caseId);
    leaveAdmin.allLeaves.splice(index, 1, item);
    const store = { ...this.store, leaveAdmin };
    this.leaveAdminDashboardStore.next(store);
  }

  public unloadStore() {
    const store: LeaveAdminDashboardStore = {
      leaveAdmin: null,
      sysText: null
    }
    this.leaveAdminDashboardStore.next(store);
  }
}
