import { StatusChipStyle } from '@core/models';
import { StatusChips } from '@core/models/status-chips/status-chips.model';

const STATUS_CHIP_COLORS_BACKGROUND = {
  success: 'rgba(125, 219, 125, 0.5)',
  inProgress: 'rgba(109, 141, 255, 0.5)',
  warn: 'rgba(255, 179, 167, 0.5)',
  warnYel: 'rgba(255, 240, 40, 0.5)',
  neutral: 'rgb(212, 212, 214)',
  none: '',
};

const STATUS_CHIP_COLORS_FOREGROUND = {
  success: '#077923', // fill4-dk
  inProgress: '#1360fa', // fill6-dk
  warn: '#c2524c', // fill5-dk
  warnYel: '#cbbc70', //fill3-dk
  neutral: '#63626a' // grey2
};

const SUCCESS_CHIP_BG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.success,
  color: STATUS_CHIP_COLORS_FOREGROUND.success,
};

const SUCCESS_CHIP_NO_BG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.none,
  color: STATUS_CHIP_COLORS_FOREGROUND.success,
};

const WARN_CHIP_BG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.warn,
  color: STATUS_CHIP_COLORS_FOREGROUND.warn,
};

const WARN_CHIP_YEL_BG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.warnYel,
  color: STATUS_CHIP_COLORS_FOREGROUND.warnYel,
};

const WARN_CHIP_NOBG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.none,
  color: STATUS_CHIP_COLORS_FOREGROUND.warn,
};

const INPROG_CHIP_BG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.inProgress,
  color: STATUS_CHIP_COLORS_FOREGROUND.inProgress,
};

const INPROG_CHIP_NOBG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.none,
  color: STATUS_CHIP_COLORS_FOREGROUND.inProgress,
};

const NEUTRAL_CHIP_BG: StatusChipStyle = {
  backgroundColor: STATUS_CHIP_COLORS_BACKGROUND.neutral,
  color: STATUS_CHIP_COLORS_FOREGROUND.neutral
}

export const STATUS_CHIPS_STYLES: StatusChips = {
  successBg: SUCCESS_CHIP_BG,
  successNoBg: SUCCESS_CHIP_NO_BG,
  warnBg: WARN_CHIP_BG,
  warnYelBg: WARN_CHIP_YEL_BG,
  warnNoBg: WARN_CHIP_NOBG,
  inProgBg: INPROG_CHIP_BG,
  inProgNoBg: INPROG_CHIP_NOBG,
  neutralBg: NEUTRAL_CHIP_BG
};
