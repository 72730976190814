<div class="drawer-container">
    <div class="drawer-header">
        <div class="section-header-container">
            <p class="overline grey1 semibold section-title">{{helpAndFeedback.sysText.knowledgeBase_title}}</p>
            <mat-divider class="section-header-underline"></mat-divider>
        </div>
        <button
          mat-icon-button
          aria-label="remove button"
          [disableRipple]="true"
          (click)="onCloseHelp()"
          class="close-button">
          <mat-icon>close</mat-icon>
        </button>
    </div>
    <p class="body2">{{helpAndFeedback.sysText.knowledgeBase_subtitle}}</p>
    <div class="section-body-container">
        <p class="body2 knowledge-links" *ngFor="let link of helpAndFeedback.helpInfo.knowledgeBaseLinks">
            <a [href]="link.url" target="_blank">{{link.title}}<mat-icon *ngIf="!(isMobile$ | async)">open_in_new</mat-icon></a>
        </p>
    </div>

    <div *ngIf="helpAndFeedback.helpInfo.resourceLinks?.length > 0">
        <div class="section-header-container">
            <p class="overline grey1 semibold section-title">{{helpAndFeedback.sysText.resources_title}}</p>
            <mat-divider class="section-header-underline"></mat-divider>
        </div>
        <p class="body2">{{helpAndFeedback.sysText.resources_subtitle}}</p>
        <div class="section-body-container">
            <p class="body2 knowledge-links" *ngFor="let link of helpAndFeedback.helpInfo.resourceLinks">
                <a [href]="link.url" target="_blank">{{link.title}}<mat-icon *ngIf="!(isMobile$ | async)">open_in_new</mat-icon></a>
            </p>
        </div>
    </div>

    <div class="section-header-container">
        <p class="overline grey1 semibold section-title">{{helpAndFeedback.sysText.contactLeaveAdmin_title}}</p>
        <mat-divider class="section-header-underline"></mat-divider>
    </div>
    <p class="body2">{{helpAndFeedback.sysText.contactLeaveAdmin_subtitle}}</p>
    <div class="section-body-container">
        <div class="contact-admin-container">
            <div>
                <p class="overline admin-title">{{helpAndFeedback.sysText.helpFeedbackAdmin_title}}</p>
                <div class="d-flex" *ngIf="helpAndFeedback.helpInfo.helpAndFeedbackAdmin != null; else noAdmin">
                    <img *ngIf="helpAndFeedback.helpInfo.helpAndFeedbackAdmin.image" class="image-container" [src]="helpAndFeedback.helpInfo.helpAndFeedbackAdmin.image">
                    <div *ngIf="!helpAndFeedback.helpInfo.helpAndFeedbackAdmin.image" class="circle">
                        {{ getInitials(helpAndFeedback.helpInfo.helpAndFeedbackAdmin.description) }}
                    </div> 
                    <div class="employee-info">
                        <p class="grey1 body2 bold">{{ helpAndFeedback.helpInfo.helpAndFeedbackAdmin.description }}</p>
                        <p class="grey2 caption">{{ helpAndFeedback.helpInfo.helpAndFeedbackAdmin.workEmail }}</p>
                        <p class="grey2 caption">{{ helpAndFeedback.helpInfo.helpAndFeedbackAdmin.workPhone }}</p>
                    </div>
                </div>
            </div>
            <span>
                <button
                    *ngIf="!(isMobile$ | async) && helpAndFeedback.helpInfo.helpAndFeedbackAdmin != null"
                    (click)="contactAdmin(helpAndFeedback.helpInfo.helpAndFeedbackAdmin)"
                    mat-flat-button
                    color="accent"
                    class="button">
                    {{helpAndFeedback.sysText.contact}}
                </button>
                <button *ngIf="isMobile$ | async" mat-icon-button (click)="contactAdmin(helpAndFeedback.helpInfo.helpAndFeedbackAdmin)" color="accent" class="button">
                    <mat-icon>mail</mat-icon>
                </button>
            </span>
        </div>

        <div *ngIf="helpAndFeedback.helpInfo.leaveAdmins?.length > 0">
            <div class="contact-admin-container" *ngFor="let admin of helpAndFeedback.helpInfo.leaveAdmins">
                <div>
                    <p class="overline admin-title">{{helpAndFeedback.sysText.assignedAdmin_title}}</p>
                    <div class="d-flex" *ngIf="admin != null; else noAdmin">
                        <img *ngIf="admin.image" class="image-container" [src]="admin.image">
                        <div *ngIf="!admin.image" class="circle">
                            {{ getInitials(admin.description) }}
                        </div> 
                        <div class="employee-info">
                            <p class="grey1 body2 bold">{{ admin.description }}</p>
                            <p class="grey2 caption">{{ admin.workEmail }}</p>
                            <p class="grey2 caption">{{ admin.workPhone }}</p>
                        </div>
                    </div>
                </div>
                <span>
                    <button
                        *ngIf="!(isMobile$ | async)"
                        (click)="contactAdmin(admin)"
                        mat-flat-button
                        color="accent"
                        class="button">
                        {{helpAndFeedback.sysText.contact}}
                    </button>
                    <button *ngIf="isMobile$ | async" mat-icon-button (click)="contactAdmin(admin)" color="accent" class="button">
                        <mat-icon>mail</mat-icon>
                    </button>
                </span>
            </div>
        </div>

        <ng-template #noAdmin>
            <div class="d-flex">
                <img [src]="'assets/images/missing_avatar.svg'" alt="user-image" class="image-container"/>
                <div class="employee-info">
                    <p class="grey1 body2">{{helpAndFeedback.sysText.noAdminAvailable}}</p>
                </div>
            </div>
        </ng-template>
    </div>

    <div *ngIf="helpAndFeedback.helpInfo.canContactSupport">
        <div class="section-header-container">
            <p class="overline grey1 semibold section-title">{{helpAndFeedback.sysText.contactStiiraSupport_title}}</p>
            <mat-divider class="section-header-underline"></mat-divider>
        </div>
        <p class="body2">{{helpAndFeedback.sysText.contactStiiraSupport_subtitle}}</p>
        <div class="section-body-container">
            <a 
                mat-flat-button 
                color="accent"
                class="button"
                [href]="helpAndFeedback.helpInfo.contactSupportUrl"
                target="_blank">
                {{helpAndFeedback.sysText.contactStiiraSupport_button}}
            </a>
        </div>
    </div>

    <div class="section-header-container">
        <p class="overline grey1 semibold section-title">{{helpAndFeedback.sysText.submitFeedback_title}}</p>
        <mat-divider class="section-header-underline"></mat-divider>
    </div>
    <p class="body2">{{helpAndFeedback.sysText.submitFeedback_subtitle}}</p>
    <div class="section-body-container">
        <a 
            mat-flat-button 
            color="accent"
            class="button"
            [href]="helpAndFeedback.helpInfo.submitFeedbackUrl"
            target="_blank">
            {{helpAndFeedback.sysText.submitFeedback_button}}
        </a>
    </div>
</div>
