import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Role } from '@core/models';

@Component({
  selector: 'app-role-chip',
  templateUrl: './role-chip.component.html',
  styleUrls: ['./role-chip.component.scss'],
})
export class RoleChipComponent implements OnInit {
  @Output() removeRole = new EventEmitter<Role>();
  @Input() inputRole: Role;
  @Input() isRemovingRole: boolean;

  constructor() {}

  ngOnInit(): void {}

  onRemoveRole(e: MouseEvent) {
    e.stopPropagation();
    this.removeRole.emit(this.inputRole);
  }
}
