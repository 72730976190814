<mat-card appearance="raised" class="anon-form--container mat-elevation-z12">
    <div class="anon-form--header">
      <h5 class="anon-form--title">{{ sysText.anonymousNewRequest.anonymousNewRequest_title | titlecase }}</h5>
      <p class="body2 anon-form--subtitle">{{ sysText.anonymousNewRequest.anonymousNewRequest_subtitle | capitalizefirst }}</p>
    </div>
    <mat-card-content>
      <form [formGroup]="form" class="anon-form--form">
        <div class="new-employee">
          <div class="ee-row">
            <div class="ee-row-left">
              <app-input-field 
                [icon]="'person'"
                [label]="sysText.anonymousNewRequest.submitNewRequest_firstName" 
                [control]="form.controls.firstName" 
                [group]="form">
              </app-input-field>
            </div>
            <div class="ee-row-right">
              <app-input-field 
                [hideIconSpace]="!(isHandheld$ | async)"
                [label]="sysText.anonymousNewRequest.submitNewRequest_middleName" 
                [control]="form.controls.middleName" 
                [group]="form">
              </app-input-field>
            </div>
          </div>
          <div class="ee-row">
            <div class="ee-row-left">
              <app-input-field 
                [label]="sysText.anonymousNewRequest.submitNewRequest_lastName" 
                [control]="form.controls.lastName" 
                [group]="form">
              </app-input-field>
            </div>
            <div class="ee-row-right">
              <app-input-field 
                [hideIconSpace]="!(isHandheld$ | async)"
                [label]="sysText.anonymousNewRequest.submitNewRequest_suffix" 
                [control]="form.controls.suffix" 
                [group]="form">
              </app-input-field>
            </div>
          </div>
          <div class="new-ee-full-column">
            <app-input-field 
              [group]="form"
              [control]="form.controls.company"
              [icon]="'business'"
              [label]="sysText.anonymousNewRequest.submitNewRequest_company"
              [inputType]="'string'">
            </app-input-field>
          </div>
          <app-email-field-group
            [group]="form"
            [workEmailControl]="form.controls.workEmail"
            [isWorkEmailPreferredControl]="form.controls.isWorkEmailPreferred"
            [personalEmailControl]="form.controls.personalEmail"
            [isPersonalEmailPreferredControl]="form.controls.isPersonalEmailPreferred">
          </app-email-field-group>
          <div class="new-ee-full-column">
              <app-input-field 
                [icon]="'phone'"
                [label]="sysText.anonymousNewRequest.submitNewRequest_workPhone"
                [control]="form.controls.workPhone" 
                [group]="form">
              </app-input-field>
          </div>
          <div class="new-ee-full-column">
              <app-input-field 
                [label]="sysText.anonymousNewRequest.submitNewRequest_personalPhone" 
                [control]="form.controls.personalPhone"
                [group]="form">
              </app-input-field>
          </div>
        </div>
  
        <div class="date-fields">
            <div class="date-fields-column-left">
                <app-date-field
                  [label]="sysText.anonymousNewRequest.submitNewRequest_startDate"
                  [control]="form.controls.startDate"
                  [group]="form"
                  [icon]="'date_range'"
                  (emitDateChange)="updateEndDate()">
                </app-date-field>
            </div>
            <div class="date-fields-column left-margin">
                <app-date-field
                  [label]="sysText.anonymousNewRequest.submitNewRequest_endDate"
                  [control]="form.controls.endDate"
                  [hideIconSpace]="!(isHandheld$ | async)"
                  [group]="form">
                </app-date-field>
            </div>
        </div>
        <app-select-field
          [group]="form"
          [control]="form.controls.leaveReasonId"
          [options]="anonymousRequestInfo.leaveReasonOptions"
          [icon]="'help_outline'"
          [label]="sysText.anonymousNewRequest.submitNewRequest_leaveReason"
          [shouldCapitalize]="false">
        </app-select-field>
        <app-input-field
          [group]="form"
          [control]="form.controls.comments"
          [icon]="'comment'"
          [label]="sysText.anonymousNewRequest.submitNewRequest_comments">
        </app-input-field>
        <div class="section-header-container">
          <p class="overline grey1 semibold section-title">{{sysText.anonymousNewRequest.submitNewRequest_documents | uppercase}}</p>
          <mat-divider class="section-header-underline"></mat-divider>
        </div>
        <input hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files)"/>
        <div class="file-input-field">
          <mat-icon class="input-icon">attach_file</mat-icon>
          <button mat-stroked-button color="accent" (click)="imgFileInput.click()" class="body2">
            <mat-icon>upload</mat-icon>
            <span > {{sysText.anonymousNewRequest.submitNewRequest_upload | capitalizefirst}} </span>
          </button>
        </div>
        <div class="pad" *ngIf="fileToUpload">
          <div class="flex-horiz bot-space">
            <mat-icon class="fill4-dk">check</mat-icon>
            <div>
              <span class="body2 side-space grey3">{{ fileToUpload?.name }}</span>
            </div>
            <mat-icon class="black-inactive small pointer" (click)="clearFile()">clear</mat-icon>
          </div>
        </div>
        <app-input-field
          [group]="form"
          [control]="form.controls.documentTitle"
          [icon]="'description'"
          [label]="sysText.anonymousNewRequest.submitNewRequest_documentTitle">
        </app-input-field>
        <app-select-field
          [group]="form"
          [control]="form.controls.documentCategoryId"
          [options]="anonymousRequestInfo.documentCategoryOptions"
          [icon]="'list'"
          [label]="sysText.anonymousNewRequest.submitNewRequest_documentCategory"
          [shouldCapitalize]="false">
        </app-select-field>
        <app-input-field
          [control]="form.controls.documentComments"
          [label]="sysText.anonymousNewRequest.submitNewRequest_docComments"
          [inputType]="'string'"
          [icon]="'comment'">
        </app-input-field>
      </form>
      <button
        (click)="onSubmit()"
        mat-flat-button
        color="accent"
        class="button submit--button"
        *ngIf="!(isSubmitting$ | async); else submitting">
        {{ sysText.anonymousNewRequest.submitRequest }}
      </button>
      <ng-template #submitting>
        <button type="submit" mat-flat-button color="accent" class="button anon-form--disabled-button" disabled>
          <mat-spinner [diameter]="30" color="accent"></mat-spinner>
        </button>
      </ng-template>
      <div class="anon-form--link caption">
        <a class="pointer" (click)="backToLogin()">
          {{ sysText.anonymousNewRequest.backToLogin | capitalizefirst }}
        </a>
      </div>
    </mat-card-content>


    <ng-template #loadingTemplate>
      <div class="spinner-container">
        <mat-spinner color="accent" diameter="40"></mat-spinner>
      </div>
    </ng-template>
  </mat-card>
  