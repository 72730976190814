import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { SelectionOption } from '@core/models';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
})
export class RadioButtonGroupComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() icon: string;
  @Input() label: string;
  @Input() shouldCapitalizeLabel: boolean = true;
  @Input() control: FormControl;
  @Input() options: SelectionOption[];
  @Input() isStacked: boolean;
  @Input() hint: string;
  @Input() public isHintHighlighted: boolean = true;
  @Output() change = new EventEmitter<MatRadioChange>();
  @Input() isYesNo: boolean;
  @Input() customYes: string;
  @Input() customNo: string;
  @Input() info: string;
  public sysText: any;

  constructor(private text: SharedComponentsSysTextService) {
    this.sysText = this.text.sysText[this.text.templates.radioButtonGroup];
  }

  ngOnInit(): void {}

  onChange(event: MatRadioChange): void {
    this.change.emit(event);
  }
}
