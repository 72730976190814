import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirectiveQueryParam } from '@core/enums';
import { ForgotPassword, Recipient, ResetPassword, SetPassword, SendCode, VerifyCode, VerifyPhoneCode, SendPhoneCode, RememberBrowser} from '@core/models';
import { AnonymousNewRequest } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { Observable } from 'rxjs';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private userId: string;
  private username: string;
  private code: string;
  private otpDest: string;
  private apiUrl: string;
  private dir: DirectiveQueryParam;
  private dir2: DirectiveQueryParam;
  private caseId: string;
  private anonymousNewRequest: AnonymousNewRequest;

  constructor(private constants: ConstantsService, private http: HttpClient) {
    this.apiUrl = this.constants.ACCOUNT_API;
  }

  sendCode(dto: SendCode): Observable<any> {   
    return this.http.post<any>(`${this.apiUrl}/sendcode`, dto)
  }

  sendPhoneCode(dto: SendPhoneCode): Observable<any> {   
    return this.http.post<any>(`${this.apiUrl}/sendphonecode`, dto)    
    // return of(null);
  }  

  verifyCode(dto: VerifyCode): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/verifycode`, dto)
  }

  verifyPhoneCode(dto: VerifyPhoneCode): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/verifyphonecode`, dto)
  }  

  setTwoFactorRememberBrowser(dto: RememberBrowser): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/settwofactorrememberbrowser`, dto)
  }

  verifyTwoFactorRememberBrowser(dto: RememberBrowser): Observable<boolean> {    
    return this.http.post<boolean>(`${this.apiUrl}/verifytwofactorrememberbrowser`, dto);
  }

  forgotPassword(fpModel: ForgotPassword): Observable<Recipient> {
    return this.http.post<Recipient>(`${this.apiUrl}/forgotpassword`, fpModel);
    // return of(FORGOTPASSWORD_MOCK);
  }

  resetPassword(rpModel: ResetPassword): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/resetpassword`, rpModel);
    // return of(null);
  }

  setFirstPassword(spModel: SetPassword): Observable<any> {    
    return this.http.post<any>(`${this.apiUrl}/setfirstpassword`, spModel);
    // return of(null); 
  }  

  checkFirstTimeAccess(username: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/checkfirsttimeaccess/`, {
      params: {
        username,
      },
    });
    // return of(true);
    // return of(false);
  }

  getUserId(): string {
    return this.userId;
  }

  setUserId(uId: string): void {
    this.userId = uId;
  }

  getUsername(): string {
    return this.username;
  }

  setUsername(un: string): void {
    this.username = un;
  }

  getCode(): string {
    return this.code ? this.code.split(' ').join('+') : this.code;
  }

  setCode(code: string): void {
    this.code = code;
  }

  getOtpDest(): string {
    return this.otpDest;
  }

  setOtpDest(otpDest: string): void {
    this.otpDest = otpDest;
  }  

  getDir(): DirectiveQueryParam {
    return this.dir;
  }

  getDir2(): DirectiveQueryParam {
    return this.dir2;
  }

  setDir(dir: string) {
    this.dir = DirectiveQueryParam[dir];
  }

  setDir2(dir2: string) {
    this.dir2 = DirectiveQueryParam[dir2];
  }

  getCaseId(): string {
    return this.caseId;
  }

  setCaseId(cid: string) {
    this.caseId = cid;
  }

  getAnonymousNewRequest(): AnonymousNewRequest {
    return this.anonymousNewRequest;
  }

  setAnonymousNewRequest(anr: AnonymousNewRequest) {
    this.anonymousNewRequest = anr;
  }

  resetCodeAndUserId(): void {
    this.setCode('');
    this.setUserId('');
  }

  resetCodeAndUsername(): void {
    this.setCode('');
    this.setUsername('');
  }

  resetData(): void {
    this.setCode('');
    this.setUserId('');
    this.setUsername('');
    this.setDir('');
    this.setCaseId('');
  }
}
