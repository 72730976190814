import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SysTextService } from '@core/services/sys-text.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-idle-alert',
  templateUrl: './idle-alert.component.html',
  styleUrls: ['./idle-alert.component.scss'],
})
export class IdleAlertComponent extends DialogDragConstraints implements OnInit {
  private readonly template = 'idleAlertDialog';

  sysText$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { timeRemaining: number },
    private textService: SysTextService,
    private idleAlertDialogRef: MatDialogRef<IdleAlertComponent>
  ) {
    super(idleAlertDialogRef);
  }

  ngOnInit() {
    this.sysText$ = this.textService.getSysText(this.template).pipe(
      catchError((err) => {
        // keeps them logged in
        // pass false to close method to log out on sysText error
        this.idleAlertDialogRef.close(true);
        return of(err);
      })
    );
  }
}
