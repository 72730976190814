import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { accountRoutePaths } from '@core/routes/route-paths.constants';
import { AccountService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let { username = '', code = '', dir = '', cid = ''} = { ...next.queryParams };
    //example query: http://localhost:4200/login?dir=cd&cid=300217

    if (dir.toLowerCase() === 'fta') {
      this.router.navigate([`/${accountRoutePaths.SET_PASSWORD}`], {
        queryParams: {
          username,
          code,
        },
      });

      return false;
    }

    if (dir) {
      this.accountService.setDir(dir)
    }
    
    if (cid) {
      this.accountService.setCaseId(cid);
    }

    return true;
  }
}
