import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { NewTemplatePost } from '@core/models/case-communication/new-template-post.model';
import { ErrorService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-new-template',
  templateUrl: './add-new-template.component.html',
  styleUrls: ['./add-new-template.component.scss']
})
export class AddNewTemplateComponent extends DialogDragConstraints implements OnInit {

  public form: UntypedFormGroup;

  isSubmitting$: Subject<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      sysText: any, 
      duplicateName: string,
      sourceId: number 
    },
    private service: CaseCommunicationService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddNewTemplateComponent>
  ) {
    super(dialogRef);
    
    this.isSubmitting$ = new Subject();
    this.form = this.fb.group({
      templateName: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    if(this.data.duplicateName) {
      this.form.controls.templateName.patchValue(this.data.duplicateName + ' (' + this.data.sysText.copy + ')')
    }
  }

  onSubmit() {
    this.dialogRef.disableClose = true;
    let newTemplatePost: NewTemplatePost = {
      templateName: this.form.controls.templateName.value,
      sourceId: this.data.sourceId
    }
    this.service
      .postNewTemplate(newTemplatePost)
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe((res) => {
        this.dialogRef.close(res);
      }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

}
