<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 >
      {{data.event.start | date}}
  </h6>
  <ng-container *ngIf="isHandheld">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-fields">
      <div class="container hours-taken">
        <mat-icon [ngClass]="{ focus: input.focused }">schedule</mat-icon>
        <mat-form-field color="accent">
          <mat-label>{{ data.sysText.numberOfHours | titlecase }}</mat-label>
          <input matInput #input="matInput" type="number" [formControl]="form.controls.hours" />
          <mat-error *ngIf="form.controls.hours.errors?.msError">{{ form.controls.hours.errors?.msError }}</mat-error>
          <mat-error *ngIf="form.controls.hours.errors?.pattern">{{ data.sysText.invalidHoursError | capitalizefirst }}</mat-error>
          <mat-error *ngIf="form.controls.hours.errors?.hoursGreaterThan24">{{ data.sysText.hoursGreaterThan24Error | capitalizefirst }}</mat-error>
          <mat-hint *ngIf="(this.data.hoursExtended?.leaveCaseHoursID == null && this.data.hoursExtended?.leavesCount > 0) || (this.data.hoursExtended?.leaveCaseHoursID != null && this.data.hoursExtended?.leavesCount > 1)"
            class = "hint-blue">
            {{ data.sysText.otherLeavesWarn }}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="actions" *ngIf="!(isSubmitting$ | async); else submitting">   
  <button mat-stroked-button class="button" color="accent" [disabled]="!this.data.hoursExtended" (click)="onDelete()">
    {{data.sysText.deleteTime}}
  </button> 
    <div>
      <button mat-stroked-button class="button" color="accent" (click)="close(false)">
        {{data.sysText.cancel}}
      </button>
      <button mat-stroked-button class="button" color="accent" [disabled]="isSubmitting$ | async" (click)="onSubmit()">
        {{data.sysText.save}}
      </button>
    </div>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </mat-dialog-actions>
</ng-template>