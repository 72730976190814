// Adapted from https://javascript.plainenglish.io/a-step-by-step-guide-to-build-a-currency-formatter-directive-for-angular-reactive-form-controls-d7e2c230d05b
// https://github.com/kondareddyyaramala/currency-input

import { Directive, HostListener, Self, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Directive({
  selector: '[currencyFormatter]'
})
export class CurrencyFormatterDirective implements OnDestroy {

  private formatter: Intl.NumberFormat;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(@Self() private ngControl: NgControl) {
    this.formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2});
  }

  ngAfterViewInit() {
    this.ngControl
      .control
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.updateValue.bind(this));
  }

  updateValue(value) {
    let inputVal = value || '';
    this.setValue(!!inputVal ?
      this.validateDecimalValue(inputVal.replace(/[^0-9.]/g, '')) : '');
  }

  @HostListener('focus') onFocus() {
    if(this.ngControl.value !== null)
      this.setValue(this.unformatValue(this.ngControl.value));
  }

  @HostListener('blur') onBlur() {
    let value = this.ngControl.value || '';
    !!value && this.setValue(this.formatPrice(value));
  }

  formatPrice(value: number) {
    return this.formatter.format(value);
  }

  unformatValue(value: string) {
    return value? value.replace(/,/g, ''): null;
  }

  validateDecimalValue(value: string) {
    if (Number.isNaN(Number(value))) {
      const strippedValue = value.slice(0, value.length - 1);

      return Number.isNaN(Number(strippedValue)) ? '' : strippedValue;
    }

    return value;
  }

  setValue(value) {
    this.ngControl.control.setValue(value, { emitEvent: false });
  }

  ngOnDestroy() {
    this.setValue(this.unformatValue(this.ngControl.value));
    this.destroy$.next();
    this.destroy$.complete();
  }
}
