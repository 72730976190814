import { Injectable } from '@angular/core';
import { EmployerNote } from '@core/models/leave-admin/employers/employer-note.model';
import { EmployerProfileStore } from '@core/models/leave-admin/employers/employer-profile-store.model';
import { EmployerProfile } from '@core/models/leave-admin/employers/employer-profile.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ManageEmployersService } from './manage-employers.service';


@Injectable({
  providedIn: 'root'
})
export class EmployerProfileStoreService {

  private readonly _initialStoreData: EmployerProfileStore = {
    employerProfile: null,
    sysText: null
  };

  private employerProfileStore: BehaviorSubject<EmployerProfileStore>;  
  private store$: Observable<EmployerProfileStore>;

  private readonly templateNames = {
    employerProfile: "employerProfile",
    applicableStates: "applicableStates",
    employerContacts: "employerContacts",
    employerLeaveAdminInfo: "employerLeaveAdminInfo",
    employerLeaveYearRules: "employerLeaveYearRules",
    employerNotes: "employerNotes",
    editNoteDialog: "editNoteDialog",
    deleteConfirmation: "deleteConfirmation",
    employerSettings: "employerSettings",
    unsavedChanges: "unsavedChanges",
    editEmployerContacts: "editEmployerContacts",
    leavingPage: "leavingPage",
    editDocumentDialog: "editDocumentDialog",
    employerDocuments: "employerDocuments",
    uploadDocumentDialog: "uploadDocumentDialog",
    duplicateDetected: "duplicateDetected",
    updateConfirmation: "updateConfirmation",
    employerHistory: "employerHistory",
    employerCaseComSettings:"employerCaseComSettings"
  };

  constructor(private manageEmployersService: ManageEmployersService) { 
    this.employerProfileStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.employerProfileStore.asObservable();    
  }

  public get store() {
    return this.employerProfileStore.value;
  }

  public get employerProfile() {
    return this.store.employerProfile;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public get employerProfile$(): Observable<EmployerProfile> {
    return this.store$.pipe(pluck('employerProfile'))
  }

  public get templates() {
    return this.templateNames;
  }

  public set employerProfile(employerProfile: EmployerProfile) {
    const store = { ...this.store, employerProfile };
    this.employerProfileStore.next(store);
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.employerProfileStore.next(store);
  }

  addEmployerNote(note: EmployerNote) {
    const employerProfile = this.employerProfile;
    employerProfile.employerNotes.unshift(note);
    const store = { ...this.store, employerProfile };
    this.employerProfileStore.next(store);
  }

  updateEmployerNote(note: EmployerNote) {
    const employerProfile = this.employerProfile;
    const index = employerProfile.employerNotes.findIndex(i => i.id === note.id)
    employerProfile.employerNotes.splice(index, 1, note);
    const store = { ...this.store, employerProfile };
    this.employerProfileStore.next(store);
  }

  deleteEmployerNote(id: number) {
    const employerProfile = this.employerProfile;
    const index = employerProfile.employerNotes.findIndex(i => i.id === id);
    employerProfile.employerNotes.splice(index, 1);
    const store = { ...this.store, employerProfile };
    this.employerProfileStore.next(store);
  }
 
  public unloadStore() {
    const store: EmployerProfileStore = {
      employerProfile: null,
      sysText: null
    }
    this.employerProfileStore.next(store)
  }
}