<div class="group-container">
	<div class="email-button-container">
		<app-input-field 
			[ngClass]="{ 'button-width': showPreferredButtons }"
			[label]="sysText.workEmail"
			[control]="workEmailControl"
			[group]="group"
			[hideIconSpace]="hideIconSpace"
			[icon]="!hideIcon ? 'email' : ''">
		</app-input-field>
		<mat-button-toggle-group
			*ngIf="showPreferredButtons" 
			(change)="onPreferredClick($event)">
			<mat-button-toggle 
				class="preferred-email-option"
				[value]="preferredEmail.Work"
				[checked]="isWorkEmailPreferredControl.value"
				[matTooltip]="sysText.preferred_message | capitalizefirst" 
				[matTooltipShowDelay]="500" 
				matTooltipPosition="above">
				<mat-icon [ngClass]="{ 'selected': isWorkEmailPreferredControl.value }">stars</mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
	<div class="email-button-container">
		<app-input-field
			[ngClass]="{ 'button-width': showPreferredButtons }"
			[label]="sysText.personalEmail"
			[control]="personalEmailControl"
			[hideIconSpace]="hideIconSpace"
			[group]="group">
		</app-input-field>
		<mat-button-toggle-group
			*ngIf="showPreferredButtons" 
			(change)="onPreferredClick($event)">
			<mat-button-toggle 
				class="preferred-email-option"
				[value]="preferredEmail.Personal"
				[checked]="isPersonalEmailPreferredControl.value"
				[matTooltip]="sysText.preferred_message | capitalizefirst" 
				[matTooltipShowDelay]="500" 
				matTooltipPosition="above">
				<mat-icon [ngClass]="{ 'selected': isPersonalEmailPreferredControl.value }">stars</mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
</div>