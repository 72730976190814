import { Component, HostListener, OnInit } from '@angular/core';
import { GuardsCheckEnd, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Token } from '@core/models';
import { HelpAndFeedback } from '@core/models/help-and-feedback/help-and-feedback.model';
import { AuthService, GlobalLoaderService, IdleTimerService, LayoutService, SysTextService } from '@core/services';
import { HelpAndFeedbackService } from '@core/services/help-and-feedback.service';
import { indicate } from '@shared/helpers';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { CaseCommunicationDrawer } from '@core/models/case-communication/case-communication-drawer.model';
import { CaseCommunicationStoreService } from '@core/services/case-communication.store.service';
import { TearDownService } from '@core/services/tear-down.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:mousedown', ['$event'])

  onEvent(event: KeyboardEvent | MouseEvent): void {
    if (!this.idleService.dialogIsOpen) {
      this.idleService.resetTimer();
    }
  }

  public isAuth$: Observable<Token>;
  public isHandheld$: Observable<boolean>;
  public showLoadSpinner: boolean;
  public drawerOpen: boolean = false;
  public helpIsLoading$: Subject<boolean> = new Subject<boolean>();
  public caseComIsLoading$: Subject<boolean> = new Subject<boolean>();
  public caseId: number;
  public caseComDrawer$: Observable<CaseCommunicationDrawer>;
  public helpData$: Observable<{
    sysText: any;
    helpInfo: HelpAndFeedback;
  }>;

  constructor(
    private authService: AuthService,
    private layoutService: LayoutService,
    private router: Router,
    private idleService: IdleTimerService,
    private globalLoader: GlobalLoaderService,
    private helpService: HelpAndFeedbackService,
    private ccService: CaseCommunicationService,
    private ccStore: CaseCommunicationStoreService,
    private textService: SysTextService,
    private titleService: Title,
    private tearDownService: TearDownService
  ) {
    this.globalLoader.isLoading$.pipe(tap((res) => (this.showLoadSpinner = res))).subscribe();
    
    if (environment.debugBannerTitle.length > 0)
      this.titleService.setTitle(this.titleService.getTitle() + " " + environment.debugBannerTitle);
  }

  ngOnInit() {
    this.isAuth$ = this.authService.isAuth$;
    this.isHandheld$ = this.layoutService.isHandheld$();
    this.router.events.subscribe((event) => {
      if ((event instanceof NavigationStart || event instanceof GuardsCheckEnd) && !this.ccStore.ccIsEditing) {
        this.globalLoader.show();
        this.drawerOpen = false;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.globalLoader.hide();
      }
    });

    this.tearDownService.tearDownTrigger$.subscribe(res => {
      if (res) {
        this.clearData();
        this.drawerOpen = false;
      }
    });
  }

  public onOpenedChange(event: boolean) {
    this.drawerOpen = event;
  }

  public setCaseId(event) {
    this.caseId = event;
  }

  public toggleHelp(): void {
    this.clearData();
    if (!this.drawerOpen) {
      const sysText$ = this.textService.getSysText('helpAndFeedback');
      const helpInfo$ = this.helpService.getHelpAndFeedback();
      this.helpData$ = combineLatest([sysText$, helpInfo$]).pipe(
        indicate(this.helpIsLoading$),
        map(([sysText, helpInfo]) => {
          return { sysText, helpInfo };
        }),
        tap(() => {
          this.drawerOpen = true;
        })
      );
    } else {
      this.drawerOpen = false;
    }
  }

  public toggleCaseCom(caseId: number): void {
    this.clearData();
    if (!this.drawerOpen) {
      const sysText$ = this.textService.getSysText(this.ccStore.templates);
      const caseComInfo$ = this.ccService.getCaseCommunicationDrawer(caseId);
      this.caseComDrawer$ = combineLatest([sysText$, caseComInfo$]).pipe(
        indicate(this.caseComIsLoading$),
        map(([sysText, caseComInfo]) => {
          this.ccStore.sysText = sysText
          this.ccStore.caseComDrawer = caseComInfo
          return caseComInfo;
        }),
        tap(() => {
          this.drawerOpen = true;
        })
      );
    } else {
      this.drawerOpen = false;
    }
  }

  private clearData(): void {
    this.caseComDrawer$ = null;
    this.helpData$ = null;
  }
}