import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { ApplicableStatesDialog, ApplicableStatesPost } from '@core/models/leave-admin/employers/applicable-states.model';
import { EmployerProfile } from '@core/models/leave-admin/employers/employer-profile.model';
import { StateSelectionItem } from '@core/models/leave-admin/employers/state-abbreviation.model';
import { ErrorService, LayoutService } from '@core/services';
import { EmployerProfileStoreService } from '@core/services/employer-profile-store.service';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { takeUntil } from 'rxjs/operators';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-applicable-states',
  templateUrl: './edit-applicable-states.component.html',
  styleUrls: ['./edit-applicable-states.component.scss']
})
export class EditApplicableStatesComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  
  public form: UntypedFormGroup;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public selectionOptions: StateSelectionItem[] = [];

  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get additionalStates(): FormControl {
    return this.form.get('additionalStates') as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      employerProfile: EmployerProfile,
      applicableStatesInfo: ApplicableStatesDialog,
      sysText: any
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditApplicableStatesComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    private service: ManageEmployersService,
    private store: EmployerProfileStoreService,
  ) { 
    super(dialogRef);
    
    this.form = this.fb.group({
      headquartersState: [null],
      additionalStates: [null]
    });
  }

  ngOnInit(): void {
    if (this.data.employerProfile.modelStateErrors.hasOwnProperty('applicableStates')){
      this.form.controls.headquartersState.addValidators(Validators.required)
      this.form.controls.additionalStates.addValidators(Validators.required)
      this.form.controls.headquartersState.markAsTouched()
      this.form.controls.additionalStates.markAsTouched()
    }
    
    this.data.applicableStatesInfo.stateSelectionList.forEach(state => {
      if (state.stateId !== this.data.employerProfile.headquartersState?.stateId) {
        this.selectionOptions.push(state)
      }
    })

    this.form.patchValue({
      headquartersState: this.data.employerProfile.headquartersState?.stateId
    })
    this.patchAdditionalStates();
    this.formInitValues = { ...this.form.value };
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.formChangeEmitted && !this.noChanges) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onHqSelectChange(stateId: number) {
    let newOptions = [];
    this.data.applicableStatesInfo.stateSelectionList.forEach(state => {
      if (state.stateId !== stateId) {
        newOptions.push(state)
      }
    })
    this.selectionOptions = newOptions;

    if (this.additionalStates.value.includes(stateId)) {
      let index = this.additionalStates.value.indexOf(stateId)
      this.additionalStates.value.splice(index, 1)
    }
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  public onSubmit() {
    const dto: ApplicableStatesPost = {
      employerId: this.data.employerProfile.employerId,
      headquartersStateId: this.form.controls.headquartersState.value,
      additionalStateIds: this.form.controls.additionalStates.value?.length > 0 ? this.form.controls.additionalStates.value : null
    };
    this.service.postApplicableStates(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        this.store.employerProfile = res;
        this.dialogRef.close();
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  private patchAdditionalStates() {
    let additionalStateIds: number[] = [];
    this.data.applicableStatesInfo.stateSelectionList.forEach(as => {
      if (as.selected) {
        additionalStateIds.push(as.stateId)
      }
    })
    this.form.controls.additionalStates.patchValue(additionalStateIds)
  }

  public getStateAbbr(stateId: number) {
    let stateAbbr = this.data.applicableStatesInfo.stateSelectionList.find(as => as.stateId == stateId);
    
    if (stateAbbr != null)
      return stateAbbr.abbreviation;

    return null;
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.store.sysText.unsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }
}
