import { Injectable } from '@angular/core';
import { WorkScheduleDetailsStore, WorkScheduleDetails} from '@core/models/work-schedules/work-schedule-details.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkScheduleDetailsStoreService {

  private readonly _initialStoreData: WorkScheduleDetailsStore = {
    workScheduleDetails: null,
    sysText: null
  };

  private workScheduleDetailsStore: BehaviorSubject<WorkScheduleDetailsStore>;  

  private store$: Observable<WorkScheduleDetailsStore>;

  private readonly templateNames = {
		workScheduleDetails: 'workScheduleDetails',
    deleteConfirmation: "deleteConfirmation",
    leavingPage: "leavingPage",
    unsavedChanges: "unsavedChanges",
  };

  constructor() { 
    this.workScheduleDetailsStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.workScheduleDetailsStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.workScheduleDetailsStore.value;
  }
  
  public get workScheduleDetails$(): Observable<WorkScheduleDetails> {
    return this.store$.pipe(pluck('workScheduleDetails'));
  }

  public get workScheduleDetails() {
    return this.store.workScheduleDetails;
  }

  public set workScheduleDetails(workScheduleDetails: WorkScheduleDetails) {
    const store = { ...this.store, workScheduleDetails };
    this.workScheduleDetailsStore.next(store);
  }
  
  public get sysText() {
    return this.store.sysText;
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.workScheduleDetailsStore.next(store);
  }

  public workScheduleAssignedToEmployer(assigned: boolean) {
    this.store.workScheduleDetails.assignedToEmployer = assigned;
  }

  public unloadStore() {
    const store: WorkScheduleDetailsStore = {
      workScheduleDetails: null,
      sysText: null
    }
    this.workScheduleDetailsStore.next(store)
  }
}
