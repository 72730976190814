<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6>{{ data.sysText.spreadLeaveHours_title }}</h6>
  <ng-container *ngIf="isHandheld$ | async">
    <span class="spacer"></span>
    <mat-icon 
      role="button" 
      (click)="cancel()" 
      class="icon__button">
      close
    </mat-icon>
  </ng-container>
</div>

<mat-dialog-content class="mat-dialog-content">
  <form [formGroup]="form">
    <div class="dialog-section">
      <mat-icon class="field-icon">today</mat-icon>
      <p class="grey1">{{ data.sysText.dateSection }}</p>
      <mat-icon
        class="info-icon"
        [matTooltip]="data.sysText.spreadLeaveHours_info" 
        [matTooltipShowDelay]="500" 
        matTooltipPosition="before">
        info_outline
      </mat-icon>
    </div>
    <app-date-field
      class="half-column"
      [group]="form"
      [control]="form.controls.startDate"
      [label]="data.sysText.startDate">
    </app-date-field>
    <app-date-field
      class="half-column"
      [group]="form"
      [control]="form.controls.endDate"
      [label]="data.sysText.endDate">
    </app-date-field>
    <div class="dialog-section">
      <mat-icon class="field-icon">schedule</mat-icon>
      <p class="grey1">{{ data.sysText.daysSection }}</p>
      <mat-icon
        class="info-icon"
        [matTooltip]="data.sysText.daysSection_info" 
        [matTooltipShowDelay]="500" 
        matTooltipPosition="before">
        info_outline
      </mat-icon>
    </div>
    <div class="toggle-section">
      <div class="toggle-container">
        <app-slide-toggle
          [control]="form.controls.useWorkSchedule"
          [label]="data.sysText.useWorkSchedule"
          [capitalizeLabel]="false">
        </app-slide-toggle>
      </div>
    </div>
    <app-input-field
      [label]="data.sysText.averageHoursPerWeek"
      [removeLabelCasePipe]="true"
      [control]="leaveDaysGroup.controls.averageHoursPerWeek"
      [inputType]="'number'"
      [retainDefaultDisabled]="true">
    </app-input-field>
    <div class="checkbox-section">
      <div class="checkbox">
          <p class="body2">{{this.data.sysText.sunday}}</p>
          <mat-checkbox
            [formControl]="leaveDaysGroup.controls.sundayLeave">
          </mat-checkbox>
      </div>
      <div class="checkbox">
          <p class="body2">{{this.data.sysText.monday}}</p>
          <mat-checkbox
            [formControl]="leaveDaysGroup.controls.mondayLeave">
          </mat-checkbox>
      </div>
      <div class="checkbox">
          <p class="body2">{{this.data.sysText.tuesday}}</p>
          <mat-checkbox
            [formControl]="leaveDaysGroup.controls.tuesdayLeave">
          </mat-checkbox>
      </div>
      <div class="checkbox">
          <p class="body2">{{this.data.sysText.wednesday}}</p>
          <mat-checkbox
            [formControl]="leaveDaysGroup.controls.wednesdayLeave">
          </mat-checkbox>
      </div>
      <div class="checkbox">
          <p class="body2">{{this.data.sysText.thursday}}</p>
          <mat-checkbox
            [formControl]="leaveDaysGroup.controls.thursdayLeave">
          </mat-checkbox>
      </div>
      <div class="checkbox">
          <p class="body2">{{this.data.sysText.friday}}</p>
          <mat-checkbox
            [formControl]="leaveDaysGroup.controls.fridayLeave">
          </mat-checkbox>
      </div>
      <div class="checkbox">
          <p class="body2">{{this.data.sysText.saturday}}</p>
          <mat-checkbox
            [formControl]="leaveDaysGroup.controls.saturdayLeave">
          </mat-checkbox>
      </div>
    </div>
    <div class="dialog-section">
      <mat-icon class="field-icon">tune</mat-icon>
      <p class="grey1">{{data.sysText.spreadHoursOptions}}</p>
      <mat-icon
        class="info-icon"
        [matTooltip]="data.sysText.additionalOptions_info" 
        [matTooltipShowDelay]="500" 
        matTooltipPosition="before">
        info_outline
      </mat-icon>
    </div>
    <div class="toggle-section">
      <div class="toggle-container">
        <app-slide-toggle 
          [control]="form.controls.removeExistingHours"
          [label]="data.sysText.removeExistingHours"
          [capitalizeLabel]="false">
        </app-slide-toggle>
      </div>
      <div class="toggle-container">
        <app-slide-toggle 
          [control]="form.controls.removeOnlySpreadPeriod"
          [label]="data.sysText.removeOnlySpreadPeriod"
          [capitalizeLabel]="false">
        </app-slide-toggle>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
  <button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSubmitting$ | async" *ngIf="!(isHandheld$ | async)">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" color="accent" (click)="onSubmit()">
    {{ data.sysText.submit }}
  </button>
</mat-dialog-actions>

<ng-template #submitting>
  <mat-dialog-actions align="end">
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>