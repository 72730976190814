<ng-container *ngIf="!showVerification">
  <div [ngClass]="{formpadding: anonymousNewRequest.available}">
    <app-account-form
      [sysText]="sysText$ | async"
      [options]="options"
      [page]="templateName"
      [isSubmitting]="isSubmitting$ | async"
      [usernameOrEmail]="username"
      (formSubmit)="onFormSubmit($event)"
    ></app-account-form>  
  </div>

  <app-anonymous-option
    *ngIf="anonymousNewRequest.available"
    [sysText]="sysText$ | async"
  ></app-anonymous-option>
</ng-container>

<ng-container *ngIf="showVerification">
  <app-verification-form
    [sysText]="sysText$ | async"
    [options]="verificationOptions"
    [selectedProviders] ="selectedProviders"
    [page]="templateName"
    [otpDest]="otpDest"   
    [otpIsPhone]="otpIsPhone"  
    [isSubmitting]="isSubmitting$ | async"
    (formSubmit)="onVerificationSubmit($event)"
    (sendCode)="handleSendCode($event)"
    (goBack)="onGoBack()"
  ></app-verification-form>
</ng-container>