import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {
  // #region Properties (12)

  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Input() public hideIconSpace: boolean;
  @Input() public hint: string;
  @Input() public icon: string;
  @ViewChild('input') public input: MatInput;
  @Input() public inputType: string = 'text';
  @Input() public isCurrency: number;
  @Input() public isHintHighlighted: boolean = true;
  @Input() public isHintWarning: boolean = false;
  @Input() public label: string;
  @Input() public minNumAllowed = 0;
  @Input() public patternError: string;
  @Input() public msErrorKey: string;
  @Input() public customMsError: string;
  @Input() public removeLabelCasePipe: boolean = false;
  @Input() public customRequiredMessage: string;
  @Input() public info: string;
  @Input() public retainDefaultDisabled: boolean = false;
  @Output() emitInputChange = new EventEmitter<string>();
  public sysText: any;
  public _isDisabled: boolean;

  // #endregion Properties (12)

  // #region Constructors (1)

  constructor(private text: SharedComponentsSysTextService) {}

  // #endregion Constructors (1)

  // #region Public Methods (1)

  @Input() public set isDisabled(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
    this._isDisabled = isDisabled;
  }

  public ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.inputField];
  }

  onEmitInputChange(event: any) {
    this.emitInputChange.emit(event.target.value);
  }

  // #endregion Public Methods (1)
}
