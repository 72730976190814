import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPLOYEESWITHROLES_SELECTIONOPTIONS } from '@core/mocks';
import { EMPLOYEESWITHROLES, ROLES } from '@core/mocks/role-admin';
import { Role, SelectionOption } from '@core/models';
import { EmployeeRole } from '@core/models/role-admin/employee-role.model';
import { RoleAdminDetails } from '@core/models/role-admin/role-admin-details.model';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class RoleAdminService {
  private apiUrl: string;
  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.apiUrl = this.constants.RA_API;
  }

  public getRoleAdminDetails(): Observable<RoleAdminDetails> {
    return this.http.get<RoleAdminDetails>(`${this.apiUrl}/getRoleAdminDetails`);
    // return of(ROLEADMINDETAILS);
  }

  public getEmployeesSelectionOptions(): Observable<SelectionOption[]> {
    return this.http.get<SelectionOption[]>(`${this.apiUrl}/getEmployeesSelectionOptions`);
    // return of(EMPLOYEESWITHROLES_SELECTIONOPTIONS);
  }

  public getRolesList(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.apiUrl}/getRolesList`);
    // return of(ROLES);
  }

  public addEmployeeRole(eeRole: EmployeeRole): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/addEmployeeRole`, eeRole);
    // return of(null).pipe(delay(3000));
  }

  public removeEmployeeRole(eeRole: EmployeeRole): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/removeEmployeeRole`, eeRole);
    // return of(null).pipe(delay(3000));
  }
}
