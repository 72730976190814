import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnonymousNewRequestPage } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { AnonymousRequestsStoreService } from '@core/services/anonymous-requests-store.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-anonymous-new-request',
  templateUrl: './anonymous-new-request.component.html',
  styleUrls: ['./anonymous-new-request.component.scss']
})
export class AnonymousNewRequestComponent implements OnInit, OnDestroy {
  sysText: any;
  anonymousNewRequestPage$: Observable<AnonymousNewRequestPage>;

  constructor(
    private store: AnonymousRequestsStoreService,
  ) { }

  ngOnInit(): void {
    this.sysText = this.store.sysText;
    this.anonymousNewRequestPage$ = this.store.anonymousRequestPage$;
  }

  ngOnDestroy(): void {
    this.store.unloadStore();
  }

}
