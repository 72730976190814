export enum LeaveCategory {
    Continuous = -1,
    Intermittent = -2,
    Unprotected = -3,
    ADA = -4    
}

export enum LeaveTab {
    All = 1,
    Closed = 2,
    New = 3,
    Open = 4,
    Hidden = 5
}