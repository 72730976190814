<mat-card appearance="raised" class="anonymous-request--container mat-elevation-z12">
  <ng-container *ngIf="!(hasSubmitted$ | async); else hasSubmitted">
    <div class="anonymous-request--header">
      <h5 class="anonymous-request--title">{{sysText.login_anonymousRequestTitle}}</h5>
      <p class="body2 anonymous-request--subtitle">{{sysText.login_anonymousRequestSubtitle1}}</p>
      <p class="body2 anonymous-request--subtitle">{{sysText.login_anonymousRequestSubtitle2}}</p>
    </div>
    <mat-card-content>
    <button
      type="submit"
      mat-flat-button
      color="accent"
      class="button anonymous-request--button"
      (click)="onAnonRequestNavigate()"
    >
      {{sysText.login_anonymousRequestButton}}
    </button>
    </mat-card-content>
  </ng-container>
  <ng-template #hasSubmitted>
    <mat-card-content>
      <h5 class="anonymous-request--title">{{sysText.login_submittedSuccessfully}}</h5>
      <p class="body2 anonymous-request--subtitle">{{sysText.login_requestSubmitted}}</p>
    </mat-card-content>
  </ng-template>
</mat-card>