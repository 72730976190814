import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployeeWithRoles } from '@core/models';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-view-employee-roles',
  templateUrl: './view-employee-roles.component.html',
  styleUrls: ['./view-employee-roles.component.scss']
})
export class ViewEmployeeRolesComponent extends DialogDragConstraints implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: { 
      showEmployer: boolean, 
      ee: EmployeeWithRoles, 
      sysText: any;
    },
    private dialogRef: MatDialogRef<ViewEmployeeRolesComponent>,
  ) { 
    super(dialogRef);
  }

  ngOnInit(): void {
  }

}
