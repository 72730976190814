import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionOption } from '@core/models';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
})
export class SelectFieldComponent implements OnInit {
  // #region Properties (8)

  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Input() public hint: string;
  @Input() public showHintArea: boolean = true;
  @Input() public icon: string;
  @Input() public hideIconSpace: boolean;
  @Input() public isHintHighlighted: boolean = true;
  @Input() public isHintWarning: boolean = false;
  @Input() public label: string;
  @Input() public shouldTitlecaseLabel: boolean = true;
  @Input() public options: SelectionOption[];
  @Input() public shouldCapitalize: boolean = true;
  @Input() public showBlankOption: boolean = false;
  @Input() public info: string;
  @Input() public msErrorKey: string;
  @Input() public customMsError: string;
  @Input() public showSpinner: boolean = false;
  @Output() emitSelectionChange = new EventEmitter<number>();
  public sysText: any;

  // #endregion Properties (8)

  // #region Constructors (1)

  constructor(private text: SharedComponentsSysTextService) {}

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.selectField];
  }

  onEmitSelectionChange(value: number) {
    this.emitSelectionChange.emit(value)
  }

  // #endregion Public Methods (1)
}
