import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MergeDuplicatesEmployee } from '@core/models/leave-admin/employees/merge-duplicates-data.model';
import { MergeDuplicatesGet, MergeDuplicatesPost } from '@core/models/leave-admin/employees/merge-duplicates-form.model';
import { ErrorService, LayoutService } from '@core/services';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { UpdateConfirmationComponent } from '../update-confirmation/update-confirmation.component';
import { StiiraError } from '@core/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-review-merge',
  templateUrl: './review-merge.component.html',
  styleUrls: ['./review-merge.component.scss']
})
export class ReviewMergeComponent extends DialogDragConstraints implements OnInit {
  public form: FormGroup;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      employeeInfo: MergeDuplicatesEmployee; 
      dto: MergeDuplicatesGet,
      sysText: any;
      updateConfSysText: any;
      calendarUpdateConfSysText: any;
    },
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<ReviewMergeComponent>,
    private service: ManageEmployeesService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private fb: FormBuilder,
  ) { 
    super(dialogRef);
    
    //empty form in order to display form errors
    this.form = this.fb.group({})
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onConfirm(overrideUpdateConf: boolean = false): void {
    const dto: MergeDuplicatesPost = {
      overrideUpdateConf: overrideUpdateConf,
      primaryEmployee: this.data.dto.primaryEmployee,
      secondaryEmployee: this.data.dto.secondaryEmployee
    }
    this.service.postConfirmMerge(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(res => {
        if (res.showUpdateConfirmationDialog) {
          this.openUpdateConfirmationDialog(res as UpdateConfirmationDialog);
        } else {
          this.dialogRef.close(res)
        }
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  private openUpdateConfirmationDialog(updateConfirmationDialog: UpdateConfirmationDialog): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: "mat-dialog-container-mobileWidth",
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.updateConfSysText,
        updateConfirmationDialog: updateConfirmationDialog
      }
    };

    this.dialog.open(UpdateConfirmationComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.onConfirm(true);
        }
      });
  }
}
