import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { WorkSchedulesDashboard, WorkSchedulesDashboardForm, WorkSchedulesDashboardStore } from '@core/models/work-schedules/work-schedules.model';
import { noChangesReplacer } from '@shared/helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkSchedulesDashboardStoreService {

  private readonly _initialStoreData: WorkSchedulesDashboardStore = {
    workSchedules: null,
    sysText: null,
    selectedIndex: null,
    workSchedulesTab: {
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<WorkSchedulesDashboardForm>({
        searchValue: this.fb.control(null),
        employer: this.fb.control(null),
      })
    },
    nonWorkdayListsTab: {
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<WorkSchedulesDashboardForm>({
        searchValue: this.fb.control(null),
        employer: this.fb.control(null)
      })
    }
  };

  private workSchedulesDashboardStore: BehaviorSubject<WorkSchedulesDashboardStore>;  

  private store$: Observable<WorkSchedulesDashboardStore>;

  private readonly templateNames = {
    workSchedules: 'workSchedules',
    unsavedChanges: "unsavedChanges",
    leavingPage: "leavingPage",
    deleteConfirmation: "deleteConfirmation"
  };

  constructor(private fb: FormBuilder) { 
    this.workSchedulesDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.workSchedulesDashboardStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.workSchedulesDashboardStore.value;
  }
  
  public get workSchedules$(): Observable<WorkSchedulesDashboard> {
    return this.store$.pipe(pluck('workSchedules'));
  }

  public get workSchedules() {
    return this.store.workSchedules;
  }

  public set workSchedules(workSchedules: WorkSchedulesDashboard) {
    const store = { ...this.store, workSchedules };
    this.workSchedulesDashboardStore.next(store);
  }
  
  public get sysText() {
    return this.store.sysText;
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.workSchedulesDashboardStore.next(store);
  }

  public workScheduleAssignedToEmployer(scheduleId: number, assigned: boolean) {
    this.store.workSchedules.workScheduleItems.find(et => et.id === scheduleId).assignedToEmployer = assigned;
  }

  public get selectedIndex() {
    return this.store.selectedIndex;
  }

  public set selectedIndex(selectedIndex: number) {
    this.store.selectedIndex = selectedIndex;
  }

  // work schedules tab

  public get workSchedulesTabForm(): FormGroup<WorkSchedulesDashboardForm> {
    return this.store.workSchedulesTab.dashForm;
  }

  get workSchedulesTabFormNoChanges(): boolean {
    return JSON.stringify(this.workSchedulesTabForm.value, noChangesReplacer) === JSON.stringify(this.store.workSchedulesTab.formInitValues, noChangesReplacer);
  }

  public set workSchedulesTabSortData(sortData: Sort) {
    this.store.workSchedulesTab.matSortData = sortData;
  }

  public get workSchedulesTabSortData(): Sort {
    return this.store.workSchedulesTab.matSortData;
  }

  public set workSchedulesTabPageData(pageData: PageEvent) {
    this.store.workSchedulesTab.pageData = pageData;
  }

  public get workSchedulesTabPageData(): PageEvent {
    return this.store.workSchedulesTab.pageData;
  }

  // non-workday lists tab

  public get nonWorkdayListsTabForm(): FormGroup<WorkSchedulesDashboardForm> {
    return this.store.nonWorkdayListsTab.dashForm;
  }

  get nonWorkdayListsTabFormNoChanges(): boolean {
    return JSON.stringify(this.nonWorkdayListsTabForm.value, noChangesReplacer) === JSON.stringify(this.store.nonWorkdayListsTab.formInitValues, noChangesReplacer);
  }

  public set nonWorkdayListsTabSortData(sortData: Sort) {
    this.store.nonWorkdayListsTab.matSortData = sortData;
  }

  public get nonWorkdayListsTabSortData(): Sort {
    return this.store.nonWorkdayListsTab.matSortData;
  }

  public set nonWorkdayListsTabPageData(pageData: PageEvent) {
    this.store.nonWorkdayListsTab.pageData = pageData;
  }

  public get nonWorkdayListsTabPageData(): PageEvent {
    return this.store.nonWorkdayListsTab.pageData;
  }

  public unloadStore() {
    const store: WorkSchedulesDashboardStore = {
      workSchedules: null,
    sysText: null,
    selectedIndex: null,
    workSchedulesTab: {
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<WorkSchedulesDashboardForm>({
        searchValue: this.fb.control(null),
        employer: this.fb.control(null),
      })
    },
    nonWorkdayListsTab: {
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<WorkSchedulesDashboardForm>({
        searchValue: this.fb.control(null),
        employer: this.fb.control(null)
      })
    }
    }
    this.workSchedulesDashboardStore.next(store)
  }
}
