import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EmailTemplates } from '@core/models/case-communication/email-templates.model';
import { appRoutePaths } from '@core/routes/route-paths.constants';
import { SysTextService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { EmailTemplatesDashboardStoreService } from '@core/services/email-templates-dashboard-store.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesResolver  {
  constructor(
    private caseCommunicationService: CaseCommunicationService,
    private textService: SysTextService, 
    private storeService: EmailTemplatesDashboardStoreService,
    private router: Router,
    ){}

resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmailTemplates> {
    const emailTemplatesHttpData$ = this.caseCommunicationService.getEmailTemplates();
    const sysText$ = this.textService.getSysText(this.storeService.templates);

    return combineLatest([emailTemplatesHttpData$, sysText$]).pipe(
      tap(([emailTemplatesHttpData, sysText]) => {
        if (!emailTemplatesHttpData && !this.router.navigated) {
          this.router.navigate([appRoutePaths.HOME]);
        }
        this.storeService.sysText = sysText;
      }),
      map(([emailTemplatesHttpData]) => {
        return emailTemplatesHttpData;
      }),
      filter((res) => !!res),
      tap((res) => {
        this.storeService.emailTemplates = res;
      }),
      catchError(() => {
        this.router.navigate([appRoutePaths.HOME]);
        return of(null);
      })
    );
  }
}
