import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-indictor-circle',
  templateUrl: './progress-indictor-circle.component.html',
  styleUrls: ['./progress-indictor-circle.component.scss'],
})
export class ProgressIndictorCircleComponent implements OnInit {
  @Input() progress: number;
  @Input() showPercentage: boolean = false;
  @Input() isComplete: boolean = false;

  completePercent = 100;
  constructor() {}

  ngOnInit(): void {}
}
