<mat-tab-group
	class="tabs"
	color="accent"
	id="leave-tab-group"
	[selectedIndex]="selectedIndex"
	(selectedTabChange)="changeTab($event)"
	[dynamicHeight]="true">
	<mat-tab>
		<ng-template mat-tab-label>
		{{ sysText.emailTemplates.emailTemplates_header_title | uppercase }}
		</ng-template>
		<app-email-templates-table
			[isMobile]="isMobile$ | async"
			[emailTemplatesData]="emailTemplates$ | async"
			[sysText]="sysText.emailTemplates"
			(createNew)="onCreateNew()"
			(onDuplicate)="onDuplicate($event)"
			[isLoadingNewTemplateDialog]="isLoadingNewTemplateDialog$ | async">
		</app-email-templates-table>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>
		{{ sysText.templateDocCategories.templateDocCategories_title | uppercase }}
		</ng-template>
		<app-document-categories-tab
			[isMobile]="isMobile$ | async"
			[emailTemplatesData]="emailTemplates$ | async"
			[sysText]="sysText.templateDocCategories"
			[unsavedChangesSysText]="sysText.unsavedChanges">
		</app-document-categories-tab>
	</mat-tab>
</mat-tab-group>