import { Component, Input, OnInit } from '@angular/core';
import { MergeDuplicatesEmployee } from '@core/models/leave-admin/employees/merge-duplicates-data.model';

@Component({
  selector: 'app-merge-duplicates-card',
  templateUrl: './merge-duplicates-card.component.html',
  styleUrls: ['./merge-duplicates-card.component.scss']
})
export class MergeDuplicatesCardComponent implements OnInit {
  @Input() employeeInfo: MergeDuplicatesEmployee;
  @Input() sysText: any;
  @Input() isPrimary: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public getInitials(nameString: string) {
    //remove quotes in case there is a nickname in the nameString (and no firstname)
    nameString = nameString.replace(/["]/g, '');
    const fullName = nameString.split(', ');
    const initials = fullName.pop().charAt(0) + (fullName.length > 0 ? fullName.shift().charAt(0) : "");
    return initials.toUpperCase();
  }

}
