<ng-container [formGroup]="group" *ngIf="group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>

<ng-container *ngIf="!group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>

<ng-template #formField>
  <div class="container">
    <mat-icon *ngIf="!hideIconSpace">{{ icon }}</mat-icon>
    <mat-form-field color="accent">
      <mat-spinner matSuffix [diameter]="20" color="accent" class="suffix-icon" *ngIf="showSpinner"></mat-spinner>
      <mat-label *ngIf="shouldTitlecaseLabel">{{ label | titlecase }}</mat-label>
      <mat-label *ngIf="!shouldTitlecaseLabel">{{ label }}</mat-label>
      <mat-select #select="matSelect" [formControl]="control" (selectionChange)="onEmitSelectionChange($event.value)" disableOptionCentering panelClass="dropDownSelect">
        <mat-option *ngIf="showBlankOption" [value]="null"></mat-option>
        <mat-option *ngFor="let option of options" [value]="option.id">
          <span *ngIf="shouldCapitalize">{{ option.description | capitalizefirst }}</span>
          <span *ngIf="!shouldCapitalize">{{ option.description }}</span>
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="showHintArea" [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }">{{ hint }}</mat-hint>
      <mat-error *ngIf="control.errors?.msError">{{ control.errors?.msError == msErrorKey ? customMsError : control.errors?.msError }}</mat-error>
      <mat-error *ngIf="control.errors?.required"> {{ sysText.required | capitalizefirst }}</mat-error>
    </mat-form-field>
    <mat-icon *ngIf="info" [matTooltip]="info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
  </div>
</ng-template>
