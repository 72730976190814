import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent extends DialogDragConstraints implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) 
    public data: { 
      sysText: any,
      deleteConfirm2: string
    },
    private dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    ) {
    super(dialogRef);
   }

  ngOnInit(): void {}
}
