import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { appRoutePaths } from '@core/routes/route-paths.constants';
import { SysTextService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { TemplateDetailsStoreService } from '@core/services/template-details-store.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateDetailsResolver  {
  constructor(
    private caseCommunicationService: CaseCommunicationService, 
    private textService: SysTextService, 
    private storeService: TemplateDetailsStoreService,
    private router: Router,
    ){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TemplateDetails> {
    const templateDetailsHttpData$ = this.caseCommunicationService.getTemplateDetails(route.paramMap.get('templateId') != null ? parseInt(route.paramMap.get('templateId')) : null);
    const sysText$ = this.textService.getSysText(this.storeService.templates);

    return combineLatest([templateDetailsHttpData$, sysText$]).pipe(
      tap(([templateDetailsHttpData, sysText]) => {
        if (!templateDetailsHttpData && !this.router.navigated) {
          this.router.navigate([appRoutePaths.HOME]);
        }
        this.storeService.sysText = sysText;
      }),
      map(([templateDetailsHttpData, sysText]) => {
        return templateDetailsHttpData;
      }),
      filter((res) => !!res),
      tap((res) => {
        this.storeService.templateDetails = res;
      }),
      catchError(() => {
        this.router.navigate([appRoutePaths.HOME]);
        return of(null);
      })
    );
  }
}
