import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { SidenavCopyrightComponent } from './components/sidenav/sidenav-copyright/sidenav-copyright.component';
import { SidenavEmployeeInfoComponent } from './components/sidenav/sidenav-employee-info/sidenav-employee-info.component';
import { SidenavListItemComponent } from './components/sidenav/sidenav-list-item/sidenav-list-item.component';
import { SidenavListComponent } from './components/sidenav/sidenav-list/sidenav-list.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { HelpComponent } from './components/help/help.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    SidenavEmployeeInfoComponent,
    SidenavListComponent,
    SidenavListItemComponent,
    SidenavCopyrightComponent,
    HelpComponent
  ],
  imports: [SharedModule],
  exports: [HeaderComponent, SidenavComponent, HelpComponent]
})
export class LayoutModule {}
