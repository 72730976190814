import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StatusChips } from '@core/models';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { ConstantsService, LayoutService } from '@core/services';
import { EditEmailContentComponent } from '@modules/dialogs/edit-email-content/edit-email-content.component';
import { insertPlaceholderChips } from '@shared/helpers';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { Editor } from 'tinymce';

@Component({
  selector: 'app-template-email-content',
  templateUrl: './template-email-content.component.html',
  styleUrls: ['./template-email-content.component.scss']
})
export class TemplateEmailContentComponent implements OnInit {
  @Input() templateDetails: TemplateDetails;
  @Input() sysText: any;
  @Input() unsavedChangesSysText: any;
  @Output() isEditing = new EventEmitter<boolean>();

  @ViewChild('bodyEditor') bodyEditor: EditorComponent;

  public panelOpenState: boolean = true;
  public editorStyle: string;
  public chipStyles: StatusChips;

  constructor(
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private constants: ConstantsService, 
  ) { }

  ngOnInit(): void {
    this.editorStyle = this.constants.EDITOR_STYLES;
    this.chipStyles = this.constants.STAT_CHIP_STYLES;
    this.templateDetails.subjectHtml = insertPlaceholderChips(this.templateDetails.subjectHtml, this.constants.EDITOR_CHIP, this.templateDetails.placeholderOptions);
    this.templateDetails.bodyHtml = insertPlaceholderChips(this.templateDetails.bodyHtml, this.constants.EDITOR_CHIP, this.templateDetails.placeholderOptions);
  }

  public tinySetup(editor: Editor): void {
    editor.mode.set("readonly");
  }

  public editEmailContent(event: any): void {
    event.stopPropagation();
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '600px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      maxHeight: this.layoutService.isHandheld ? '85vh' : '',
      height: this.layoutService.isHandheld ? '85vh' : '',
      data: {
        templateDetails: this.templateDetails,
        placeholderOptions: this.templateDetails.placeholderOptions,
        sysText: this.sysText,
        unsavedChangesSysText: this.unsavedChangesSysText
      }
    };
    
    const dialogRef = this.dialog.open(EditEmailContentComponent, dialogConfig);
    dialogRef.componentInstance.isEditing.subscribe((res) => {
        this.isEditing.emit(res);
      });
    dialogRef.beforeClosed().subscribe((res: TemplateDetails) => {
      this.isEditing.emit(false);
      if (res?.bodyHtml) {
        this.setContent(res.bodyHtml); //update to force body editor to resize
      }
    });
  }

  private setContent(content: string): void {
    // sometimes the body editor does not auto resize, setContent forces it to 
    this.bodyEditor.editor.setContent(content);
  }
}
