import { Component, OnInit, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { RecipientBoxChip, RecipientsFormGroup, SendNewEmailDialog } from '@core/models/case-communication/send-new-email-dialog.model';
import { FormGroup } from '@angular/forms';

enum ChipType {
  RelevantContact = 1,
  Contact = 2,
  AdditionalEmail = 3
}

export enum RecipientType {
  recipients = 1,
  cc = 2,
  bcc = 3
}

@Component({
  selector: 'app-add-recipients',
  templateUrl: './add-recipients.component.html',
  styleUrls: ['./add-recipients.component.scss']
})
export class AddRecipientsComponent implements OnInit {
  @Input() formGroup: FormGroup<RecipientsFormGroup>;
  @Input() sysText: any;
  @Input() boxLabel: any;
  @Input() dialogData: SendNewEmailDialog;
  @Input() recipientType: RecipientType;

  public recipientsBox: RecipientBoxChip[] = [];
  private prevSelectedRelCon: number[] = [];
  private chipType = ChipType;
  private recipientTypes = RecipientType;

  constructor() { }

  ngOnInit(): void {
    if (this.dialogData.automaticCcAddresses && this.recipientType === this.recipientTypes.cc) {
      this.autoAddContactChips(this.dialogData.automaticCcAddresses)
      
    } else if (this.dialogData.automaticBccAddresses && this.recipientType === this.recipientTypes.bcc) {
      this.autoAddContactChips(this.dialogData.automaticBccAddresses)
    }
  } 

  public onClickRelevantContact(msc: MatSelectChange): void {
    const selectedRelCon = msc.value as number[]
    const addRelConId = selectedRelCon.filter(v => !this.prevSelectedRelCon.includes(v))[0];
    if (addRelConId) {
      this.addContactChip(
        addRelConId,
        this.dialogData.relevantContactOptions.find(rco => rco.id == addRelConId).description,
        this.chipType.RelevantContact);
    } else {
      const remRelConId = this.prevSelectedRelCon.filter(pv => !selectedRelCon.includes(pv))[0];
      if (remRelConId) {
        this.recipientsBox = this.recipientsBox.filter(r => r.id !== remRelConId);
      }
    }
    this.prevSelectedRelCon = msc.value;
  }

  public onAddContact(): void {
    const cid = this.formGroup.controls.allContactAdd.value;
    this.handleAddContact(cid);
    this.formGroup.controls.allContactAdd.setValue(null)
  }

  public onAddEmail(): void {
    const emailText = this.formGroup.controls.additionalEmailAddresseAdd.value;
    if (emailText) {
      this.addContactChip(
        this.formGroup.controls.additionalEmailAddresses.value.length - 1,
        emailText,
        this.chipType.AdditionalEmail);
      this.formGroup.controls.additionalEmailAddresses.value.push(emailText);
      this.formGroup.controls.additionalEmailAddresseAdd.setValue(null)
    }
  }

  public onRemoveRecipient(recipient: {id: number, description: string, type: number}): void {
    switch(recipient.type) {
      case this.chipType.RelevantContact: {
        const updateArray = this.formGroup.controls.relevantContacts.value.
          filter(rc => rc !== recipient.id);
        this.formGroup.controls.relevantContacts.setValue(updateArray);
        this.recipientsBox = this.recipientsBox.filter(r => r.id !== recipient.id);
        this.prevSelectedRelCon = updateArray;
        break;
      }
      case this.chipType.Contact: {
        const updateArray = this.formGroup.controls.allContacts.value
          .filter(oc => oc !== recipient.id);
        this.formGroup.controls.allContacts.setValue(updateArray);
        this.recipientsBox = this.recipientsBox.filter(r => r.id !== recipient.id);
        break;
      }
      case this.chipType.AdditionalEmail: {
        const updateArray = this.formGroup.controls.additionalEmailAddresses.value
          .filter(ada => ada !== recipient.description);
        this.formGroup.controls.additionalEmailAddresses.setValue(updateArray);
        this.recipientsBox = this.recipientsBox.filter(r => r.id !== recipient.id);
        break;
      }
    }
  }

  private handleAddContact(cid: number): void{
    const isRelevantContact = this.dialogData.relevantContactOptions.find(rc => cid == rc.id);
    if (isRelevantContact) {
      // if selected contact is a 'relevant contact', then treat the
      // same as onClickRelevantContact 'add' if not already checked
      let rcArray = this.formGroup.controls.relevantContacts.value;
      if (!rcArray.includes(cid)) {
        rcArray.push(cid)
        this.formGroup.controls.relevantContacts.setValue(rcArray);
        this.addContactChip(
          cid,
          this.dialogData.relevantContactOptions.find(rco => rco.id == cid).description,
          this.chipType.RelevantContact);
        this.prevSelectedRelCon = this.formGroup.controls.relevantContacts.value;
      }
    } else if (cid) {
      this.formGroup.controls.allContacts.value.push(cid);
      this.addContactChip(
        cid, 
        this.dialogData.allContactOptions.find(oco => oco.id == cid).description,
        this.chipType.Contact);
    }
  }

  private autoAddContactChips(addresses: string[]): void {
    addresses.forEach((address, index) => {
      this.addContactChip(index, address, this.chipType.AdditionalEmail);
    });
  }

  private addContactChip(id: number, description: string, type: ChipType ): void {
    this.recipientsBox.push({
      id: id,
      description: description,
      type: type
    });
  }
}