import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPLOYERPROFILEWIDGET_MOCK, MANAGEEMPLOYERSWIDGET_MOCK, MYDOCUMENTSWIDGET_MOCK, REPORTSWIDGET_MOCK, SETUPWIDGET_MOCK, WIDGETINFO_MOCK } from '@core/mocks';
import { RoleAdminWidget, WidgetInfo, LeaveAdminWidget, MyDocumentsWidget, SubmitNewRequestWidget, MyCaseWidgets, SubmitIntermittentTimeWidgets, ManageEmployersWidget, EmployerProfileWidget, ReportsWidget, SetupWidgetInfo } from '@core/models';
import { ManageEmployeesWidget } from '@core/models/widgets/manage-employees-widget.model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { EmailTemplatesWidget } from '@core/models/widgets/email-templates-widget.model';
import { EMAILTEMPLATES_WIDGET } from '@core/mocks/widgets/email-templates-widget.mock';
import { SetupWidget } from '@core/models/widgets/setup-widget.model';
import { WorkSchedulesWidget } from '@core/models/widgets/work-schedules-widget.model';
import { WORK_SCHED_WIDGET } from '@core/mocks/widgets/work-schedules-widget.mock';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private widgetInfoSubject: BehaviorSubject<WidgetInfo>;
  widgetInfo$: Observable<WidgetInfo>;

  get widgetInfo(): WidgetInfo {
    return this.widgetInfoSubject.value;
  }

  set widgetInfo(info: WidgetInfo) {
    this.widgetInfoSubject.next(info);
  }

  constructor(private constants: ConstantsService, private http: HttpClient) {
    this.widgetInfoSubject = new BehaviorSubject(null);
    this.widgetInfo$ = this.widgetInfoSubject.asObservable();
  }

  // #region Home Dashboard Widgets (11)

  getRoleAdminWidget(): Observable<RoleAdminWidget> {
    return this.http.get<RoleAdminWidget>(`${this.constants.WIDGETS_API}/getRoleAdminWidget`);
    //return of(ROLEADMINWIDGET_MOCK);
  }

  getMyDocumentsWidget(): Observable<MyDocumentsWidget> {
    return this.http.get<MyDocumentsWidget>(`${this.constants.WIDGETS_API}/getMyDocumentsWidget`);
    //return of(MYDOCUMENTSWIDGET_MOCK);
  }

  getLeaveAdminWidget(): Observable<LeaveAdminWidget> {
    return this.http.get<LeaveAdminWidget>(`${this.constants.WIDGETS_API}/getLeaveAdminWidget`);
    //return of(LEAVEADMINWIDGET_MOCK);
  }

  getSubmitNewRequestWidget(): Observable<SubmitNewRequestWidget> {
    return this.http.get<SubmitNewRequestWidget>(`${this.constants.WIDGETS_API}/getSubmitNewRequestWidget`);
    //return of(SUBMITNEWREQUESTWIDGET_MOCK);
  }
  
  getMyCaseWidgets(): Observable<MyCaseWidgets> {
    return this.http.get<MyCaseWidgets>(`${this.constants.WIDGETS_API}/getMyCaseWidgets`);
    //return of(MYCASEWIDGETS_MOCK);
  }

  getSubmitIntermittentTimeWidgets(): Observable<SubmitIntermittentTimeWidgets> {
    return this.http.get<SubmitIntermittentTimeWidgets>(`${this.constants.WIDGETS_API}/getSubmitIntermittentTimeWidgets`);
    //return of (SUBMITINTERMITTENTTIMEWIDGETS_MOCK);
  }

  getManageEmployersWidget(): Observable<ManageEmployersWidget> {
    return this.http.get<ManageEmployersWidget>(`${this.constants.WIDGETS_API}/getManageEmployersWidget`);
    //return of (MANAGEEMPLOYERSWIDGET_MOCK);
  }

  getManageEmployeesWidget(): Observable<ManageEmployeesWidget> {
    return this.http.get<ManageEmployersWidget>(`${this.constants.WIDGETS_API}/getManageEmployeesWidget`);
  }

  getEmployerProfileWidget(): Observable<EmployerProfileWidget> {
    return this.http.get<EmployerProfileWidget>(`${this.constants.WIDGETS_API}/getEmployerProfileWidget`);
    //return of (EMPLOYERPROFILEWIDGET_MOCK);
  }

  getReportsWidget(): Observable<ReportsWidget> {
    return this.http.get<ReportsWidget>(`${this.constants.WIDGETS_API}/getReportsWidget`);
    //return of (REPORTSWIDGET_MOCK);
  }

  getSetupWidget(): Observable<SetupWidget> {
    return this.http.get<SetupWidget>(`${this.constants.WIDGETS_API}/getSetupWidget`);
    // return of (SETUPWIDGET_MOCK).pipe(delay(1000));
  }

  // #endregion Home Dashboard Widgets (11)

  // #region Setup Dashboard Widgets (2)

  getEmailTemplatesWidget(): Observable<EmailTemplatesWidget> {
    return this.http.get<EmailTemplatesWidget>(`${this.constants.WIDGETS_API}/getEmailTemplatesWidget`);
    //return of (EMAILTEMPLATES_WIDGET).pipe(delay(1000));
  } 

  getWorkSchedulesWidget(): Observable<WorkSchedulesWidget> {
    return this.http.get<WorkSchedulesWidget>(`${this.constants.WIDGETS_API}/getWorkSchedulesWidget`);
    // return of (WORK_SCHED_WIDGET).pipe(delay(1000));
  } 

  // #endregion Setup Dashboard Widgets (2)

  // widget calls 'catchError return of null' in case one call fails the whole dashboard won't error out

  getWidgetInfo(): Observable<WidgetInfo> {
    const roleObservables$ = combineLatest([
      this.getRoleAdminWidget().pipe(catchError(()=>{return of(null)}))
    ]);

    const leaveObservables$ = combineLatest([
      this.getLeaveAdminWidget().pipe(catchError(()=>{return of(null)})),
      this.getSubmitNewRequestWidget().pipe(catchError(()=>{return of(null)})),
      this.getSubmitIntermittentTimeWidgets().pipe(catchError(()=>{return of(null)}))
    ]);

    const miscObservables$ = combineLatest([
      this.getReportsWidget().pipe(catchError(()=>{return of(null)})), 
      this.getSetupWidget().pipe(catchError(()=>{return of(null)})), 
    ]);

    const myCasesObservables$ = combineLatest([
      this.getMyCaseWidgets().pipe(catchError(()=>{return of(null)}))
    ]);

    const employerObservables$ = combineLatest([
      this.getManageEmployersWidget().pipe(catchError(()=>{return of(null)})),
      this.getEmployerProfileWidget().pipe(catchError(()=>{return of(null)}))
    ]);
    
    const employeeObservables$ = combineLatest([
      this.getManageEmployeesWidget().pipe(catchError(()=>{return of(null)}))
    ]);

    return combineLatest([roleObservables$, leaveObservables$, miscObservables$, myCasesObservables$, employerObservables$, employeeObservables$]).pipe(
      map(([[ra], [la, sr, sis], [rw, sw], [mcs], [mer, ep], [mee]]) => {
        return { ra, la, sr, sis, rw, sw, mcs, mer, ep, mee };
      }),
      tap((res) => (this.widgetInfo = res))
    );

    //return of(WIDGETINFO_MOCK).pipe(tap((res) => (this.widgetInfo = res)));
  }

  getSetupWidgetInfo(): Observable<SetupWidgetInfo> {
    const setupObservables$ = combineLatest([
      this.getEmailTemplatesWidget().pipe(catchError(()=>{return of(null)})),
      this.getWorkSchedulesWidget().pipe(catchError(()=>{return of(null)})),
    ]);

    return combineLatest([setupObservables$]).pipe(
      map(([[et, ws]]) => {
        return { et, ws };
      })
    );
  }

}
