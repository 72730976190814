import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { ErrorService, LayoutService } from '@core/services';
import { Observable, Subject } from 'rxjs';
import { LeaveDaysFormGroup, PostSpreadLeaveHours, SpreadLeaveHoursForm } from '@core/models/leave-admin/spread-leave-hours.model';
import { CaseDetails, StiiraError } from '@core/models';
import { takeUntil } from 'rxjs/operators';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-spread-leave-hours',
  templateUrl: './spread-leave-hours.component.html',
  styleUrl: './spread-leave-hours.component.scss'
})
export class SpreadLeaveHoursComponent extends DialogDragConstraints {
  public form: FormGroup<SpreadLeaveHoursForm>;
  public formInitValues: any;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();

  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get isHandheld$(): Observable<boolean> {
    return this.layoutService.isHandheld$();
  }

  get leaveDaysGroup(): FormGroup<LeaveDaysFormGroup> {
    return this.form.controls.leaveDaysGroup;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      sysText: any; 
      unsavedChangesSysText: any;
      caseDetails: CaseDetails;
    },
    private dialogRef: MatDialogRef<SpreadLeaveHoursComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private service: LeaveAdminService,
    private errorService: ErrorService,
    private fb: FormBuilder,
  ) { 
    super(dialogRef);

    this.form = this.fb.group<SpreadLeaveHoursForm>({
      startDate: this.fb.control(null, Validators.required),
      endDate: this.fb.control(null, Validators.required),
      useWorkSchedule: this.fb.control(false),
      leaveDaysGroup: this.fb.group<LeaveDaysFormGroup>({
        averageHoursPerWeek: this.fb.control(null),
        sundayLeave: this.fb.control(false),
        mondayLeave: this.fb.control(false),
        tuesdayLeave: this.fb.control(false),
        wednesdayLeave: this.fb.control(false),
        thursdayLeave: this.fb.control(false),
        fridayLeave: this.fb.control(false),
        saturdayLeave: this.fb.control(false),
      }),
      removeExistingHours: this.fb.control(false),
      removeOnlySpreadPeriod: this.fb.control(false),
    });
  }

  ngOnInit(): void {
    this.form.controls.useWorkSchedule.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val) {
          this.leaveDaysGroup.disable();
        } else {
          this.leaveDaysGroup.enable();
        }
      });

    this.form.controls.removeExistingHours.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val) {
          this.form.controls.removeOnlySpreadPeriod.enable();
        } else {
          this.form.controls.removeOnlySpreadPeriod.setValue(false);
          this.form.controls.removeOnlySpreadPeriod.disable();
        }
      });

    this.form.controls.startDate.setValue(this.data.caseDetails.leaveInformation.startDate);
    this.form.controls.endDate.setValue(this.data.caseDetails.leaveInformation.endDate);

    this.form.controls.useWorkSchedule.setValue(this.data.caseDetails.employeeInformation.workSchedules?.length > 0);

    this.form.controls.leaveDaysGroup.controls.averageHoursPerWeek.setValue(this.data.caseDetails.leaveInformation.averageWeeklyHours);
    
    this.form.controls.removeExistingHours.setValue(this.data.caseDetails.leaveCalendar.hasLeaveHours);
    this.form.controls.removeOnlySpreadPeriod.setValue(false);

    this.formInitValues = { ...this.form.value };
  }

  public onSubmit(): void {
    this.form.markAsUntouched();
    const dto: PostSpreadLeaveHours = {
      caseId: this.data.caseDetails.leaveInformation.caseId,
      startDate: this.form.controls.startDate.value,
      endDate: this.form.controls.endDate.value,
      useWorkSchedule: this.form.controls.useWorkSchedule.value,
      leaveDaysGroup: {
        averageHoursPerWeek: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.averageHoursPerWeek.value : null,
        sundayLeave: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.sundayLeave.value : null,
        mondayLeave: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.mondayLeave.value : null,
        tuesdayLeave: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.tuesdayLeave.value : null,
        wednesdayLeave: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.wednesdayLeave.value : null,
        thursdayLeave: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.thursdayLeave.value : null,
        fridayLeave: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.fridayLeave.value : null,
        saturdayLeave: !this.form.controls.useWorkSchedule.value ? 
          this.leaveDaysGroup.controls.saturdayLeave.value : null,
      },
      removeExistingHours: this.form.controls.removeExistingHours.value,
      removeOnlySpreadPeriod: this.form.controls.removeExistingHours.value ? 
        this.form.controls.removeOnlySpreadPeriod.value : null
    };
    
    this.service.postSpreadLeaveHours(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res) => {
        this.dialogRef.close(res);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }
}