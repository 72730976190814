import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSliderDragEvent } from '@angular/material/slider';
import { LayoutService, SnackbarService } from '@core/services';
import { ProfileService } from '@core/services/profile.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers/rxjs.helpers';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-image',
  templateUrl: './change-image.component.html',
  styleUrls: ['./change-image.component.scss'],
})
export class ChangeImageComponent extends DialogDragConstraints implements OnInit, AfterViewInit {
  @ViewChild('imgFileInput') input: ElementRef;
  croppedImage: any = '';
  imageChangedEvent: any = '';
  isLoading$ = new Subject<boolean>();
  isMobile: boolean;
  scale = 1;
  transform: ImageTransform = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public sysText: any,
    private dialogRef: MatDialogRef<ChangeImageComponent>,
    private profileService: ProfileService,
    private layoutService: LayoutService,
    private snackbarService: SnackbarService
  ) {
    super(dialogRef);
    
    this.isMobile = this.layoutService.isMobile;
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.input.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    if (event.target.files[0]) {
      this.imageChangedEvent = event;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.snackbarService
      .open('Image failed to load. Please try again', 'Retry', 5000)
      .onAction()
      .subscribe(() => this.input.nativeElement.click());
  }

  zoomOut() {
    if (this.scale > 0.1) {
      this.scale -= 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  zoomIn() {
    if (this.scale < 2) {
      this.scale += 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  zoom(event: MatSliderDragEvent): void {
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  save() {
    this.dialogRef.disableClose = true;
    this.profileService
      .changeProfileImage(this.croppedImage)
      .pipe(
        indicate(this.isLoading$),
        finalize(() => (this.dialogRef.disableClose = false))
      )
      .subscribe(() => this.dialogRef.close(this.croppedImage));
  }
}
