import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SidenavMenuLink } from '@core/models';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {
  @Input() links: SidenavMenuLink[];
  @Output() linkClick = new EventEmitter<SidenavMenuLink>();
  @Output() logout = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  handleLogout(): void {
    this.logout.emit();
    this.linkClick.emit();
  }

  handleLinkClick(link: SidenavMenuLink): void {
    this.linkClick.emit(link);
  }
}
