import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AnonymousNewRequestPage } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { accountRoutePaths } from '@core/routes/route-paths.constants';
import { SysTextService } from '@core/services';
import { AnonymousRequestsStoreService } from '@core/services/anonymous-requests-store.service';
import { AnonymousRequestsService } from '@core/services/anonymous-requests.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnonymousRequestResolver  {
  constructor(
    private anonymousRequestService: AnonymousRequestsService, 
    private textService: SysTextService, 
    private storeService: AnonymousRequestsStoreService,
    private router: Router,
    ){}

resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AnonymousNewRequestPage> {
    const anonymousRequestHttpData$ = this.anonymousRequestService.getAnonymousNewRequestPage();
    const sysText$ = this.textService.getSysText(this.storeService.templates);

    return combineLatest([anonymousRequestHttpData$, sysText$]).pipe(
      tap(([anonymousRequestHttpData, sysText]) => {
        if (!anonymousRequestHttpData && !this.router.navigated) {
          this.router.navigate([accountRoutePaths.LOGIN]);
        }
        this.storeService.sysText = sysText;
      }),
      map(([anonymousRequestHttpData]) => {
        return anonymousRequestHttpData;
      }),
      filter((res) => !!res),
      tap((res) => {
        this.storeService.anonymousRequestPage = res;
      }),
      catchError(() => {
        this.router.navigate([accountRoutePaths.LOGIN]);
        return of(null);
      })
    );
  }
}
