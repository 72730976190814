<mat-expansion-panel 
    class="info-panel" 
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState"
>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="section-header-container">
                <p class="overline grey1 semibold section-title">{{sysText.templateInfo | uppercase}}</p>
                <mat-divider class="section-header-underline"></mat-divider>
            </div> 
            <ng-container *ngIf="templateDetails.canEditInfo">
                <button *ngIf="!(isLoading$ | async); else loading" mat-icon-button color="accent" aria-label="icon-button with edit icon"  class="edit-employee-button" (click)="editTemplateInfo($event)">
                    <mat-icon>edit</mat-icon>
                </button>
                <ng-template #loading>
                    <mat-spinner [diameter]="25" color="accent"></mat-spinner>
                </ng-template>
            </ng-container>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="main-content flex">
        <div class="item-container">
            <div class="item-label">
                <p class="body2 grey2">{{sysText.templateName | titlecase}}</p>
            </div>
            <div class="item-content">
                <p class="body2 grey1 semibold">
                    {{templateDetails.name}}
                </p>
            </div>
        </div>
        <div class="item-container">
            <div class="item-label">
                <p class="body2 grey2">{{sysText.emailType | titlecase}}</p>
            </div>
            <div class="item-content">
                <p class="body2 grey1 semibold">
                    {{templateDetails.emailType}}
                </p>
            </div>
        </div>
        <div class="item-container">
            <div class="item-label">
                <p class="body2 grey2">{{sysText.owner | titlecase}}</p>
            </div>
            <div class="item-content">
                <p class="body2 grey1 semibold">
                    {{ this.templateDetails.id < 0 ? "-- " + (this.sysText.system | titlecase) + "--"
                        : !this.templateDetails.ownerEmployer ? "-- " + (this.sysText.stock | titlecase) + "--"
                        : this.templateDetails.ownerEmployer }}
                </p>
            </div>
        </div>
        <div class="item-container">
            <div class="item-label">
                <p class="body2 grey2">{{sysText.allowUse | titlecase}}</p>
            </div>
            <div class="item-content">
                <p class="body2 grey1 semibold status-field">
                    <app-status-icon [isEnabled]="templateDetails.allowUse"></app-status-icon> {{templateDetails.allowUse ? (sysText.enabled | titlecase) : (sysText.disabled | titlecase)}}
                </p>
            </div>
        </div>
    </div>
</mat-expansion-panel>