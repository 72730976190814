import { PasswordRequirement } from '@core/models';

export const PASSWORD_REQS: PasswordRequirement[] = [
  {
    description: 'number',
    validationMethodId: -6,
    value: 1,
    valid: false
  },
  {
    description: 'lower case letter',
    validationMethodId: -4,
    value: 1,
    valid: false
  },
  {
    description: 'upper case letter',
    validationMethodId: -3,
    value: 1,
    valid: false
  },
  {
    description: 'special character',
    validationMethodId: -5,
    value: 1,
    valid: false
  },
  {
    description: 'characters',
    validationMethodId: -1,
    value: 6,
    valid: false
  }
];
