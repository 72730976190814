import { DatePipe, TitleCasePipe } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { AccountModule } from '@modules/account/account.module';
import { DialogsModule } from '@modules/dialogs/dialogs.module';
import { LayoutModule } from '@modules/layout/layout.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CaseCommunicationModule } from '@modules/case-communication/case-communication.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';

export function initApp1(sharedSysTextService: SharedComponentsSysTextService): () => Promise<any> {
  return (): Promise<any> => {
    return sharedSysTextService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, CoreModule, SharedModule, AccountModule, LayoutModule, DialogsModule, CaseCommunicationModule, DragDropModule],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', subscriptSizing: 'dynamic' } },
    DatePipe,
    TitleCasePipe,
    SharedComponentsSysTextService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp1,
      multi: true,
      deps: [SharedComponentsSysTextService],
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        autoFocus: false,
        disableClose: true,
        closeOnNavigation: false
        } as MatDialogConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
