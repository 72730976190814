import { FormControl } from "@angular/forms";
import { SelectionOption } from "@core/models/shared";

export interface EmployeeWorkSchedule {
	employeeWorkScheduleId: number;
	effectiveDate: Date; //nullable
	employerWorkScheduleId: number; //nullable
	isIrregular: boolean;
	scheduleName: string;
	scheduleNonWorkdays: SelectionOption[];
}

export interface EmployeeWorkScheduleDialog {
	workScheduleOptions: SelectionOption[];
	scheduleNonWorkdays: ScheduleNonWorkdays[];
	nonWorkdayOptions: SelectionOption[];
}

export interface ScheduleNonWorkdays {
	scheduleId: number;
	nonWorkdays: number[];
}

export interface EmployeeWorkScheduleDialogForm {
	effectiveDate: FormControl<Date>;
	employerWorkScheduleId: FormControl<number>;
	isIrregular: FormControl<boolean>;
	nonWorkdays: FormControl<number[]>;
}

export interface EmployeeWorkSchedulePost {
	caseId?: number; // only 
	employeeId: number;
	overrideUpdateConf: boolean;
	employeeWorkScheduleId: number; //nullable
	effectiveDate: Date; //nullable
	employerWorkScheduleId: number; //nullable
	isIrregular: boolean;
	nonWorkdays: number[];
}

export enum WorkScheduleDialogMode {
	employeeRecord,
	caseDetails
}