import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { AuthService, ErrorService, ProfileService, SnackbarService } from '@core/services';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers/rxjs.helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.scss'],
})
export class ChangeUsernameComponent extends DialogDragConstraints implements OnInit {
  form: UntypedFormGroup;
  hidePassword = true;
  isSubmitting$ = new Subject<boolean>();

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }

  get username(): FormControl {
    return this.form.get('username') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangeUsernameComponent>,
    private profileService: ProfileService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public sysText
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', Validators.required],
      username: ['', Validators.required],
    });
  }

  changeUsername(): void {
    this.dialogRef.disableClose = true;
    this.profileService
      .changeUsername({password: this.form.value.password, username: this.form.value.username})
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => (this.dialogRef.disableClose = false))
      )
      .subscribe(
        () => {
          this.snackbarService.open('Username successfully changed', 'Dismiss');
          this.authService.logout();
          this.dialogRef.close();
        },
        (err: StiiraError) => {
          this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors);
        }
      );
  }
}
