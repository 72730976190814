<h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  {{ (sysText.leavingPageDialog_title ? sysText.leavingPageDialog_title : sysText.unsavedChangesDialog_title) | titlecase }}!
</h6>
<mat-dialog-content>
  <p class="subtitle1">{{ (sysText.leavingPageDialog_subtitle ? sysText.leavingPageDialog_subtitle : sysText.unsavedChangesDialog_subtitle) }}.</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button class="button" [mat-dialog-close]="false" color="accent">{{ sysText.stay ? sysText.stay : sysText.keepEditing }}</button>
  <button mat-stroked-button class="button" [mat-dialog-close]="true" color="accent">
    {{ sysText.leave ? sysText.leave : sysText.discard }}
  </button>
</mat-dialog-actions>
