import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';
import { LayoutService } from '@core/services';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-update-confirmation',
  templateUrl: './update-confirmation.component.html',
  styleUrls: ['./update-confirmation.component.scss']
})
export class UpdateConfirmationComponent extends DialogDragConstraints implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
    public data: { 
      sysText: any,
      updateConfirmationDialog: UpdateConfirmationDialog
    },
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<UpdateConfirmationComponent>,
    ) {
      super(dialogRef);
    }

  ngOnInit(): void {}

  public get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
