import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  open(message: string, action: string, duration = 5000 ): MatSnackBarRef<any> {
    return this.snackBar.open(message, action, {
      panelClass: 'snackbar',
      duration
    });
  }
}
