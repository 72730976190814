import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { SelectedProviders, VerificationFormOptions } from '@core/models';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-verification-form',
  templateUrl: './verification-form.component.html',
  styleUrls: ['./verification-form.component.scss']
})
export class VerificationFormComponent implements OnInit {
  @Input() isSubmitting = false;
  @Input() otpDest: string;  
  @Input() otpIsPhone: boolean;
  @Input() sysText: any;
  @Input() options: VerificationFormOptions;
  @Input() selectedProviders: SelectedProviders
  @Input() page: string;
  @Output() formSubmit = new EventEmitter<FormGroup>();
  @Output() sendCode = new EventEmitter<{provider: string, resend: boolean}>();
  @Output() goBack = new EventEmitter();
  verificationForm: FormGroup;  

  get code(): FormControl {
    return this.verificationForm.get('code') as FormControl;
  }

  get rememberMe(): FormControl {
    return this.verificationForm.get('rememberMe') as FormControl;
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {    
    this.verificationForm = this.fb.group({
      code: [null, Validators.required],
      rememberMe: [false]
    })
  }

  onSubmit(): void {
    this.formSubmit.emit(this.verificationForm);
  }

  onResend(): void {    
    this.sendCode.emit({provider: this.otpIsPhone ? this.selectedProviders.phoneProvider : this.selectedProviders.emailProvider, resend: true});
  }

  onEmailCode(): void {    
    this.sendCode.emit({provider:this.selectedProviders.emailProvider, resend: true});
  }

  onGoBack(): void {
    this.goBack.emit();
  }
}