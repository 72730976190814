import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { DocumentCategoryItem } from '@core/models/case-communication/document-category-item.model';
import { PostDocumentCategory } from '@core/models/case-communication/post-document-category.model';
import { ErrorService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { AddEditDocumentCategoryDialog } from '@core/models/case-communication/add-edit-document-category-dialog.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-add-edit-document-category',
  templateUrl: './add-edit-document-category.component.html',
  styleUrls: ['./add-edit-document-category.component.scss']
})
export class AddEditDocumentCategoryComponent extends DialogDragConstraints implements OnInit {

  public form: UntypedFormGroup;
  public formInitValues: any;
  public isSubmitting$: Subject<boolean>;

  get noChanges(): boolean {
    var curValues = JSON.stringify(this.form.value, noChangesReplacer);
    var initValues = JSON.stringify(this.formInitValues, noChangesReplacer);
    return curValues === initValues;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      sysText: any, 
      category: DocumentCategoryItem, 
      unsavedChangesSysText: any 
      dialogData: AddEditDocumentCategoryDialog
    },
    private service: CaseCommunicationService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddEditDocumentCategoryComponent>
  ) {
    super(dialogRef);

    this.isSubmitting$ = new Subject();
    this.form = this.fb.group({
      categoryName: [null, Validators.required],
      ownerEmployerId: [null, Validators.required],
      allowUse: [false]
    });
  }

  ngOnInit(): void {
    if (this.data.category != null) {
      this.form.patchValue({
          categoryName: this.data.category?.documentCategoryName,
          ownerEmployerId: !this.data.dialogData.showOwner
            ? null
            : this.data.dialogData.canChangeOwner
              ? this.data.category?.ownerEmployer?.employerId
              : this.data.category?.isSystemCategory 
                ? `-- ${this.data.sysText.system.charAt(0).toUpperCase() + this.data.sysText.system.slice(1)} --` :
                this.data.category?.ownerEmployer?.employerName 
                  ?? `-- ${this.data.sysText.stock.charAt(0).toUpperCase() + this.data.sysText.stock.slice(1)} --`,
          allowUse: this.data.category?.allowUse
      });
    }

    if (this.data?.category?.isSystemCategory || this.data?.category?.isStockCategory) {
      this.form.controls.categoryName.disable();
    }

    if (this.data.dialogData.showOwner && !this.data.dialogData.canChangeOwner) {
      this.form.controls.ownerEmployerId.disable();
    }

    this.formInitValues = { ...this.form.value };
  }

  onSubmit() {
    this.dialogRef.disableClose = true;
    if (this.data.category) {
      let editCategoryPost: PostDocumentCategory = {
        documentCategoryId: this.data.category.documentCategoryId,
        categoryName: this.form.controls.categoryName.value,
        ownerEmployerId: this.data.dialogData.canChangeOwner 
          ? this.form.controls.ownerEmployerId.value 
          : this.data.category?.ownerEmployer?.employerId,
        allowUse: this.form.controls.allowUse.value
      }
      this.service
        .postEditDocumentCategory(editCategoryPost)
        .pipe(
          indicate(this.isSubmitting$),
          finalize(() => {
            this.dialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.dialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    } else {
      let newCategoryPost: PostDocumentCategory = {
        documentCategoryId: null,
        categoryName: this.form.controls.categoryName.value,
        ownerEmployerId: this.data.dialogData.canChangeOwner 
          ? this.form.controls.ownerEmployerId.value 
          : this.data.category?.ownerEmployer?.employerId,
        allowUse: this.form.controls.allowUse.value
      }
      this.service
        .postNewDocumentCategory(newCategoryPost)
        .pipe(
          indicate(this.isSubmitting$),
          finalize(() => {
            this.dialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.dialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    }
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }
}
