<editor
	#bodyEditor
	[formControl]="control"
	[init]="{ 
		height: 300,
		width: '100%',
		menubar: 'edit insert format placeholders',
		menu: {
			format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | removeformat' },
			placeholders: {
				title: 'Placeholders', 
				items: placeholdersMenuItems 
			}
		},
		promotion: false,
		plugins: 'link autoresize',
		statusbar: false,
		toolbar: false,
		setup: bodyEditorSetup,
		placeholder: placeholderText,
		content_style: editorStyle
	}"
></editor>
<mat-error class="error">{{ control.errors?.msError }}</mat-error>