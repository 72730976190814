export enum LeaveActions{
    // stage DeterminingEligibility
    ConfirmEmployeeInfo = -22,
    DetermineEligibilityStatus = -4,
    SendNoticeOfEligibilityStatus = -5,
    SendRightsAndResponsibilitiesLetter = -6,
    NotifyCompanyContactsPendingReq = -3,
    // stage DeterminingApproval
    SendInitiationOfADANotification = -7,
    SendReasonableAccommodationRequestForm = -8,
    SendMedicalCertDocRequest = -9,
    EngageInInteractiveProcess = -10,
    AssessAccommodationRequestToDetermineDesignation = -11,
    AssessMedicalDocsAndDetermineDesignation = -12,
    ObtainCompanyApprovalForUnprotectedLeave = -13,
    SendDesignationNotice = -14,
    NotifyCompanyContactsOfDesignationStatus = -15,
    // stage OnLeave
    CheckInPriorToLeaveEndDate = -16,
    SendExpiringLeaveLetter = -17,
    ConfirmLeaveEndDateRequestApplicableReturnToWorkDocs = -18,
    NotifyCompanyContactsOfConfirmedLeaveEndDate = -19,
    // stage LeaveCompleted
    VerifyReturnToWork = -20,
}