export const APP_COLORS_THEME = {
    primary: '#ffffff',
    accent: '#4f4aa9',
    warn: '#b00020',
    success: '#077923',
}

export const APP_COLORS_FILLS = {
    fill1std: '#4f4aa9',
    fill1lt: '#8176db',
    fill1dk: '#162279',
    fill2std: '#968fd8',
    fill2lt: '#c8bfff',
    fill2ltalt: '#c3c1e7',
    fill2dk: '#6662a6',
    fill3std: '#ffee9f',
    fill3lt: '#ffffd1',
    fill3dk: '#cbbc70',
    fill4std: '#4aa94f',
    fill4lt: '#7ddb7d',
    fill4dk: '#077923',
    fill5std: '#f98278',
    fill5lt: '#ffb3a7',
    fill5dk: '#c2524c',
    fill6std: '#1360fa',
    fill6lt: '#6d8dff',
    fill6dk: '#0037c6',
}