import { Component, OnInit } from '@angular/core';
import { GlobalLoaderService } from '@core/services/global-loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
})
export class GlobalLoaderComponent implements OnInit {
  isLoading$: Observable<boolean>;
  constructor(private globalLoaderService: GlobalLoaderService) {}

  ngOnInit() {
    this.isLoading$ = this.globalLoaderService.isLoading$;
  }
}
