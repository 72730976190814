import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StatusChips } from '@core/models';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { ConstantsService, GlobalLoaderService, HeaderService, SnackbarService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { TemplateDetailsStoreService } from '@core/services/template-details-store.service';
import { UnsavedChangesComponent } from '@modules/dialogs/unsaved-changes/unsaved-changes.component';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss']
})
export class TemplateDetailsComponent implements OnInit {
  public templateDetails$: Observable<TemplateDetails>;
  public sysText: any;
  public isEditing: boolean = false;
  public isSaving$ = new Subject<boolean>();
  public chipStyles: StatusChips;

  constructor(
    private store: TemplateDetailsStoreService,
    private headerService: HeaderService,
    private dialog: MatDialog,
    private globalLoader: GlobalLoaderService,
    private constants: ConstantsService,
    private service: CaseCommunicationService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.templateDetails$ = this.store.templateDetails$;
    this.chipStyles = this.constants.STAT_CHIP_STYLES;
    this.sysText = this.store.sysText;
    this.headerService.setTitle(this.sysText.templateDetails["templateDetails_header_title"]);
  }

  public async canDeactivate(): Promise<boolean> {
    if (!this.isEditing) {
      this.dialog.closeAll();
      return true;
    } else {
      this.globalLoader.hide();
      const dialogConfig: MatDialogConfig = {
        width: '300px',
        data: this.sysText.leavingPage,
      };
      
      const dialogRef = this.dialog.open(UnsavedChangesComponent, dialogConfig);
      const response = await dialogRef.afterClosed().toPromise();
      if (response === true) {
        this.globalLoader.show();
        this.dialog.closeAll();
        return true;
      }
      return false;
    }
  }

  public onEdit(editing: boolean): void {
    this.isEditing = editing;
  }

  public assignEmployer(): void {
    this.service.postAssignMyEmployer({templateId: this.store.templateDetails.id})
      .pipe(indicate(this.isSaving$))
      .subscribe(res => {
        this.store.emailTemplateAssignedToEmployer(res);
        this.snackbarService.open(this.sysText.templateDetails.assignedToMyEmployerMessage, this.sysText.templateDetails.dismiss);
      });
  }

  public unassignEmployer(): void {
    this.service.postUnassignMyEmployer({templateId: this.store.templateDetails.id})
    .pipe(indicate(this.isSaving$))
      .subscribe(res => {
        this.store.emailTemplateAssignedToEmployer(res);
        this.snackbarService.open(this.sysText.templateDetails.unassignedToMyEmployerMessage, this.sysText.templateDetails.dismiss);
      });
  }
}
