import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { TemplateItem } from '@core/models/case-communication/template-item.model';
import { TemplateDetailsStore } from '@core/models/case-communication/template-details-store.model';
import { insertPlaceholderChips } from '@shared/helpers';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateDetailsStoreService {

  private readonly _initialStoreData: TemplateDetailsStore = {
    templateDetails: null,
    sysText: null
  };

  private templateDetailsStore: BehaviorSubject<TemplateDetailsStore>;  
  private store$: Observable<TemplateDetailsStore>;

  private readonly templateNames = {
    templateDetails: "templateDetails",
    templateItem: "templateItem",
    deleteConfirmation: "deleteConfirmation",
    leavingPage: "leavingPage",
    unsavedChanges: "unsavedChanges",
    uploadDocumentDialog: "uploadDocumentDialog",
    editDocumentDialog: "editDocumentDialog"
  };

  constructor(private constants: ConstantsService) {
    this.templateDetailsStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.templateDetailsStore.asObservable();
  }

  public get store() {
    return this.templateDetailsStore.value;
  }

  public get templateDetails() {
    return this.store.templateDetails;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public get templateDetails$(): Observable<TemplateDetails> {
    return this.store$.pipe(pluck('templateDetails'))
  }

  public get templateItem$(): Observable<TemplateItem> {
    return this.store$.pipe(pluck('templateItem'))
  }

  public get templates() {
    return this.templateNames;
  }

  public set templateDetails(templateDetails: TemplateDetails) {
    templateDetails.subjectHtml = insertPlaceholderChips(templateDetails.subjectHtml, this.constants.EDITOR_CHIP, templateDetails.placeholderOptions);
    templateDetails.bodyHtml = insertPlaceholderChips(templateDetails.bodyHtml, this.constants.EDITOR_CHIP, templateDetails.placeholderOptions);
    const store = { ...this.store, templateDetails};
    this.templateDetailsStore.next(store);
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.templateDetailsStore.next(store);
  }

  public emailTemplateAssignedToEmployer(assigned: boolean) {
    this.store.templateDetails.assignedToEmployer = assigned;
  }

  public unloadStore() {
    const store: TemplateDetailsStore = {
      templateDetails: null,
      sysText: null
    }
    this.templateDetailsStore.next(store)
  }
}