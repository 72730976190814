import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss']
})
export class UnsavedChangesComponent extends DialogDragConstraints implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public sysText: any,
    private dialogRef: MatDialogRef<UnsavedChangesComponent>,
    ) {
      super(dialogRef);
    }

  ngOnInit() {}
}
