import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSecurityQuestions } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityQuestionsService {
  private sqCorrectSubject: BehaviorSubject<boolean>;

  get hasCorrectSqAnswers(): boolean {
    return this.sqCorrectSubject.value;
  }

  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.sqCorrectSubject = new BehaviorSubject<boolean>(false);
  }

  getSecurityQuestions(username: string): Observable<UserSecurityQuestions> {
    return this.http.get<UserSecurityQuestions>(`${this.constants.ACCOUNT_API}/getsecurityquestions`, {
      params: {
        username,
      },
    });

    // return of(SECURITYQUESTIONS_MOCK);
  }

  postSecurityQuestions(userSqs: UserSecurityQuestions): Observable<any> {
    return this.http.post(`${this.constants.ACCOUNT_API}/postsecurityquestions`, userSqs);

    // return of(true);
  }

  allowAccessToSetOrResetPwd(): void {
    this.sqCorrectSubject.next(true);
  }

  disallowAccessToSetOrResetPwd(): void {
    this.sqCorrectSubject.next(false);
  }
}
