<ng-container *ngIf="group" [formGroup]="group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>
  
<ng-container *ngIf="!group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>
  
<ng-template #formField>
  <div class="container">
    <div class="toggle-container">
      <mat-icon *ngIf="icon != null">{{ icon }}</mat-icon>
      <mat-slide-toggle #toggle="matSlideToggle" checked="checked" [formControl]="control" [disabled]="isDisabled"></mat-slide-toggle>
      <p *ngIf="capitalizeLabel" class="subtitle2"> {{ label | capitalizefirst }}</p>
      <p *ngIf="!capitalizeLabel" class="subtitle2"> {{ label }}</p>
    </div>
    <mat-hint *ngIf="hint" class="hint-error" [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }">{{ hint }}</mat-hint>
    <mat-error *ngIf="control.errors?.msError" class="hint-error">{{ control.errors?.msError }}</mat-error>
    <mat-error *ngIf="control.errors?.required" class="hint-error">{{ sysText.required | capitalizefirst }}</mat-error>
  </div>
</ng-template>
