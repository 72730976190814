<mat-dialog-content>
  <ng-container *ngIf="data.roleSysText; else actions">
    <div class="info-container">
      <mat-icon>admin_panel_settings</mat-icon>
      <p>{{ data.roleSysText.adminSysText | titlecase }}</p>
    </div>
    <div class="info-container">
      <mat-icon>psychology</mat-icon>
      <p>{{ data.roleSysText.responsibleSysText | titlecase }}</p>
    </div>
  </ng-container>
  <ng-template #actions>
    <div class="info-container">
      <mat-icon>visibility</mat-icon>
      <p>{{ data.actionSysText.enabledSysText | titlecase }}</p>
    </div>
    <div class="info-container bottom-margin">
      <mat-icon>visibility_off</mat-icon>
      <p>{{ data.actionSysText.disabledSysText | titlecase }}</p>
    </div>
  </ng-template>
</mat-dialog-content>
