import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastrService: ToastrService) {}

  public showErrorToast(message: string, title: string = 'Error') {
    this.toastrService.error(message, title, {
      positionClass: 'toast-top-right',
      timeOut: 0,
      extendedTimeOut: 0,
      closeButton: true,
      tapToDismiss: false,
      titleClass: 'toast-title',
      messageClass: 'toast-message',
      enableHtml: true
    });
  }

  public showSuccessToast(title: string, message: string) {
    this.toastrService.success(message, title, {
      positionClass: 'toast-top-center',
      timeOut: 5000,
      extendedTimeOut: 5000,
      closeButton: true,
      tapToDismiss: true,
    });
  }

  public showInfoToast(title: string, message: string) {
    this.toastrService.info(message, title, {
      positionClass: 'toast-top-right',
      timeOut: 0,
      extendedTimeOut: 5000,
      closeButton: true,
      tapToDismiss: false,
      titleClass: 'toast-title',
      messageClass: 'toast-message',
    });
  }

  public showWarningToast(title: string, message: string) {
    this.toastrService.warning(message, title, {
      positionClass: 'toast-top-right',
      timeOut: 0,
      extendedTimeOut: 0,
      closeButton: true,
      tapToDismiss: false,
      titleClass: 'toast-title',
      messageClass: 'toast-message',
      enableHtml: true
    });
  }  

  public closeAllToasts(): void {
    this.toastrService.clear();
  }
}