<div class="relative-container">
    <div *ngIf="isLoading" class="spinner-container">
        <mat-spinner color="accent"></mat-spinner>
    </div>
    <div class="calendar-bg">
        <div id="calendar">
            <full-calendar [options]="calendarOptions" style="display: none"></full-calendar>
        </div>
    </div>
</div>
