import { Injectable } from '@angular/core';
import { CaseCommunicationDrawer } from '@core/models/case-communication/case-communication-drawer.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

export interface CaseComStore {
  sysText: any;
  caseComInfo: CaseCommunicationDrawer;
}

@Injectable({
  providedIn: 'root'
})

export class CaseCommunicationStoreService {
  private readonly _initialStoreData: CaseComStore = {
    sysText: null,
    caseComInfo: null
  }

  private caseComStore: BehaviorSubject<CaseComStore>;
  private store$: Observable<CaseComStore>;
  private ccEditing: BehaviorSubject<boolean>;
  private ccEditing$: Observable<boolean>;

  private readonly templateNames = {
    caseCommunicationDrawer: 'caseCommunicationDrawer',
    viewEmailDialog: 'viewEmailDialog',
    sendNewEmailDialog: 'sendNewEmailDialog',
    unsavedChanges: 'unsavedChanges'
  };

  constructor() { 
    this.caseComStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.caseComStore.asObservable();
    this.ccEditing = new BehaviorSubject(false);
    this.ccEditing$ = this.ccEditing.asObservable();
  }

  public get store() {
    return this.caseComStore.value;
  }

  public get caseComDrawer$(): Observable<CaseCommunicationDrawer> {
    return this.store$.pipe(pluck('caseComInfo'))
  }

  public get ccIsEditing$(): Observable<boolean> {
    return this.ccEditing$;
  }

  public get ccIsEditing(): boolean {
    return this.ccEditing.value;
  }

  public get templates() {
    return this.templateNames;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public get caseComInfo() {
    return this.store.caseComInfo;
  }

  public set setCcIsEditing(bool: boolean) {
    this.ccEditing.next(bool);
  }

  public set caseComDrawer(caseComInfo: CaseCommunicationDrawer) {
    const store = { ...this.store, caseComInfo };
    this.caseComStore.next(store);
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.caseComStore.next(store);
  }

  public unloadStore() {
    const store: CaseComStore = {
      sysText: null,
      caseComInfo: null
    }
    this.caseComStore.next(store)
  }
}
