<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6>
      {{ (data.updateConfirmationDialog.title ?? data.sysText.updateConf_title) | titlecase }} 
  </h6>
  <ng-container *ngIf="isHandheld">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <div class="subtitle1">
    <p class="body2 first-sentence">{{ data.updateConfirmationDialog.firstSentence ?? data.sysText.updateConf_firstSentence }}</p>
  </div>
  <div>
    @for(message of data.updateConfirmationDialog.messages; track message) {
      <p class="body2">
        <span class="bullet-space">&bull;</span>{{ message }}
      </p>
    }
  </div>
  <div class="subtitle1">
    <p class="body2 last-sentence">{{ data.updateConfirmationDialog.lastSentence ?? data.sysText.updateConf_lastSentence}}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!isHandheld" class="actions" align="end">
  <button mat-stroked-button class="button" [mat-dialog-close]="false" color="accent">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" [mat-dialog-close]="true" color="accent">
    {{ data.sysText.proceed }}
  </button>
</mat-dialog-actions>
