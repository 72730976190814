import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorService } from '@core/services';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { 
  EmployeeWorkSchedule, 
  EmployeeWorkScheduleDialog, 
  EmployeeWorkScheduleDialogForm, 
  EmployeeWorkSchedulePost, 
  ScheduleNonWorkdays, 
  WorkScheduleDialogMode} from '@core/models/leave-admin/employees/employee-schedules.model';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { EmployeeRecordStoreService } from '@core/services/employee-record-store.service';
import { CaseDetails, StiiraError } from '@core/models';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';
import { UpdateConfirmationComponent } from '../update-confirmation/update-confirmation.component';
import { EmployeeRecord } from '@core/models/leave-admin/employees/employee-record.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-add-edit-employee-schedule',
  templateUrl: './add-edit-employee-schedule.component.html',
  styleUrl: './add-edit-employee-schedule.component.scss'
})
export class AddEditEmployeeScheduleComponent extends DialogDragConstraints implements OnInit{
  @Output() isEditing = new EventEmitter<boolean>();
  
  public form: FormGroup<EmployeeWorkScheduleDialogForm>;
  public formInitValues: any;
  public isSubmitting$: Subject<boolean>;
  public formChangeEmitted: boolean = false;
  public scheduleNonWorkdays: ScheduleNonWorkdays;

  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      sysText: any,
      unsavedChangesSysText: any,
      updateConfSysText: any,
      mode: WorkScheduleDialogMode,
      employeeId: number, //nullable
      caseId: number, //nullable
      isAnonymousCase: boolean, //nullable
      employeeSchedule: EmployeeWorkSchedule,
      dialogData: EmployeeWorkScheduleDialog
    },
    private manageEmployeeService: ManageEmployeesService,
    private caseDetailsService: LeaveAdminService,
    private employeeRecordStore: EmployeeRecordStoreService,
    private caseDetailsStore: LeaveAdminStoreService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddEditEmployeeScheduleComponent>
  ) {
    super(dialogRef);
    
    this.isSubmitting$ = new Subject();
    this.form = this.fb.group<EmployeeWorkScheduleDialogForm>({
      effectiveDate: this.fb.control(null),
      employerWorkScheduleId: this.fb.control(null),
      isIrregular: this.fb.control(false),
      nonWorkdays: this.fb.control([])
    });
  }

  ngOnInit(): void {
    if (this.data.employeeSchedule) {
      this.form.patchValue({
        effectiveDate: this.data.employeeSchedule.effectiveDate,
        employerWorkScheduleId: this.data.employeeSchedule.employerWorkScheduleId,
        isIrregular: this.data.employeeSchedule.isIrregular,
        nonWorkdays: this.data.employeeSchedule.scheduleNonWorkdays.map(snwd => snwd.id as number)
      });
      this.scheduleNonWorkdays = this.data.dialogData.scheduleNonWorkdays
        .find(snwd => snwd.scheduleId === this.data.employeeSchedule.employerWorkScheduleId);
      if (this.data.employeeSchedule.isIrregular) {
        this.form.controls.employerWorkScheduleId.disable();
      }
    }

    this.form.controls.employerWorkScheduleId.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.scheduleNonWorkdays = this.data.dialogData.scheduleNonWorkdays
          .find(snwd => snwd.scheduleId === res);
        if (this.scheduleNonWorkdays) {
          const valsNoDups = [...new Set([...this.form.controls.nonWorkdays.value, ...this.scheduleNonWorkdays.nonWorkdays])];
          this.form.controls.nonWorkdays.patchValue(valsNoDups);
        } else {
          this.scheduleNonWorkdays = null;
        }
      });

    this.form.controls.isIrregular.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.form.controls.employerWorkScheduleId.setValue(null, {emitEvent: false});
          this.form.controls.employerWorkScheduleId.disable();
        } else {
          this.form.controls.employerWorkScheduleId.enable();
        }
      });

    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.formInitValues = { ...this.form.value }
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public onSubmit(overrideUpdateConf: boolean = false): void {
    this.dialogRef.disableClose = true;
    const dto: EmployeeWorkSchedulePost = {
      employeeId: this.data.employeeId,
      overrideUpdateConf: overrideUpdateConf,
      employeeWorkScheduleId: this.data.employeeSchedule?.employeeWorkScheduleId ?? null,
      effectiveDate: this.form.controls.effectiveDate.value,
      employerWorkScheduleId: this.form.controls.employerWorkScheduleId.value,
      isIrregular: this.form.controls.isIrregular.value,
      nonWorkdays: this.form.controls.nonWorkdays.value
    }

    if (this.data.mode == WorkScheduleDialogMode.employeeRecord) {
      const postObs = this.data.employeeSchedule ? 
        this.manageEmployeeService.postEditEmployeeWorkSchedule(dto) : this.manageEmployeeService.postNewEmployeeWorkSchedule(dto);

      postObs.pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        }))
        .subscribe((res) => {
          if (res.showUpdateConfirmationDialog) {
            this.openUpdateConfirmationDialog(res as UpdateConfirmationDialog);
          } else {
            this.employeeRecordStore.employeeRecord = res as EmployeeRecord;
            this.dialogRef.close();
          }
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    } 
    
    else if (this.data.mode == WorkScheduleDialogMode.caseDetails) {
      dto.caseId = this.data.caseId;
      const postObs = this.data.employeeSchedule ? 
        this.caseDetailsService.postEditEmployeeWorkSchedule(dto) : this.caseDetailsService.postNewEmployeeWorkSchedule(dto);

      postObs.pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
          this.dialogRef.close();
        }))
        .subscribe((res) => {
          if (res.showUpdateConfirmationDialog) {
            this.openUpdateConfirmationDialog(res as UpdateConfirmationDialog);
          } else {
            this.caseDetailsStore.caseDetails = res as CaseDetails;
          }
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    }
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private openUpdateConfirmationDialog(updateConfirmationDialog: UpdateConfirmationDialog): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: "mat-dialog-container-mobileWidth",
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.updateConfSysText,
        updateConfirmationDialog: updateConfirmationDialog
      }
    };

    this.dialog.open(UpdateConfirmationComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.onSubmit(true);
        }
      });
  }
}