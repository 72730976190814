import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountFormOptions, ForgotPassword, Recipient, StiiraError } from '@core/models';
import { AccountService, ConstantsService, ErrorService, SnackbarService } from '@core/services';
import { AccountPagesSystemTextService } from '@core/services/account-pages-system-text.service';
import { FormGroup } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  isSubmitting$ = new Subject<boolean>();
  options: AccountFormOptions;
  successNotificationText: string;
  sysText$: Observable<any>;
  templateName: string;

  constructor(
    private acctService: AccountService,
    private acctTextService: AccountPagesSystemTextService,
    private constants: ConstantsService,
    private snackbarService: SnackbarService,
    private errorService: ErrorService,
    private router: Router  ) {}

  ngOnInit() {
    this.templateName = this.acctTextService.templates.forgotPassword;
    this.sysText$ = this.acctTextService.sysText$.pipe(
      pluck(this.templateName),
      tap((res) => (this.successNotificationText = res[`${this.templateName}_successNotification`]))
    );
    this.options = this.constants.ACCOUNTFORM_OPTIONS_FORGOTPASSWORD;
  }

  onFormSubmit(form: FormGroup): void {
    const forgotPasswordModel: ForgotPassword = {
      username: form.value.username,
    };

    this.acctService
      .forgotPassword(forgotPasswordModel)

      .pipe(indicate(this.isSubmitting$))
      .subscribe(
        (res) => {
            this.handleSuccess(res);
        },
        (err: StiiraError) => {
          this.errorService.setFormModelStateErrors(form, err.modelStateErrors);
        }
      );
  }

  private handleSuccess(recipient: Recipient): void {
    const successText: string = this.successNotificationText + recipient.email;
    this.snackbarService.open(successText, '', 5000);
    this.router.navigate(['/login']);
  }

}
