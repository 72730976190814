import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent extends DialogDragConstraints implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA)
    public sysText: any,
    private dialogRef: MatDialogRef<ComingSoonComponent>,
  ) {
    super(dialogRef);
  }

  ngOnInit() {}
}
