import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Sidenav, SidenavEmployeeInfo, SidenavMenuLink } from '@core/models';
import { AuthService, AvatarService, SidenavService, SysTextService } from '@core/services';
import { ComingSoonComponent } from '@modules/dialogs/coming-soon/coming-soon.component';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Output() linkClick = new EventEmitter<void>();
  @Output() onLogout = new EventEmitter<void>();

  public data$: Observable<Sidenav>;

  get employeeInfo$(): Observable<SidenavEmployeeInfo> {
    return this.avatarService.avatar$;
  }

  private readonly templateNames = {
    comingSoon: 'comingSoonDialog',
  };

  private comingSoonDialogData: any;
  private comingSoonDialogRef: MatDialogRef<ComingSoonComponent>;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private sidenavService: SidenavService,
    private avatarService: AvatarService,
    private authService: AuthService,
    private dialog: MatDialog,
    private textService: SysTextService,
  ) {}

  ngOnInit() {
    const data$ = this.sidenavService.getSidenavInfo();
    const sysText$ = this.textService.getSysText(this.templateNames).pipe(
      tap((res) => {
        this.comingSoonDialogData = res[this.templateNames.comingSoon];
      })
    );

    this.data$ = combineLatest([data$, sysText$]).pipe(
      map(([data, sysText]) => {
        return data;
      })
    );

    this.avatarService.sidenavUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        this.data$ = null;
        this.data$ = this.sidenavService.getSidenavInfo();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.onLogout.emit();
    this.linkClick.emit();
    this.authService.logout(true);
  }

  handleLinkClick(link: SidenavMenuLink): void {
    const route = link ? link.route : null;
    this.linkClick.emit();
  }

  openComingSoonDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '280px',
      restoreFocus: false,
      disableClose: false,
      closeOnNavigation: true,
      data: this.comingSoonDialogData,
    };

    this.comingSoonDialogRef = this.dialog.open(ComingSoonComponent, dialogConfig);
  }
}
