<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6 *ngIf="data.submitNewRequestDialog.isSubmitNewRequest">
    {{data.sysText.submitNewRequest_title | titlecase}}
  </h6>
  <h6 *ngIf="!data.submitNewRequestDialog.isSubmitNewRequest">
    {{data.sysText.submitNewRequest_addCaseTitle | titlecase}}
  </h6>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <div class="main-container">
      <app-autocomplete-field *ngIf="!isForSelf"
        [group]="form"
        [control]="form.controls.employeeId"
        [icon]="'person'"
        [label]="data.sysText.submitNewRequest_employee"
        [options]="data.submitNewRequestDialog.employeeOptions"
        [interfaceName]="'employee'"
        [isDisabled]="isNewEmployee"
        (hintClick)="onEEHintClick()"
        [hasClickableHint]="true"
        [showSearchIcon]="true"
        [showImage]="true"
        [comboMsError]="true">
      </app-autocomplete-field>
      <div class="new-employee" *ngIf="isNewEmployee && !isForSelf">
        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysText.submitNewRequest_firstName" 
              [control]="newEmployee.controls.firstName" 
              [group]="form">
            </app-input-field>
          </div>
          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysText.submitNewRequest_middleName" 
              [control]="newEmployee.controls.middleName" 
              [group]="form"
              [hideIconSpace]="!isMobile">
            </app-input-field>
          </div>
        </div>
        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysText.submitNewRequest_lastName" 
              [control]="newEmployee.controls.lastName" 
              [group]="form">
            </app-input-field>
          </div>
          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysText.submitNewRequest_suffix" 
              [control]="newEmployee.controls.suffix" 
              [group]="form"
              [hideIconSpace]="!isMobile">
            </app-input-field>
          </div>
        </div>
        <div class="new-ee-full-column">
          <app-input-field 
            [label]="data.sysText.submitNewRequest_preferredName" 
            [control]="newEmployee.controls.preferredName" 
            [group]="form">
          </app-input-field>
        </div>
        <div class="new-ee-full-column">
          <app-select-field
            *ngIf="data.submitNewRequestDialog.companyOptions?.length > 0"
            [group]="form"
            [control]="form.controls.companyId"
            [options]="data.submitNewRequestDialog.companyOptions"
            [label]="data.sysText.submitNewRequest_company"
            [shouldCapitalize]="false">
          </app-select-field>
        </div>
      </div>
      <app-email-field-group
        [group]="form"
        [workEmailControl]="workEmail"
        [isWorkEmailPreferredControl]="isWorkEmailPreferred"
        [personalEmailControl]="personalEmail"
        [isPersonalEmailPreferredControl]="isPersonalEmailPreferred">
      </app-email-field-group>
      <div class="sameRow-fields">
        <div class="sameRow-fields-left">
          <app-input-field
            [group]="form"
            [control]="form.controls.workPhoneNumber"
            [icon]="'phone'"
            [label]="data.sysText.submitNewRequest_workPhoneNumber"
            [inputType]="'string'">
          </app-input-field>
        </div>
        <div class="sameRow-fields-right">
          <app-input-field
            [hideIconSpace]="!isHandheld"
            [group]="form"
            [control]="form.controls.personalPhoneNumber"
            [label]="data.sysText.submitNewRequest_personalPhoneNumber"
            [inputType]="'string'">
          </app-input-field>
        </div>
      </div>
      <div class="sameRow-fields">
        <div class="sameRow-fields-left">
          <app-date-field
            [label]="data.sysText.submitNewRequest_startDate"
            [control]="form.controls.startDate"
            [group]="form"
            [icon]="'date_range'"
            (emitDateChange)="updateEndDate()">
          </app-date-field>
        </div>
        <div class="sameRow-fields-right">
          <app-date-field
            [hideIconSpace]="!isHandheld"
            [label]="data.sysText.submitNewRequest_endDate"
            [control]="form.controls.endDate"
            [group]="form">
          </app-date-field>
        </div>
      </div>
      <app-select-field
        [group]="form"
        [control]="form.controls.leaveReasonId"
        [options]="data.submitNewRequestDialog.leaveReasonOptions"
        [icon]="'help_outline'"
        [label]="data.sysText.submitNewRequest_leaveReason"
        [shouldCapitalize]="false">
      </app-select-field>
      <app-input-field
        [group]="form"
        [control]="form.controls.comments"
        [icon]="'comment'"
        [label]="data.sysText.submitNewRequest_comments">
      </app-input-field>

      <div class="section-header-container">
        <p class="overline grey1 semibold section-title">{{data.sysText.submitNewRequest_documents | uppercase}}</p>
        <mat-divider class="section-header-underline"></mat-divider>
      </div>
      <input hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files)"/>
      <div class="file-input-field">
        <mat-icon class="input-icon">attach_file</mat-icon>
        <button mat-stroked-button color="accent" (click)="imgFileInput.click()" class="body2">
          <mat-icon>upload</mat-icon>
          <span> {{data.sysText.submitNewRequest_upload | capitalizefirst}} </span>
        </button>
      </div>
      <div class="pad" *ngIf="fileToUpload">
        <div class="flex-horiz bot-space">
          <mat-icon class="fill4-dk">check</mat-icon>
          <div>
            <span class="body2 side-space grey3">{{ fileToUpload?.name }}</span>
          </div>
          <mat-icon class="black-inactive small pointer" (click)="clearFile()">clear</mat-icon>
        </div>
      </div>
      <app-input-field
        [group]="form"
        [control]="form.controls.documentTitle"
        [icon]="'description'"
        [label]="data.sysText.submitNewRequest_documentTitle">
      </app-input-field>
      <app-select-field
        [group]="form"
        [control]="form.controls.documentCategory"
        [options]="data.submitNewRequestDialog.documentCategoryOptions"
        [icon]="'list'"
        [label]="data.sysText.submitNewRequest_documentCategory"
        [shouldCapitalize]="false">
      </app-select-field>
      <app-input-field
        [control]="form.controls.documentComments"
        [label]="data.sysText.submitNewRequest_docComments"
        [inputType]="'string'"
        [icon]="'comment'">
      </app-input-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-actions" align="end">
  <div *ngIf="!isForSelf" class="slide-container">
    <app-slide-toggle
      [control]="form.controls.notifyEmployee"
      [isDisabled]="(isSubmitting$ | async)"
      [label]="data.sysText.submitNewRequest_notifyEmployee">
    </app-slide-toggle>
  </div>
  <ng-container *ngIf="!(isSubmitting$ | async); else submitting">
    <div>
      <button *ngIf="!isMobile"
        mat-stroked-button 
        class="button" 
        color="accent" 
        (click)="close(false)">
        {{data.sysText.cancel}}
      </button>
      <button
        mat-stroked-button 
        class="button" 
        color="accent" 
        [disabled]="isSubmitting$ | async" 
        (click)="onSubmit(false)">
        {{data.sysText.submit}}
      </button>
    </div>
  </ng-container>
  <ng-template #submitting>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
  </ng-template>
</mat-dialog-actions>
