import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
})
export class StatusChipComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;
  @Input() tooltip: string;

  @Input() set removeMargin(val: boolean) {
    this.margin = val;
  }

  @HostBinding('class.margin') margin: boolean;

  constructor() {}

  ngOnInit(): void {}
}
