import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailNotice } from '@core/models/help-and-feedback/email-notice.model';
import { HelpAndFeedback } from '@core/models/help-and-feedback/help-and-feedback.model';
import { Observable } from 'rxjs';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class HelpAndFeedbackService {

  constructor(private constants: ConstantsService, private http: HttpClient) { }

  public getHelpAndFeedback(): Observable<HelpAndFeedback> {
    return this.http.get<HelpAndFeedback>(`${this.constants.HELPANDFEEDBACK_API}/getHelpAndFeedback`);
    //return of(HELPANDFEEDBACK).pipe(delay(1000));
  }

  public postContactAdmin(formData: FormData): Observable<EmailNotice> {
    return this.http.post<EmailNotice>(`${this.constants.HELPANDFEEDBACK_API}/postContactAdmin`, formData)
    //return of(EMAILNOTICE).pipe(delay(1000));
  }
}
