import { Component, HostBinding, OnInit } from '@angular/core';
import { AccountPagesSysText } from '@core/models';
import { AnonymousNewRequest } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { EmployerLogo } from '@core/models/leave-admin/anonymous-request/employer-logo.model';
import { AccountService, GlobalLoaderService, SysTextService } from '@core/services';
import { AccountPagesSystemTextService } from '@core/services/account-pages-system-text.service';
import { AnonymousRequestsService } from '@core/services/anonymous-requests.service';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @HostBinding('class.bg-img') showBgImg: boolean;
  public data$: Observable<{
    sysText: any;
    anonymousInfo: AnonymousNewRequest;
  }>;
  public employerLogo$: Observable<EmployerLogo>;
  public customEmployerLogo: string = null;

  private templates: any;

  constructor(
    private textService: SysTextService,
    private anonymousService: AnonymousRequestsService,
    private accountService: AccountService,
    private acctTextService: AccountPagesSystemTextService,
    private globalLoader: GlobalLoaderService
  ) {
    this.templates = acctTextService.templates;
    this.globalLoader.isLoading$.pipe(tap((res) => (this.showBgImg = !res))).subscribe();
  }

  ngOnInit() {
    this.employerLogo$ = this.anonymousService.getEmployerLogo();

    const sysText$ = this.textService
      .getSysText(this.templates)
      .pipe(tap((res: AccountPagesSysText) => (this.acctTextService.sysText = res)));
    
    const anonymousInfo$ = this.anonymousService
      .getAnonymousNewRequest()
      .pipe(tap((res: AnonymousNewRequest) => (this.accountService.setAnonymousNewRequest(res))));

    this.data$ = combineLatest([sysText$, anonymousInfo$]).pipe(
      map(([sysText, anonymousInfo]) => {
        return { sysText, anonymousInfo};
      })
    );
  }
}
