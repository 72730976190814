import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formattedphonenumber'
})

export class FormattedphonenumberPipe implements PipeTransform  {
  transform(value: string): string {
    var formattedNumber = value;

    // if the string isn't only digits, don't change anything 

    if (value == null || value.length == 0 || !(/^\d+$/.test(value))) {
      return value;
    }

    // check for one of 7, 10, or 11 digits
    // 1234567 == 123-4567
    // 1234567890 == (123) 456-7890
    // 12345678901 == 1 (234) 567-8901

    if (formattedNumber.length == 7 ) {
        // 1234567 == 123-4567
        return `${formattedNumber.substring(0, 3)}-${formattedNumber.substring(3)}`;
    }
    else if (formattedNumber.length == 10) {
        // 1234567890 == (123) 456-7890
        return `(${formattedNumber.substring(0, 3)}) ${formattedNumber.substring(3, 6)}-${formattedNumber.substring(6)}`;
    }
    else if (formattedNumber.length == 11) {
        // 12345678901 == 1 (234) 567-8901
        return `${formattedNumber.substring(0, 1)} (${formattedNumber.substring(1, 4)}) ${formattedNumber.substring(4, 7)}-${formattedNumber.substring(7)}`;
    }

    return formattedNumber;
  }
}
