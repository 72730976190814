<editor
	#subjectEditor
	[formControl]="control"
	[init]="{ 
		height: 103,
		width: '100%',
		menubar: 'placeholders',
		menu: {
			placeholders: {
				title: 'Placeholders', 
				items: placeholdersMenuItems 
			}
		},
		statusbar: false,
		toolbar: 'placeholders',
		setup: subjectEditorSetup,
		paste_block_drop: true,
		paste_as_text: true,
		promotion: false,
		placeholder: placeholderText,
		content_style: editorStyle
	}"
></editor>
<mat-error class="error">{{ control.errors?.msError }}</mat-error>