import { Injectable } from '@angular/core';
import { LeaveAdminDashboardForm } from '@core/models/leave-admin/leave-admin-dashboard-form.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { LeaveTab } from '@core/enums/leave-category.enum';
import { nameof } from '@shared/helpers';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})

export class LeaveAdminDashboardFormService {
  public form: FormGroup<LeaveAdminDashboardForm>;
  public isDirty: boolean;
  public isNew: boolean = true;
  public categoryId: number;
  public selectedIndex: number;
  public pageIndex: number;
  public pageSize: number;
  public isOpen: boolean;
  public isClosed: boolean;
  public isAll: boolean;
  public isHidden: boolean;
  public triggerFilter$ = new Subject<any>();
  public triggerCategoryChange$ = new Subject<number>();

  private matSortData: Sort;
  private tabCountsSubject = new BehaviorSubject<{[key: number]: number }>({});
  private filterCount: number = 0;
  
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group<LeaveAdminDashboardForm>({
      company: new FormControl(null),
      leaveType: new FormControl(null),
      stage: new FormControl(null),
      adminId: new FormControl(null),
      actionNeededId: new FormControl(null),
      category: new FormControl(null),
      searchValue: new FormControl(null),
      showHidden: new FormControl(null)
    });

    this.form.valueChanges.subscribe(()=>{
      this.isDirty = true;
      this.filterCount = this.getActiveFilterCount();
    });
  }

  public get isChanged(): boolean {
    return this.isDirty;
  }

  public get companyControl(): FormControl<number> {
    return this.form.controls.company as FormControl<number>;
  }

  public get leaveTypeControl(): FormControl<number> {
    return this.form.controls.leaveType as FormControl<number>;
  }

  public get stageControl(): FormControl<number> {
    return this.form.controls.stage as FormControl<number>;
  }

  public get adminControl(): FormControl<number> {
    return this.form.controls.adminId as FormControl<number>;
  }

  public get actionNeededControl(): FormControl<number> {
    return this.form.controls.actionNeededId as FormControl<number>;
  }

  public get categoryControl(): FormControl<number> {
    return this.form.controls.category as FormControl<number>;
  }

  public get hiddenControl(): FormControl<boolean> {
    return this.form.controls.showHidden as FormControl<boolean>;
  }

  public get searchValue(): FormControl<string> {
    return this.form.controls.searchValue as FormControl<string>;
  }

  public get activeFilterCount(): number {
    return this.filterCount;
  }

  public get tabCounts$() {
    return this.tabCountsSubject.asObservable()
  }

  public set sortData(sortData: Sort) {
    this.matSortData = sortData;
  }

  public get sortData(): Sort {
    return this.matSortData;
  }

  public triggerFilter(): void {
    this.triggerFilter$.next();
  }

  public updateTabCounts(tabCounts: {[key: number]: number }): void {
    this.tabCountsSubject.next(tabCounts)
  }
  
  public applyTabCategory() {
    const categoryId: number = this.isNew ? null : 
      this.isOpen ? LeaveTab.Open : 
      this.isClosed ? LeaveTab.Closed : 
      this.isAll ? LeaveTab.All : 
      this.isHidden ? LeaveTab.Hidden : 
      this.categoryId;
    this.triggerCategoryChange$.next(categoryId);
  }

  public unloadStore() {
    this.form.patchValue({
      company: null,
      leaveType: null,
      stage: null,
      adminId: null,
      actionNeededId: null,
      category: null,
      searchValue: null,
      showHidden: null
    });

    this.isDirty = null;
    this.isNew = true;
    this.categoryId = null;
    this.selectedIndex = null;
    this.pageIndex = null;
    this.pageSize = null;
    this.isOpen = null;
    this.isClosed = null;
    this.isAll = null;  
    this.isHidden = null;
    this.matSortData = null;
  }

  public getActiveFilterCount(): number {
    let count = 0;
    for (const field in this.form.controls){
      const val = this.form.get(field).value;
      if (val && field != nameof<LeaveAdminDashboardForm>('searchValue')) {
        count ++;
      }
    }
    return count;
  }
}