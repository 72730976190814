export enum CalendarEventTypes {
    Hours = -1,
    AddHours = -2,
    ActionItem = -3,
    // background cell events...
    BgWorkdayCell = -4,
    BgNonWorkdayCell = -5,
    BgBlankCell = -6,
    BgBlankCellNoTitle = -7
}

export enum CalendarEventFlags {
    Warning = -1,
    Exhausted = -2
}