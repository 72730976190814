import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IdleTimerConfig } from '@core/models';
import { environment } from '@environments/environment';
import { IdleAlertComponent } from '@modules/dialogs/idle-alert/idle-alert.component';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { AuthService } from './auth.service';
import { ConstantsService } from './constants.service';
import { NotificationService } from './notification.service';
import { finalize, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IdleTimerService {
  private _dialogIsOpen: boolean;

  private config: IdleTimerConfig;
  private idleDialogRef: MatDialogRef<IdleAlertComponent>;
  private timerReady: boolean = false;
  private targetTimeMs: number;
  private timeRemaingtargetTimeMs: number;
  private idleTimeLimitMs: number;
  private alertUserTimeLeftMs: number;
  private restartTimerSubject = new Subject();
  private stopRemainingTimerSubject: Subject<boolean>;
  private idleTimeSubject: BehaviorSubject<number>;
  private idleTime$: Observable<number>;
  private timeRemainingSubject: BehaviorSubject<number>;
  private timeRemaining$: Observable<number>;

  get dialogIsOpen(): boolean {
    return this._dialogIsOpen;
  }

  get idleTime(): number {
    return this.idleTimeSubject.value;
  }

  get timeRemaining(): number {
    return this.timeRemainingSubject.value;
  }

  constructor(
    private authService: AuthService,
    private notificationServices: NotificationService,
    private dialog: MatDialog,
    private constants: ConstantsService,
    private http: HttpClient
  ) {

    if (environment.disableIdleTimeout === false) {
      this.config = this.constants.IDLETIMERCONFIG;
      this.idleTimeLimitMs = this.config.idleTimeLimit * 1000;
      this.alertUserTimeLeftMs = this.config.alertUserTimeLeft * 1000;

      //calculate targetTime ms by getting idleTimeLimit(seconds) * 1000 and adding to current time ms minus the alertUserTimeLeft(seconds) * 1000
      this.targetTimeMs = (Date.now() + this.idleTimeLimitMs) - this.alertUserTimeLeftMs;

      this.getSessionTimeout()
        .pipe(
          finalize(()=>{
            this.prepareTimer();
            this.startTimer();
          })
        )
        .subscribe((res) => {
          if (res) {
            this.config.idleTimeLimit = +res;
            this.idleTimeLimitMs = this.config.idleTimeLimit * 1000;
            //if config.idleTimeLimit gets overwritten here recalc targetTime ms
            this.targetTimeMs = (Date.now() + this.idleTimeLimitMs) - this.alertUserTimeLeftMs;
          }
        });
    }
  }

  getSessionTimeout(): Observable<string> {
    return this.http.get<string>(`${this.constants.EEPORTAL_API}/getsessiontimeout`);    
  }

  resetTimer(): void {
    if (environment.disableIdleTimeout === false && this.timerReady) {
      this.targetTimeMs = (Date.now() + this.idleTimeLimitMs) - this.alertUserTimeLeftMs;
      this.idleTimeSubject.next(this.idleTimeLimitMs- this.alertUserTimeLeftMs);
      this.restartTimerSubject.next(void 0);
    }
    return;
  }

  private endUserSession(): void {
    this.authService.logout();
    this.dialog.closeAll();
    this.resetTimer();
  }

  private prepareTimer(): void {
    this.idleTimeSubject = new BehaviorSubject<number>(this.idleTimeLimitMs - this.alertUserTimeLeftMs);
    this.idleTime$ = this.idleTimeSubject.asObservable();
    this.timeRemainingSubject = new BehaviorSubject<number>(this.config.alertUserTimeLeft);
    this.timeRemaining$ = this.timeRemainingSubject.asObservable();
    this.stopRemainingTimerSubject = new Subject<boolean>();
    this.timerReady = true;
  }

  private startTimer(): void {
    this.restartTimerSubject
    .pipe(
      startWith(0),
      switchMap(() => timer(this.idleTime)),
    )
    .subscribe(() => {
      if (this.authService.isAuth || this.authService.isImpersonating) {
        this.idleCheck();
      }
    });
  }

  private idleCheck(): void {
    let currentTimeMs = Date.now();
     if (currentTimeMs >= this.targetTimeMs){
      this.timeRemaingtargetTimeMs = currentTimeMs;
      this.timeRemainingSubject.next(this.config.alertUserTimeLeft);
      this.timeRemainingTimer();
    } else {
      this.idleTimeSubject.next(this.targetTimeMs - currentTimeMs);
      this.restartTimerSubject.next(void 0);
    }
  }

  private timeRemainingTimer(): void {
      this.timeRemainingSubject
      .pipe(    
        startWith(0),  //startWith operator is required to trigger creating the first timer on subscription
        switchMap(() => timer(1000)),  //switchMap unsubscribes the previous timer and subscribes to the new one that start all over again.
        takeUntil(this.stopRemainingTimerSubject)  //setting stopRemainingTimerSubject.next when closing the counter dialog in openIdleDialog() stops the counter from popping right back up 
      )
      .subscribe(() => {

        if (this.timeRemaining <= 0) {
          this.handleTimesUp();
        } 
        if (this.timeRemaining > 0) {
          this.openOrUpdateDialog();
        } 

        this.timeRemaingtargetTimeMs = (Date.now() + 1000);
      }) 
  }

  private handleTimesUp(): void {
    this.authService.setIsImpersonating(false);
    this.endUserSession();
    this.notificationServices.showInfoToast('Session Expired', 'You have been logged out due to inactivity.');
  }

  private openOrUpdateDialog(): void {
    let currentTimeMs = Date.now();

    if (!this._dialogIsOpen) {    
      this.timeRemainingSubject.next(this.config.alertUserTimeLeft); 
      this.openIdleDialog();
    } else {
 
      let timeLapsedSeconds = Math.round((currentTimeMs - this.timeRemaingtargetTimeMs) / 1000);
      let isMoreThanOneSecond = Boolean((timeLapsedSeconds) > 1);
      this.timeRemainingSubject.next(!isMoreThanOneSecond ? 
                                      this.timeRemaining - 1 : 
                                      this.timeRemaining - timeLapsedSeconds < 0 ? 
                                          0 : 
                                          this.timeRemaining - timeLapsedSeconds );


      this.idleDialogRef.componentInstance.data = {
        timeRemaining: this.timeRemainingSubject.value,
      };
    }
 }

  private openIdleDialog(): void {
    const dialogConfig: MatDialogConfig = {
      disableClose: false,
      closeOnNavigation: true,
      data: { timeRemaining: this.timeRemaining },
    };

    this._dialogIsOpen = true;
    this.idleDialogRef = this.dialog.open(IdleAlertComponent, dialogConfig);
    this.idleDialogRef.afterClosed().subscribe((continueWork: boolean) => {
        this.stopRemainingTimerSubject.next(true);
        this.onDialogClose(continueWork);
      }
    );
  }

  private onDialogClose(continueWorK: boolean): void {
    if (continueWorK) {
      this.resetTimer();
    } else {
      this.endUserSession();
    }

   this._dialogIsOpen = false;
  }

}
