import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { HideCaseDialog, HideCaseForm, HideCasePost } from '@core/models/leave-admin/hide-case.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { ErrorService, LayoutService } from '@core/services';
import { Validators } from '@angular/forms';
import { SelectionOption, StiiraError } from '@core/models';
import { indicate } from '@shared/helpers';
import { finalize, takeUntil } from 'rxjs/operators';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-hide-case',
  templateUrl: './hide-case.component.html',
  styleUrls: ['./hide-case.component.scss']
})
export class HideCaseComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  public form: FormGroup<HideCaseForm>;
  public canClose: boolean;
  public isSaving$: Subject<boolean>;
  
  private formInitValues: any;
  private hideCasePost: HideCasePost;
  private destroy$: Subject<void> = new Subject<void>();

  public hideReasonOptions: SelectionOption[] = [];

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get hideReasonId(): FormControl {
    return this.form.controls.hideReasonId as FormControl;
  }

  get requiresComments(): boolean {
    return this.data.hcdata.hideReasons.find(hr => hr.hideReasonId == this.hideReasonId.value)?.requiresComments == true;
  }

  get comments(): FormControl {
    return this.form.controls.comments as FormControl;
  }

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      caseId: number, 
      hcdata: HideCaseDialog; 
      sysText: any },
    private hideCaseDialogComponent: MatDialogRef<HideCaseComponent>,
    private service: LeaveAdminService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private errorService: ErrorService, 
    private layoutService: LayoutService,
  ) {
    super(hideCaseDialogComponent);
    
    this.isSaving$ = new Subject();
    this.form = this.fb.group<HideCaseForm>({
      caseId: this.fb.control(this.data.caseId),
      hideReasonId: this.fb.control(null, Validators.required),
      comments: this.fb.control(null) })
  }

  ngOnInit(): void {
    this.formInitValues = { ...this.form.value };

    this.comments.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=> {
        if (!this.noChanges)
          this.isEditing.emit(true);
        });

    this.data.hcdata.hideReasons
      .forEach(hr => this.hideReasonOptions
        .push({ 
          id: hr.hideReasonId, 
          description: hr.name }
        ));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public hideCase(): void {
    this.hideCaseDialogComponent.disableClose = true;

    this.hideCasePost = {
      caseId: this.data.caseId,
      hideReasonId: this.hideReasonId.value,
      comments: this.comments.value };

    this.service.postHideCase(this.hideCasePost)
    .pipe(
      indicate(this.isSaving$),
      finalize(
        () => {
          this.hideCaseDialogComponent.disableClose = false; 
        }))
    .subscribe(
      (res) => { 
        this.hideCaseDialogComponent.close(res);
      },
      (err: StiiraError) => {
        this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors);
      });
  }

  public cancel(): void {
    if (this.noChanges)
      this.hideCaseDialogComponent.close();
    else
      this.openUnsavedChangesDialog();
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysText.leavePage,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed()
      .subscribe(
        (res: boolean) => {
          if (res)
            this.hideCaseDialogComponent.close(); 
        });
  }

  public hideReasonChangeHandler (): void {
    this.isEditing.emit(true);

    if (this.requiresComments)
      this.comments.setValidators(Validators.required);
    else
      this.comments.removeValidators(Validators.required);

    this.form.updateValueAndValidity();
    this.form.markAsTouched();
  }
}