import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionOption } from '@core/models';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { FormControl, FormGroup } from '@angular/forms';
import { searchIncludes } from '@shared/helpers/search.helpers';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

@Component({
  selector: 'app-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.scss'],
})
export class AutocompleteFieldComponent implements OnInit {
  @ViewChild('input') input: any;

  @Input() public control: FormControl = new FormControl();
  @Input() public group: FormGroup;
  @Input() public hasClickableHint: boolean;
  @Input() public hint: string;
  @Input() public icon: string;
  @Input() public interfaceName: string;
  @Input() public secondaryInterfaceName: string;
  @Input() public isHintHighlighted: boolean = true;
  @Input() public isHintWarning: boolean = false;
  @Input() public isSimple: boolean;
  @Input() public label: string;
  @Input() public options: SelectionOption[] | string[];
  @Input() public showImage: boolean;
  @Input() public showSearchIcon: boolean;
  @Input() public showHintAndError: boolean = false;
  @Input() public msErrorKey: string;
  @Input() public customMsError: string;
  @Input() public shouldCasePipe: boolean = true;
  @Input() public comboMsError: boolean = false;
  @Input() public showSpinner: boolean = false;
  @Input() public showClear: boolean = true;
  @Input() public companyName: BehaviorSubject<string> = null;

  @Output() public hintClick = new EventEmitter<void>();
  @Output() public optionSelect = new EventEmitter<void>()

  public _isDisabled: boolean;
  public filteredSelectionOptions: BehaviorSubject<SelectionOption[]> = new BehaviorSubject<SelectionOption[]>(null);
  public filteredStringOptions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public sysText: any;

  constructor(private text: SharedComponentsSysTextService) {}

  @Input() public set isDisabled(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
    this._isDisabled = isDisabled;
  }

  public displayFn(id: number): string {
    if (id == null)
      return '';

    return (this.options as SelectionOption[]).find((opt) => opt.id === id)?.description;
  }

  public ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.autocompleteField];

    if (!this.isSimple) {
      this.control.valueChanges.subscribe((opt) => this.filterOptions(opt));
      this.filteredSelectionOptions.next(this.options as SelectionOption[]);
    } 
    else {
      this.control.valueChanges.subscribe((opt) => this.filterOptions(opt));
      this.filteredStringOptions.next(this.options as string[]);
    }

    if (this.companyName != null)
      this.companyName
        .asObservable()
        .subscribe((cn) => this.filterOptionsComplex(this.control.value));
  }

  public onHintClick(): void {
    this.hintClick.emit();
  }

  public onOptionSelect() {
    this.optionSelect.emit();
  }

  public clearInput(event: any): void {
    event.stopPropagation();
    this.control?.reset();
    this.input.focus();
  }

  public hasData(): boolean {
    return this.control?.value !== null && this.control?.value !== '' && !Number.isNaN(this.control?.value);
  }

  private filterOptions(value: string | number): void {
    const options = this.isSimple 
      ? this.filterOptionsSimple(value as string) 
      : this.filterOptionsComplex(value);
  }

  private filterOptionsComplex(value: number | string): void {
    let options: SelectionOption[] = null;
      
    if (typeof value == 'string' || typeof value == 'object') {
      let filterValue = (value as string)?.toLowerCase()?.trim();
      let companyName = this.companyName?.getValue();
  
      if ((companyName == null || companyName.length == 0) && (filterValue == null || filterValue.length == 0))
        options = (this.options as SelectionOption[]);
      else
        options = (this.options as SelectionOption[]).filter((opt) => {
          let companyMatch = companyName == null || companyName.length == 0 
            ? true 
            : companyName == opt.companyName;

          let optionValues = (`${opt.description ?? ''} ${opt.companyName ?? ''} ${opt.department ?? ''} ${opt.workEmail ?? ''} ${opt.personalEmail ?? ''}`)
            .toLowerCase();

          let filterMatch = filterValue == null || filterValue.length == 0
            ? true
            : searchIncludes(optionValues, filterValue);

          return companyMatch && filterMatch;
        });
    }
    else if (typeof value == 'number') {
      options = (this.options as SelectionOption[]).filter((opt) => opt.id == (value as number));
    }

    this.filteredSelectionOptions.next(options);
  }

  private filterOptionsSimple(value: string): void {
    let filterValue = (value as string)?.toLowerCase();
    let options = null;

    if (filterValue == null || filterValue.length == 0)
      options = this.options;
    else
      options = (this.options as string[]).filter((opt) => opt.toLowerCase().includes(filterValue));
    
    this.filteredStringOptions.next(options);
  }
}
