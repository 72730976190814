import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService, GlobalLoaderService, SecurityQuestionsService } from '@core/services';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SetPasswordGuard  {
  constructor(
    private router: Router,
    private acctService: AccountService,
    private sqService: SecurityQuestionsService,
    private globalLoader: GlobalLoaderService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.sqService.hasCorrectSqAnswers) {
      this.acctService.setUsername(this.router.getCurrentNavigation().extras.state.username);
      return true;
    }

    const username: string = next.queryParams.username;
    const code: string = next.queryParams.code;
    if (username && code) {
      this.globalLoader.show();
      return this.acctService.checkFirstTimeAccess(username).pipe(
        map((res: boolean) => {
          if (res) {
            this.acctService.setUsername(username);
            this.acctService.setCode(code);

            // handle stacked directive (dir2)
            // example query: http://localhost:4200/set-password?username=johndoe2753&dir=fta&code=12345&dir2=cd&cid=300079
            const { dir2 = '', cid = ''} = { ...next.queryParams };
            if (dir2) {
              this.acctService.setDir2(dir2);
            }
            if (cid) {
              this.acctService.setCaseId(cid);
            }
            
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        }),
        catchError((err) => {
          this.router.navigate(['/login']);
          return of(false);
        }),
        finalize(() => this.globalLoader.hide())
      );
    }

    this.router.navigate(['/login']);
    return false;
  }
}
