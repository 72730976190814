<!-- Navigational Items -->
<ng-container *ngIf="link.route; else logout">
  <a
    mat-list-item
    [routerLink]="route"
    [routerLinkActive]="route ? 'active-list-item' : ''"
    (click)="handleLinkClick(link)"
  >
    <span class="item-content">
      <mat-icon matListIcon>{{ link.symbol }}</mat-icon>
      <span class="body2" matLine>{{ link.title }}</span>
    </span>
  </a>
</ng-container>

<!-- Logout Item -->
<ng-template #logout>
  <a mat-list-item (click)="handleLogout()">
    <span class="item-content">
      <mat-icon matListIcon>{{ link.symbol }}</mat-icon>
      <span class="body2" matLine>{{ link.title }}</span>
    </span>
  </a>
</ng-template>
