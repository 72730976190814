import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { EmployerContact, EmployerContactsDialog, EmployerContactsPost } from '@core/models/leave-admin/employers/employer-contacts.model';
import { ErrorService, LayoutService } from '@core/services';
import { EmployerContactsFormService } from '@core/services/employer-contacts-form.service';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { Validators } from '@angular/forms';
import { EmployerProfileStoreService } from '@core/services/employer-profile-store.service';
import { EmployerProfile } from '@core/models/leave-admin/employers/employer-profile.model';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { DuplicateDetectedDialogComponent } from '../duplicate-detected-dialog/duplicate-detected-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-employer-contacts',
  templateUrl: './edit-employer-contacts.component.html',
  styleUrls: ['./edit-employer-contacts.component.scss'],
  providers: [EmployerContactsFormService],
})
export class EditEmployerContactsComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public isNewPrimaryTenantAdmin: boolean = false;
  public isNewPrimaryEmployerAdmin: boolean = false;
  public isNewHelpFeedbackAdmin: boolean = false;
  public isNewMainContact: boolean[] = [];
  public isNewBenefitsContact: boolean[] = [];
  public isNewBenefitDeductionsContact: boolean[] = [];
  public isNewPayrollContact: boolean[] = [];
  public isHandheld: boolean;
  public isSaving$: Subject<boolean> = new Subject<boolean>();
  public formInitValues: any;

  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get ecForm(): FormGroup {
    return this.ecFormService.form;
  } 

  get primaryTenantAdminForm(): FormGroup {
    return this.ecForm.get('primaryTenantAdmin') as FormGroup;
  } 

  get primaryEmployerAdminForm(): FormGroup {
    return this.ecForm.get('primaryEmployerAdmin') as FormGroup;
  } 

  get helpFeedbackAdminForm(): FormGroup {
    return this.ecForm.get('helpFeedbackAdmin') as FormGroup;
  } 

  get includeCaseAdminsOnHelpForm(): FormControl {
    return this.ecForm.get('includeCaseAdminsOnHelpForm') as FormControl;
  } 

  get showHelpAndFeedbackOnCaseDetails(): FormControl {
    return this.ecForm.get('showHelpAndFeedbackOnCaseDetails') as FormControl;
  } 

  get mainContactsArray(): FormArray {
    return this.ecForm.get('mainContacts') as FormArray;
  } 

  get benefitsContactsArray(): FormArray {
    return this.ecForm.get('benefitsContacts') as FormArray;
  }

  get benefitDeductionsContactsArray(): FormArray {
    return this.ecForm.get('benefitDeductionsContacts') as FormArray;
  }

  get payrollContactsArray(): FormArray {
    return this.ecForm.get('payrollContacts') as FormArray;
  }

  get noChanges(): boolean {
    return JSON.stringify(this.ecForm.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get formIsValid(): boolean {
    return this.ecForm.valid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      employerId: number,
      employerContacts: EmployerContactsDialog;
      sysText: any
    },
    private employerContactsEditDialogRef: MatDialogRef<EditEmployerContactsComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private manageEmployersService: ManageEmployersService,
    private errorService: ErrorService,
    private ecFormService: EmployerContactsFormService,
    private layoutService: LayoutService,
    private store: EmployerProfileStoreService,
  ) { 
    super(employerContactsEditDialogRef);
  }

  ngOnInit(): void {
    this.isHandheld = this.layoutService.isHandheld;

    //one form group for each array is automatically created in employer-contacts-form service.
    // if there are more profile contacts stored add additional controls so values can be patched in loadFormForEdit
    for (let i = 1; i < this.data.employerContacts.profileEmployerContacts.mainContacts.length; i++) {
      this.addMainContactFormGroup();
    }
    for (let i = 1; i < this.data.employerContacts.profileEmployerContacts.benefitsContacts.length; i++) {
      this.addBenefitsContactFormGroup();
    }
    for (let i = 1; i < this.data.employerContacts.profileEmployerContacts.benefitDeductionsContacts.length; i++) {
      this.addBenefitDeductionsContactFormGroup();
    }
    for (let i = 1; i < this.data.employerContacts.profileEmployerContacts.payrollContacts.length; i++) {
      this.addPayrollContactFormGroup();
    }

    this.ecFormService.loadFormForEdit(this.data.employerContacts);
    this.formInitValues = JSON.parse(JSON.stringify(this.ecForm.value));

    this.ecForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.formChangeEmitted && !this.noChanges) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addMainContactFormGroup() {
    this.addContactFormGroup(this.isNewMainContact, this.mainContactsArray);
  }

  public addBenefitsContactFormGroup() {
    this.addContactFormGroup(this.isNewBenefitsContact, this.benefitsContactsArray);
  }

  public addBenefitDeductionsContactFormGroup() {
    this.addContactFormGroup(this.isNewBenefitDeductionsContact, this.benefitDeductionsContactsArray);
  }

  public addPayrollContactFormGroup() {
    this.addContactFormGroup(this.isNewPayrollContact, this.payrollContactsArray);
  }

  public addContactFormGroup(newContactToggle: boolean[], formArray: FormArray): void {
    const createContactGroup = this.fb.group({
      employeeId: [null],
      firstName: [null],
      middleName: [null],
      lastName: [null],
      suffix: [null],
      preferredName: [null],
      workPhone: [null],
      personalPhone: [null],
      workEmail: [null],
      workEmailChecked: [null],
      personalEmail: [null],
      personalEmailChecked: [null]
    });

    formArray.push(createContactGroup);
    newContactToggle.push(false);
  }


  public onAddNewPrimaryTenantAdmin(): void {
    this.isNewPrimaryTenantAdmin = !this.isNewPrimaryTenantAdmin
    this.onAddNewEmployee(this.isNewPrimaryTenantAdmin, this.primaryTenantAdminForm);
  }

  public onAddNewPrimaryEmployerAdmin(): void {
    this.isNewPrimaryEmployerAdmin = !this.isNewPrimaryEmployerAdmin
    this.onAddNewEmployee(this.isNewPrimaryEmployerAdmin, this.primaryEmployerAdminForm);
  }

  public onAddNewHelpFeedbackAdmin(): void {
    this.isNewHelpFeedbackAdmin = !this.isNewHelpFeedbackAdmin
    this.onAddNewEmployee(this.isNewHelpFeedbackAdmin, this.helpFeedbackAdminForm);
  }

  public onAddNewMainContact(index: number): void {
    this.isNewMainContact[index] = !this.isNewMainContact[index]
    let formgroup: FormGroup = this.mainContactsArray.controls[index] as FormGroup;
    this.onAddNewEmployee(this.isNewMainContact[index], formgroup);
  }

  public onAddNewBenefitContact(index: number) {
    this.isNewBenefitsContact[index] = !this.isNewBenefitsContact[index]
    let formgroup: FormGroup = this.benefitsContactsArray.controls[index] as FormGroup;
    this.onAddNewEmployee(this.isNewBenefitsContact[index], formgroup);
  }

  public onAddNewBenefitDeductionsContact(index: number) {
    this.isNewBenefitDeductionsContact[index] = !this.isNewBenefitDeductionsContact[index]
    let formgroup: FormGroup = this.benefitDeductionsContactsArray.controls[index] as FormGroup;
    this.onAddNewEmployee(this.isNewBenefitDeductionsContact[index], formgroup);
  }

  public onAddNewPayrollContact(index: number) {
    this.isNewPayrollContact[index] = !this.isNewPayrollContact[index]
    let formgroup: FormGroup = this.payrollContactsArray.controls[index] as FormGroup;
    this.onAddNewEmployee(this.isNewPayrollContact[index], formgroup);
  }

  public onAddNewEmployee(toggleNewFields: boolean, formGroup: FormGroup): void {
    formGroup.patchValue({
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      preferredName: null,
      workPhone: null,
      personalPhone: null,
      workEmail: null,
      personalEmail: null
    });

    if (toggleNewFields) {
      formGroup.controls.firstName.setValidators(Validators.required);
      formGroup.controls.lastName.setValidators(Validators.required);

    } else {
      formGroup.controls.firstName.setValidators(null);
      formGroup.controls.lastName.setValidators(null);
    }

    formGroup.updateValueAndValidity();
    formGroup.markAsUntouched();
  }

  public onRemoveMainContact(index: number) {
    this.mainContactsArray.removeAt(index);
  }

  public onRemoveBenefitsContact(index: number) {
    this.benefitsContactsArray.removeAt(index);
  }

  public onRemoveBenefitDeductionsContact(index: number) {
    this.benefitDeductionsContactsArray.removeAt(index);
  }

  public onRemovePayrollContact(index: number) {
    this.payrollContactsArray.removeAt(index);
  }

  public save(overrideDuplicates: boolean): void {
    const dto: EmployerContactsPost = this.createEmployerContactsDto(overrideDuplicates);

    this.manageEmployersService.postEmployerContacts(dto)
      .pipe( indicate(this.isSaving$)  )
      .subscribe((res) => {
        if (res.showDuplicateDialog) {
          this.openDuplicateDetectedDialog(res as DuplicateDetectedDialog)
        } else {
          this.store.employerProfile = res as EmployerProfile;
          this.employerContactsEditDialogRef.close(res);
        }
      }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.ecForm, err.modelStateErrors));
  }

  public cancel(canNavigate: boolean): void {
    if (canNavigate) {
      this.employerContactsEditDialogRef.close();
    } else {
      if (this.noChanges) {
        this.employerContactsEditDialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysText.unsavedChanges,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.employerContactsEditDialogRef.close();
        }
      });
  }

  private createEmployerContactsDto(overrideDuplicates: boolean): EmployerContactsPost {

    var cleanMainContacts = [];
    this.removeEmptyContactArrayObject(cleanMainContacts, this.mainContactsArray, this.isNewMainContact);
    var cleanBenefitsContacts = [];
    this.removeEmptyContactArrayObject(cleanBenefitsContacts, this.benefitsContactsArray, this.isNewBenefitsContact);
    var cleanBenefitDeductionsContacts = [];
    this.removeEmptyContactArrayObject(cleanBenefitDeductionsContacts, this.benefitDeductionsContactsArray, this.isNewBenefitDeductionsContact);
    var cleanPayrollContacts = [];
    this.removeEmptyContactArrayObject(cleanPayrollContacts, this.payrollContactsArray, this.isNewPayrollContact);

    const details: EmployerContactsPost = {
        overrideDuplicates: overrideDuplicates,
        employerId: this.data.employerId,
        primaryTenantAdmin: this.data.employerContacts.canEditTenantAdmin
          ? this.removeEmptyContactObject(this.primaryTenantAdminForm, this.isNewPrimaryTenantAdmin)
          : null, 
        primaryEmployerAdmin: this.removeEmptyContactObject(this.primaryEmployerAdminForm, this.isNewPrimaryEmployerAdmin),
        helpFeedbackAdmin: this.removeEmptyContactObject(this.helpFeedbackAdminForm, this.isNewHelpFeedbackAdmin),
        mainContacts: cleanMainContacts,
        benefitsContacts: cleanBenefitsContacts,
        benefitDeductionsContacts: cleanBenefitDeductionsContacts,
        payrollContacts: cleanPayrollContacts,
        includeCaseAdminsOnHelp: this.includeCaseAdminsOnHelpForm.value,
        showHelpAndFeedbackOnCase: this.showHelpAndFeedbackOnCaseDetails.value
    }
    return details;
  }

  private removeEmptyContactObject(formGroup: FormGroup, isNewContact: boolean): EmployerContact {
      let isEmpty : boolean;
      let ep: EmployerContact = null
      
      Object.keys(formGroup.controls).forEach((control: string) => {
        const val = formGroup.controls[control].value;
        if (val){
          isEmpty = false;
        }
    });
      if (isEmpty == false){
        ep =  this.createNewEmployeeDetailsForPost(formGroup, isNewContact);
      } 
      return ep;
  }

  private removeEmptyContactArrayObject(newArray, contactArray, isNewContact: boolean[]) {
    contactArray.controls.forEach((item, index) => {
      let isEmpty : boolean;
      Object.keys(item.controls).forEach((control: string) => {
        const val = item.controls[control].value;
        if (val){
          isEmpty = false;
        }
    });
      if (isEmpty == false){
        const ep: EmployerContact =  this.createNewEmployeeDetailsForPost(item, isNewContact[index]);
        newArray.push(ep);
      }
    });

  }

  private createNewEmployeeDetailsForPost(formGroup: FormGroup, isNew: boolean): EmployerContact {
    const newEmp = {
      employeeId: isNew ? null : formGroup.controls.employeeId.value,
      firstName: isNew ? formGroup.controls.firstName.value : null,
      middleName: isNew ? formGroup.controls.middleName.value : null,
      lastName: isNew ? formGroup.controls.lastName.value : null,
      suffix: isNew ? formGroup.controls.suffix.value : null,
      preferredName: isNew ? formGroup.controls.preferredName.value : null,
      workPhone: isNew ? formGroup.controls.workPhone.value : null,
      personalPhone: isNew ? formGroup.controls.personalPhone.value : null,
      workEmail: !isNew || formGroup.controls.workEmail.value?.trim().length == 0 ? null : formGroup.controls.workEmail.value,
      personalEmail: !isNew || formGroup.controls.personalEmail.value?.trim().length == 0 ? null : formGroup.controls.personalEmail.value,
      primaryEmailIsPersonal: isNew 
        ? formGroup.controls.personalEmailChecked.value 
          ? true 
          : formGroup.controls.workEmailChecked.value 
            ? false 
            : null
        : null
    }
    return newEmp;
  }

  private openDuplicateDetectedDialog(duplicateDetectedDialog: DuplicateDetectedDialog): void {
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '550px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      maxHeight: this.layoutService.isHandheld ? '100vh' : '',
      height: this.layoutService.isHandheld ? '100vh' : '',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.sysText.duplicateDetected,
        duplicateDetectedDialog: duplicateDetectedDialog,
      }
    };

    this.dialog.open(DuplicateDetectedDialogComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.save(true);
        }
      });
  }
}