<mat-card appearance="raised" class="setphone-form--container mat-elevation-z12">
    <div class="setphone-form--header">
      <ng-container *ngIf="!(showNumberField | async); else setNumber">
        <h5 class="setphone-form--title">{{ sysText[page + '_phoneForm_title'] }}</h5>
        <p class="body2 setphone-form--subtitle">{{ sysText[page + '_phoneForm_subtitle'] }}</p>
      </ng-container>
      <ng-template #setNumber>
        <h5 class="setphone-form--title">{{ sysText[page + '_phoneForm_titleSetNumber'] }}</h5>
        <p class="body2 setphone-form--subtitle">{{ sysText[page + '_phoneForm_subtitleSetNumber'] }}</p>
      </ng-template>
    </div>
    <mat-card-content>
      <form  [formGroup]="phoneForm" (ngSubmit)="onSubmit()" class="setphone-form--form">
        <mat-form-field *ngIf="!(showNumberField | async)" color="accent">
          <input
            type="number"
            matInput
            [placeholder]="sysText[page + '_phoneForm_code']"
            formControlName="otp"/>
          <mat-error>{{ otp.errors?.msError }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="(showNumberField | async)" color="accent">
          <input
            type="number"
            matInput
            placeholder="Phone number"
            formControlName="phonenumber"/>
          <mat-error>{{ otp.errors?.msError }}</mat-error>
        </mat-form-field>
        <ng-container *ngIf="!(showNumberField | async)">
          <button
            type="submit"
            mat-flat-button
            color="accent"
            class="button setphone-form--button"
            *ngIf="!isSubmitting; else submitting">
          {{ sysText[page + '_phoneForm_button'] }}
          </button>
        </ng-container> 
        <ng-container *ngIf="(showNumberField | async)">
          <button 
            type='button'
            (click)="onSetNumber()"
            mat-flat-button
            color="accent"
            class="button setphone-form--button"
            *ngIf="!isSubmitting; else submitting"
          >
          {{ sysText[page + '_phoneForm_buttonSetNumber'] }}
          </button>
        </ng-container> 
        <ng-template #submitting>
          <button type="submit" mat-flat-button color="accent" class="button setphone-form--button__disabled" disabled>
            <mat-spinner [diameter]="30" color="accent"></mat-spinner>
          </button>
        </ng-template>
      </form>
      <div class="setphone-form--link caption">
        <a *ngIf="!(showNumberField | async)" (click)="onResend()">
          {{ sysText[page + '_phoneForm_resend'] }}
        </a>
        <a *ngIf="!(showNumberField | async)" (click)="onShowSetPhoneNumber()" class="setphone-form--link__secondary">
          {{ sysText[page + '_phoneForm_setDifferent'] }}
        </a>
        <a *ngIf="(showNumberField | async)" (click)="onHideSetPhoneNumber()" class="setphone-form--link__secondary">
          {{ sysText[page + '_phoneForm_goBackSetNumber'] }}
        </a>
        <a *ngIf="!(showNumberField | async)" (click)="onGoBack()" class="setphone-form--link__secondary">
          {{ sysText[page + '_phoneForm_goBack'] }}
        </a>
      </div>
    </mat-card-content>
  </mat-card>
  
