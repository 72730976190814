import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sidenav } from '@core/models';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AvatarService } from './avatar.service';
import { ConstantsService } from './constants.service';
import { SIDENAV_MOCK } from '@core/mocks';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {

  constructor(private constants: ConstantsService, private http: HttpClient, private avatarService: AvatarService) { }

  getSidenavInfo(): Observable<Sidenav> {
    return this.http.get<Sidenav>(`${this.constants.EEPORTAL_API}/getsidenavinfo`).pipe(
      tap((res) => {
        this.avatarService.setAvatarSubject(res.employeeInfo);
        return res;
      })
    );

    // return of(SIDENAV_MOCK).pipe(
    //   tap((res) => {
    //     this.avatarService.setAvatarSubject(res.employeeInfo);
    //     return res;
    //   })
    // );
  }
}
