import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { PostScheduleWorkWeeks, PostScheduleWorkWeeksForm, ScheduleWeek, ScheduleWeekGroup, WorkScheduleDetails } from '@core/models/work-schedules/work-schedule-details.model';
import { ErrorService } from '@core/services';
import { WorkScheduleDetailsStoreService } from '@core/services/work-schedule-details.store.service';
import { WorkSchedulesService } from '@core/services/work-schedules.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrl: './edit-schedule.component.scss'
})
export class EditScheduleComponent extends DialogDragConstraints {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<PostScheduleWorkWeeksForm>;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      workScheduleDetails: WorkScheduleDetails; 
      sysText: any; 
      unsavedChangesSysText: any
    },
    private templateInfoDialogRef: MatDialogRef<EditScheduleComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private service: WorkSchedulesService,
    private store: WorkScheduleDetailsStoreService,
    private errorService: ErrorService
    ) { 
    super(templateInfoDialogRef);
    
    this.isSaving$ = new Subject();
    this.form = this.fb.group<PostScheduleWorkWeeksForm>({
      alignmentDate: this.fb.control(null),
      scheduleWeeks: new FormArray<FormGroup<ScheduleWeekGroup>>([])
    })
  }

  ngOnInit(): void {
    this.form.patchValue({
      alignmentDate: this.data.workScheduleDetails.alignmentDate
    });

    if (this.data.workScheduleDetails.scheduleWeeks.length > 0) {
      this.data.workScheduleDetails.scheduleWeeks.forEach((sw) => {
        this.form.controls.scheduleWeeks.push(this.initNewWeekGroup(sw));
      });
    } else {
      this.form.controls.scheduleWeeks.push(this.initNewWeekGroup());
    }

    this.formInitValues = { ...this.form.value };
    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.workScheduleDetails.missingDetails)
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public save(): void {
    this.templateInfoDialogRef.disableClose = true;
    const dto: PostScheduleWorkWeeks = { 
      workScheduleId: this.data.workScheduleDetails.id,
      alignmentDate: this.form.controls.alignmentDate.value,
      scheduleWeeks: this.form.controls.scheduleWeeks.controls.map((formGroup) => {
        return {
          employerWeekScheduleWeekId: formGroup.controls.employerWorkScheduleWeekId.value,
          worksSunday: formGroup.controls.worksSunday.value,
          sundayHours: formGroup.controls.sundayHours.value,
          worksMonday: formGroup.controls.worksMonday.value,
          mondayHours: formGroup.controls.mondayHours.value,
          worksTuesday: formGroup.controls.worksTuesday.value,
          tuesdayHours: formGroup.controls.tuesdayHours.value,
          worksWednesday: formGroup.controls.worksWednesday.value,
          wednesdayHours: formGroup.controls.wednesdayHours.value,
          worksThursday: formGroup.controls.worksThursday.value,
          thursdayHours: formGroup.controls.thursdayHours.value,
          worksFriday: formGroup.controls.worksFriday.value,
          fridayHours: formGroup.controls.fridayHours.value,
          worksSaturday: formGroup.controls.worksSaturday.value,
          saturdayHours: formGroup.controls.saturdayHours.value,
        }
      })
    }

    this.service.postScheduleWorkWeeks(dto)
      .pipe(
        indicate(this.isSaving$),
        finalize(() => {
          this.templateInfoDialogRef.disableClose = false;
        }))
      .subscribe((res) => {
        this.store.workScheduleDetails = res;
        this.templateInfoDialogRef.close(res);
      }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.templateInfoDialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public onAddWeek(): void {
    this.form.controls.scheduleWeeks.controls.push(this.initNewWeekGroup());
  }

  public onRemoveWeek(index: number): void {
    this.form.controls.scheduleWeeks.removeAt(index);

    if (this.form.controls.scheduleWeeks.length === 1) {
      this.form.controls.alignmentDate.setValue(null);
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.templateInfoDialogRef.close();
        }
      });
  }

  private initNewWeekGroup(scheduleWeek: ScheduleWeek = null): FormGroup<ScheduleWeekGroup>{
    return this.fb.group<ScheduleWeekGroup>({
      employerWorkScheduleWeekId: this.fb.control(scheduleWeek?.employerWeekScheduleWeekId ?? null),
      worksSunday: this.fb.control(scheduleWeek?.worksSunday ?? false),
      sundayHours: this.fb.control(scheduleWeek?.sundayHours ?? null),
      worksMonday: this.fb.control(scheduleWeek?.worksMonday ?? false),
      mondayHours: this.fb.control(scheduleWeek?.mondayHours ?? null),
      worksTuesday: this.fb.control(scheduleWeek?.worksTuesday ?? false),
      tuesdayHours: this.fb.control(scheduleWeek?.tuesdayHours ?? null),
      worksWednesday: this.fb.control(scheduleWeek?.worksWednesday ?? false),
      wednesdayHours: this.fb.control(scheduleWeek?.wednesdayHours ?? null),
      worksThursday: this.fb.control(scheduleWeek?.worksThursday ?? false),
      thursdayHours: this.fb.control(scheduleWeek?.thursdayHours ?? null),
      worksFriday: this.fb.control(scheduleWeek?.worksFriday ?? false),
      fridayHours: this.fb.control(scheduleWeek?.fridayHours ?? null),
      worksSaturday: this.fb.control(scheduleWeek?.worksSaturday ?? false),
      saturdayHours: this.fb.control(scheduleWeek?.saturdayHours ?? null),
    });
  }
}