<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6>
    {{ data.sysText.addRole | titlecase }}
  </h6>
</div>
<mat-dialog-content>
  <p class="body2">{{ data.sysText.addRoleDesc | capitalizefirst }}</p>
  <app-select-field
    [control]="selectedRole"
    [label]="data.sysText.selectRole"
    [options]="roleOptions"
    [icon]="'verified_user'"
    [shouldCapitalize]="false"
  ></app-select-field>
  <p class="body2 role-desc">{{ roleNotes }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
  <button mat-stroked-button class="button" mat-dialog-close color="accent">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" color="accent" [disabled]="!selectedRole.valid" (click)="onSubmit()">
    {{ data.sysText.save }}
  </button>
</mat-dialog-actions>

<ng-template #submitting>
  <mat-dialog-actions align="end">
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>
