<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6>{{ data.employeeSchedule == null ? data.sysText.addWorkSchedule_header : data.sysText.editWorkSchedule_header }}</h6>
</div>

<ng-container *ngIf="!data.isAnonymousCase; else anonymousMessage">
	<mat-dialog-content>
		<form [formGroup]="form">
			<app-date-field
				[label]="data.sysText.workSchedule_effectiveDate"
				[control]="form.controls.effectiveDate"
				[icon]="'calendar_today'"
				[group]="form"
				[hint]="data.sysText.workSchedule_effectiveDateHint"
				[isHintHighlighted]="false"
				[info]="data.sysText.workSchedule_effectiveDate_Info">
			</app-date-field>
			<div class="section-container	">
				<div class="label">
					<mat-icon>schedule</mat-icon>
					<p class="grey1">{{ data.sysText.employeeSchedule_schedule }}</p>
					<mat-icon 
						[matTooltip]="data.sysText.employeeSchedule_schedule_info" 
						[matTooltipShowDelay]="500" 
						matTooltipPosition="above" 
						class="info-icon">
						info_outline
					</mat-icon>
				</div>
				<div class="fields">
					<app-select-field
						[control]="form.controls.employerWorkScheduleId"
						[label]="data.sysText.workSchedule_title"
						[options]="data.dialogData.workScheduleOptions"
						[showBlankOption]="true"
						[hideIconSpace]="true"
						[shouldCapitalize]="false">
					</app-select-field>
					<div class="toggle-container">
						<app-slide-toggle 
							[control]="this.form.controls.isIrregular"
							[label]="'Irregular Work Schedule'">
						</app-slide-toggle>
					</div>
				</div>
			</div>
			<div class="section-container">
				<div class="label">
					<mat-icon>event</mat-icon>
					<p class="grey1">{{ data.sysText.workSchedule_nonWorkdays | titlecase }}</p>
					<mat-icon 
						[matTooltip]="data.sysText.employeeSchedule_nonWorkdays_info" 
						[matTooltipShowDelay]="500" 
						matTooltipPosition="above" 
						class="info-icon">
						info_outline
					</mat-icon>
				</div>
				<div class="select-container">
					<mat-selection-list [formControl]="form.controls.nonWorkdays">
						@for (nonWorkday of data.dialogData.nonWorkdayOptions; track nonWorkday) {
							<mat-list-option 
								class="restore-condition" 
								[disabled]="scheduleNonWorkdays?.nonWorkdays.includes(+nonWorkday.id)"
								[value]="nonWorkday.id">
								{{ nonWorkday.description }}
							</mat-list-option>
						}
					</mat-selection-list>
				</div>
			</div>
		</form>
	</mat-dialog-content>
</ng-container>

<ng-template #anonymousMessage>
	<mat-dialog-content class="form-container">
			<p class="disallow">{{ data.sysText.workSchedule_anonymousMessage }}</p>
	</mat-dialog-content>
</ng-template>

<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
	<button mat-stroked-button class="button" color="accent" (click)="cancel()">
		{{ data.isAnonymousCase ? data.sysText.close : data.sysText.cancel }}
	</button>
	<button *ngIf="!data.isAnonymousCase" mat-stroked-button class="button" color="accent" (click)="onSubmit()">
		{{ data.sysText.save }}
	</button>

</mat-dialog-actions>
<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>
