import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { DocCatDashboardForm, EmailTemplatesDashboardForm } from '@core/models/case-communication/email-templates-dashboard-form.model';
import { EmailTemplatesDashboardStore } from '@core/models/case-communication/email-templates-dashboard-store.model';
import { EmailTemplates } from '@core/models/case-communication/email-templates.model';
import { noChangesReplacer } from '@shared/helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesDashboardStoreService {

  private readonly _initialStoreData: EmailTemplatesDashboardStore = {
    emailTemplates: null,
    sysText: null,
    selectedIndex: null,
    emailTemplatesTab: {
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<EmailTemplatesDashboardForm>({
        searchValue: this.fb.control(null),
        employer: this.fb.control(null),
        type: this.fb.control(null)
      })
    },
    docCatsTab: {
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<DocCatDashboardForm>({
        searchValue: this.fb.control(null),
        employer: this.fb.control(null)
      })
    }
  };

  private emailTemplatesDashboardStore: BehaviorSubject<EmailTemplatesDashboardStore>;  

  private store$: Observable<EmailTemplatesDashboardStore>;

  private readonly templateNames = {
    emailTemplates: "emailTemplates",
    unsavedChanges: "unsavedChanges",
    addNewTemplate: "addNewTemplate",
    templateDocCategories: "templateDocCategories"
  };

  constructor(private fb: FormBuilder) { 
    this.emailTemplatesDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.emailTemplatesDashboardStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.emailTemplatesDashboardStore.value;
  }
  
  public get emailTemplates$(): Observable<EmailTemplates> {
    return this.store$.pipe(pluck('emailTemplates'));
  }

  public get emailTemplates() {
    return this.store.emailTemplates;
  }

  public set emailTemplates(emailTemplates: EmailTemplates) {
    const store = { ...this.store, emailTemplates };
    this.emailTemplatesDashboardStore.next(store);
  }
  
  public get sysText() {
    return this.store.sysText;
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.emailTemplatesDashboardStore.next(store);
  }

  public get selectedIndex() {
    return this.store.selectedIndex;
  }

  public set selectedIndex(selectedIndex: number) {
    this.store.selectedIndex = selectedIndex;
  }

  // email templates tab

  public get emailTemplatesTabForm(): FormGroup<EmailTemplatesDashboardForm> {
    return this.store.emailTemplatesTab.dashForm;
  }

  get emailTemplatesTabFormNoChanges(): boolean {
    return JSON.stringify(this.emailTemplatesTabForm.value, noChangesReplacer) === JSON.stringify(this.store.emailTemplatesTab.formInitValues, noChangesReplacer);
  }

  public set emailTemplatesTabSortData(sortData: Sort) {
    this.store.emailTemplatesTab.matSortData = sortData;
  }

  public get emailTemplatesTabSortData(): Sort {
    return this.store.emailTemplatesTab.matSortData;
  }

  public set emailTemplatesTabPageData(pageData: PageEvent) {
    this.store.emailTemplatesTab.pageData = pageData;
  }

  public get emailTemplatesTabPageData(): PageEvent {
    return this.store.emailTemplatesTab.pageData;
  }

  // document categories tab

  public get docCatsTabForm(): FormGroup<DocCatDashboardForm> {
    return this.store.docCatsTab.dashForm;
  }

  get docCatsTabFormNoChanges(): boolean {
    return JSON.stringify(this.docCatsTabForm.value, noChangesReplacer) === JSON.stringify(this.store.docCatsTab.formInitValues, noChangesReplacer);
  }

  public set docCatsTabSortData(sortData: Sort) {
    this.store.docCatsTab.matSortData = sortData;
  }

  public get docCatsTabSortData(): Sort {
    return this.store.docCatsTab.matSortData;
  }

  public set docCatsTabPageData(pageData: PageEvent) {
    this.store.docCatsTab.pageData = pageData;
  }

  public get docCatsTabPageData(): PageEvent {
    return this.store.docCatsTab.pageData;
  }
  
  public emailTemplateAssignedToEmployer(templateId: number, assigned: boolean) {
    this.store.emailTemplates.templateItems.find(et => et.id === templateId).assignedToEmployer = assigned;
  }

  public unloadStore() {
    const store: EmailTemplatesDashboardStore = {
      emailTemplates: null,
      sysText: null,
      selectedIndex: null,
      emailTemplatesTab: {
        formInitValues: null,
        matSortData: null,
        pageData: null,
        dashForm: this.fb.group<EmailTemplatesDashboardForm>({
          searchValue: this.fb.control(null),
          employer: this.fb.control(null),
          type: this.fb.control(null)
        })
      },
      docCatsTab: {
        formInitValues: null,
        matSortData: null,
        pageData: null,
        dashForm: this.fb.group<DocCatDashboardForm>({
          searchValue: this.fb.control(null),
          employer: this.fb.control(null)
        })
      }
    }
    this.emailTemplatesDashboardStore.next(store)
  }
}
