<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6>
		{{ data.sysText.addNewSchedule }}
	</h6>
</div>
<mat-dialog-content>
	<app-input-field
		[control]="form.controls.scheduleName"
		[icon]="'event'"
		[label]="data.sysText.addScheduleName"
		[inputType]="'string'">
	</app-input-field>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
	<button mat-stroked-button class="button" mat-dialog-close color="accent">
		{{ data.sysText.cancel }}
	</button>
	<button mat-stroked-button class="button" color="accent" (click)="onSubmit()">
		{{ data.sysText.add }}
	</button>
</mat-dialog-actions>

<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>
