import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav-copyright',
  templateUrl: './sidenav-copyright.component.html',
  styleUrls: ['./sidenav-copyright.component.scss']
})
export class SidenavCopyrightComponent implements OnInit {
  @Input() copyright: string;

  constructor() {}

  ngOnInit() {}
}
