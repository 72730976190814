<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6>
        {{ sysText.leaveCalendarWarning | titlecase }}
    </h6>
    <ng-container *ngIf="isHandheld">
      <span class="spacer"></span>
      <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
    </ng-container>
</div>

<mat-dialog-content>
    <div class="subtitle1">
        <!-- message for hidden or closed case is always shown -->

        <p class="body2 dialog-section" *ngIf="caseDetails.isHidden">{{ sysText.leaveCalendarWarning_hidden_subtitle }}</p>
        <p class="body2 dialog-section" *ngIf="!caseDetails.isHidden && caseDetails.isClosed">{{ sysText.leaveCalendarWarning_closed_subtitle }}</p>

        <!-- message for missing requirements or error conditions (only one shown) -->

        <p class="body2 dialog-section" *ngIf="caseDetails.showCalendarOverlayForAdmins && caseDetails.leaveCalendar.missingCalendarRequirements">{{ sysText.leaveCalendarWarning_subtitle }}</p>
        <p class="body2 dialog-section" *ngIf="caseDetails.showCalendarOverlayForAdmins && !caseDetails.leaveCalendar.missingCalendarRequirements && caseDetails.leaveCalendar.hasLeaveHoursErrors">{{ sysText.leaveCalendarErrors_subtitle }}</p>
    </div>
    
    <div *ngIf="caseDetails.showCalendarOverlayForAdmins && (caseDetails.leaveCalendar.missingCalendarRequirements || caseDetails.leaveCalendar.hasLeaveHoursErrors)">
        <!-- anonymous -->

        <p class="body2" *ngIf="caseDetails.employeeInformation.isAnonymous">
            <span class="bullet-space">&bull;</span>{{ sysText.employeeRequired }}
        </p>

        <ng-container *ngIf="!caseDetails.employeeInformation.isAnonymous">
            <!-- conditions that only apply when an employee is assigned to the case -->

            <!-- case settings missing -->

            <p class="body2" *ngIf="caseDetails.leaveInformation.currentStageId > leaveStages.ReadyForLeave">
                <span class="bullet-space">&bull;</span>{{ sysText.onOrAfterReadyForLeave }}
            </p>
            <p class="body2" *ngIf="caseDetails.leaveInformation.leaveType == null">
                <span class="bullet-space">&bull;</span>{{ sysText.leaveTypeRequired }}
            </p>
            <p class="body2" *ngIf="caseDetails.leaveInformation.startDate == null">
                <span class="bullet-space">&bull;</span>{{ sysText.startDateRequired }}
            </p>
            <p class="body2" *ngIf="caseDetails.leaveInformation.endDate == null">
                <span class="bullet-space">&bull;</span>{{ sysText.endDateRequired }}
            </p>

            <!-- employee settings missing -->
            
            <p class="body2" *ngIf="caseDetails.leaveCalendar.missingWorkSchedule">
                <span class="bullet-space">&bull;</span>{{ sysText.workScheduleRequired }}
            </p>
            <p class="body2" *ngIf="caseDetails.leaveCalendar.missingDateOfHire">
                <span class="bullet-space">&bull;</span>{{ sysText.dateOfHireRequired }}
            </p>
            <p class="body2" *ngIf="caseDetails.leaveCalendar.missingWorkingInState">
                <span class="bullet-space">&bull;</span>{{ sysText.workingInStateRequired }}
            </p>

            <p class="body2" *ngIf="caseDetails.leaveInformation.isFmlaHoursApplicable && caseDetails.leaveHours.employeeFmlaTotalHours == null">
                <span class="bullet-space">&bull;</span>{{ sysText.fmlaTotalHoursRequired }}
            </p>
            <p class="body2" *ngIf="caseDetails.leaveInformation.isStateHoursApplicable && caseDetails.leaveHours.employeeStateTotalHours == null">
                <span class="bullet-space">&bull;</span>{{ sysText.stateTotalHoursRequired }}
            </p>
            <p class="body2" *ngIf="caseDetails.leaveInformation.isPloHoursApplicable && caseDetails.leaveHours.employeePloTotalHours == null">
                <span class="bullet-space">&bull;</span>{{ sysText.ploTotalHoursRequired }}
            </p>
        </ng-container>

        <p class="body2" *ngIf="caseDetails.leaveCalendar.malformedEmployerLeaveYearRules">
            <span class="bullet-space">&bull;</span>{{ sysText.malformedEmployerLeaveYearRules }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingFmlaLeaveYear">
            <span class="bullet-space">&bull;</span>{{ sysText.fmlaLeaveYearRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingStateLeaveYear">
            <span class="bullet-space">&bull;</span>{{ sysText.stateLeaveYearRequired }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.missingFixedStart">
            <span class="bullet-space">&bull;</span>{{ sysText.fixedStartRequired }}
        </p>

        <!-- leave hours error conditions -->

        <p class="body2" *ngIf="caseDetails.leaveCalendar.leavesOutsideCaseTimeframe">
            <span class="bullet-space">&bull;</span>{{ sysText.leavesOutsideCaseTimeframe }}
        </p>
        <p class="body2" *ngIf="caseDetails.leaveCalendar.leavesOnNonWorkdays">
            <span class="bullet-space">&bull;</span>{{ sysText.leavesOnNonWorkdays }}
        </p>           
        <p class="body2" *ngIf="caseDetails.leaveCalendar.leavesExceedWorkdayHours">
            <span class="bullet-space">&bull;</span>{{ sysText.leavesExceedWorkdayHours }}
        </p>           
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!isHandheld" class="actions" align="end">
    <button mat-stroked-button class="button" [mat-dialog-close]="true" color="accent">
        {{ sysText.close }}
    </button>
</mat-dialog-actions>
