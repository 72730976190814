import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PasswordRequirement, StiiraError } from '@core/models';
import { AuthService, ConstantsService, ErrorService, ProfileService, SnackbarService } from '@core/services';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { indicate } from '@shared/helpers/rxjs.helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends DialogDragConstraints implements OnInit {
  form: UntypedFormGroup;
  hideCurrentPassword = true;
  hideNewPassword = true;
  isSubmitting$ = new Subject<boolean>();
  passwordRequirements: PasswordRequirement[];
  passwordValid = true;

  get currentPassword(): FormControl {
    return this.form.get('currentPassword') as FormControl;
  }

  get newPassword(): FormControl {
    return this.form.get('newPassword') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private constants: ConstantsService,
    private profileService: ProfileService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public sysText
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.form = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
    });
    this.passwordRequirements = this.constants.PASSWORDREQUIREMENTS;
  }

  changePassword(): void {
    this.dialogRef.disableClose = true;
    this.profileService
      .changePassword({currentPassword: this.form.value.currentPassword, newPassword: this.form.value.newPassword})
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => (this.dialogRef.disableClose = false))
      )
      .subscribe(
        () => {
          this.snackbarService.open('Password successfully changed', 'Dismiss');
          this.authService.logout();
          this.dialogRef.close();
        },
        (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
      );
  }
}
