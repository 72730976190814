import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionNeededTypes } from '@core/enums/action-needed-type.enum';
import { LeaveAdminDashboardTableFilters } from '@core/models/leave-admin/leave-admin-dashboard-table-filters.model';
import { ConstantsService, LayoutService } from '@core/services';
import { FormControl, FormGroup } from '@angular/forms';
import { isNumber } from '@microsoft/applicationinsights-core-js';
import { SelectionOption, StatusChips } from '@core/models';
import { LeaveAdminDashboardFormService } from '@core/services/leave-admin-dashboard-form.service';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-leave-admin-dashboard-table-filters',
  templateUrl: './leave-admin-dashboard-table-filters.component.html',
  styleUrls: ['./leave-admin-dashboard-table-filters.component.scss'],
})
export class LeaveAdminDashboardTableFiltersComponent extends DialogDragConstraints implements OnInit {
  @Output() showHiddenTab = new EventEmitter<boolean>();

  public actionNeededOptions: SelectionOption[];
  public chipStyles: StatusChips;
  public actionNeededTypes = ActionNeededTypes;

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get company(): FormControl {
    return this.formService.companyControl;
  }

  get leaveType(): FormControl {
    return this.formService.leaveTypeControl;
  }

  get stage(): FormControl {
    return this.formService.stageControl;
  }

  get admin(): FormControl {
    return this.formService.adminControl;
  }

  get actionNeeded(): FormControl {
    return this.formService.actionNeededControl;
  }

  get showHidden(): FormControl {
    return this.formService.hiddenControl;
  }

  get form(): FormGroup {
    return this.formService.form;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      tableFilterOptions: LeaveAdminDashboardTableFilters;
      sysText: any;
      showHideCaseFeatures: boolean;
    },
    private layoutService: LayoutService,
    private constants: ConstantsService,
    private formService: LeaveAdminDashboardFormService,
    private dialogRef: MatDialogRef<LeaveAdminDashboardTableFiltersComponent>
  ) { 
    super(dialogRef);
  }

  ngOnInit(): void {
    this.chipStyles = this.constants.STAT_CHIP_STYLES; 
    this.actionNeededOptions = this.buildActionNeededOptions();
  }

  public onApply():void {
    //This may look odd, but it's checking for a "blank" admin value (neither null or undefined).
    //If it's blank, we want to set it to null.
    if (!this.admin.value) {
      this.admin.patchValue(null);
    }

    if (this.data.showHideCaseFeatures) {
      this.showHiddenTab.emit(this.showHidden.value == true ?? false);
    }

    this.dialogRef.close(true)
  }

  public onClearFilters(): void {
    this.company.patchValue(null);
    this.leaveType.patchValue(null);
    this.stage.patchValue(null);
    this.admin.patchValue(null);
    this.actionNeeded.patchValue(null);

    if (this.data.showHideCaseFeatures) {
      this.showHidden.patchValue(null);
    }
  }

  private buildActionNeededOptions(): SelectionOption[] {
    const actionNeededIds = Object.values(this.actionNeededTypes).filter(v => isNumber(v));
    let actionNeededOptions: SelectionOption[] = [];

    actionNeededIds.forEach(id => {
      const desc: string = this.data.sysText['actionNeededOptions_' + id];
      const option: SelectionOption = {
        id: id,
        description: desc
      };
      actionNeededOptions.push(option);
    });

    return actionNeededOptions;
  }
}