<mat-toolbar [ngClass]="{ 'mat-elevation-z1': !(isMobile$ | async), 'mat-toolbar-testing-envir': !(environment.production) }" color="primary">
  <button mat-icon-button *ngIf="isHandheld$ | async" (click)="onMenuClick()" class="menu-button">
    <mat-icon>menu</mat-icon>
  </button>
  <h6 class="header-title subtitle1">{{ title$ | async | titlecase }} 
      <span *ngIf="(environment.debugBannerTitle.length > 0) && (title$ | async)">{{ environment.debugBannerTitle }}</span>
  </h6>
  <span class="spacer"></span>
  <ng-container *ngIf="!((caseComIsLoading$ | async) || (helpIsLoading$ | async)); else loading">
    <ng-container *ngIf="showEmailButton$ | async">
      <button mat-icon-button (click)="onEmailClick()" class="menu-button">
        <mat-icon>email</mat-icon>
      </button>
    </ng-container>
    <button mat-icon-button (click)="onHelpClick()" class="menu-button">
      <mat-icon>help</mat-icon>
    </button>
  </ng-container>
  <ng-template #loading>
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="25" color="accent"></mat-spinner>
    </button>
  </ng-template>
</mat-toolbar>