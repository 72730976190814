<h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  {{ data.sysText.delete | titlecase }} 
</h6>
<mat-dialog-content>
  <p class="subtitle1">
    {{ data.sysText.deleteConfirm1 }}
    {{ data.deleteConfirm2 ?? data.sysText.defaultDeleteConfirm2 }}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button class="button" [mat-dialog-close]="false" color="accent">{{ data.sysText.keep }}</button>
  <button mat-stroked-button class="button" [mat-dialog-close]="true" color="accent">{{ data.sysText.delete }}</button>
</mat-dialog-actions>
