<h6 class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  {{ sysText.changeUsernameDialog_title | titlecase }}
</h6>

<mat-dialog-content [formGroup]="form">
  <p class="subtitle1 bottom-margin">
    {{ sysText.changeUsernameDialog_subtitle }}
  </p>
  <mat-form-field color="accent">
    <input
      matInput
      [type]="hidePassword ? 'password' : 'text'"
      [placeholder]="sysText.password | titlecase"
      formControlName="password"
    />
    <!-- mat hint for spacing -->
    <mat-hint></mat-hint>
    <mat-error>{{ password.errors?.msError }}</mat-error>
    <mat-icon matSuffix (click)="hidePassword = !hidePassword">
      {{ hidePassword ? 'visibility_off' : 'visibility' }}
    </mat-icon>
  </mat-form-field>
  <mat-form-field color="accent">
    <input matInput type="text" [placeholder]="sysText.newUsername | titlecase" formControlName="username"/>
    <mat-error>{{ username.errors?.msError }}</mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="!(isSubmitting$ | async); else submitting">
    <button mat-stroked-button class="button" type="button" [mat-dialog-close] color="accent">
      {{ sysText.cancel }}
    </button>
    <button
      mat-stroked-button
      class="button"
      type="submit"
      (click)="changeUsername()"
      color="accent"
    >
      {{ sysText.changeUsername }}
    </button>
  </ng-container>
  <ng-template #submitting>
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </ng-template>
</mat-dialog-actions>
