import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoutePaths } from '@core/routes/route-paths.constants';

const routes: Routes = [
  {
    path: appRoutePaths.HOME,
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: appRoutePaths.SETUP,
    loadChildren: () => import('./modules/setup-dashboard/setup-dashboard.module').then((m) => m.SetupDashboardModule),
  },
  {
    path: appRoutePaths.LEAVE_ADMIN,
    loadChildren: () => import('./modules/leave-admin/leave-admin.module').then((m) => m.LeaveAdminModule),
  },  
  {
    path: appRoutePaths.PROFILE,
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: appRoutePaths.ROLE_ADMIN,
    loadChildren: () => import('./modules/role-admin/role-admin.module').then((m) => m.RoleAdminModule),
  },
  {
    path: appRoutePaths.MY_DOCUMENTS,
    loadChildren: () => import('./modules/my-documents/my-documents.module').then((m) => m.MyDocumentsModule),
  },
  {
    path: appRoutePaths.MANAGE_EMPLOYERS,
    loadChildren: () => import('./modules/manage-employers/manage-employers.module').then((m) => m.ManageEmployersModule),
  },
  {
    path: appRoutePaths.MANAGE_EMPLOYEES,
    loadChildren: () => import('./modules/manage-employees/manage-employees.module').then((m) => m.ManageEmployeesModule),
  },
  {
    path: appRoutePaths.REPORTS,
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: appRoutePaths.EMAIL_TEMPLATES,
    loadChildren: () => import('./modules/case-communication/case-communication.module').then((m) => m.CaseCommunicationModule),
  },
  {
    path: appRoutePaths.WORK_SCHEDULES,
    loadChildren: () => import('./modules/work-schedules/work-schedules.module').then((m) => m.WorkSchedulesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    canceledNavigationResolution: 'computed'
}),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
