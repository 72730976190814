import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ErrorInterceptor, JwtInterceptor } from './interceptors';
import { ApplicationInsightsService } from './services/app-insights.service';

@NgModule({
  declarations: [],
  imports: [BrowserAnimationsModule, HttpClientModule, ToastrModule.forRoot()],
  providers: [
    ApplicationInsightsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
