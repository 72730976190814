<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6 >
		{{data.sysText.editEmployerCaseComSettings_title | titlecase}}
</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<app-text-area
			[control]="form.controls.automaticCcAddresses"
			[label]="data.sysText.automaticCcAddresses_title"
			[icon]="'email'"
			[rows]="1"
			[info]="data.sysText.automaticCcAddresses_info"
		></app-text-area>
		<app-text-area
			[control]="form.controls.automaticBccAddresses"
			[label]="data.sysText.automaticBccAddresses_title"
			[icon]="'email'"
			[rows]="1"
			[info]="data.sysText.automaticBccAddresses_info"
		></app-text-area>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions" *ngIf="!(isSubmitting$ | async); else submitting">   
	<button mat-stroked-button class="button" color="accent" (click)="close(false)">
		{{data.sysText.cancel}}
	</button>
	<button mat-stroked-button class="button" color="accent" 
		[disabled]="" (click)="onSubmit()">
		{{data.sysText.save}}
	</button>
</mat-dialog-actions>

<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>