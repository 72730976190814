import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImpersonateEmployee, ImpersonateEmployeeCredentials, Token } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConstantsService } from './constants.service';

const DEFAULT_IMPERSONATE_EMPLOYEE: ImpersonateEmployee = {
  additionalMatches: false,
  eeList: null,
  showSearch: true,
  userId: '',
  totalMatchesFound: 0,
};

@Injectable()
export class ImpersonateService {
  private impersonateSubject: BehaviorSubject<ImpersonateEmployee>;

  public impersonate$: Observable<ImpersonateEmployee>;

  get userId(): string {
    // TODO: Look into
    // return this.impersonateSubject?.value?.userId;

    return this.impersonateSubject.value ? this.impersonateSubject.value.userId : '';
  }

  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.impersonateSubject = new BehaviorSubject(DEFAULT_IMPERSONATE_EMPLOYEE);
    this.impersonate$ = this.impersonateSubject.asObservable();
  }

  public searchForEmployees(searchString: string, token: Token): Observable<any> {
    return this.http
      .get(`${this.constants.ACCOUNT_API}/searchforemployees`, {
        params: { searchString },
        headers: {
          [this.constants.AUTH_HEADER]: token.access_token,
        },
      })
      .pipe(
        tap((res: ImpersonateEmployee) => {
          this.impersonateSubject.next(res);
          return res;
        })
      );

    // return of(NEWIMPERSONATEUSER_SEARCH).pipe(
    //   delay(3000),
    //   tap((res: ImpersonateEmployee) => this.impersonatingEmployeeSubject.next(res))
    // );
  }

  public impersonateEmployee(creds: ImpersonateEmployeeCredentials, token: Token): Observable<Token> {
    return this.http.post<Token>(`${this.constants.ACCOUNT_API}/impersonateemployee`, creds, {
      headers: {
        [this.constants.AUTH_HEADER]: token.access_token,
      },
    });

    // return of(JWT_MOCK).pipe(
    //   delay(3000),
    //   map((res: Token) => {
    //     this.updateUser(res);
    //     return res;
    //   })
    // );
  }
}
