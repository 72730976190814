import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewEmployeeWithoutContactInfoForm, StiiraError } from '@core/models';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { NewEmployee, NewEmployeeWithoutContactInfo } from '@core/models/leave-admin/employee-info-post.model';
import { SubmitNewRequestDialog } from '@core/models/leave-admin/submit-new-request-dialog.model';
import { SubmitNewRequestForm, SubmitNewRequestPost } from '@core/models/leave-admin/submit-new-request-post.model';
import { ErrorService, LayoutService } from '@core/services';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { indicate, nameof } from '@shared/helpers';
import { Subject } from 'rxjs';
import { DuplicateDetectedDialogComponent } from '../duplicate-detected-dialog/duplicate-detected-dialog.component';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { takeUntil } from 'rxjs/operators';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-submit-new-request',
  templateUrl: './submit-new-request.component.html',
  styleUrls: ['./submit-new-request.component.scss'],
})
export class SubmitNewRequestComponent extends DialogDragConstraints implements OnInit {
  @ViewChild('imgFileInput') fileInput: ElementRef;
  public fileToUpload: File;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup<SubmitNewRequestForm>;
  public isNewEmployee: boolean = false;
  public showCompanyField: boolean = false;
  public isForSelf: boolean = false; // true when effective user can only submit for self
  
  private formInitValues: any;
  private destroy$: Subject<void> = new Subject<void>();
  
  get employeeField(): FormControl {
    return this.form.controls.employeeId as FormControl;
  }

  get newEmployee(): FormGroup<NewEmployeeWithoutContactInfoForm> {
    return this.form.controls.newEmployee as FormGroup<NewEmployeeWithoutContactInfoForm>;
  }

  get workEmail(): FormControl<string> {
    return this.form.controls.workEmailAddress as FormControl<string>;
  }

  get isWorkEmailPreferred(): FormControl<boolean> {
    return this.form.controls.isWorkEmailPreferred as FormControl<boolean>;
  }

  get personalEmail(): FormControl<string> {
    return this.form.controls.personalEmailAddress as FormControl<string>;
  }

  get isPersonalEmailPreferred(): FormControl<boolean> {
    return this.form.controls.isPersonalEmailPreferred as FormControl<boolean>;
  }

  get workPhone(): FormControl<string> {
    return this.form.controls.workPhoneNumber as FormControl<string>;
  }

  get personalPhone(): FormControl<string> {
    return this.form.controls.personalPhoneNumber as FormControl<string>;
  }

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      submitNewRequestDialog: SubmitNewRequestDialog; 
      sysText: any;
      unsavedChangesSystext: any;
      duplicateDetectedSysText: any;
    },
    private fb: FormBuilder,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SubmitNewRequestComponent>,
    private service: LeaveAdminService,
    private layoutService: LayoutService
  ) { 
    super(dialogRef);
    
    this.isForSelf = this.data.submitNewRequestDialog.isForSelf;

    this.form = this.fb.group<SubmitNewRequestForm>({
      employeeId: this.fb.control(null, Validators.required),
      newEmployee: this.fb.group<NewEmployeeWithoutContactInfoForm>({
        firstName: this.fb.control(''),
        middleName: this.fb.control(''),
        lastName: this.fb.control(''),
        preferredName: this.fb.control(''),
        suffix: this.fb.control('')
      }),
      companyId: this.fb.control(null),
      workPhoneNumber: this.fb.control(null),
      personalPhoneNumber: this.fb.control(null),
      workEmailAddress: this.fb.control(null),
      isWorkEmailPreferred: this.fb.control(false),
      personalEmailAddress: this.fb.control(null),
      isPersonalEmailPreferred: this.fb.control(false),
      startDate: this.fb.control(null, Validators.required),
      endDate: this.fb.control(null),
      leaveReasonId: this.fb.control(null, Validators.required),
      comments: this.fb.control(null),
      documentTitle: this.fb.control(null),
      documentCategory: this.fb.control(null),
      documentComments: this.fb.control(null),
      notifyEmployee: this.fb.control(false)
    });
  }

  ngOnInit(): void {
    if (this.isForSelf) {
      // employee info provide by effective employee and is locked
      this.employeeField.setValidators(null);
      this.patchEmailAndPhone(this.data.submitNewRequestDialog.effectiveEmployeeId);
    } 
    else {
      // admin will pick employee
      this.employeeField.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((res)=>{
          this.patchEmailAndPhone(res);
        });
    }

    this.formInitValues = { ...this.form.value };
  }

  public onEEHintClick(): void {
    this.isNewEmployee = !this.isNewEmployee
    const newEE: NewEmployeeWithoutContactInfo = { firstName: '', middleName: '', lastName: '', preferredName: '', suffix: '' };

    this.form.patchValue({
      employeeId: null,
      newEmployee: newEE,
    });

    if (this.isNewEmployee) {
      this.form.controls.employeeId.setValidators(null)
      this.newEmployee.controls.firstName.setValidators(Validators.required)
      this.newEmployee.controls.lastName.setValidators(Validators.required)
      if (this.data.submitNewRequestDialog.companyOptions?.length > 0){
        this.form.controls.companyId.setValidators(Validators.required)
      }
    } else {
      this.form.controls.employeeId.setValidators(Validators.required)
      this.newEmployee.controls.firstName.setValidators(null)
      this.newEmployee.controls.lastName.setValidators(null)
      this.form.controls.companyId.setValidators(null)
    }

    this.form.updateValueAndValidity();
    this.form.markAsUntouched();
  }

  public onSubmit(overrideDuplicates: boolean) {
    this.form.markAsUntouched();

    const newEmployee: NewEmployeeWithoutContactInfo = {
      firstName: this.newEmployee.controls.firstName.value,
      middleName: this.newEmployee.controls.middleName.value,
      lastName: this.newEmployee.controls.lastName.value,
      preferredName: this.newEmployee.controls.preferredName?.value,
      suffix: this.newEmployee.controls.suffix?.value
    }

    const dto: SubmitNewRequestPost = {
      overrideDuplicates: overrideDuplicates,
      isForSelf: this.isForSelf,
      isNewEmployee: this.isNewEmployee,
      employeeId: (this.isNewEmployee || this.isForSelf) ? null : this.form.controls.employeeId.value,
      newEmployee: this.isNewEmployee ? newEmployee : null,
      companyId: (this.data.submitNewRequestDialog.companyOptions?.length === 0) ? null : this.form.controls.companyId.value,
      personalEmailAddress: this.form.controls.personalEmailAddress.value,
      workEmailAddress: this.form.controls.workEmailAddress.value,
      primaryEmailAddressIsPersonal: this.isWorkEmailPreferred.value ? false : this.isPersonalEmailPreferred.value ? true : null,
      workPhoneNumber: this.form.controls.workPhoneNumber.value,
      personalPhoneNumber: this.form.controls.personalPhoneNumber.value,
      startDate: this.form.controls.startDate.value,
      endDate: this.form.controls.endDate.value,
      leaveReasonId: this.form.controls.leaveReasonId.value,
      comments: this.form.controls.comments.value,
      document: this.fileToUpload ? this.fileToUpload : null,
      documentTitle: this.fileToUpload ? this.form.controls.documentTitle.value : null,
      documentCategoryId: this.fileToUpload ? this.form.controls.documentCategory.value : null,
      documentComments: this.fileToUpload ? this.form.controls.documentComments.value : null,
      notifyEmployee: this.form.controls.notifyEmployee.value
    };

    // convert dto into formData to send to backend
    const formData = this.makeSubmitNewRequestPostFormData(dto);

    this.service.postSubmitNewRequest(formData)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(
        (res) => {
              if (res.showDuplicateDialog) {
                this.openDuplicateDetectedDialog(res as DuplicateDetectedDialog);
              } 
              else {
                this.dialogRef.close({result: res, isForSelf: dto.isForSelf});
              }
        },
        (err: StiiraError) => 
          this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
      );
  }

  public close(canNavigate: boolean): void {
    if (canNavigate){
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  public clearFile(): void {
    this.fileToUpload = null;
    this.form.patchValue({
      documentTitle: null,
      documentCategory: null,
    });
    this.fileInput.nativeElement.value = "";
    this.form.controls.documentTitle.setValidators(null)
    this.form.controls.documentCategory.setValidators(null)
    this.form.updateValueAndValidity();
  }

  public handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.form.controls.documentTitle.setValidators(Validators.required)
    this.form.controls.documentCategory.setValidators(Validators.required)
    this.form.updateValueAndValidity();
  }

  public updateEndDate(): void {
    //setValue on endDate when selecting a startDate clears model state errors which was preventing resubmitting of form 
    const endDate = this.form.controls.endDate.value;
    if (endDate != null){
      this.form.patchValue({endDate: endDate});
      this.form.updateValueAndValidity();
    }
  }

  private openDuplicateDetectedDialog(duplicateDetectedDialog: DuplicateDetectedDialog): void {
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '550px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      maxHeight: this.layoutService.isHandheld ? '100vh' : '',
      height: this.layoutService.isHandheld ? '100vh' : '',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.duplicateDetectedSysText,
        duplicateDetectedDialog: duplicateDetectedDialog,
      }
    };

    this.dialog.open(DuplicateDetectedDialogComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.onSubmit(true);
        }
      });
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSystext,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }  

  private makeSubmitNewRequestPostFormData(dto: SubmitNewRequestPost): FormData {
    const formData = new FormData();

    formData.append(nameof<SubmitNewRequestPost>('overrideDuplicates'), dto.overrideDuplicates.toString());
    formData.append(nameof<SubmitNewRequestPost>('isForSelf'), dto.isForSelf.toString());
    formData.append(nameof<SubmitNewRequestPost>('isNewEmployee'), dto.isNewEmployee.toString());
    formData.append(nameof<SubmitNewRequestPost>('employeeId'), dto.employeeId ? dto.employeeId.toString() : null);

    formData.append(nameof<NewEmployee>('firstName'), dto.newEmployee?.firstName ?? null);
    formData.append(nameof<NewEmployee>('middleName'), dto.newEmployee?.middleName ?? null);
    formData.append(nameof<NewEmployee>('lastName'), dto.newEmployee?.lastName ?? null);
    formData.append(nameof<NewEmployee>('preferredName'), dto.newEmployee?.preferredName ?? null);
    formData.append(nameof<NewEmployee>('suffix'), dto.newEmployee?.suffix ?? null);
    
    formData.append(nameof<SubmitNewRequestPost>('companyId'),dto.companyId ? dto.companyId.toString() : null);
    formData.append(nameof<SubmitNewRequestPost>('workPhoneNumber'),dto.workPhoneNumber ?? null);
    formData.append(nameof<SubmitNewRequestPost>('workEmailAddress'),dto.workEmailAddress ?? null);
    formData.append(nameof<SubmitNewRequestPost>('personalPhoneNumber'),dto.personalPhoneNumber ?? null);
    formData.append(nameof<SubmitNewRequestPost>('personalEmailAddress'),dto.personalEmailAddress ?? null);
    formData.append(nameof<SubmitNewRequestPost>('primaryEmailAddressIsPersonal'), dto.primaryEmailAddressIsPersonal?.toString()  ?? null);
    formData.append(nameof<SubmitNewRequestPost>('startDate'), dto.startDate ? dto.startDate.toISOString() : null);
    formData.append(nameof<SubmitNewRequestPost>('endDate'), dto.endDate ? dto.endDate.toISOString() : null);
    formData.append(nameof<SubmitNewRequestPost>('leaveReasonId'), dto.leaveReasonId ? dto.leaveReasonId.toString() : null);
    formData.append(nameof<SubmitNewRequestPost>('comments'), dto.comments);
    formData.append(nameof<SubmitNewRequestPost>('document'), dto.document);
    formData.append(nameof<SubmitNewRequestPost>('documentTitle'), dto.documentTitle);
    formData.append(nameof<SubmitNewRequestPost>('documentCategoryId'), dto.documentCategoryId ? dto.documentCategoryId.toString() : null);
    formData.append(nameof<SubmitNewRequestPost>('documentComments'), dto.documentComments);
    formData.append(nameof<SubmitNewRequestPost>('notifyEmployee'), dto.notifyEmployee.toString());

    return formData;
  }

  private patchEmailAndPhone(employeeId: number): void {
    this.workEmail.patchValue(null);
    this.workPhone.patchValue(null);
    this.personalEmail.patchValue(null);
    this.personalPhone.patchValue(null);
    this.isWorkEmailPreferred.patchValue(false);
    this.isPersonalEmailPreferred.patchValue(false);
    
    const employee = this.data.submitNewRequestDialog.employeeOptions.filter(eo => {
      if (eo.id === employeeId) {
        return eo
      }
    })[0];

    this.workEmail.patchValue(employee?.workEmail);
    this.workPhone.patchValue(employee?.workPhone);
    this.personalEmail.patchValue(employee?.personalEmail);
    this.personalPhone.patchValue(employee?.personalPhone);
    if (employee?.primaryEmailIsPersonal){
      this.isPersonalEmailPreferred.patchValue(true);
      this.isWorkEmailPreferred.patchValue(false);

    }
    if (employee?.primaryEmailIsPersonal === false){
      this.isWorkEmailPreferred.patchValue(true);
      this.isPersonalEmailPreferred.patchValue(false);
    }
  }
}