export interface DateHours {
	date: Date
	hours: number
}

export function parseDateHours(inputText: string): DateHours[] {
	if (!inputText || inputText.trim() == '') {
		return [];
	}
	const rows = inputText.replace(/(\r\n|\r)/g, '\n').split('\n');
	const dateHoursArray: DateHours[] = [];
	rows.forEach(r => {
		const splitRow = r.split('\t');
		if (splitRow.length == 2) {
			const date = Date.parse(splitRow[0]);
			const hours = parseFloat(splitRow[1]);
			if (date || !isNaN(hours)) {
				dateHoursArray.push({date: date ? new Date(date) : null, hours: !isNaN(hours) ? hours : null });
			}
		}
	});
	return dateHoursArray;
}