import { Injectable } from '@angular/core';
import { CaseDetails, ChangeActionItem, LeaveAdminStore, SelectionOption, LeaveCaseDocument, DocumentNeedingUpload } from '@core/models';
import { CaseNote } from '@core/models/leave-admin/case-note.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, pluck } from 'rxjs/operators';
import { LeaveAdminService } from './leave-admin.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveAdminStoreService {

  private readonly _initialStoreData: LeaveAdminStore = {
    caseDetails: null,
    sysText: null
  };

  private leaveAdminStore: BehaviorSubject<LeaveAdminStore>;  
  private actionSubmitting$: Subject<number> = new Subject<number>();    
  private actionEventQueArray: ChangeActionItem[] = [];
  private actionEventSubject: Subject<ChangeActionItem> = new Subject<ChangeActionItem>();
  private actionEventRunning: boolean = false;
  private store$: Observable<LeaveAdminStore>;

  private readonly templateNames = {
    caseAdmin: "caseDetails",
    assignAdmin: "assignCaseAdminDialog",
    leavingPage: "leavingPage",
    actionItems: "leaveActionItems",
    closeCase: "closeCaseDialog",
    employeeInfo: "caseEmployeeInfo",
    leaveInfo: "caseLeaveInfo",
    leaveInfoDialog: "caseLeaveInfoDialog",
    reopenCase: "reopenCaseDialog",
    hideCase: "hideCaseDialog",
    caseRecert: "caseRecert",
    caseNotes: "caseNotes",
    caseDocuments: "caseDocuments",
    deleteConfirmation: "deleteConfirmation",
    editNoteDialog: "editNoteDialog",
    editNoteVisibilityDialog: "editNoteVisibilityDialog",
    unsavedChanges: "unsavedChanges",
    submitIntermittentTime: 'submitIntermittentTime',
    submitNewRequest: "submitNewRequest",
    leaveCalendar: "leaveCalendar",
    viewCalendarItem: "viewCalendarItem",
    addEditCalendarWorkday: "addEditCalendarWorkday",
    editCalendarTime: "editCalendarTime",
    updateConfirmation: "updateConfirmation",
    editDocumentDialog: "editDocumentDialog",
    uploadDocumentDialog: "uploadDocumentDialog",
    duplicateDetected: "duplicateDetected",
    caseLeaveHours: "caseLeaveHours",
    caseHistory: "caseHistory",
    unhideCaseConfirmation: "unhideCaseConfirmation",
    calendarRequirementsWarning: "calendarRequirementsWarning",
    employeeSchedule: "employeeSchedule",
    spreadLeaveHours: "spreadLeaveHours"
  };

  constructor(private leaveAdminService: LeaveAdminService) { 
    this.leaveAdminStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.leaveAdminStore.asObservable();    
  }

  public get isActionRunning() {
    return this.actionEventRunning;
  }

  public get actionQue() {
    return this.actionEventQueArray;
  }

  public get actionEvents() {
    return this.actionEventSubject;
  }
  
  public get actionSubmitting() {
    return this.actionSubmitting$
  }

  public get store() {
    return this.leaveAdminStore.value;
  }

  public get caseDetails() {
    return this.store.caseDetails;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public get caseDetails$(): Observable<CaseDetails> {
    return this.store$.pipe(pluck('caseDetails'))
  }

  public get currentStageIndex() {
    let i = this.caseDetails?.stages?.findIndex(i => i.stageId === this.caseDetails.leaveInformation.currentStageId);    
    return i;
  }

  public get templates() {
    return this.templateNames;
  }

  public set isActionRunning(bool: boolean) {
    this.actionEventRunning = bool;
  }

  public set caseDetails(caseDetails: CaseDetails) {
    const store = { ...this.store, caseDetails };
    this.leaveAdminStore.next(store);
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.leaveAdminStore.next(store);
  }

  public actionEventQuePush(dto: ChangeActionItem): void {
    this.actionEventQueArray.push(dto);
  }  

  public runActionEvent(): void {
    const event = this.actionEventQueArray.shift();    
    this.leaveAdminService.changeActionItem(event)  
      .pipe(
        finalize(()=>{
          this.actionSubmitting.next(event.leaveActionId);
          this.actionEventRunning = false;
          if (this.actionEventQueArray.length > 0) {
            this.runActionEvent();
          };
        })
      )    
      .subscribe((res)=> {
        this.caseDetails = res;
      },
      ()=>{
        this.handleActionError(event);
      }
      );
  } 
  
  private handleActionError(event: ChangeActionItem): void {
    const caseDetails = this.caseDetails;
        caseDetails.stages.forEach(stage => {
          stage.actionItems.forEach(ai => {
            if (ai.actionId == event.leaveActionId){
              ai.isChecked = !event.isChecked;              
            };
          });
        });
        const store = { ...this.store, caseDetails };
        this.leaveAdminStore.next(store);
  }

  public updateCaseAdmin(admin: SelectionOption): void {
    const caseDetails = this.caseDetails;
    if (caseDetails) {
      caseDetails.administrator = { ...admin };
      const store = { ...this.store, caseDetails };
      this.leaveAdminStore.next(store);   
    };
  }

  addCaseNote(note: CaseNote) {
    const caseDetails = this.caseDetails;
    caseDetails.caseNotes.unshift(note);
    const store = { ...this.store, caseDetails };
    this.leaveAdminStore.next(store);
  }

  deleteCaseNote(id: number) {
    const caseDetails = this.caseDetails;
    const index = caseDetails.caseNotes.findIndex(i => i.id === id);
    caseDetails.caseNotes.splice(index, 1);
    const store = { ...this.store, caseDetails };
    this.leaveAdminStore.next(store);
  }

  updateCaseNote(note: CaseNote) {
    const caseDetails = this.caseDetails;
    const index = caseDetails.caseNotes.findIndex(i => i.id === note.id)
    caseDetails.caseNotes.splice(index, 1, note);
    const store = { ...this.store, caseDetails };
    this.leaveAdminStore.next(store);
  }

  public unloadStore() {
    const store: LeaveAdminStore = {
      caseDetails: null,
      sysText: null
    }
    this.leaveAdminStore.next(store)
  }
}