import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard, ResetPasswordGuard, SetPasswordGuard } from '@core/guards';
import { LoginGuard } from '@core/guards/login.guard';
import { AnonymousRequestResolver } from '@core/resolvers/anonymous-request.resolver';
import { accountRoutePaths } from '@core/routes/route-paths.constants';
import { AccountComponent } from './account.component';
import { AnonymousNewRequestComponent } from './pages/anonymous-new-request/anonymous-new-request.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    canActivateChild: [NoAuthGuard],
    children: [
      { path: accountRoutePaths.LOGIN, component: LoginComponent, canActivate: [LoginGuard] },
      { path: accountRoutePaths.FORGOT_PASSWORD, component: ForgotPasswordComponent },
      { path: accountRoutePaths.RESET_PASSWORD, component: ResetPasswordComponent, canActivate: [ResetPasswordGuard] },
      { path: accountRoutePaths.SET_PASSWORD, component: SetPasswordComponent, canActivate: [SetPasswordGuard] },
      { path: accountRoutePaths.ANONYMOUS_REQUEST, component: AnonymousNewRequestComponent, resolve: { anonymousRequest: AnonymousRequestResolver } },
      { path: '**', redirectTo: `/${accountRoutePaths.LOGIN}` },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
