<mat-list id="password-requirements-list">
  <mat-list-item *ngFor="let req of reqList" class="requirement-item">
    <span matListItemLine class="list-line">
      <mat-icon mat-list-icon [ngClass]="{
        'valid-req': req.valid,
        'invalid-req': !req.valid
      }">
        {{ req.valid ? 'check_circle' : 'check_circle_outline' }}
      </mat-icon>
      <p mat-line class="caption">at least {{ req.value + ' ' + req.description }}</p>
    </span>
  </mat-list-item>
</mat-list>
