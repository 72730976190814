import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private redirectUrlSubject: BehaviorSubject<string>;
  private lastNavUrl: string;

  constructor(private router: Router) {
    this.redirectUrlSubject = new BehaviorSubject('');
    this.router.events.subscribe((re) => {
      if (re instanceof NavigationStart) {
        this.lastNavUrl = re.url;
      }
    });
  }

  set setRedirectUrl(url: string) {
    this.redirectUrlSubject.next(url);
  }

  get redirectUrl(): string {
    return this.redirectUrlSubject.value;
  }

  get lastNavigationUrl(): string {
    return this.lastNavUrl;
  }

  public resetRedirectUrl(): void {
    this.redirectUrlSubject.next('');
  }
}