<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6>{{ data.event.start | date }}</h6>
  <mat-icon
    class="info-icon"
    [matTooltip]="data.sysText.addEditCalendarWorkday_info" 
    [matTooltipShowDelay]="500" 
    matTooltipPosition="before">
    info_outline
  </mat-icon>
  <ng-container *ngIf="isHandheld$ | async">
    <span class="spacer"></span>
    <mat-icon 
      role="button" 
      (click)="cancel()" 
      class="icon__button">
      close
    </mat-icon>
  </ng-container>
</div>

<mat-dialog-content class="mat-dialog-content">
	<ng-container>
		<form [formGroup]="form">
			<div class="form-container">
				<div class="radio-stacked">
					<mat-radio-group aria-label="Select an option" [formControl]="form.controls.workdayOption" color="accent" (change)="onChange($event)">
						<mat-radio-button *ngFor="let option of workdayOptions" [value]="option.id">{{ option.description | capitalizefirst }}</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="column-container">
					<app-input-field
						class="hours-input"
						[group]="form"
						[label]="data.sysText.addEditCalendarWorkday_workdayHours"
						[hideIconSpace]="true"
						[control]="form.controls.hours"
						[inputType]="'number'">
					</app-input-field>
					<div class="text-container">
						@for(item of defaultItems; track item) {
							<p class="body2"><i>{{ item }}</i></p>
						}
					</div>
				</div>
			</div>
		</form>
	</ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
	<button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSaving$ | async" *ngIf="!(isHandheld$ | async)">
		{{ data.sysText.cancel }}
	</button>
	<button mat-stroked-button class="button" color="accent" (click)="save()">
		{{ data.sysText.save }}
	</button>
</mat-dialog-actions>

<ng-template #submitting>
	<mat-dialog-actions align="end">
			<button mat-button class="button" type="button" color="accent" disabled>
					<mat-spinner diameter="30" color="accent"></mat-spinner>
				</button>
	</mat-dialog-actions>
</ng-template>