<mat-icon 
    class="icon"
    [matTooltip]="tooltip" 
    [matTooltipShowDelay]="500" 
    matTooltipPosition="above"
    [matTooltipDisabled]="!tooltip"
    [ngClass]="{ margin: margin }" 
    *ngIf="icon"
>{{ icon }}</mat-icon>
<p class="caption">{{ text }}</p>
