import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectiveQueryParam } from '@core/enums';
import { AccountFormOptions, Credentials, StiiraError, Token, SendCode, VerifyCode, SelectedProviders, VerificationFormOptions, RememberBrowser } from '@core/models';
import { AnonymousNewRequest } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { appRoutePaths, leaveAdminRoutes } from '@core/routes/route-paths.constants';
import { AccountService, AuthService, ConstantsService, ErrorService, RedirectService, SnackbarService } from '@core/services';
import { AccountPagesSystemTextService } from '@core/services/account-pages-system-text.service';
import { AnonymousRequestsStoreService } from '@core/services/anonymous-requests-store.service';
import { ImpersonateComponent } from '@modules/dialogs/impersonate/impersonate.component';
import { LoginPageAlertComponent } from '@modules/dialogs/login-page-alert/login-page-alert.component';
import { FormGroup } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { filter, pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public isSubmitting$ = new Subject<boolean>();
  public options: AccountFormOptions;
  public verificationOptions: VerificationFormOptions;
  public sysText$: Observable<any>;
  public username: string;
  public templateName: string;
  public otpDest: string;
  public otpIsPhone: boolean;
  public showVerification: boolean;
  public selectedProviders: SelectedProviders;
  public anonymousNewRequest: AnonymousNewRequest;

  private token: Token;
  private creds: Credentials;
  private twoFactorRememberCookie: string = "StiiraTwoFactorRememberBrowser";
  private provider: string;

  constructor(
    private authService: AuthService,
    private acctTextService: AccountPagesSystemTextService,
    private constants: ConstantsService,
    private errorService: ErrorService,
    private router: Router,
    private route: ActivatedRoute,
    private acctService: AccountService,
    private dialog: MatDialog,
    private redirect: RedirectService,
    private snackbar: SnackbarService,
    private anonStore: AnonymousRequestsStoreService
  ) {}

  ngOnInit() {
    // the function below will launch an alert dialog if we need to tell users there are technical difficulties, etc (customize dialog text before using)
    // this.launchLoginPageAlert();
    this.templateName = this.acctTextService.templates.login;
    this.sysText$ = this.acctTextService.sysText$.pipe(pluck(this.templateName));
    this.options = this.constants.ACCOUNTFORM_OPTIONS_LOGIN;
    this.verificationOptions = this.constants.VERIFICATIONFORM_OPTIONS_LOGIN;
    this.selectedProviders = this.constants.SELECTED_PROVIDERS;
    this.username = this.route.snapshot.queryParams.username;
    this.showVerification = false;
    this.provider = this.selectedProviders.emailProvider;    
    this.anonymousNewRequest = this.acctService.getAnonymousNewRequest();
  }

  ngOnDestroy() {
    this.anonStore.setHasSubmitted = false;
  }

  private launchLoginPageAlert(): void {
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      disableClose: false,
      closeOnNavigation: true,
    };

    this.dialog.open(LoginPageAlertComponent, dialogConfig);
  }

  public onFormSubmit(form: FormGroup): void {
    this.creds = {
      username: form.value.username,
      password: form.value.password,
      clientTzo: new Date().getTimezoneOffset().toString(),
    };

    this.authService
      .login(this.creds)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res) => {
        this.token = res;
        if (res.validated_phone_number) {
          this.provider = this.selectedProviders.phoneProvider;
          this.verificationOptions.phoneNumberConfirmed = true;
          this.verificationOptions.secondaryLink.show = true;
        }
        const twoFactorCookie = this.checkForTwoFactorCookie();
        if (res.two_factor_enabled == 'True' && twoFactorCookie) {
          const rb: RememberBrowser = {
            username: this.creds.username,
            password: this.creds.password
          };
          this.acctService.verifyTwoFactorRememberBrowser(rb).subscribe((res => {
            if (res){
              this.handleLoginRouting(this.token);
            } else {
              this.handleVerificationStep(this.token.validated_phone_number);
            }
          }));
        } else if (res.two_factor_enabled == 'True'){
          this.handleVerificationStep(this.token.validated_phone_number);
        } else {
          this.handleLoginRouting(res);
        }
      },
      (err: StiiraError) => {
        this.errorService.setFormModelStateErrors(form, err.modelStateErrors);
      });
  }

  public onVerificationSubmit(form: FormGroup): void {
    const verifyCode: VerifyCode = {
      code: form.value.code,
      provider: this.provider, 
      username: this.creds.username,
      userId: form.value.userId,
      rememberMe: form.value.rememberMe
    };
    this.acctService.verifyCode(verifyCode)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res) => {
        if (res) {
          if (verifyCode.rememberMe) {
            this.setTwoFactorRememberBrowserCookie(this.creds);
          }
          this.handleLoginRouting(this.token);
          this.showVerification = false;
        }
      },
      (err: StiiraError) => {
        this.errorService.setFormModelStateErrors(form, err.modelStateErrors);
      });
  }

  private handleVerificationStep(phoneNumber: string): void {
    this.handleSendCode({provider: this.provider, resend: false});

    if (phoneNumber == null)  {
      this.otpIsPhone = false;
    }  
    else {
      let int: number = Number(phoneNumber.slice(phoneNumber.length - 4))
      this.otpIsPhone =  Number.isInteger(int);
    }
    
    this.otpDest =  this.otpIsPhone ? phoneNumber.slice(phoneNumber.length - 4) : this.token.email;
    this.showVerification = true;
  }

  public handleSendCode(handleCode: {provider: string, resend: boolean}) : void {
    this.provider = handleCode.provider;
    const sendCode: SendCode = {
      selectedProvider: this.provider,
      username: this.creds.username
    };
    this.acctService.sendCode(sendCode)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(() => {
        if ( handleCode.resend && handleCode.provider === this.selectedProviders.emailProvider){
          //todo: text should come from system text
          this.snackbar.open('Code emailed!', 'Dismiss');
        } 
        else if ( handleCode.resend && handleCode.provider === this.selectedProviders.phoneProvider){
          //todo: text should come from system text
          this.snackbar.open('Code resent!', 'Dismiss');
        }
      });
  }

  public onGoBack(): void {
    this.showVerification = false;
  }

  private handleLoginRouting(res: Token): void {
    if (res.can_impersonate == 'True') {
      this.authService.setIsImpersonating(true);
      this.openImpersonateDialog(res);
    } else {
      this.authService.updateUser(this.token);
      this.router.navigate([this.getRedirectPath()]);
      this.redirect.resetRedirectUrl();
    }
  }

  private getRedirectPath(): string {
    const dir: DirectiveQueryParam = this.acctService.getDir();
    const cid: string = this.acctService.getCaseId();
    let route = '';
    switch (dir) {
      case DirectiveQueryParam.la:
        route = `/${appRoutePaths.LEAVE_ADMIN}`;
        break;
      case DirectiveQueryParam.cd:
        this.authService.setIsUrlWithQueryParams(true);
        route = `/${appRoutePaths.LEAVE_ADMIN}/${leaveAdminRoutes.DETAILS}/${cid}`;
        break;
      default:
        route = this.redirect.redirectUrl;
    }

    if (!route) {
      route = `/${appRoutePaths.HOME}`;
    }

    return route;
  }

  private setTwoFactorRememberBrowserCookie(creds: Credentials): void {
    const rb: RememberBrowser = {
      username: creds.username,
      password: creds.password
    }
    this.acctService.setTwoFactorRememberBrowser(rb).subscribe((res)=> {
      if (res){
        document.cookie = this.twoFactorRememberCookie + "=true";
      }
    });
  }

  private checkForTwoFactorCookie(): string {
    let value: string;
    let name = this.twoFactorRememberCookie + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        value = c.substring(name.length, c.length);
        return value;
      }
    }   
    return null;
  }

  private openImpersonateDialog(token: Token): void {
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '80vw',
      height: '440px',
      maxHeight: '440px',
      autoFocus: true,
      disableClose: false,
      closeOnNavigation: true,
      data: { 
        sysText: this.acctTextService.getSysText(this.acctTextService.templates.impersonateEmployee), 
        creds: this.creds,
        token: token
      }
    };

    this.dialog.open(ImpersonateComponent, dialogConfig)
      .afterClosed()
        .pipe(
          tap(() => this.authService.setIsImpersonating(false)),
          filter((res: Token) => !!res)
        )
        .subscribe((res) => {
          this.authService.updateUser(res);
          this.router.navigate([this.getRedirectPath()]);
          this.redirect.resetRedirectUrl();
        });
  }
}