<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6 class="grey1">{{ data.sysText.addNewEmployee_header | titlecase }}</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content class="mat-dialog-content">
	<div class="new-employee">
		<div class="sub-employee">
			<div class="new-ee-column">
				<app-input-field
					[label]="data.sysText.firstName"
					[control]="firstName"
					[group]="form"
					[icon]="'person'"
				></app-input-field>
			</div>
			<div class="new-ee-column">
				<app-input-field 
					[label]="data.sysText.middleName"
					[control]="middleName"
					[group]="form"
				></app-input-field>
			</div>
		</div>
		<div class="sub-employee">
			<div class="new-ee-column">
				<app-input-field 
					[label]="data.sysText.lastName"
					[control]="lastName"
					[group]="form"
				></app-input-field>
			</div>
			<div class="new-ee-column">
				<app-input-field 
					[label]="data.sysText.suffix"
					[control]="suffix"
					[group]="form"
				></app-input-field>
			</div>
		</div>
		<div class="new-ee-full-column">
			<div>
				<app-input-field 
					[label]="data.sysText.preferredName"
					[control]="preferredName"
					[group]="form"
				></app-input-field>
			</div>
		</div>
		<div class="new-ee-full-column" *ngIf="data.addNewEmployeeDialog.showCompany">
			<app-select-field
				[control]="companyId"
				[label]="data.sysText.company"
				[options]="data.addNewEmployeeDialog.companyOptions"
				[icon]="'business'"
				[shouldCapitalize]="false"
      		></app-select-field>
		</div>
		<app-email-field-group
			[group]="form"
			[workEmailControl]="workEmail"
			[isWorkEmailPreferredControl]="isWorkEmailPreferred"
			[personalEmailControl]="personalEmail"
			[isPersonalEmailPreferredControl]="isPersonalEmailPreferred">
		</app-email-field-group>
		<div class="sub-employee">
			<div class="new-ee-column">
				<app-input-field 
					[label]="data.sysText.workPhone"
					[control]="workPhone"
					[group]="form"
					[icon]="'phone'"
				></app-input-field>
			</div>
			<div class="new-ee-column">
				<app-input-field 
					[label]="data.sysText.personalPhone"
					[control]="personalPhone"
					[group]="form"
				></app-input-field>
			</div>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<ng-container *ngIf="!(isSaving$ | async); else saving">
		<button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSaving$ | async"
			*ngIf="!isHandheld">
			{{ data.sysText.cancel }}
		</button>
		<button mat-stroked-button class="button" color="accent" (click)="save(false)" [disabled]="(isSaving$ | async)">
			{{ data.sysText.submit }}
		</button>
	</ng-container>
	<ng-template #saving>
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</ng-template>
</mat-dialog-actions>