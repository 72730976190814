import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { LayoutService } from '@core/services';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-duplicate-detected-dialog',
  templateUrl: './duplicate-detected-dialog.component.html',
  styleUrls: ['./duplicate-detected-dialog.component.scss']
})
export class DuplicateDetectedDialogComponent extends DialogDragConstraints implements OnInit {

  public isSaving$: Subject<boolean> = new Subject<boolean>();

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      sysText: any,
      duplicateDetectedDialog: DuplicateDetectedDialog
      },
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<DuplicateDetectedDialogComponent>,
  ) { 
    super(dialogRef);
  }

  ngOnInit(): void {
  }
}
