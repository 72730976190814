import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionOption, StiiraError } from '@core/models';
import { NewEmployee } from '@core/models/leave-admin/employee-info-post.model';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { EmployeeRecord, EmployeeRecordInfoForm, EmployeeRecordInfoPost, NewEmployeeGroupForm } from '@core/models/leave-admin/employees/employee-record.model';
import { ErrorService, LayoutService } from '@core/services';
import { EmployeeRecordStoreService } from '@core/services/employee-record-store.service';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { DuplicateDetectedDialogComponent } from '../duplicate-detected-dialog/duplicate-detected-dialog.component';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { UpdateConfirmationComponent } from '../update-confirmation/update-confirmation.component';
import { StateAbbreviation } from '@core/models/leave-admin/employers/state-abbreviation.model';
import { takeUntil } from 'rxjs/operators';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-employee-record-info',
  templateUrl: './edit-employee-record-info.component.html',
  styleUrls: ['./edit-employee-record-info.component.scss']
})
export class EditEmployeeRecordInfoComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup<EmployeeRecordInfoForm>;
  public isNewSupervisor: boolean[] = [];
  public isNewHrManager: boolean;
  
  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get supervisorsArray(): FormArray {
    return this.form.controls.supervisors as FormArray;
  } 

  get newEmployeeHrManager(): FormGroup {
    return this.form.controls.newEmployeeHrManager as FormGroup;
  }

  get companyField(): FormControl {
    return this.form.controls.company as FormControl;
  }

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      employeeRecord: EmployeeRecord; 
      sysText: any; 
      sysTextDialog: any;
      sysTextUnsavedChanges: any;
      sysTextDuplicateDetected: any;
      updateConfSysText: any;
      supervisorOptions: SelectionOption[];
      hrManagerOptions: SelectionOption[];
      stateAbbreviations: StateAbbreviation[];
      stateOptions: SelectionOption[];
      jobTitles: string[];
    },
    private fb: FormBuilder,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private eeRecordInfoDialogRef: MatDialogRef<EditEmployeeRecordInfoComponent>,
    private service: ManageEmployeesService,
    private store: EmployeeRecordStoreService,
    private layoutService: LayoutService
  ) { 
    super(eeRecordInfoDialogRef);
    
    this.form = this.fb.group<EmployeeRecordInfoForm>({
      firstName: this.fb.control(null),
      lastName: this.fb.control(null),
      middleName: this.fb.control(null),
      preferredName: this.fb.control(null),
      suffix: this.fb.control(null),
      primaryEmailIsPersonal: this.fb.control(null),
      workEmail: this.fb.control(null),
      workEmailChecked: this.fb.control(null),
      personalEmail: this.fb.control(null),
      personalEmailChecked: this.fb.control(null),
      company: this.fb.control(null), 
      workPhone: this.fb.control(null),
      personalPhone: this.fb.control(null),
      fixedLeaveYearStart: this.fb.control(null),
      firstLeaveUsageDate: this.fb.control(null),
      jobTitle: this.fb.control(null),
      supervisors: this.fb.array([this.initNewEEGroup()]),
      hrManagerId: this.fb.control(null),
      hireDate: this.fb.control(null), 
      city: this.fb.control(null), 
      stateId: this.fb.control(null),
      newEmployeeSupervisor: this.initNewEEGroup(),
      newEmployeeHrManager: this.initNewEEGroup()
    });
  }

  ngOnInit(): void {
    this.setupForm();
    this.companyField.disable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setupForm() {
    this.form.patchValue({
      firstName: this.data.employeeRecord?.firstName,
      middleName: this.data.employeeRecord?.middleName,
      lastName: this.data.employeeRecord?.lastName,
      suffix: this.data.employeeRecord?.suffix,
      preferredName: this.data.employeeRecord?.preferredName,
      workEmail: this.data.employeeRecord?.workEmail,
      workEmailChecked: this.data.employeeRecord?.primaryEmailIsPersonal === false,
      personalEmail: this.data.employeeRecord?.personalEmail,
      personalEmailChecked: this.data.employeeRecord?.primaryEmailIsPersonal === true,
      fixedLeaveYearStart: this.data.employeeRecord?.fixedLeaveYearStart,
      firstLeaveUsageDate: this.data.employeeRecord?.firstLeaveUsageDate,
      company: this.data.employeeRecord.company.name,
      workPhone: this.data.employeeRecord?.workPhone,
      personalPhone: this.data.employeeRecord?.personalPhone,
      jobTitle: this.data.employeeRecord?.jobTitle,
      hrManagerId: +this.data.employeeRecord?.hrManager?.id,
      hireDate: this.data.employeeRecord?.hireDate,
      city: this.data.employeeRecord?.city,
      stateId: this.data.employeeRecord?.stateId
    });

    // handle patching supervisors
    for (let i = 1; i < this.data.employeeRecord?.supervisors?.length; i++) {
      this.addSupervisorFormGroup();
    }
    if (this.data.employeeRecord?.supervisors?.length > 0) {
      this.supervisorsArray.controls.forEach((obj, index) => {
        if (this.data.employeeRecord?.supervisors[index] != null) {
          obj.patchValue({employeeId: this.data.employeeRecord?.supervisors[index].id});
        }
      });
    }

    this.form.controls.firstName.setValidators(Validators.required);
    this.form.controls.lastName.setValidators(Validators.required);

    this.hasChangesSetTimeout();
  }

  public initNewEEGroup(): FormGroup<NewEmployeeGroupForm> {
    return this.fb.group<NewEmployeeGroupForm>({
      employeeId: this.fb.control(null),
      firstName: this.fb.control(null),
      middleName: this.fb.control(null),
      lastName: this.fb.control(null),
      suffix: this.fb.control(null),
      preferredName: this.fb.control(null),
      workPhone: this.fb.control(null),
      personalPhone: this.fb.control(null),
      workEmail: this.fb.control(null),
      workEmailChecked: this.fb.control(null),
      personalEmail: this.fb.control(null),
      personalEmailChecked: this.fb.control(null),
      primaryEmailIsPersonal: this.fb.control(null)
    })
  }

  public onAddSupervisorContact(index: number): void {
    this.isNewSupervisor[index] = !this.isNewSupervisor[index];
    const formGroup: FormGroup = this.supervisorsArray.controls[index] as FormGroup;

    formGroup.patchValue({
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      prefferedName: null,
      workPhone: null,
      personalPhone: null,
      workEmail: null,
      personalEmail: null
    });

    if (this.isNewSupervisor[index]) {
      formGroup.controls.firstName.setValidators(Validators.required)
      formGroup.controls.lastName.setValidators(Validators.required)
    } else {
      formGroup.controls.firstName.setValidators(null);
      formGroup.controls.lastName.setValidators(null);
    }

    formGroup.updateValueAndValidity();
    formGroup.markAsUntouched();
  }

  public addSupervisorFormGroup(): void {
    const createContactGroup = this.initNewEEGroup();
    this.supervisorsArray.push(createContactGroup);
    this.isNewSupervisor.push(false);
  }

  public onRemoveSupervisorContact(index: number) {
    this.supervisorsArray.removeAt(index);
  }

  public onHrManagerHintClick(): void {
    this.isNewHrManager = !this.isNewHrManager

    this.form.controls.newEmployeeHrManager.patchValue({
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      preferredName: null,
      workPhone: null,
      personalPhone: null,
      workEmail: null,
      personalEmail: null
    });

    if (this.isNewHrManager) {
      this.newEmployeeHrManager.controls.firstName.setValidators(Validators.required);
      this.newEmployeeHrManager.controls.lastName.setValidators(Validators.required);
    } else {
      this.newEmployeeHrManager.controls.firstName.setValidators(null);
      this.newEmployeeHrManager.controls.lastName.setValidators(null);
    }

    this.newEmployeeHrManager.updateValueAndValidity();
    this.newEmployeeHrManager.markAsUntouched();
  }

  public onSubmit(overrideDuplicates: boolean = false, overrideUpdateConf: boolean = false) {
    let cleanSupervisors: NewEmployee[] = [];
    this.supervisorsArray.controls.forEach((formGroup: FormGroup, index) => {
      let isEmpty : boolean;
      Object.keys(formGroup.controls).forEach((control: string) => {
        const val = formGroup.controls[control].value;
        if (val){
          isEmpty = false;
        }
      });

      if (isEmpty == false) {
        const newSup: NewEmployee = {
          employeeId: this.isNewSupervisor[index] ? null : formGroup.controls.employeeId.value,
          firstName: this.isNewSupervisor[index] ? formGroup.controls.firstName.value : null,
          middleName: this.isNewSupervisor[index] ? formGroup.controls.middleName.value : null,
          lastName: this.isNewSupervisor[index] ? formGroup.controls.lastName.value : null,
          suffix: this.isNewSupervisor[index] ? formGroup.controls.suffix.value : null,
          preferredName: this.isNewSupervisor[index] ? formGroup.controls.preferredName.value : null,
          workPhone: this.isNewSupervisor[index] ? formGroup.controls.workPhone.value : null,
          personalPhone: this.isNewSupervisor[index] ? formGroup.controls.personalPhone.value : null,
          workEmail: !this.isNewSupervisor[index] || formGroup.controls.workEmail.value?.trim().length == 0 ? null : formGroup.controls.workEmail.value,
          personalEmail: !this.isNewSupervisor[index] || formGroup.controls.personalEmail.value?.trim().length == 0 ? null : formGroup.controls.personalEmail.value,
          primaryEmailIsPersonal: this.isNewSupervisor[index] 
          ? formGroup.controls.personalEmailChecked.value 
            ? true 
            : formGroup.controls.workEmailChecked.value 
              ? false 
              : null
          : null
        }
        cleanSupervisors.push(newSup);
      }
    });

    const newHrMan: NewEmployee = {
      employeeId: this.isNewHrManager ? null : this.newEmployeeHrManager.controls.employeeId.value,
      firstName: this.newEmployeeHrManager.controls.firstName.value,
      middleName: this.newEmployeeHrManager.controls.middleName.value,
      lastName: this.newEmployeeHrManager.controls.lastName.value,
      suffix: this.newEmployeeHrManager.controls.suffix.value,
      preferredName: this.newEmployeeHrManager.controls.preferredName.value,
      workEmail: this.newEmployeeHrManager.controls.workEmail.value,
      personalEmail: this.newEmployeeHrManager.controls.personalEmail.value,
      primaryEmailIsPersonal: this.newEmployeeHrManager.controls.workEmailChecked.value
        ? false
        : this.newEmployeeHrManager.controls.personalEmailChecked.value
          ? true
          : null,
      workPhone: this.newEmployeeHrManager.controls.workPhone.value,
      personalPhone: this.newEmployeeHrManager.controls.personalPhone.value,
    }

    const dto: EmployeeRecordInfoPost = {
      overrideDuplicates: overrideDuplicates,
      overrideUpdateConf: overrideUpdateConf,
      employeeId: this.data.employeeRecord.employeeId, 
      firstName: this.form.controls.firstName.value,
      middleName:  this.form.controls.middleName.value,
      lastName:  this.form.controls.lastName.value, 
      suffix:  this.form.controls.suffix.value,
      preferredName:  this.form.controls.preferredName.value,
      workPhone: this.form.controls.workPhone.value, 
      personalPhone: this.form.controls.personalPhone.value, 
      workEmail: this.form.controls.workEmail.value,
      personalEmail: this.form.controls.personalEmail.value,
      primaryEmailIsPersonal: this.form.controls.personalEmailChecked.value ? true : this.form.controls.workEmailChecked.value ? false : null,
      jobTitle: this.form.controls.jobTitle.value,
      supervisors: cleanSupervisors,
      hrManagerId: this.isNewHrManager ? null : this.form.controls.hrManagerId.value, 
      hireDate: this.form.controls.hireDate.value, 
      city: this.form.controls.city.value, 
      stateId: this.form.controls.stateId.value,
      newEmployeeHrManager: this.isNewHrManager ? newHrMan : null, 
      fixedLeaveYearStart: this.form.controls.fixedLeaveYearStart.value,
      firstLeaveUsageDate: this.form.controls.firstLeaveUsageDate.value,
    }
    this.service.postEmployeeRecordInfo(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        if (res.showDuplicateDialog) {
          this.openDuplicateDetectedDialog(res as DuplicateDetectedDialog, overrideUpdateConf);
        } else if (res.showUpdateConfirmationDialog) {
          this.openUpdateConfirmationDialog(res as UpdateConfirmationDialog, overrideDuplicates);
        } else {
          this.store.employeeRecord = res as EmployeeRecord;
          this.close(true);
        }
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public close(canNavigate: boolean): void {
    if (canNavigate){
      this.eeRecordInfoDialogRef.close();
    } else {
      if (this.noChanges) {
        this.eeRecordInfoDialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  private hasChangesSetTimeout() {
    setTimeout(()=>{
      this.formInitValues = JSON.parse(JSON.stringify(this.form.value));
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysTextUnsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.eeRecordInfoDialogRef.close();
        }
      });
  }

  private openDuplicateDetectedDialog(duplicateDetectedDialog: DuplicateDetectedDialog, overrideUpdateConf: boolean): void {
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '550px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      maxHeight: this.layoutService.isHandheld ? '100vh' : '',
      height: this.layoutService.isHandheld ? '100vh' : '',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.sysTextDuplicateDetected,
        duplicateDetectedDialog: duplicateDetectedDialog,
      }
    };

    this.dialog.open(DuplicateDetectedDialogComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.onSubmit(true, overrideUpdateConf);
        }
      });
  }

  private openUpdateConfirmationDialog(updateConfirmationDialog: UpdateConfirmationDialog, overrideDuplicates: boolean): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: "mat-dialog-container-mobileWidth",
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.updateConfSysText,
        updateConfirmationDialog: updateConfirmationDialog
      }
    };

    this.dialog.open(UpdateConfirmationComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.onSubmit(true, overrideDuplicates);
        }
      });
  }
}
