<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6>
    {{ data.editing ? data.sysText.editNonWorkday_header : data.sysText.addNonWorkday_header }}
  </h6>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <app-input-field
      [control]="form.controls.name"
      [icon]="'subject'"
      [hideIconSpace]="isMobile$ | async"
      [label]="data.sysText.name"
      [inputType]="'string'">
    </app-input-field>
    <div *ngIf="data.dialogData.showOwner">
      <app-select-field
        [control]="form.controls.ownerEmployerId"
        [label]="data.sysText.owner"
        [options]="data.dialogData.employerOptions"
        [icon]="'business'"
        [hideIconSpace]="isMobile$ | async"
        [shouldCapitalize]="false">
      </app-select-field>
    </div>
    <div class="slide-container">
      <app-slide-toggle
        [control]="form.controls.allowUse"
        [label]="data.sysText.allowUse">
      </app-slide-toggle>
    </div>
    <div class="add-nonworkday-section">
      <div class="field-container">
        <app-date-field
          #dateInput
          class="non-workday-field"
          [label]="data.sysText.nonWorkday_date"
          [control]="form.controls.nonWorkdayDateAdd"
          [hideIconSpace]="isMobile$ | async"
          [icon]="'calendar_today'"
          [group]="form">
        </app-date-field>
        <app-input-field
          class="non-workday-field"
          [control]="form.controls.nonWorkdayDescAdd"
          [hideIconSpace]="!(isHandheld$ | async) || (isMobile$ | async)"
          [label]="data.sysText.nonWorkday_description"
          [inputType]="'string'">
        </app-input-field>
      </div>
      <button
        (click)="onAddNonWorkday()"
        mat-flat-button
        [disabled]="editingRow"
        color="accent"
        class="button">
        {{data.sysText.addNonWorkday | uppercase}}
      </button>
      <mat-error class="mat-error" *ngIf="form.controls.yearNonWorkdayGroups.errors?.notAdded">
        {{ data.sysText.nonWorkdays_notAddedError }}
      </mat-error>
      <div *ngIf="addedMessage" class="hint-container">
        <mat-hint class="mat-form-field">
          {{ data.sysText.nonWorkdays_addedMessage
              .replace("@[desc]", addedMessage.desc)
              .replace("@[date]", (addedMessage.date | date)) }}
        </mat-hint>
      </div>
      </div>
    <div class="non-workdays-section">
      @for(yearFormGroup of yearFormGroups.controls; track yearFormGroup; let j = $index) {
        <mat-expansion-panel 
          class="info-panel remove-mat-expansion-panel-padding non-workdays" 
          [expanded]="yearPanelOpenState.includes(yearFormGroup.controls.year.value)">
          <mat-expansion-panel-header *ngIf="yearFormGroup.controls.year.value" class="no-padding" [ngClass]="{ 'disabled': editingRow }">
            <mat-panel-title>
              <div class="section-header-container">
                <p class="overline grey1 semibold section-title">
                  {{yearFormGroup.controls.year.value + ' (' + yearFormGroup.controls.nonWorkdays.controls.length + ')'}}
                </p>
                <mat-divider class="section-header-underline"></mat-divider>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          @for(nonWorkdayFormGroup of yearFormGroup.controls.nonWorkdays.controls; track nonWorkdayFormGroup; let i = $index) {
            <div class="non-workday-form-row">
              <div class="field-container">
                <div class="non-workday-field date">
                  <div class="container ">
                    <mat-icon [ngClass]="{ focus: input.focused }">{{ 'calendar_today' }}</mat-icon>
                    <mat-form-field color="accent">
                      <mat-label>{{ data.sysText.nonWorkday_date | titlecase }}</mat-label>
                      <input matInput [matDatepicker]="picker" [formControl]="nonWorkdayFormGroup.controls.date" #input="matInput"/>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <mat-error class="mat-error" *ngIf="nonWorkdayFormGroup.controls.date.errors?.required && !nonWorkdayFormGroup.controls.date.errors?.matDatepickerParse">
                    {{ data.sysText.required | capitalizefirst }}
                  </mat-error>
                  <mat-error class="mat-error" *ngIf="nonWorkdayFormGroup.controls.date.errors?.msError">
                    {{nonWorkdayFormGroup.controls.date.errors?.msError}}
                  </mat-error>
                  <!-- mat hint for spacing -->
                  <mat-hint *ngIf="!nonWorkdayFormGroup.controls.date.errors"></mat-hint>
                </div>
                <app-input-field
                  class="non-workday-field"
                  [control]="nonWorkdayFormGroup.controls.name"
                  [hideIconSpace]="!(isHandheld$ | async)"
                  [label]="data.sysText.nonWorkday_description"
                  [inputType]="'string'">
                </app-input-field>
              </div>
              <div class="form-row-buttons">
                <button
                  mat-icon-button
                  [disabled]="editingRow"
                  [disableRipple]="true"
                  (click)="onRemoveNonWorkday(j, i)">
                  <mat-icon [ngClass]="{ 'disabled': editingRow }">delete</mat-icon>
                </button>
                <button
                  *ngIf="editingRow?.yearGroupIndex === j && editingRow?.nonWorkdayIndex === i; else edit"
                  mat-icon-button
                  [disableRipple]="true"
                  (click)="onSaveNonWorkday(j, i)">
                  <mat-icon >check</mat-icon>
                </button>
                <ng-template #edit>
                  <button
                    mat-icon-button
                    [disabled]="editingRow"
                    [disableRipple]="true"
                    (click)="onEditNonWorkday(j, i)">
                    <mat-icon [ngClass]="{ 'disabled': editingRow }">edit</mat-icon>
                  </button>
                </ng-template>
              </div>
            </div>
          }
        </mat-expansion-panel>
      }
    </div>
    <div class="bottom-section">
      <mat-error *ngIf="yearFormGroups.errors?.msError" class="mse-form-error caption bottom-hint">
        {{ yearFormGroups.errors?.msError }}
      </mat-error>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
  <button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="editingRow">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" color="accent" (click)="onSubmit()" [disabled]="editingRow">
    {{ data.sysText.save }}
  </button>
  
</mat-dialog-actions>
<ng-template #submitting>
  <mat-dialog-actions align="end">
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>
