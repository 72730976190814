export enum RelvantContext {
	Employee = -1,
	Supervisor = -2,
	HrManager = -3,
	AssignedAdmin = -4,
	PrimaryTenantAdmin = -5,
	PrimaryEmployerAdmin = -6,
	MainContact = -7, 
	BenefitsContact = -8,
	BenefitsDeductionsContact = -9,
	PayrollContact = -10
}