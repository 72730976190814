<ng-container *ngIf="group" [formGroup]="group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>

<ng-container *ngIf="!group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>

<ng-template #formField>
  <div class="container">
    <mat-icon *ngIf="!hideIconSpace" [ngClass]="{ focus: input.focused }">{{ icon }}</mat-icon>
    <mat-form-field color="accent" [ngClass]="{ 'retain-default-disabled': retainDefaultDisabled }">
      <mat-label *ngIf="!removeLabelCasePipe">{{ label | titlecase }}</mat-label>
      <mat-label *ngIf="removeLabelCasePipe">{{ label }}</mat-label>
      <input matInput #input="matInput" [type]="inputType" [formControl]="control"  (change)="onEmitInputChange($event)"/>
      <mat-hint [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }">{{ hint }}</mat-hint>
      <mat-error *ngIf="control.errors?.msError">{{ control.errors?.msError == msErrorKey ? customMsError : control.errors?.msError }}</mat-error>
      <mat-error *ngIf="control.errors?.required">{{ customRequiredMessage != null ? customRequiredMessage : sysText.required | capitalizefirst }}</mat-error>
      <mat-error *ngIf="control.errors?.min">{{ sysText.invalidMinValueError | capitalizefirst }} {{ control.errors.min.min }}</mat-error>
      <mat-error *ngIf="control.errors?.minlength">{{ sysText.invalidMinLengthError | capitalizefirst }} {{ control.errors.minlength.requiredLength }}</mat-error>
      <mat-error *ngIf="control.errors?.pattern">{{ patternError }}</mat-error>
    </mat-form-field>
    <mat-icon *ngIf="info" [matTooltip]="info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
  </div>
</ng-template>
