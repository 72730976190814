<div class="spinner-container">
  <mat-progress-spinner
    diameter="85"
    value="100"
    color="accent"
    class="progress-spinner-bottom--purple"
    [ngClass]="{ 'is-complete': isComplete }"
  ></mat-progress-spinner>
  <p class="top__with-space" *ngIf="showPercentage">{{ isComplete ? completePercent : (progress | number: '1.0-0') }}%</p>

  <div class="top" *ngIf="!isComplete">
    <mat-progress-spinner diameter="85" [value]="progress" color="accent" class="progress-spinner-top"></mat-progress-spinner>
  </div>
</div>
