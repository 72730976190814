<ng-container [formGroup]="group" *ngIf="group">
  <ng-container *ngTemplateOutlet="textArea"></ng-container>
</ng-container>

<ng-container *ngIf="!group">
  <ng-container *ngTemplateOutlet="textArea"></ng-container>
</ng-container>

<ng-template #textArea>
  <div class="container">
    <mat-icon [ngClass]="{ focus: input.focused }" *ngIf="!hideIconSpace">{{ icon }}</mat-icon>
    <mat-form-field [appearance]="isOutline ? 'outline' : ''" color="accent">
      <mat-label>{{ shouldCapitalizeLabel ? (label | titlecase) : label }}</mat-label>
      <mat-hint [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }">{{ hint }}</mat-hint>
      <mat-error>{{ control.errors?.msError == msErrorKey ? customMsError : control.errors?.msError }}</mat-error>
      <textarea
        matInput
        #input="matInput"
        [formControl]="control"
        [rows]="rows || 1"
        [cdkTextareaAutosize]="shouldAutoResize">
      </textarea>
    </mat-form-field>
    <mat-icon *ngIf="info" [matTooltip]="info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
  </div>
</ng-template>
