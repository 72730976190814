import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-login-page-alert',
  templateUrl: './login-page-alert.component.html',
  styleUrls: ['./login-page-alert.component.scss']
})
export class LoginPageAlertComponent extends DialogDragConstraints implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private dialogRef: MatDialogRef<LoginPageAlertComponent>,
    ) {
    super(dialogRef);
  }

  ngOnInit() {}

}
