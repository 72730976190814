import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { CaseCommunicationComponent } from './case-communication.component';
import { CaseCommunicationRoutingModule } from './case-communication-routing.module';
import { EmailTemplatesDashboardComponent } from './pages/email-templates-dashboard/email-templates-dashboard.component';
import { EmailTemplatesTableComponent } from './components/email-templates-table/email-templates-table.component';
import { CaseCommunicationDrawerComponent } from './components/case-communication-drawer/case-communication-drawer.component';
import { AddRecipientsComponent } from './components/add-recipients/add-recipients.component';
import { TemplateDetailsComponent } from './pages/template-details/template-details.component';
import { TemplateInformationComponent } from './components/template-information/template-information.component';
import { TemplateAssignedEmployersComponent } from './components/template-assigned-employers/template-assigned-employers.component';
import { TemplateEmailContentComponent } from './components/template-email-content/template-email-content.component';
import { TemplateAttachedDocumentsComponent } from './components/template-attached-documents/template-attached-documents.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { EmailSubjectEditorComponent } from './components/email-subject-editor/email-subject-editor.component';
import { EmailBodyEditorComponent } from './components/email-body-editor/email-body-editor.component';
import { DocumentCategoriesTabComponent } from './components/document-categories-tab/document-categories-tab.component';

@NgModule({
  declarations: [
    CaseCommunicationComponent,
    EmailTemplatesDashboardComponent,
    EmailTemplatesTableComponent,
    CaseCommunicationDrawerComponent,
    AddRecipientsComponent,
    TemplateDetailsComponent,
    TemplateInformationComponent,
    TemplateAssignedEmployersComponent,
    TemplateEmailContentComponent,
    TemplateAttachedDocumentsComponent,
    EmailSubjectEditorComponent,
    EmailBodyEditorComponent,
    DocumentCategoriesTabComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    CaseCommunicationRoutingModule,
    EditorModule
  ],
  exports: [CaseCommunicationDrawerComponent, AddRecipientsComponent, EmailSubjectEditorComponent, EmailBodyEditorComponent],
  providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}]
})
export class CaseCommunicationModule { }
