import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseDetails, LeaveInfoDialog, LeaveInfo, PostLeaveInfo, StiiraError, PostLeaveInfoForm, LeaveTypeOption } from '@core/models';
import { ErrorService, LayoutService } from '@core/services';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { LeaveStages } from '@core/enums/leave-stages.enum';
import { UpdateConfirmationComponent } from '../update-confirmation/update-confirmation.component';
import { IneligibilityReasons, LeaveEligibilities } from '@core/enums/leave-eligibilities.enum';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-leave-info-dialog',
  templateUrl: './leave-info-dialog.component.html',
  styleUrls: ['./leave-info-dialog.component.scss'],
})
export class LeaveInfoDialogComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup<PostLeaveInfoForm>;
  public categoryHint: string = null;
  public intermittentFreqHint: string = null;
  public isAnonymous: boolean = false;
  public leaveStages = LeaveStages;
  
  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private leaveInfo: LeaveInfo;
  private destroy$: Subject<void> = new Subject<void>();

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get caseId(): FormControl {
    return this.form.get('caseId') as FormControl;
  }  

  get stage(): FormControl {
    return this.form.get('stage') as FormControl;
  }  

  get dateReceived(): FormControl {
    return this.form.get('dateReceived') as FormControl;
  }  

  get requestedStartDate(): FormControl {
    return this.form.get('requestedStartDate') as FormControl;
  }  

  get requestedEndDate(): FormControl {
    return this.form.get('requestedEndDate') as FormControl;
  }  

  get leaveReason(): FormControl {
    return this.form.get('leaveReason') as FormControl;
  }  
  
  get twelveMonthsHours(): FormControl {
    return this.form.get('twelveMonthsHours') as FormControl;
  }

  get twelveMonthsDate(): FormControl {
    return this.form.get('twelveMonthsDate') as FormControl;
  }

  get twelveMonthsComments(): FormControl {
    return this.form.get('twelveMonthsComments') as FormControl;
  }

  get averageWeeklyHours(): FormControl {
    return this.form.get('averageWeeklyHours') as FormControl;
  }

  get averageWeeklyHoursDate(): FormControl {
    return this.form.get('averageWeeklyHoursDate') as FormControl;
  }

  get averageWeeklyHoursComments(): FormControl {
    return this.form.get('averageWeeklyHoursComments') as FormControl;
  }

  get startDate(): FormControl {
    return this.form.get('startDate') as FormControl;
  }

  get leaveType(): FormControl {
    return this.form.get('leaveType') as FormControl;
  } 

  get leaveSubtype(): FormControl {
    return this.form.get('leaveSubtype') as FormControl;
  }

  get endDate(): FormControl {
    return this.form.get('endDate') as FormControl;
  }  

  get leaveCategory(): FormControl {
    return this.form.get('leaveCategory') as FormControl;
  } 

  get intermittentFreq(): FormControl {
    return this.form.get('intermittentFreq') as FormControl;
  }  

  get relatedCases(): FormControl {
    return this.form.get('relatedCases') as FormControl;
  }  

  get eligibility(): FormControl {
    return this.form.get('eligibility') as FormControl;
  }  

  get ineligibilityReasonId(): FormControl {
    return this.form.get('ineligibilityReasonId') as FormControl;
  }  

  get ineligibilityReasonComments(): FormControl {
    return this.form.get('ineligibilityReasonComments') as FormControl;
  }  

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      caseData: CaseDetails;
      sysText: any;
      updateConfSysText: any
      options: LeaveInfoDialog;
      fieldNotifications: { [key: string]: string };
    },
    private fb: FormBuilder,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private leaveInfoDialogRef: MatDialogRef<LeaveInfoDialogComponent>,
    private leaveService: LeaveAdminService,
    private store: LeaveAdminStoreService,
    private layoutService: LayoutService,
  ) { 
    super(leaveInfoDialogRef);
    
    this.form = this.fb.group<PostLeaveInfoForm>({
      caseId: this.fb.control(null),
      stage: this.fb.control(null), 
      dateReceived: this.fb.control(null),
      requestedStartDate: this.fb.control(null),
      requestedEndDate: this.fb.control(null),
      leaveReason: this.fb.control(null),

      twelveMonthsHours: this.fb.control(null),
      twelveMonthsDate: this.fb.control(null),
      twelveMonthsComments: this.fb.control(null),
      averageWeeklyHours: this.fb.control(null),
      averageWeeklyHoursDate: this.fb.control(null),
      averageWeeklyHoursComments: this.fb.control(null),

      startDate: this.fb.control(null),
      leaveType: this.fb.control(null),
      leaveSubtype: this.fb.control(null),
      endDate: this.fb.control(null),
      leaveCategory: this.fb.control(null),
      intermittentFreq: this.fb.control(null),
      relatedCases: this.fb.control(null),
      eligibility: this.fb.control(null),
      ineligibilityReasonId: this.fb.control(null),
      ineligibilityReasonComments: this.fb.control(null)
    });
  }
  
  ngOnInit(): void {
    this.leaveInfo = this.data.caseData.leaveInformation;
    this.setupForm();
    
    this.isAnonymous = this.data.caseData.employeeInformation.isAnonymous;

    this.patchRelatedCases();

    this.leaveType.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((typeId: number)=>{
        this.handleLeaveTypeChange(typeId);
      });

    this.eligibility.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        this.handleEligibilityChange();
      });

    this.ineligibilityReasonId.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        this.handleIneligibilityReasonChange();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setupForm() {
    this.form.patchValue({
      ...this.leaveInfo,
      caseId: this.leaveInfo?.caseId,
      stage: this.currentStageName(),
      dateReceived: this.leaveInfo?.dateReceived,
      requestedStartDate: this.leaveInfo?.requestedStartDate,
      requestedEndDate: this.leaveInfo?.requestedEndDate,
      startDate: this.leaveInfo?.startDate,
      endDate: this.leaveInfo?.endDate,
      leaveReason: <string>this.leaveInfo?.leaveReason?.id,

      twelveMonthsHours: this.leaveInfo?.twelveMonthsHours,
      twelveMonthsDate: this.leaveInfo?.twelveMonthsDate,
      twelveMonthsComments: this.leaveInfo?.twelveMonthsComments,
      averageWeeklyHours: this.leaveInfo?.averageWeeklyHours,
      averageWeeklyHoursDate: this.leaveInfo?.averageWeeklyHoursDate,
      averageWeeklyHoursComments: this.leaveInfo?.averageWeeklyHoursComments,

      intermittentFreq: this.leaveInfo?.intermittentFreq,
      eligibility: <string>this.leaveInfo?.eligibility?.id,
      relatedCases: this.leaveInfo?.relatedCases.map(({ caseId }) => caseId),
      leaveType: <string>this.leaveInfo?.leaveType?.id,
      leaveSubtype: this.leaveInfo.leaveSubtype,
      leaveCategory: this.leaveInfo?.category?.description,
      ineligibilityReasonId: <number>this.leaveInfo?.ineligibilityReason?.id,
      ineligibilityReasonComments: this.leaveInfo?.ineligibilityReasonComments
    });
    
    this.caseId.disable();
    this.stage.disable();
    this.leaveCategory.disable();
    
    if (this.data.options.relatedCases.length === 0) {
      this.relatedCases.disable();
    }
    
    if (this.leaveInfo.leaveType) {
      const isIntermittent = this.data.options.leaveTypes.filter(lt => lt.id === this.leaveInfo.leaveType.id)[0].isIntermittent;
      this.setIntermittentFreqField(isIntermittent);
    } else {
      this.leaveSubtype.disable();
    }

    if (this.leaveInfo.eligibility?.id !== LeaveEligibilities.Ineligible) {
      this.ineligibilityReasonId.disable();
      this.ineligibilityReasonComments.disable();
    }

    if (this.leaveInfo.eligibility?.id === LeaveEligibilities.Ineligible) {
      this.ineligibilityReasonId.setValidators(Validators.required);
    }

    if (this.leaveInfo.ineligibilityReason?.id === IneligibilityReasons.Other) {
      this.ineligibilityReasonComments.setValidators(Validators.required);
    }
    
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.caseData.missingDetails)
      this.formInitValues = { ...this.form.value }
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  private currentStageName(): string {
    var stageName = this.data.caseData.stages != null 
      ? this.data.caseData.stages
        .filter(s => s.stageId === this.leaveInfo?.currentStageId)[0].stageName 
      : null;

    return stageName;
  }

  public onSubmit(overrideUpdateConf: boolean = false): void {
    const dto: PostLeaveInfo = {
      overrideUpdateConf: overrideUpdateConf,
      caseId: this.caseId.value,
      dateReceived: this.dateReceived.value,
      requestedStartDate: this.requestedStartDate.value,
      requestedEndDate: this.requestedEndDate.value,
      startDate: this.startDate.value,
      endDate: this.endDate.value,
      leaveReasonId: this.leaveReason.value,

      twelveMonthsHours: this.twelveMonthsHours.value,
      twelveMonthsDate: this.twelveMonthsDate.value,
      twelveMonthsComments: this.twelveMonthsComments.value,
      averageWeeklyHours: this.averageWeeklyHours.value,
      averageWeeklyHoursDate: this.averageWeeklyHoursDate.value,
      averageWeeklyHoursComments: this.averageWeeklyHoursComments.value,

      eligibilityId: this.eligibility.value,
      leaveTypeId: this.leaveType.value,
      leaveSubtype: this.leaveSubtype.value,
      ineligibilityReasonId: this.ineligibilityReasonId.value,
      intermittentFreq: this.intermittentFreq.value,
      relatedCases: this.form.value.relatedCases,
      ineligibilityReasonComments: this.ineligibilityReasonComments.value
    }
    this.leaveService.postLeaveInfo(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        if (res.showUpdateConfirmationDialog) {
          this.openUpdateConfirmationDialog(res as UpdateConfirmationDialog);
        } else {
          this.store.caseDetails = res as CaseDetails;
          this.leaveInfoDialogRef.close();
        }
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
      );
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysText.unsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.leaveInfoDialogRef.close();
        }
      });
  }

  public close(): void {
    if (this.noChanges) {
      this.leaveInfoDialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private patchRelatedCases() {
    let relatedCases: number[] = [];
    this.leaveInfo?.relatedCases?.forEach(rc => {
      relatedCases.push(rc.caseId)
    })
    this.form.controls.relatedCases.patchValue(relatedCases)
  }

  private setIntermittentFreqField(isIntermittent: boolean): void {
    if (!isIntermittent) {
      this.intermittentFreq.disable();
      this.intermittentFreq.reset();
      this.intermittentFreqHint = this.data.sysText.caseLeaveInfoDialog.notApplicable;
    } else {
      this.intermittentFreq.enable();
      this.intermittentFreqHint = null;
    }
  }

  private handleLeaveTypeChange(typeId: number): void {
    if (!typeId) {
      this.leaveCategory.setValue(null);
      this.leaveSubtype.setValue(null);
      this.leaveSubtype.disable();
    } else {
      this.leaveSubtype.enable();
      const leaveType = this.data.options.leaveTypes.filter(lt => lt.id === typeId)[0];
      const catName = this.data.options.leaveCategories.filter(lc => lc.id === leaveType.categoryId)[0].description;
      this.leaveCategory.setValue(catName);
      this.setIntermittentFreqField(leaveType.isIntermittent);
      if (this.leaveInfo.category != null && leaveType.categoryId != this.leaveInfo.category?.id) {
        const hintText =  this.data.sysText.caseLeaveInfoDialog.categoryHint;
        const capHint = hintText[0].toUpperCase() + hintText.slice(1);
        this.categoryHint = capHint.replace('@[category]', catName);
      } else {
        this.categoryHint = null;
      }
    }
  }

  private handleEligibilityChange(): void {
    if (this.eligibility.value !== LeaveEligibilities.Ineligible) {
      this.ineligibilityReasonId.removeValidators(Validators.required)
      this.ineligibilityReasonComments.removeValidators(Validators.required)
      this.ineligibilityReasonId.setValue(null);
      this.ineligibilityReasonComments.setValue(null);
      this.ineligibilityReasonId.disable();
      this.ineligibilityReasonComments.disable();
    }
    else {
      this.ineligibilityReasonId.enable();
      this.ineligibilityReasonComments.enable();
      this.ineligibilityReasonId.setValidators(Validators.required);
      this.handleIneligibilityReasonChange();
    }
    this.form.updateValueAndValidity();
    this.form.markAsTouched();
  }

  private handleIneligibilityReasonChange(): void {
      if (this.ineligibilityReasonId.value == IneligibilityReasons.Other) {
        this.ineligibilityReasonComments.setValidators(Validators.required);
      }
      else {
        this.ineligibilityReasonComments.removeValidators(Validators.required);
      }
      this.form.updateValueAndValidity();
      this.form.markAsTouched();
  }

  private openUpdateConfirmationDialog(updateConfirmationDialog: UpdateConfirmationDialog): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: "mat-dialog-container-mobileWidth",
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.updateConfSysText,
        updateConfirmationDialog: updateConfirmationDialog
      }
    };

    this.dialog.open(UpdateConfirmationComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.onSubmit(true);
        }
      });
  }
}