import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StiiraError } from '@core/models';
import { AnonymousNewRequestPost } from '@core/models/leave-admin/anonymous-request/anonymous-new-request-post.model';
import { AnonymousNewRequestPage } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { accountRoutePaths } from '@core/routes/route-paths.constants';
import { ErrorService, LayoutService, SnackbarService } from '@core/services';
import { AnonymousRequestsStoreService } from '@core/services/anonymous-requests-store.service';
import { AnonymousRequestsService } from '@core/services/anonymous-requests.service';
import { indicate, nameof } from '@shared/helpers';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent implements OnInit {
  @Input() sysText: any;
  @Input() anonymousRequestInfo: AnonymousNewRequestPage;
  @ViewChild('imgFileInput') fileInput: ElementRef;

  public isSubmitting$ = new BehaviorSubject<boolean>(false);
  public form: UntypedFormGroup;
  public fileToUpload: File;

  get isHandheld$(): Observable<boolean> {
    return this.layoutService.isHandheld$();
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private errorService: ErrorService,
    private service: AnonymousRequestsService,
    private snackbarService: SnackbarService,
    private store: AnonymousRequestsStoreService,
    private layoutService: LayoutService
  ) { 
    this.form = this.fb.group({
      firstName: [null, Validators.required],
      middleName: [null],
      lastName: [null, Validators.required],
      suffix: [null],
      company: [null],
      workPhone: [null],
      personalPhone: [null],
      workEmail: [null],
      isWorkEmailPreferred: [null],
      personalEmail: [null],
      isPersonalEmailPreferred: [null],
      startDate: [null, Validators.required],
      endDate: [null],
      leaveReasonId: [null, Validators.required],
      comments: [null],
      documentTitle: [null],
      documentCategoryId: [null],
      documentComments: [null]
    });
  }

  ngOnInit(): void {
    this.form.controls.company.patchValue(this.anonymousRequestInfo.companyName);
    this.form.controls.company.disable();
  }

  public backToLogin() {
    this.router.navigate([accountRoutePaths.LOGIN]);
  }

  public onSubmit(): void {
    const dto: AnonymousNewRequestPost = {
      firstName: this.form.controls.firstName.value,
      middleName: this.form.controls.middleName.value,
      lastName: this.form.controls.lastName.value,
      suffix: this.form.controls.suffix.value,
      workPhone: this.form.controls.workPhone.value,
      personalPhone: this.form.controls.personalPhone.value,
      workEmail: this.form.controls.workEmail.value,
      personalEmail: this.form.controls.personalEmail.value,
      primaryEmailIsPersonal: this.form.controls.isWorkEmailPreferred.value ? false : this.form.controls.isPersonalEmailPreferred.value ? true : null,
      startDate: this.form.controls.startDate.value,
      endDate: this.form.controls.endDate.value,
      leaveReasonId: this.form.controls.leaveReasonId.value,
      comments: this.form.controls.comments.value,
      documentFile: this.fileToUpload ? this.fileToUpload : null,
      documentTitle: this.fileToUpload ? this.form.controls.documentTitle.value : null,
      documentCategoryId: this.fileToUpload ? this.form.controls.documentCategoryId.value : null,
      documentComments: this.fileToUpload ? this.form.controls.documentComments.value : null,
    };

    // convert dto into formData to send to backend
    const formData = this.makeSubmitNewRequestPostFormData(dto);

    this.service.postAnonymousNewRequest(formData)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(()=>{
        this.snackbarService.open(this.sysText.anonymousNewRequest.submissionSuccess, this.sysText.anonymousNewRequest.dismiss);
        this.store.setHasSubmitted = true;
        this.router.navigate([accountRoutePaths.LOGIN]);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public clearFile(): void {
    this.fileToUpload = null;
    this.form.patchValue({
      documentTitle: null,
      documentCategoryId: null,
    });
    this.fileInput.nativeElement.value = "";
    this.form.controls.documentTitle.setValidators(null);
    this.form.controls.documentCategoryId.setValidators(null);
    this.form.updateValueAndValidity();
  }

  public handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.form.controls.documentTitle.setValidators(Validators.required);
    this.form.controls.documentCategoryId.setValidators(Validators.required);
    this.form.updateValueAndValidity();
  }

  public updateEndDate(): void {
    //setValue on endDate when selecting a startDate clears model state errors which was preventing resubmitting of form 
    const endDate = this.form.controls.endDate.value;
    if (endDate != null){
      this.form.patchValue({endDate: endDate});
      this.form.updateValueAndValidity();
    }
  }

  private makeSubmitNewRequestPostFormData(dto: AnonymousNewRequestPost): FormData {
    const formData = new FormData();
    formData.append(nameof<AnonymousNewRequestPost>('firstName'), dto.firstName);
    formData.append(nameof<AnonymousNewRequestPost>('middleName'), dto.middleName ?? null);
    formData.append(nameof<AnonymousNewRequestPost>('lastName'), dto.lastName);
    formData.append(nameof<AnonymousNewRequestPost>('suffix'), dto.suffix ?? null);
    formData.append(nameof<AnonymousNewRequestPost>('workPhone'), dto.workPhone);
    formData.append(nameof<AnonymousNewRequestPost>('workEmail'), dto.workEmail);
    formData.append(nameof<AnonymousNewRequestPost>('primaryEmailIsPersonal'), dto.primaryEmailIsPersonal?.toString() ?? null);
    formData.append(nameof<AnonymousNewRequestPost>('personalPhone'), dto.personalPhone);
    formData.append(nameof<AnonymousNewRequestPost>('personalEmail'),dto.personalEmail);
    formData.append(nameof<AnonymousNewRequestPost>('startDate'), dto.startDate ? dto.startDate.toISOString() : null);
    formData.append(nameof<AnonymousNewRequestPost>('endDate'),  dto.endDate? dto.endDate.toISOString() : null);
    formData.append(nameof<AnonymousNewRequestPost>('leaveReasonId'), dto.leaveReasonId ? dto.leaveReasonId.toString() : null);
    formData.append(nameof<AnonymousNewRequestPost>('comments'), dto.comments);
    formData.append(nameof<AnonymousNewRequestPost>('documentFile'), dto.documentFile);
    formData.append(nameof<AnonymousNewRequestPost>('documentTitle'), dto.documentTitle);
    formData.append(nameof<AnonymousNewRequestPost>('documentCategoryId'), dto.documentCategoryId ? dto.documentCategoryId.toString() : null);
    formData.append(nameof<AnonymousNewRequestPost>('documentComments'), dto.documentComments);

    return formData;
  }
}
