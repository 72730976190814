import { Injectable } from '@angular/core';
import {
  FORGOTPASSWORD_OPTIONS,
  IDLETIMERCONFIG,
  LOGIN_OPTIONS,
  LOWERCASE_ASCII_RANGES,
  NUMBER_ASCII_RANGES,
  PASSWORD_REQS,
  RESETPASSWORD_OPTIONS,
  SETPASSWORD_OPTIONS,
  SPECIALCHAR_ASCII_RANGES,
  UPPERCASE_ASCII_RANGES,
  SELECTED_PROVIDERS, 
  VFORM_LOGIN_OPTIONS,
  VFORM_RESETPASSWORD_OPTIONS,  
  PAGINATOR_OPTIONS,
  APP_COLORS_THEME,
  APP_COLORS_FILLS,
  TINY_EDITOR_CHIP,
  TINY_EDITOR_STYLES,
  VISIBILITY_ICONS
} from '@core/constants';
import { AUTH_HEADER, TOKEN_NAME } from '@core/constants/auth.constants';
import { STATUS_CHIPS_STYLES } from '@core/constants/status-chip-colors.constants';
import { AccountFormOptions, IdleTimerConfig, PasswordRequirement, SelectedProviders, VerificationFormOptions  } from '@core/models';
import { PaginatorOptions } from '@core/models/leave-admin/reports/paginator-options.model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  public readonly ACCOUNT_API: string = `${environment.gatewayApi}/api/accounts`;
  public readonly LANGUAGE_API: string = `${environment.gatewayApi}/api/language`;
  public readonly EEPORTAL_API: string = `${environment.gatewayApi}/api/employeeportal`;
  public readonly WIDGETS_API: string = `${environment.gatewayApi}/api/widgets`;
  public readonly PROFILE_API: string = `${environment.gatewayApi}/api/myprofile`;
  public readonly MYDOCUMENTS_API: string = `${environment.gatewayApi}/api/mydocuments`;
  public readonly HELPANDFEEDBACK_API: string = `${environment.gatewayApi}/api/help`;

  public readonly FREQUENCY_API: string = `${environment.gatewayApi}/api/contributionfrequency`;
  public readonly RA_API: string = `${environment.gatewayApi}/api/roleadmin`;
  public readonly LA_API: string = `${environment.gatewayApi}/api/leaveadmin`;
  public readonly MER_API: string = `${environment.gatewayApi}/api/manageemployers`;
  public readonly MEE_API: string = `${environment.gatewayApi}/api/manageemployees`;
  public readonly RE_API: string = `${environment.gatewayApi}/api/reports`;
  public readonly AR_API: string = `${environment.gatewayApi}/api/anonymousrequests`;
  public readonly CC_API: string = `${environment.gatewayApi}/api/casecommunication`;
  public readonly WS_API: string = `${environment.gatewayApi}/api/workschedules`;

  public readonly APP_COLORS_THEME = APP_COLORS_THEME;
  public readonly APP_COLORS_FILLS = APP_COLORS_FILLS;

  public readonly ACCOUNTFORM_OPTIONS_LOGIN: AccountFormOptions = LOGIN_OPTIONS;
  public readonly ACCOUNTFORM_OPTIONS_FORGOTPASSWORD: AccountFormOptions = FORGOTPASSWORD_OPTIONS;
  public readonly ACCOUNTFORM_OPTIONS_RESETPASSWORD: AccountFormOptions = RESETPASSWORD_OPTIONS;
  public readonly ACCOUNTFORM_OPTIONS_SETPASSWORD: AccountFormOptions = SETPASSWORD_OPTIONS;
  public readonly PASSWORDREQUIREMENTS: PasswordRequirement[] = PASSWORD_REQS;

  public readonly VERIFICATIONFORM_OPTIONS_LOGIN: VerificationFormOptions = VFORM_LOGIN_OPTIONS;
  public readonly VERIFICATIONFORM_OPTIONS_RESETPASSWORD: VerificationFormOptions = VFORM_RESETPASSWORD_OPTIONS;

  public readonly SELECTED_PROVIDERS:  SelectedProviders = SELECTED_PROVIDERS;

  public readonly PAGINATOR_OPTIONS: PaginatorOptions = PAGINATOR_OPTIONS;

  public readonly LOWERCASE_ASCII_RANGES = LOWERCASE_ASCII_RANGES;
  public readonly UPPERCASE_ASCII_RANGES = UPPERCASE_ASCII_RANGES;
  public readonly NUMBER_ASCII_RANGES = NUMBER_ASCII_RANGES;
  public readonly SPECIALCHAR_ASCII_RANGES = SPECIALCHAR_ASCII_RANGES;

  public readonly STAT_CHIP_STYLES = STATUS_CHIPS_STYLES;

  public readonly IDLETIMERCONFIG: IdleTimerConfig = IDLETIMERCONFIG;
  public readonly AUTH_HEADER: string = AUTH_HEADER;
  public readonly TOKEN_NAME: string = TOKEN_NAME;

  public readonly EDITOR_STYLES: string = TINY_EDITOR_STYLES;
  public readonly EDITOR_CHIP: string = TINY_EDITOR_CHIP;

  public readonly VISIBILITY_ICONS: string[] = VISIBILITY_ICONS;

  constructor() {}
}
