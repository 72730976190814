import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { ErrorService } from '@core/services';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { finalize, takeUntil } from 'rxjs/operators';
import { StiiraError } from '@core/models';
import { TemplateDetailsStoreService } from '@core/services/template-details-store.service';
import { TemplateAssignedEmployersDialog, TemplateAssignedEmployersDialogPost } from '@core/models/case-communication/template-assigned-employers-dialog.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-template-assigned-employers',
  templateUrl: './edit-template-assigned-employers.component.html',
  styleUrls: ['./edit-template-assigned-employers.component.scss']
})
export class EditTemplateAssignedEmployersComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  
  public form: UntypedFormGroup;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    let curValues = JSON.stringify(this.form.value, noChangesReplacer);
    let initValues = JSON.stringify(this.formInitValues, noChangesReplacer);
    return curValues === initValues;
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      templateDetails: TemplateDetails; 
      options: TemplateAssignedEmployersDialog;
      sysText: any; 
      unsavedChangesSysText: any
    },
    private dialogRef: MatDialogRef<EditTemplateAssignedEmployersComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private caseCommunicationService: CaseCommunicationService,
    private store: TemplateDetailsStoreService,
    private errorService: ErrorService
    ) { 
    super(dialogRef);
    
    this.isSaving$ = new Subject();
    this.form = this.fb.group({
      employers: [null]
    })
  }

  ngOnInit(): void {
    this.form.patchValue({
      employers: this.data.templateDetails.employers.map(er => er.id)
    });
    this.formInitValues = { ...this.form.value };
    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.templateDetails.missingDetails)
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public save(): void {
    this.dialogRef.disableClose = true;
    const dto: TemplateAssignedEmployersDialogPost = { 
      templateId: this.data.templateDetails.id,
      employers: this.form.controls.employers.value 
    }
    this.caseCommunicationService.postTemplateAssignedEmployers(dto)
      .pipe(
        indicate(this.isSaving$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe((res) => {
        this.store.templateDetails = res as TemplateDetails;
        this.dialogRef.close(res);
      }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }
}
